import { Injectable } from "@angular/core";
import { HqtMainForm } from "../../models/form/hqt-main-form.model";
import { Hqt } from "../../models/hqt.model";

@Injectable({ providedIn: "root" })
export class HqtHelper {

  constructor() {}

  toHqtModel(hqtMainFormValues: HqtMainForm, forUpdate: boolean, original?: Hqt): Hqt {

    return {
      id: forUpdate ? original.id : null,
      createdBy: forUpdate ? original.createdBy : null,
      modifiedBy: forUpdate ? original.modifiedBy : null,
      createdDate: forUpdate ? original.createdDate : null,
      modifiedDate: forUpdate ? original.modifiedDate : null,
      status: hqtMainFormValues.status,
      name: hqtMainFormValues.name,
      email: hqtMainFormValues.email,
    };

  }

  toHqtMainFormValues(hqt: Hqt): HqtMainForm {

    return {
      name: hqt.name,
      email: hqt.email,
      status: hqt.status,
    };

  }

}
