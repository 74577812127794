import { Injectable } from "@angular/core";

@Injectable({ providedIn: 'root' })
export class NumberHelper {

    constructor() { }

    getCentsValue(
        value: number
    ): number {
        let cents: string = String(value).split('.').slice(1)[0];
        return parseFloat(cents);
    }

    isNegativeNumber(
        value: number
    ): boolean {
        return value % 1 != 0;
    }

    hasCentsValue(
        value: number
    ): boolean {
        let cents: string = String(value).split('.').slice(1)[0];
        return cents !== undefined;
    }
}