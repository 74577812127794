import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Resource } from 'src/app/core/models/resource.model';
import { ResourceGroupForm } from 'src/app/core/models/form/resource-group-form-model';

@Component({
  selector: 'app-order-resource-details',
  templateUrl: './order-resource-details.component.html',
  styleUrls: ['./order-resource-details.component.scss']
})
export class OrderResourceDetailsComponent implements OnInit {

  @Input() selectedResource: Resource;
  @Input() groups: ResourceGroupForm[];
  @Output() getSelectedResource = new EventEmitter<any>();
  @Output() closeResourceDetailsModal = new EventEmitter<any>();

  constructor() { }

  ngOnInit() {
  }
  
  onClose(): void {
    this.closeResourceDetailsModal.emit(true);
  }
  
  onAddToCart(): void {
    this.getSelectedResource.emit(true);
  }

}
