import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { SAVE } from "src/app/core/constants/configuration/common.constant";
import { Subject } from 'rxjs';
import { CrudService } from 'src/app/core/services/data/crud.service';
import { getSchoolYearsEndpoint, getStatusEndpoint } from 'src/app/core/constants/endpoints.constant';
import { takeUntil } from 'rxjs/operators';
import { ErrorHandlerService } from 'src/app/core/services/util/error-handler.service';
import { FormUtil } from 'src/app/core/services/util/form.util';
import { LoaderMessagingService } from 'src/app/core/services/messaging/loader-messaging.service';
import { PageForm } from 'src/app/core/models/form/page-form.model';
import { sideNavLabelCurriculum } from 'src/app/core/constants/configuration/curriculum-constants.config';
import { AssetMainForm } from 'src/app/core/models/form/asset-main-form.model';
import { AssetHelper } from 'src/app/core/services/util/asset.helper';
import { Asset } from 'src/app/core/models/asset.model';

@Component({
  selector: 'asset-form',
  templateUrl: './asset-form.component.html',
  styleUrls: ['./asset-form.component.scss']
})
export class AssetFormComponent implements OnInit, OnDestroy {


	@Output() saveAsset: EventEmitter<Asset> = new EventEmitter();
	@Input() forUpdate: boolean;
	@Input() originalAsset: Asset;

	unsubscribe: Subject<any> = new Subject();
	modifiedAsset: Asset;
	assetFormValues: AssetMainForm;
	activeIndex: number = 0;
	sideNavLabels: string[] = [];
	isFirst:boolean = true;
	isLast:boolean = false;
	reference: any = {};
	schoolYears: any;
	hasInitialized: boolean = false;
	models: any;

	constructor(
		private errorHandlerService: ErrorHandlerService,
		private loaderMessagingService: LoaderMessagingService,
		private formUtil: FormUtil,
		private crudService: CrudService,
		private assetHelper : AssetHelper,
	) {
		this.loaderMessagingService.showPageLoader(true);
		this.sideNavLabels = sideNavLabelCurriculum;		
	}

	ngOnInit(): void {
		this.hasInitialized = false;
		if (this.forUpdate) {
			this.assetFormValues = this.assetHelper.setAssetMainFormValues(this.originalAsset);
			this.isFirst = true;
			this.isLast = true;
		}

		this.initParams();
	}

	ngOnDestroy(): void {
		this.unsubscribe.next();
		this.unsubscribe.complete();
	}

	private handleCompletion = (): void => {
		this.loaderMessagingService.showPageLoader(false);
	}

	initParams(): void {
		this.setReferenceSchoolYears();
	}

	get isLoadMainForm(): boolean {
		return (this.activeIndex === 0 && this.hasInitialized) && (!this.forUpdate || (this.forUpdate && this.assetFormValues !== null));
	}

	onChangePage(event: any) {
		let pageForm: PageForm = this.formUtil.setPageForm(event.label, this.sideNavLabels.length, this.activeIndex);

		this.activeIndex = pageForm.activeIndex;
		this.isFirst = pageForm.isFirst;
		this.isLast = pageForm.isLast;

		if (event.label === SAVE) {
			if(this.modifiedAsset.loanedTo){
				this.modifiedAsset.loanedTo = this.modifiedAsset.loanedTo.districtId;
			}
			this.saveAsset.emit(this.modifiedAsset);
		}
	}

	onFormSubmit($event: any): void {
		this.assetFormValues = Object.assign({}, $event.formValues);
		this.models = Object.assign({}, $event.model);
		this.setAsset();
	}

	private setAsset(): void {
		this.modifiedAsset = this.assetHelper.setAsset(this.assetFormValues, this.forUpdate, this.models, this.originalAsset);
	}


	
	// API CALLS
	
	private setReferenceSchoolYears(): void {

		this.crudService
			.getById<any>(getSchoolYearsEndpoint)
			.pipe(takeUntil(this.unsubscribe))
			.subscribe(response => {

				if (response) {
					this.reference.schoolYears = response;
					this.reference.schoolYears.sort((a, b) => a.schoolYear.localeCompare(b.schoolYear));
					this.setReferenceStatus();
				}

			}, this.errorHandlerService.handleError, this.handleCompletion);

	}

	private setReferenceStatus(): void {

		this.crudService
			.getById<any>(getStatusEndpoint)
			.pipe(takeUntil(this.unsubscribe))
			.subscribe(response => {

				if (response) {
					this.reference.status = response;
					this.reference.status.sort((a, b) => a.code.localeCompare(b.code));
					this.hasInitialized = true;
				}
				
			}, this.errorHandlerService.handleError, this.handleCompletion);

	}

}
