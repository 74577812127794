import { NgModule } from "@angular/core";
import { SharedModule } from "src/app/shared/shared.module";
import { SettingsComponent } from "./settings.component";
import { HqtListComponent } from "./hqt/hqt-list/hqt-list.component";
import { HqtDetailsDialogComponent } from "./hqt/hqt-details-dialog/hqt-details-dialog.component";
import { HqtCreateComponent } from "./hqt/hqt-create/hqt-create.component";
import { HqtUpdateComponent } from "./hqt/hqt-update/hqt-update.component";
import { ModelListComponent } from "./model/model-list/model-list.component";
import { UploadComponent } from "./upload/upload.component";
import { UploadBodyComponent } from "./upload/upload-body/upload-body.component";
import { UploadSidebarComponent } from "./upload/upload-sidebar/upload-sidebar.component";
import { SettingsRoutingModule } from "./route/settings-routing.module";
import { HqtViewComponent } from "./hqt/hqt-view/hqt-view.component";
import { ModelUpdateComponent } from "./model/model-update/model-update.component";
import { ModelViewComponent } from "./model/model-view/model-view.component";
import { ModelCreateComponent } from "./model/model-create/model-create.component";
import { AssetCreateComponent } from "./asset/asset-create/asset-create.component";
import { AssetListComponent } from "./asset/asset-list/asset-list.component";
import { AssetUpdateComponent } from "./asset/asset-update/asset-update.component";
import { AssetViewComponent } from "./asset/asset-view/asset-view.component";

@NgModule({
	imports: [
		SharedModule,
		SettingsRoutingModule,
	],
	declarations: [
		SettingsComponent,

		HqtCreateComponent,
		HqtListComponent,
		HqtDetailsDialogComponent,
		HqtUpdateComponent,
		HqtViewComponent,

		ModelCreateComponent,
		ModelListComponent,
		ModelUpdateComponent,
		ModelViewComponent,

		AssetCreateComponent,
		AssetListComponent,
		AssetUpdateComponent,
		AssetViewComponent,

		UploadComponent,
		UploadBodyComponent,
		UploadSidebarComponent,
	],
	entryComponents: [
		HqtDetailsDialogComponent,
	],
	exports: [
		SettingsComponent,

		HqtCreateComponent,
		HqtListComponent,
		HqtDetailsDialogComponent,
		HqtUpdateComponent,
		HqtViewComponent,

		ModelCreateComponent,
		ModelListComponent,
		ModelUpdateComponent,
		ModelViewComponent,

		AssetCreateComponent,
		AssetListComponent,
		AssetUpdateComponent,
		AssetViewComponent,

		UploadComponent,
		UploadBodyComponent,
		UploadSidebarComponent,
	],
})
export class SettingsModule {}
