import { Routes } from "@angular/router";

import { AuthGuard } from "src/app/core/guards/auth.guard";
import { HqtListComponent } from "../hqt/hqt-list/hqt-list.component";
import { HqtCreateComponent } from "../hqt/hqt-create/hqt-create.component";
import { HqtUpdateComponent } from "../hqt/hqt-update/hqt-update.component";
import { ModelListComponent } from "../model/model-list/model-list.component";
import { UploadComponent } from "../upload/upload.component";
import { 
    HQTS_PATH, 
    MODEL_PATH, 
    UPLOADS_PATH, 
    HQT_CREATE_PATH, 
    HQT_UPDATE_PATH, 
    MODEL_UPDATE_PATH,
    MODEL_CREATE_PATH,
    ASSET_PATH,
    ASSET_CREATE_PATH,
    ASSET_UPDATE_PATH
 } from "src/app/core/constants/routes.constant";
import { HqtViewComponent } from "../hqt/hqt-view/hqt-view.component";
import { ModelUpdateComponent } from "../model/model-update/model-update.component";
import { ModelViewComponent } from "../model/model-view/model-view.component";
import { ModelCreateComponent } from "../model/model-create/model-create.component";
import { AssetListComponent } from "../asset/asset-list/asset-list.component";
import { AssetCreateComponent } from "../asset/asset-create/asset-create.component";
import { AssetUpdateComponent } from "../asset/asset-update/asset-update.component";
import { AssetViewComponent } from "../asset/asset-view/asset-view.component";

export const settingsRoute: Routes = [

    {
        path: HQTS_PATH,
        component: HqtListComponent,
        canActivate: [AuthGuard],
    },
    {
        path: HQT_CREATE_PATH,
        component: HqtCreateComponent,
        canActivate: [AuthGuard],
    },
    {
        path: `${HQT_UPDATE_PATH}/:id`,
        component: HqtUpdateComponent,
        canActivate: [AuthGuard],
    },
    {
        path: `${HQTS_PATH}/:id`,
        component: HqtViewComponent,
        canActivate: [AuthGuard],
    },
    {
        path: MODEL_PATH,
        component: ModelListComponent,
        canActivate: [AuthGuard],
    },
    {
        path: MODEL_CREATE_PATH,
        component: ModelCreateComponent,
        canActivate: [AuthGuard],
    },
    {
        path: `${MODEL_UPDATE_PATH}/:id`,
        component: ModelUpdateComponent,
        canActivate: [AuthGuard],
    },
    {
        path: `${MODEL_PATH}/:id`,
        component: ModelViewComponent,
        canActivate: [AuthGuard],
    },
    {
        path: ASSET_PATH,
        component: AssetListComponent,
        canActivate: [AuthGuard],
    },
    {
        path: ASSET_CREATE_PATH,
        component: AssetCreateComponent,
        canActivate: [AuthGuard],
    },
    {
        path: `${ASSET_UPDATE_PATH}/:id`,
        component: AssetUpdateComponent,
        canActivate: [AuthGuard],
    },
    {
        path: `${ASSET_PATH}/:id`,
        component: AssetViewComponent,
        canActivate: [AuthGuard],
    },
    {
        path: UPLOADS_PATH,
        component: UploadComponent,
        canActivate: [AuthGuard],
    },
    

];
