import { Injectable } from '@angular/core';
import { Class } from '../../models/class.model';
import { Resource } from '../../models/resource.model';
import { ModelMainForm } from '../../models/form/model-main-form.model';
import { Asset } from '../../models/asset.model';
import { AssetMainForm } from '../../models/form/asset-main-form.model';
import { Model } from '../../models/model.model';

@Injectable({ providedIn: 'root' })
export class AssetHelper {

    asset: Asset;
    classes: Class[] = [];
    resources: Resource[] = [];

    constructor() { }

    setAsset(assetFormValues: AssetMainForm, forUpdate: boolean, model: Model, original?: any): Asset {
        return {
            id: forUpdate ? original.id : null,
            code: assetFormValues.code,
            name: assetFormValues.modelCodeName,
            loanedTo: forUpdate ? assetFormValues.loanedTo : null,
            status: assetFormValues ? assetFormValues.status : 'ASSET_STATUS_1',
            description:  assetFormValues.description,
            createdBy: forUpdate ? original.createdBy : null,
            modifiedBy: forUpdate ? original.modifiedBy : null,
            createdDate: forUpdate ? original.createdDate : null,
            modifiedDate: forUpdate ? original.modifiedDate : null,
            itemModel: model.id ? model : original.itemModel,
            itemId: model.id ? model.id : original.itemModel.id,
            scopeName: assetFormValues.scopeName,
        };

    }

    setAssetMainFormValues = (asset: any): AssetMainForm => {
        return {
            code: asset.code,
            modelCodeName: asset.itemModel.name + " (" + asset.itemModel.code + ")",
            status: asset.status,
            description: asset.description,
            loanedTo: asset.loanedTo,
            scopeName: asset.scopeName,
        }
    }

}
