import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { SAVE } from "src/app/core/constants/configuration/common.constant";
import { Subject } from 'rxjs';
import { CrudService } from 'src/app/core/services/data/crud.service';
import { getSchoolYearsEndpoint, getStatusEndpoint } from 'src/app/core/constants/endpoints.constant';
import { takeUntil } from 'rxjs/operators';
import { ErrorHandlerService } from 'src/app/core/services/util/error-handler.service';
import { FormUtil } from 'src/app/core/services/util/form.util';
import { LoaderMessagingService } from 'src/app/core/services/messaging/loader-messaging.service';
import { PageForm } from 'src/app/core/models/form/page-form.model';
import { sideNavLabelCurriculum } from 'src/app/core/constants/configuration/curriculum-constants.config';
import { ModelMainForm } from 'src/app/core/models/form/model-main-form.model';
import { ModelHelper } from 'src/app/core/services/util/model.helper';
import { Model } from 'src/app/core/models/model.model';

@Component({
  selector: 'model-form',
  templateUrl: './model-form.component.html',
  styleUrls: ['./model-form.component.scss']
})
export class ModelFormComponent implements OnInit, OnDestroy {


	@Output() saveModel: EventEmitter<Model> = new EventEmitter();
	@Input() forUpdate: boolean;
	@Input() originalModel: Model;

	unsubscribe: Subject<any> = new Subject();
	modifiedModel: Model;
	modelFormValues: ModelMainForm;
	activeIndex: number = 0;
	sideNavLabels: string[] = [];
	isFirst:boolean = true;
	isLast:boolean = false;
	reference: any = {};
	schoolYears: any;
	hasInitialized: boolean = false;

	constructor(
		private errorHandlerService: ErrorHandlerService,
		private loaderMessagingService: LoaderMessagingService,
		private formUtil: FormUtil,
		private crudService: CrudService,
		private modelHelper : ModelHelper,
	) {
		this.sideNavLabels = sideNavLabelCurriculum;		
	}

	ngOnInit(): void {

		this.hasInitialized = false;
		this.loaderMessagingService.showPageLoader(true);

		if (this.forUpdate) {
			this.modelFormValues = this.modelHelper.setModelMainFormValues(this.originalModel);
		}

		this.initParams();
	}

	ngOnDestroy(): void {
		this.unsubscribe.next();
		this.unsubscribe.complete();
	}

	private handleCompletion = (): void => {
		this.loaderMessagingService.showPageLoader(false);
	}

	initParams(): void {
		this.setReferenceSchoolYears();
	}

	get isLoadMainForm(): boolean {
		return (this.activeIndex === 0 && this.hasInitialized) && (!this.forUpdate || (this.forUpdate && this.modelFormValues !== null));
	}

	onChangePage(label: any) {

		let pageForm: PageForm = this.formUtil.setPageForm(label, this.sideNavLabels.length, this.activeIndex);

		this.activeIndex = pageForm.activeIndex;
		this.isFirst = pageForm.isFirst;
		this.isLast = pageForm.isLast;

		if (label === SAVE) {
			this.saveModel.emit(this.modifiedModel);
		}
	}

	onFormSubmit($event: any): void {
		this.modelFormValues = Object.assign({}, $event.formValues);
		this.setModel();
	}

	private setModel(): void {
		this.modifiedModel = this.modelHelper.setModel(this.modelFormValues, this.forUpdate, this.originalModel);
	}
	
	// API CALLS
	
	private setReferenceSchoolYears(): void {

		this.crudService
			.getById<any>(getSchoolYearsEndpoint)
			.pipe(takeUntil(this.unsubscribe))
			.subscribe(response => {

				if (response) {
					this.reference.schoolYears = response;
					this.reference.schoolYears.sort((a, b) => a.schoolYear.localeCompare(b.schoolYear));
					this.setReferenceStatus();
				}

			}, this.errorHandlerService.handleError);

	}

	private setReferenceStatus(): void {

		this.crudService
			.getById<any>(getStatusEndpoint)
			.pipe(takeUntil(this.unsubscribe))
			.subscribe(response => {

				if (response) {
					this.reference.status = response;
					this.reference.status.sort((a, b) => a.code.localeCompare(b.code));
					this.hasInitialized = true;
				}
				
			}, this.errorHandlerService.handleError, this.handleCompletion);

	}

}
