import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { NEXT } from 'src/app/core/constants/configuration/common.constant';
import { FormUtil } from 'src/app/core/services/util/form.util';
import { ModelMainForm } from 'src/app/core/models/form/model-main-form.model';
import { allModelsEndpoint, existsByCode } from 'src/app/core/constants/endpoints.constant';

@Component({
	selector: 'model-main-form',
	templateUrl: './model-main-form.component.html',
	styleUrls: ['./model-main-form.component.scss']
})
export class ModelMainFormComponent implements OnInit {

	@Output() changePage = new EventEmitter<any>();
	@Output() getFormValue = new EventEmitter<any>();
	@Input() isFirst: boolean;
	@Input() isLast: boolean;
	@Input() forUpdate: boolean;
	@Input() reference: any;
	@Input() modelFormValues: ModelMainForm;
	@Input() code: string;
	existsByCodeEndpoint: string = allModelsEndpoint.concat(existsByCode);

	consumableData: Array<any>;
	consumableVal: any;
	modelForm: FormGroup;
	minDate: string;
	isProcessing: boolean;
	delayTimer: NodeJS.Timer;
	timeout: number = 300;
	
	hasDeposit: boolean = false;

	constructor(
		private formsUtil: FormUtil,
	) {
		this.modelForm = this.formsUtil.createModelMainForm();
	}

	ngOnInit() {
		this.initParams();
	}

	private initParams = (): void => {
		this.minDate = new Date().toJSON().split('T')[0];
		if (this.forUpdate || this.modelFormValues) {
			this.initFormValues();
			this.modelForm.controls['consumable'].setValue(this.modelFormValues.consumable);
		}
	}

	private initFormValues = (): void => {
		this.modelForm.setValue(this.formsUtil.initModelMainForm(this.modelFormValues));
	}

	onClick = (label: string): void => {

		if (label === NEXT) {
			this.validateUntouchedForms();
			if (!this.modelForm.valid) {
				return;
			} else {
				this.changePage.emit(NEXT);

				this.getFormValue.emit({
					formValues: this.modelForm.value,
				});
				this.changePage.emit(label);
				this.modelForm.reset();
			}
		}
	}

	onToggleDeposit(): void {
		this.hasDeposit = this.modelForm.get("hasDeposit").value;
	}

	private validateUntouchedForms(): void {
		Object.keys(this.modelForm.controls).forEach(control => {
			this.modelForm.get(control).markAsTouched();
			this.modelForm.get(control).markAsDirty();
		});
	}

	onChangeNumber(type: string): void {
		const value: number = this.modelForm.controls[type].value;
		const max = 999999999;
			if (value === null){
				this.modelForm.controls[type].setValue("");
			} else if (value === -0) {
				this.modelForm.controls[type].setValue(0);
		} else if (value > max) {
			this.modelForm.controls[type].setValue(String(value).substring(0,9));
		} else if (value % 1 != 0 && String(value).split('.').slice(-1)[0].length > 2) {
			this.modelForm.controls[type].setValue(value.toFixed(2));
		}
	}

	onGetCodeResult(result: any): void {

		if (result.exists) {

			if ((this.modelFormValues && result.value !== this.modelFormValues.code && this.forUpdate) || !this.forUpdate) {
				this.modelForm.controls['code'].setErrors({'codeExists': true});
			} else {
				this.modelForm.controls['code'].updateValueAndValidity();
			}

		}

  }

}
