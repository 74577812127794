import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { CrudService } from 'src/app/core/services/data/crud.service';
import { getShippingStatesEndpoint } from 'src/app/core/constants/endpoints.constant';
import { takeUntil } from 'rxjs/operators';
import { ErrorHandlerService } from 'src/app/core/services/util/error-handler.service';
import { Subject } from 'rxjs';
import { ConfirmDialogComponent } from '../../../../shared/components/confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'app-order-shipping-dialog',
  templateUrl: './order-shipping-dialog.component.html',
  styleUrls: ['./order-shipping-dialog.component.scss']
})
export class OrderShippingDialogComponent implements OnInit {
  items: any[] = [];
  reference: any = {};
  selectedShippingDetail: any;
  shippingDetails: any;
  withConfirmation: boolean;
  canSave: boolean = false;
  unsubscribe: Subject<any> = new Subject();

  constructor(
    public dialogRef: MatDialogRef<OrderShippingDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialog: MatDialog,
    private crudService: CrudService,
    private errorHandlerService: ErrorHandlerService,
  ) { }

  ngOnInit() {
    this.getGuardianShippingDetails();
  }

  onClose() {
    this.items = [];
    this.dialogRef.close({selectedItem: null, items: this.shippingDetails});
  }

  onSelectShipping($event: any): void {
    this.canSave = true;
    this.selectedShippingDetail = $event.selectedItem;
    this.items = $event.items;
    if (!this.withConfirmation) {
      this.dialogRef.close({selectedItem: this.selectedShippingDetail, items: this.shippingDetails});
    }
  }

  onSave() {
    const dialogRef = this.dialog.open(ConfirmDialogComponent);
    dialogRef.componentInstance.confirmMessage = "Are you sure you want to change the shipping address?";
    dialogRef.afterClosed().subscribe((result) => {
      this.items = [];
      if (result) {
        this.dialogRef.close({selectedItem: this.selectedShippingDetail, items: this.shippingDetails});
      } else {
        this.dialogRef.close({selectedItem: null, items: this.shippingDetails});
      }
    });
  }

  getGuardianShippingDetails(): void {
    // this.crudService
    //   .getAllBy<GuardianShippingDetails>()
    //   .pipe(takeUntil(this.unsubscribe))
		// 	.subscribe(response => {
		// 		if (response) {
		// 			this.items = response;
		// 			this.setReferenceShippingStates();
		// 		}
    // 	}, this.errorHandlerService.handleError, this.handleCompletion);
    this.items = this.data.shippingDetails;
    this.withConfirmation = this.data.withConfirmation;
    this.setReferenceShippingStates();
  }

  private setReferenceShippingStates(): void {

		this.crudService
			.getById<any>(getShippingStatesEndpoint)
			.pipe(takeUntil(this.unsubscribe))
			.subscribe(response => {

				if (response) {
					this.reference.shippingStates = response;
					this.reference.shippingStates.sort((a, b) => a.name.localeCompare(b.name));
				}

			}, this.errorHandlerService.handleError);

  }

}
