import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { ErrorHandlerService } from 'src/app/core/services/util/error-handler.service';
import { LoaderMessagingService } from 'src/app/core/services/messaging/loader-messaging.service';
import { FormUtil } from 'src/app/core/services/util/form.util';
import { CrudService } from 'src/app/core/services/data/crud.service';
import { SAVE, STATUS_1, STATUS_2 } from 'src/app/core/constants/configuration/common.constant';
import { Subject } from 'rxjs';
import { Class } from 'src/app/core/models/class.model';
import { CurriculumClassForm } from 'src/app/core/models/form/curriculum-class-form.model';
import { getStatusEndpoint, getClassSubjectsEndpoint, getActivePeriodStatusEndpoint, allGradeLevelsEndpoint, allCurriculumsEndpoint, allHqtEndpoint } from 'src/app/core/constants/endpoints.constant';
import { takeUntil } from 'rxjs/operators';
import { ClassHelper } from 'src/app/core/services/util/class.helper';
import { PageForm } from 'src/app/core/models/form/page-form.model';
import { Curriculum } from 'src/app/core/models/curriculum.model';
import { Hqt } from 'src/app/core/models/hqt.model';
import { sideNavLabelClass } from 'src/app/core/constants/configuration/class-constants.config';

@Component({
	selector: 'class-form',
	templateUrl: './class-form.component.html',
	styleUrls: ['./class-form.component.scss']
})
export class ClassFormComponent implements OnInit, OnDestroy {

	@Output() saveClass: EventEmitter<Class> = new EventEmitter();
	@Input() forUpdate: boolean;
	@Input() originalClass: Class;

	unsubscribe: Subject<any> = new Subject();
	modifiedClass: Class;
	classFormValues: CurriculumClassForm;
	activeIndex: number = 0;
	sideNavLabels: string[] = [];
	isFirst: boolean = true;
	isLast: boolean = false;
	reference: any;
	referenceValues: any = {};
	hasInitialized: boolean = false;
	curriculum: any = {};
	hqt: any = null;

	constructor(
		private errorHandlerService: ErrorHandlerService,
		private loaderMessagingService: LoaderMessagingService,
		private formUtil: FormUtil,
		private crudService: CrudService,
		private classHelper: ClassHelper,
	) {
		this.sideNavLabels = sideNavLabelClass;
	}
	
	ngOnInit() {

		this.hasInitialized = false;
		this.loaderMessagingService.showPageLoader(true);

		if (this.forUpdate) {
			this.classFormValues = this.classHelper.setClassMainFormValues(this.originalClass);
			this.findCurriculum();
		} else {
			this.initParams();
		}

	}

	ngOnDestroy(): void {
		this.unsubscribe.next();
		this.unsubscribe.complete();
	}

	initParams(): void {
		this.setReferenceSubjects();
	}

	get isLoadMainForm(): boolean {
		return (this.activeIndex === 0 && this.hasInitialized) && (!this.forUpdate || (this.forUpdate && this.classFormValues !== null));
	}

	onChangePage(label: any) {

		let pageForm: PageForm = this.formUtil.setPageForm(label, this.sideNavLabels.length, this.activeIndex);

		this.activeIndex = pageForm.activeIndex;
		this.isFirst = pageForm.isFirst;
		this.isLast = pageForm.isLast;

		if (label === SAVE) {
			this.saveClass.emit(this.modifiedClass);
		}
	}

	onFormSubmit($event: any): void {
		this.classFormValues = Object.assign({}, $event.formValues);
		this.setReferenceValues($event.curriculum, $event.hqt);
		this.setClass();
	}

	private handleCompletion = (): void => {
		this.loaderMessagingService.showPageLoader(false);
	}

	private setReferenceValues(curriculum: any, hqt: any): void {

		const subject: string = this.reference.subjects.find(subj => this.classFormValues.subject === subj.code).name;
		const fromGradeLevel: string = this.reference.gradeLevels.find(level => this.classFormValues.fromGradeLevel === level.code).name;
		const toGradeLevel: string = this.reference.gradeLevels.find(level => this.classFormValues.toGradeLevel === level.code).name;
		const activePeriodType: string = this.reference.periodTypes.find(type => this.classFormValues.activePeriodType === type.code).name;
		const status: string = this.reference.status.find(status => this.classFormValues.status === status.code).name;

		this.curriculum = curriculum;
		this.hqt = hqt;

		this.referenceValues = { subject, fromGradeLevel, toGradeLevel, activePeriodType, status, curriculum, hqt };

	}

	private setClass(): void {
		this.classFormValues.curriculumCode = this.referenceValues.curriculum.code;
		if (this.referenceValues.hqt) {
			this.classFormValues.hqtUsername = this.referenceValues.hqt.email;
		}
		this.modifiedClass = this.classHelper.setClassModel(this.classFormValues, this.forUpdate, this.originalClass);
	}


	// API CALLS DEFINED BELOW

	private setReferenceSubjects(): void {

		this.crudService
			.getById<any>(getClassSubjectsEndpoint)
			.pipe(takeUntil(this.unsubscribe))
			.subscribe(response => {

				if (response) {
					this.reference = {};
					this.reference.subjects = response;
					this.reference.subjects.sort((a, b) => a.code.localeCompare(b.code));
					this.setReferenceGradeLevels();
				}

			}, this.errorHandlerService.handleError);

	}

	private setReferenceGradeLevels(): void {

		this.crudService
			.getById<any>(allGradeLevelsEndpoint)
			.pipe(takeUntil(this.unsubscribe))
			.subscribe(response => {

				if (response) {
					this.reference.gradeLevels = response;
					this.reference.gradeLevels.sort((a, b) => a.code.localeCompare(b.code));
					this.setReferencePeriodTypes();
				}

			}, this.errorHandlerService.handleError);

	}

	private setReferencePeriodTypes(): void {

		this.crudService
			.getById<any>(getActivePeriodStatusEndpoint)
			.pipe(takeUntil(this.unsubscribe))
			.subscribe(response => {

				if (response) {
					this.reference.periodTypes = response;
					this.reference.periodTypes.sort((a, b) => a.code.localeCompare(b.code));
					this.setReferenceStatus();
				}

			}, this.errorHandlerService.handleError);

	}

	private setReferenceStatus(): void {

		this.crudService
			.getById<any>(getStatusEndpoint)
			.pipe(takeUntil(this.unsubscribe))
			.subscribe(response => {

				if (response) {
					this.reference.status = response.filter(status => [STATUS_1, STATUS_2].includes(status.code));
					this.reference.status.sort((a, b) => a.code.localeCompare(b.code));
					this.hasInitialized = true;
				}
				
			}, this.errorHandlerService.handleError, this.handleCompletion);

	}

	private findCurriculum(): void {

		this.crudService
			.getAllBy<Curriculum>(`${allCurriculumsEndpoint}/get-by-code-or-name?keyword=${this.originalClass.classCurriculum.code}`)
			.pipe(takeUntil(this.unsubscribe))
			.subscribe(response => {

				if (response) {
					this.curriculum = Object.assign({}, response.map(curr => ({ code: curr.code, name: curr.name }))[0]);
					if (this.originalClass.hqtUsername && this.originalClass.hqtUsername.trim() !== "") this.findHqt();
					else this.setReferenceSubjects();
				}

			}, this.errorHandlerService.handleError);

	}

	private findHqt(): void {

		this.crudService
			.getAllBy<Hqt>(`${allHqtEndpoint}/get-by-name-or-email?keyword=${this.originalClass.hqtUsername}`)
			.pipe(takeUntil(this.unsubscribe))
			.subscribe(response => {

				if (response) {
					this.hqt = response.map(hqt => ({ email: hqt.email, name: hqt.name }))[0];
					this.setReferenceSubjects();
				}

			}, this.errorHandlerService.handleError);

	}

}
