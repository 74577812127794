import { Component, OnInit, EventEmitter, Input, Output } from '@angular/core';
import { Resource } from 'src/app/core/models/resource.model';
import { ResourceGroupForm } from 'src/app/core/models/form/resource-group-form-model';
import { DataCustomizerService } from 'src/app/core/services/util/data-customizer.service';
import { MatDialog } from '@angular/material';
import { ErrorHandlerService } from 'src/app/core/services/util/error-handler.service';
import { Subject } from 'rxjs';
import { ConfirmDialogComponent } from '../../../confirm-dialog/confirm-dialog.component';
import { takeUntil } from 'rxjs/operators';
import { saveResourceConfirmation } from 'src/app/core/constants/message.constant';
import { SAVE } from 'src/app/core/constants/configuration/common.constant';

@Component({
  selector: 'resource-summary',
  templateUrl: './resource-summary.component.html',
  styleUrls: ['./resource-summary.component.scss']
})
export class ResourceSummaryComponent implements OnInit {
  
  @Output() changePage = new EventEmitter<any>();
  @Input() resource: Resource;
  @Input() onlineAccount: string;
  @Input() resourceType: string;
  @Input() groups: ResourceGroupForm[];
  @Input() isFirst: boolean;
  @Input() isLast: boolean;

  resourceDetail: any;
  unsubscribe: Subject<any> = new Subject();

  constructor(
    private dataCustomizerService: DataCustomizerService,
    private errorHandlerService: ErrorHandlerService,
    private dialog: MatDialog,
  ) { }

  ngOnInit() {
    this.resourceDetail = this.dataCustomizerService.formatResourceDetailsDisplay(this.resource);
  }

  onClick = (label: string): void => {
    if (label === SAVE) {
      const dialogRef = this.dialog.open(ConfirmDialogComponent);
      dialogRef.componentInstance.confirmMessage = saveResourceConfirmation;
      dialogRef.afterClosed()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(result => {
        if (result) {
          this.changePage.emit(label);
        }
      }, this.errorHandlerService.handleError);
    } else {
      this.changePage.emit(label);
    }
  }

}
