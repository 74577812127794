import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { Class } from 'src/app/core/models/class.model';
import { Model } from 'src/app/core/models/model.model';

@Component({
  selector: 'model-details',
  templateUrl: './model-details.component.html',
  styleUrls: ['./model-details.component.scss']
})
export class ModelDetailsComponent implements OnInit {


	@Output() linkClink: EventEmitter<number> = new EventEmitter();
	@Input() model: Model;
	@Input() classes: Class[];
	@Input() referenceValues: any;

	mainDetails: any;

	constructor() { }
		
	ngOnInit() {
		this.mainDetails = { ...this.model };
	}

	onViewClass(id: number): void {
		this.linkClink.emit(id);
	}

}
