import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";

@Injectable({ providedIn: 'root' })
export class FabButtonMessagingService {

    private fabButtonMessageSource: Subject<any> = new Subject<any>();

    fabButtonMessage$: Observable<any> = this.fabButtonMessageSource.asObservable();

    sendMessage(message: any): void {
        this.fabButtonMessageSource.next(message);
    }

}
