import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HeaderMessagingService } from 'src/app/core/services/messaging/header-messaging.service';
import { takeUntil } from 'rxjs/operators';
import { Resource } from 'src/app/core/models/resource.model';
import { Subject } from 'rxjs';
import { sideNavLabelResource, RESOURCE } from 'src/app/core/constants/configuration/resource-constants.config';
import { UPDATE_MODULE_NAME } from 'src/app/core/constants/configuration/common.constant';
import { RESOURCES_PATH } from 'src/app/core/constants/routes.constant';
import { UserService } from 'src/app/core/services/util/user.service';
import { homeRoutingPermissions } from "src/app/core/constants/configuration/role-constants.config";

@Component({
  selector: 'app-resource-update',
  templateUrl: './resource-update.component.html',
  styleUrls: ['./resource-update.component.scss']
})
export class ResourceUpdateComponent implements OnInit {

  unsubscribe: Subject<any> = new Subject();
  activeIndex: number = 0;
  sideNavLabels: string[] = [];
  resource: Resource;
  resourceId: number;

  constructor(
    private route: ActivatedRoute,
    private headerMessagingService: HeaderMessagingService,
    private userService: UserService,
	) {
		this.userService.checkRolePermission(RESOURCES_PATH, homeRoutingPermissions);
    this.headerMessagingService.setHeader(RESOURCE, UPDATE_MODULE_NAME, false, null);
    this.sideNavLabels = sideNavLabelResource;
  }

  ngOnInit() {
    this.initBundle();
  }

  initBundle(): void {
    this.route.params
    .pipe(takeUntil(this.unsubscribe))
    .subscribe(params => {
      this.resourceId = params["id"];
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

}
