import { Injectable } from "@angular/core";
import { ResourceMainForm } from "../../models/form/resource-main-form.model";
import { ResourceGroupForm } from "../../models/form/resource-group-form-model";
import { Resource } from "../../models/resource.model";
import { STATUS_1, STATUS_2 } from "../../constants/configuration/common.constant";
import { ResourceAssociation } from "../../models/resource-association.model";

@Injectable({ providedIn: "root" })
export class ResourceHelper {
  constructor() {}

  setResourceModel(
    resourceFormValues: ResourceMainForm,
    associations: ResourceAssociation[],
    forUpdate: boolean,
    original?: Resource
  ): Resource {
    return {
      id: forUpdate ? original.id : null,
      code: resourceFormValues.code,
      name: resourceFormValues.name,
      description: resourceFormValues.description,
      status: forUpdate
        ? original.status
        : associations.length > 0
        ? STATUS_1
        : STATUS_2,
      createdBy: forUpdate ? original.createdBy : "",
      modifiedBy: forUpdate ? original.modifiedBy : "",
      createdDate: forUpdate ? original.createdDate : "",
      modifiedDate: forUpdate ? original.createdDate : "",
      onlineAccountType: resourceFormValues.onlineAccountType,

      type: resourceFormValues.type,
      required: false,
      consumable: forUpdate ? original.consumable : null,
      price: resourceFormValues.price ? resourceFormValues.price : 0,
      deposit: resourceFormValues.deposit ? resourceFormValues.deposit : 0,
      resourceAssoc: Object.assign([], associations)
    };
  }

  setResourceMainFormValues(resource: Resource): ResourceMainForm {
    return {
      code: resource.code,
      name: resource.name,
      description: resource.description,
      type: resource.type,
      nonconsumable: Boolean(resource.consumable),
      price: resource.price ? resource.price : 0,
      deposit: resource.deposit ? resource.deposit : 0,
      onlineAccountType: resource.onlineAccountType
    };
  }

  setResourceGroupFormValues(
    associations: ResourceAssociation[]
  ): ResourceGroupForm[] {
    const groups: ResourceGroupForm[] = associations.map(assoc => {
      const group: ResourceGroupForm = {
        code: assoc.referenceCode,
        name: assoc.referenceName,
        type: assoc.referenceType,
        isRequired: assoc.isRequired || false,
        disabled: true
      };
      return group;
    });

    return Object.assign([], groups);
  }
}
