import { Component, OnInit, Input } from '@angular/core';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'info-display',
  templateUrl: './info-display.component.html',
  styleUrls: ['./info-display.component.scss']
})
export class InfoDisplayComponent implements OnInit {

  faQuestionCircle = faQuestionCircle;

  @Input()
  helpText: string = "";

  constructor() { }

  ngOnInit() {
  }

}
