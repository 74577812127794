import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormUtil } from "src/app/core/services/util/form.util";
import { FormGroup } from "@angular/forms";
import { CrudService } from "src/app/core/services/data/crud.service";
import { ErrorHandlerService } from "src/app/core/services/util/error-handler.service";
import { allAssetsEndpoint } from '../../../../core/constants/endpoints.constant';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { ASSET_STATUS_1, ASSET_STATUS_2, ASSET_STATUS_3, ASSET_STATUS_4 } from "src/app/core/constants/configuration/asset-constants.config";
import { ConfirmDialogComponent } from '../../confirm-dialog/confirm-dialog.component';
import { MatDialog } from '@angular/material';
import { ORDER_ITEM_TYPE_3, PROCESSING_STATUS_1 } from '../../../../core/constants/configuration/order-constants.config';
import { RESOURCE_TYPE_CODE_1 } from '../../../../core/constants/configuration/resource-constants.config';

@Component({
  selector: 'app-resource-task-modal-form',
  templateUrl: './resource-task-modal-form.component.html',
  styleUrls: ['./resource-task-modal-form.component.scss']
})
export class ResourceTaskModalFormComponent implements OnInit {
  @Input() action: string;
  @Input() book: any;
  @Input() requiredTrackingId: boolean = false;
  @Output() processBooks = new EventEmitter<any>();
  @Output() closeResourceTaskFormModal = new EventEmitter<any>();

  asset: any;
  isActive: boolean = false;
  noErrors: boolean = false;
  noChange: boolean = false;
  trackingId: string = "";
  assetTag: string = "";
  errorMessage: string = "";
  hintMessage: string = "";
  bookCodeName: string = "";
  bookForm: FormGroup;
  unsubscribe: Subject<any> = new Subject();
  consumable: string = "";
  isButtonDisabled: boolean = false;

  constructor(
    private crudService: CrudService,
    private dialog: MatDialog,
    private errorHandlerService: ErrorHandlerService,
    private formUtil: FormUtil,
  ) {
    if (this.requiredTrackingId) this.bookForm = formUtil.createTaskResourceFormRequiredTrackingId();
    else this.bookForm = formUtil.createTaskResourceForm();
  }

  ngOnInit() {
    this.bookCodeName = this.book && this.book.referenceCode && this.book.referenceName ?
      `${this.book.referenceCode} ${this.book.referenceName.trim()}` : "";

    if(this.book.assetTag != "") {
      this.getAssetDetails();
    }

    if (this.book.assetCode || this.book.trackingId) {
      this.bookForm.controls['asset'].setValue(this.book.assetCode);
      this.bookForm.controls['trackingId'].setValue(this.book.trackingId);
      this.assetTag = this.book.assetCode;
      this.trackingId = this.book.trackingId;
    }
  }

  onChange(name: string) {
    const value: string = this.bookForm.controls[name].value;
    if (name === "trackingId") {
      if (value.length <= 255) this.trackingId = value;
    } else if (name === "asset") {
      this.noErrors = false;
      this.asset = null;
      this.assetTag = value;

      //For Button Lock
      if(this.action == 'update' && this.assetTag != value){
        this.isButtonDisabled = true;
      } else {
        this.isButtonDisabled = false;
      }
    }
    this.noChange = this.book.trackingId === this.trackingId ? true : false;
  }

  onValidate() {
    this.validateAssetCode(true);
  }

  onClose() {
    this.closeResourceTaskFormModal.emit();
  }

  onProcessBooks() {
    this.noChange = this.book.trackingId === this.trackingId ? true : false;
    this.validateUntouchedForms();
    this.validateAssetCode(false);
  }

  validateUntouchedForms(): void {
    Object.keys(this.bookForm.controls).forEach(control => {
      this.bookForm.get(control).markAsTouched();
      this.bookForm.get(control).markAsDirty();
    });
  }

  getAssetDetails() {
    this.crudService
    .getAllBy<any>(`${allAssetsEndpoint}/get-by-code?code=${this.assetTag}
    &itemModelId=${this.book.referenceCode ? this.book.referenceCode : ''}`)
    .pipe(takeUntil(this.unsubscribe))
    .subscribe((response: any) => {
      if(response){
        let isConsumable = false;
        isConsumable = response ? response.itemModel.consumable : null;

        if(isConsumable != null) {
          this.consumable = isConsumable ? "Yes" : "No";
        }
      }
      },
      this.errorHandlerService.handleError,
    );
  }

  validateAssetCode(validateOnly: boolean) : void {
    this.isActive = true;
    this.crudService
      .getAllBy<any>(`${allAssetsEndpoint}/get-by-code?code=${this.assetTag}
      &itemModelId=${this.book.referenceCode ? this.book.referenceCode : ''}`)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((response: any) => {
        this.isActive = false;
        if (response) {
          if (response.status === ASSET_STATUS_1 && this.assetTag === response.code) {
            this.noErrors = true;
            this.asset = response;
            if (this.action = "checkout") {
              this.book = { referenceCode: this.asset.itemModel.code, referenceName: this.asset.itemModel.name};
              this.bookCodeName = `${this.asset.itemModel.code} ${this.asset.itemModel.name.trim()}`;
              this.consumable = response.itemModel.consumable ? "Yes" : "No";
            }
            this.hintMessage = `Asset ${this.assetTag} is available.`;
          } else if (response.status === ASSET_STATUS_2 && this.book.assetCode && this.book.assetCode === this.assetTag) {
            this.noErrors = true;
            this.asset = response;
            this.hintMessage = `Asset ${this.assetTag} is the currently assigned asset.`;
          } else {
            if(response.code != this.assetTag){
              this.bookForm.get('asset').setErrors({ 'invalid': true });
            } else if (response.status === ASSET_STATUS_2) {
              this.errorMessage = `Asset ${this.assetTag} has already been checked-out. Please select an available asset.`;
              this.bookForm.get('asset').setErrors({ 'notAvailable': true });
            } else if (response.status === ASSET_STATUS_3) {
              this.errorMessage = `Asset ${this.assetTag} is for disposal. Please select an available asset.`;
              this.bookForm.get('asset').setErrors({ 'notAvailable': true });
            } else if (response.status === ASSET_STATUS_4) {
              this.errorMessage = `Asset ${this.assetTag} is lost. Please select an available asset.`;
              this.bookForm.get('asset').setErrors({ 'notAvailable': true });
            }
            
          }
          if (!validateOnly) {
            if (!this.bookForm.valid || (this.noChange && this.book.assetCode && this.book.assetCode === this.assetTag)) {
              return;
            } else {
              if (this.noErrors && this.asset) {
                const dialogRef = this.dialog.open(ConfirmDialogComponent);
                if (this.action === "update")
                  dialogRef.componentInstance.confirmMessage = `Are you sure you want to update the asset details of this workbook/text book?`;
                else dialogRef.componentInstance.confirmMessage = `Are you sure you want to ${this.action} this workbook/text book with asset ${this.assetTag}?`;
                dialogRef.afterClosed().subscribe(result => {
                  if(result) {
                    this.bookForm.reset();
                    this.processBooks.emit({
                      asset: {...this.asset},
                      trackingId: this.trackingId,
                      book: {
                        id: 0,
                        referenceCode: this.asset.itemModel.code,
                        referenceType: ORDER_ITEM_TYPE_3,
                        referenceName: this.asset.itemModel.name,
                        price: this.asset.itemModel.price ? this.asset.itemModel.price : 0,
                        deposit: this.asset.itemModel.deposit ? this.asset.itemModel.deposit : 0,
                        originalPrice: this.asset.itemModel.price ? this.asset.itemModel.price : 0,
                        originalDeposit: this.asset.itemModel.deposit ? this.asset.itemModel.deposit : 0,
                        perClassCost: 0,
                        inBundle: false,
                        currentBundleSize: 0,
                        maxBundleSize: 0,
                        status: null,
                        forCancel: false,
                        parentItem: null,
                        subItems: [],
                        curriculumOrderDetails: null,
                        classOrderDetails: null,
                        resourceOrderDetails: {
                          id: 0,
                          code: this.asset.itemModel.code,
                          name: this.asset.itemModel.name,
                          description: null,
                          status: null,
                          type: RESOURCE_TYPE_CODE_1,
                          required: false,
                          nonconsumable: !this.asset.itemModel.consumable,
                          price: this.asset.itemModel.price,
                          deposit: this.asset.itemModel.deposit,
                          physicalIdCode: null,
                          onlineAccountType: null,
                          curriculumName: null,
                          className: null,
                        },
                        assetCode: this.asset.code,
                        trackingId: this.trackingId,
                        checkIn: false,
                        withdrawn: false,
                        withdrawnDate: null,
                        withdrawnBy: null
                      }
                    });
                  }
                });
              }
            }
          }
        } else {
          this.bookForm.get('asset').setErrors({ 'invalid': true });
        }
      },
      this.errorHandlerService.handleError,
    );
  }

}
