import { DataProperties } from "../../models/data-properties.model";

export const HQT: string = "HQT";
export const hqtTab: string = "HQT";

export const sideNavLabelHqt: string[] = ["Main Form", "Summary"];

export const hqtDataProperties: DataProperties[] = [
    {
        label: "Name",
        property: "name",
        sortable: true,
        clickable: true,
        filterable: true,
        autocomplete: false,
        endpoint: ""
    },
    {
        label: "Email",
        property: "email",
        sortable: true,
        clickable: false,
        filterable: true,
        autocomplete: false,
        endpoint: ""
    },
    {
        label: "Status",
        property: "status",
        sortable: true,
        clickable: false,
        filterable: true,
        autocomplete: false,
        endpoint: ""
    }
];
