import { Component, OnInit, Output, EventEmitter, Input, NgZone } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';

import { Credential } from '../../../core/models/credential.model';

declare const gapi: any;

@Component({
  selector: 'login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.scss']
})
export class LoginFormComponent implements OnInit {

  @Input()
  message: String;

  @Input()
  isProcessing: boolean;

  @Output()
  login: EventEmitter<Credential> = new EventEmitter();

  googleTokenId: string = null;
  googleEmail: string = null;

  clientId: string = "34946334903-2s8i0cs6a2ldotgr8eh9i65059jhsc9j.apps.googleusercontent.com";

  email: FormControl = new FormControl('', [
    Validators.required,
    Validators.email,
  ]);

  password: FormControl = new FormControl('', [
    Validators.required,
  ]);

  constructor(ngZone : NgZone) { 
    this.initializeGoogleSignInConfig(ngZone);
  }

  initializeGoogleSignInConfig(ngZone) {
    gapi.load('client:auth2', () => {
			gapi.client.init({
				clientId: this.clientId,
				scope: 'email',
				plugin_name: 'school-ordering-system'
			});
		});
    window['onSignIn'] = user => ngZone.run(
      () => {
        this.googleSignIn(user);
      }
    );
  }

  googleSignIn(user) {
    if(user != null && gapi != null && gapi.auth2 != null && gapi.auth2.getAuthInstance() != null) {
      this.googleTokenId = gapi.auth2.getAuthInstance().currentUser.get().getAuthResponse().id_token;
      this.googleEmail = gapi.auth2.getAuthInstance().currentUser.get().getBasicProfile().getEmail();
      gapi.auth2.getAuthInstance().signOut();
    }
    this.login.emit(this.buildCredentials());
  }

  ngOnInit() {

  }

  onSubmit(): void {
    this.googleEmail = null;
    this.googleTokenId = null;
    
    if (this.email.valid && this.password.valid)
      this.login.emit(this.buildCredentials());

  }

  buildCredentials(): Credential {
    return {
      username: this.googleEmail != "" && this.googleEmail != null ? this.googleEmail : this.email.value.trim(),
      password: this.password.value.trim(),
      googleTokenId: this.googleTokenId,
    };
  }

}
