import { Component, EventEmitter, OnInit, Input, Output } from '@angular/core';
import {PageEvent} from '@angular/material';
import { pageSizeOptions } from 'src/app/core/constants/configuration/common.constant';

@Component({
	selector: 'app-paginator',
	templateUrl: './paginator.component.html',
	styleUrls: ['./paginator.component.scss']
})
export class PaginatorComponent implements OnInit {

	@Output() pageChange: EventEmitter<PageEvent> = new EventEmitter();
	@Input() noOfItems: number;
	@Input() pageSize: number;
	@Input() pageIndex: number;

	pageSizeOptions = pageSizeOptions;

	constructor() { }

	ngOnInit() {
	}

	handlePageChange($event: PageEvent) : void {
		this.pageChange.emit($event);
	}

}
