import { BundleXCurriculum } from "./../../models/bundlexcurriculum.model";
import { Injectable } from "@angular/core";
import { Curriculum } from "../../models/curriculum.model";
import { Class } from "../../models/class.model";
import * as moment from "moment";
import {
  tableDateFormat,
  ACTIVE,
  INACTIVE,
  STATUS_1,
  tableDateFormatWithTime,
} from "../../constants/configuration/common.constant";
import { Bundle } from "src/app/core/models/bundle.model";
import { Resource } from "../../models/resource.model";
import { Hqt } from "../../models/hqt.model";
import { ResourceAssociation } from "../../models/resource-association.model";
import { Student } from "../../models/eos/student.model";
import { Model } from "../../models/model.model";
import { Task } from "../../models/order/task.model";
import { Order } from 'src/app/core/models/order/order.model';
import { Asset } from "../../models/asset.model";
import { TxnHistoryModel } from "../../models/order/txn-history-model";
import { OrderTxnHistoryDto } from "../../models/dto/order-txn-history-dto";
import { OrderItemTxnHistoryDto } from "../../models/dto/order-item-txn-history-dto";

@Injectable({ providedIn: "root" })
export class DataCustomizerService {
  createCustomizedCurriculum(curriculum: Curriculum): any {
    return {
      id: curriculum.id,
      code: curriculum.code,
      name: curriculum.name,
      startDate: moment(curriculum.startDate).format(tableDateFormat),
      endDate: moment(curriculum.endDate).format(tableDateFormat),
      bookRequired: curriculum.bookRequired ? 'YES' : 'NO',
      status: curriculum.status === STATUS_1 ? ACTIVE : INACTIVE
    };
  }

  createCustomizedClass(clazz: Class, forList?: boolean, reference?: any): any {
    return {
      id: clazz.id,
      code: clazz.code,
      name: clazz.name,
      curriculumName: clazz.classCurriculum.name,
      subject: forList
        ? this.getSubjectName(clazz.subject, reference.subjects)
        : "",
      hqtUsername: clazz.hqtUsername,
      status: clazz.status === STATUS_1 ? ACTIVE : INACTIVE
    };
  }

  formatResourceDetailsDisplay(
    resource: Resource,
    forList?: boolean,
    reference?: any
  ): any {
    return {
      id: resource.id,
      name: resource.name,
      code: resource.code,
      description: resource.description,
      type: forList
        ? this.getResourceTypeName(resource.type, reference.resourceTypes)
        : "",
      required: resource.required,
      price: resource.price,
      deposit: resource.deposit,
      consumable: resource.consumable,
      group: this.getResourceGroupName(resource.resourceAssoc),
      onlineAccountType: forList
        ? this.getOnlineAccountTypeName(
            resource.onlineAccountType,
            reference.onlineAccounts
          )
        : "N/A",
      status: resource.status != null ? resource.status : STATUS_1,
      createdBy: resource.createdBy || "",
      createdDate: resource.createdDate
        ? moment(resource.createdDate).format(tableDateFormatWithTime)
        : "",
      modifiedBy: resource.modifiedBy || "",
      modifiedDate: resource.modifiedDate
        ? moment(resource.modifiedDate).format(tableDateFormatWithTime)
        : ""
    };
  }

  getSubjectName(type: string, subjects: any[]): string {
    const subject: any = subjects.find(subject => subject.code === type);
    return subject ? subject.name : "N/A";
  }

  getPeriodName(type: string, periodTypes: any[]): string {
    const period: any = periodTypes.find(period => period.code === type);
    return period ? period.name : "N/A";
  }

  getGradeLevelName(type: string, gradeLevels: any[]): string {
    const level: any = gradeLevels.find(level => level.code === type);
    return level ? level.name : "N/A";
  }

  getOnlineAccountTypeName(type: string, onlineAccounts: any[]): string {
    const account: any = onlineAccounts.find(account => account.code === type);
    return account ? account.name : "N/A";
  }

  getResourceTypeName(type: string, resourceTypes: any[]): string {
    const resourceType: any = resourceTypes.find(
      resourceType => resourceType.code === type
    );
    return resourceType ? resourceType.name : "N/A";
  }

  getResourceGroupName(assocs: ResourceAssociation[]): string {
    return assocs.length > 0 ? "Associated" : "None";
  }

  formatCurriculumDetailsDisplay(curriculum: Curriculum): any {
    return {
      id: curriculum.id,
      name: curriculum.name,
      code: curriculum.code,
      description: curriculum.description,
      startDate: moment(curriculum.startDate).format(tableDateFormat),
      endDate: moment(curriculum.endDate).format(tableDateFormat),
      status: curriculum.status,
      deposit: curriculum.deposit,
      hasDeposit: curriculum.hasDeposit,
      classes: curriculum.classes && curriculum.classes.map(
        (clazz): Class[] => this.formatClassDetailsDisplay(clazz)
      ),
      syCode: curriculum.syCode,
      fee: curriculum.fee,
      perClassCost: curriculum.perClassCost,
      createdBy: curriculum.createdBy,
      modifiedBy: curriculum.modifiedBy,
      createdDate: curriculum.createdDate,
      modifiedDate: curriculum.modifiedDate,
      hasHqt: curriculum.hasHqt ? "Yes" : "No",
      bookRequired: curriculum.bookRequired,
      scopes: curriculum.scopes,
    };
  }

  formatClassDetailsDisplay(clazz: Class): any {
    return {
      id: clazz.id,
      name: clazz.name,
      code: clazz.code,
      courseCode: clazz.courseCode,
      status: clazz.status,
      description: clazz.description,
      subject: clazz.subject,
      fromGradeLevel: clazz.fromGradeLevel,
      toGradeLevel: clazz.toGradeLevel,
      hqtUsername: clazz.hqtUsername,
      deposit: clazz.deposit,
      fee: clazz.fee,
      activePeriodType: clazz.activePeriodType,
      resources: "Yes",
      required: clazz.status,
      resourceType: null,
      classCurriculum: null,
      createdBy: clazz.createdBy,
      modifiedBy: clazz.modifiedBy,
      createdDate: clazz.createdDate,
      modifiedDate: clazz.modifiedDate,
      maxSlot: clazz.maxSlot,
    };
  }

  formatBundleDetailsDisplay(bundle: Bundle, bundleTypes: any[]): any {
    return {
      id: bundle.id,
      code: bundle.code,
      name: bundle.name,
      price: bundle.price,
      curriculumList:
        bundle.curriculumAssoc.length > 0
          ? bundle.curriculumAssoc.map(currAssoc => currAssoc.curriculum != null ? currAssoc.curriculum.name : "")
          : "-",
      bundleType: this.getBundleTypeValue(bundle.bundleType, bundleTypes),
      hqtLeadTag:
        bundle.curriculumAssoc.length > 0
          ? this.getHQTLedTagValue(bundle.curriculumAssoc).length > 0
            ? "Yes"
            : "No"
          : "-",
      status: this.getStatus(bundle.startDate, bundle.endDate)
    };
  }

  formatStudentDetailDisplay(student: Student, reference: any): any {
    return {
      id: student.id,
      check: false,
      districtId: student.districtId,
      name: student.firstName.concat(` ${student.lastName}`),
      masterAgreement: student.masterAgreement ? 'Yes' : 'No',
      scope: student.scope,
      gradeLevel: student.gradeLevelId.toString(),
      learningCenter: student.learningCenter,
      currentBalance: student.currentBalance,

    }
  }

  getStatus(startDate: string, endDate: string): string {
    const sDate = moment(startDate)
      .hours(0)
      .minutes(0)
      .seconds(0);
    const eDate = moment(endDate)
      .hours(23)
      .minutes(59)
      .seconds(59);

    return moment().isBetween(sDate, eDate) ? ACTIVE : INACTIVE;
  }

  getBundleTypeValue(bundleType: string, bundleTypes: any[]): string {
    let bt: string;
    bundleTypes.map(bType => {
      if (bType.code === bundleType) {
        bt = bType.name;
      }
    });
    return bt;
  }

  getHQTLedTagValue(bundleCurriculums: BundleXCurriculum[]): any[] {
    let bundleWithHQTLead: BundleXCurriculum[] = bundleCurriculums.filter(
      bundleCurriculum => bundleCurriculum.curriculum != null ? bundleCurriculum.curriculum.hasHqt : false
    );
    return bundleWithHQTLead;
  }

  customizeHqtListDisplay(hqt: Hqt): any {
    return {
      id: hqt.id,
      name: hqt.name,
      email: hqt.email,
      status: hqt.status === STATUS_1 ? ACTIVE : INACTIVE
    };
  }

  customizeModelListDisplay(model: Model): any {
    return {
      id: model.id,
      code: model.code,
      name: model.name,
      total: model.total ? model.total : 0,
      availableCount: model.availableCount ? model.availableCount : 0,
      checkOutCount: model.checkOutCount ? model.checkOutCount : 0,
      forDisposalCount: model.forDisposalCount ? model.forDisposalCount : 0,
      lostCount: model.lostCount ? model.lostCount : 0,
      consumable: model.consumable,
      type: 'RESOURCE_TYPE_1',
      price: model.price ? model.price : 0,
      deposit: model.deposit ? model.deposit : 0,
      createdBy: model.createdBy || "",
      hasDeposit: model.hasDeposit,
      createdDate: model.createdDate
        ? moment(model.createdDate).format(tableDateFormatWithTime)
        : "",
      modifiedBy: model.modifiedBy || "",
      modifiedDate: model.modifiedDate
        ? moment(model.modifiedDate).format(tableDateFormatWithTime)
        : ""
    };
  }

  customizeAssetListDisplay(asset: any, statusList: any): any {
    return {
      id: asset.id,
      code: asset.code,
      name: asset.name,
      modelId: asset.code,
      status: this.customizeAssetStatusDisplay(asset.status, statusList),
      loanedTo: asset.loanedTo,
      itemModel: asset.itemModel,
      cosOrderId: asset.cosOrderId,
      consumable: asset.itemModel.consumable
    };
  }

  customizeAssetStatusDisplay(currentStatus, statusList) {
    statusList.forEach(status => {
      if(status.code === currentStatus) {
        currentStatus = status.name;
      }
    });
    return currentStatus;
  }

  customizeClassesForHqtDisplay(classes: Class[]): any[] {
    let customizedValues: any[] = [];
    const currCodes: string[] = classes
      .map(clazz => clazz.classCurriculum.code)
      .filter((code, index, array) => array.indexOf(code) === index);

    currCodes.forEach(code => {
      let item: any = {};
      const curriculum: Curriculum = classes.find(
        clazz => clazz.classCurriculum.code === code
      ).classCurriculum;
      const customizedClasses: any[] = [];

      classes.forEach(clazz => {
        if (clazz.classCurriculum.code === code)
          customizedClasses.push({
            id: clazz.id,
            code: clazz.code,
            name: clazz.name
          });
      });

      item = {
        code: curriculum.code,
        name: curriculum.name,
        classes: customizedClasses
      };

      customizedValues.push(item);
    });

    return customizedValues;
  }

  formatShippingDetailDisplay(shippingDetail: any): any {
    return {
      id: shippingDetail.guardianId,
      check: false,
      addressLine: shippingDetail.addressLine,
      addressName: shippingDetail.firstName.concat(` ${shippingDetail.lastName}`),
      city: shippingDetail.city,
      email: shippingDetail.email,
      phone: shippingDetail.phone,
      state: shippingDetail.state,
      zipCode: shippingDetail.zipCode,
      guardianId: shippingDetail.guardianId,
      selected: shippingDetail.selected,
    }
  }

  formatOrderResourceDetailsDisplay(resource: Resource): any {
    return {
      id: resource.id,
      name: resource.name,
      code: resource.code,
      description: resource.description,
      type: resource.type,
      price: resource.price,
      deposit: resource.deposit,
      consumable: resource.consumable,
      status: resource.status != null ? resource.status : STATUS_1,
      createdBy: resource.createdBy || "",
      createdDate: resource.createdDate
        ? moment(resource.createdDate).format(tableDateFormatWithTime)
        : "",
      modifiedBy: resource.modifiedBy || "",
      modifiedDate: resource.modifiedDate
        ? moment(resource.modifiedDate).format(tableDateFormatWithTime)
        : "",
      resourceAssoc: resource.resourceAssoc,
    };
  }

  addCheckboxProperty(items: any[]): any[] {
    return items.map(obj => ({ ...obj, checked: false }));
  }

  createCustomizedOrderClass(clazz: Class, reference: any): any {
    return {
      checked: false,
      id: clazz.id,
      code: clazz.code,
      name: clazz.name,
      description: clazz.description,
      deposit: clazz.deposit,
      fee: clazz.fee,
      status: clazz.status === 'STATUS_1' ? 'Active' : 'Inactive',
      courseCode: clazz.courseCode,
      curriculumName: clazz.classCurriculum.name,
      perClassCost: clazz.classCurriculum.perClassCost,
      startDate: clazz.classCurriculum.startDate,
      endDate: clazz.classCurriculum.endDate,
      subject: this.getSubjectName(clazz.subject, reference.subjects),
      fromGradeLevel: this.getGradeLevelName(clazz.fromGradeLevel, reference.gradeLevels),
      toGradeLevel: this.getGradeLevelName(clazz.toGradeLevel, reference.gradeLevels),
      period: this.getPeriodName(clazz.activePeriodType, reference.periodTypes),
      hqt: clazz.hqtUsername,
      maxSlot: clazz.maxSlot,
      orderedCount: clazz.orderedCount,
      processedCount: clazz.processedCount,
      closed: clazz.closed,
      curriculum: {
        code: clazz.classCurriculum.code,
        name: clazz.classCurriculum.name,
        deposit: clazz.classCurriculum.deposit,
        status: clazz.classCurriculum.status === 'STATUS_1' ? 'Active' : 'Inactive',
        hasDeposit: clazz.classCurriculum.hasDeposit,
        fee: clazz.classCurriculum.fee,
        description: clazz.classCurriculum.description,
        hasHqt: clazz.classCurriculum.hasHqt,
        bookRequired: clazz.classCurriculum.bookRequired,
        syCode: clazz.classCurriculum.syCode,
        perClassCost: clazz.classCurriculum.perClassCost,
      },
    };
  }

  createCustomizedOrder(order: Order, reference: any): any {
    return {
      id: order.id,
      code: order.code,
      name: order.name,
      studentDistrictId: order.studentDistrictId,
      scope: order.description,
      dateSubmitted: moment(order.createdDate).format(tableDateFormat),
      status: this.getProcessingStatusName(order.status, reference.processingStatus),
      totalAmount: order.total,
      eosOrderCode: order.eosOrderCode,
      syCode: order.syCode
    }
  }

  getProcessingStatusName(statusCode: string, status: any[]): string {
    const statusName: any = status.find(status => status.code === statusCode);
    return statusName ? statusName.name : "";
  }

  createCustomizedTaskForList(task: Task, reference: any): any {
    return {
      id: task.id,
      code: task.code,
      curriculumCode: task.curriculumCode,
      curriculumName: task.curriculumName,
      type: this.getTaskGroupType(task.groupType, reference.taskGroupType),
      cosOrderCode: task.txnOrderCode,
      eosOrderCode: task.eosOrderCode,
      name: task.name,
      districtId: task.studentDistrictId,
      status: this.getProcessingStatusName(task.status, reference.processingStatus),
      requestedBy: task.createdBy,
      assignedTo: task.assignedTo,
      orderItemAssoc: task.orderItemAssoc,
      createdDate: moment(task.createdDate).format(tableDateFormat),
      syCode: task.syCode,
      hasWorkbookTextbook: task.hasWorkbookTextbook
    }
  }

  getTaskGroupType(groupCode: string, groupTypes: any[]): string {
    const groupType: any = groupTypes.find(type => type.code === groupCode);
    return groupType ? groupType.name : "";
  }

  formatStudentListDetailDisplay(student: Student, reference: any): any {
    return {
      id: student.id,
      districtId: student.districtId,
      name: student.firstName.concat(` ${student.lastName}`),
      masterAgreement: student.masterAgreement ? 'Yes' : 'No',
      scope: student.scope,
      gradeLevel: student.gradeLevelId.toString(),
      learningCenter: student.learningCenter,
      status: student.active,
      currentBalance: student.currentBalance,
    }
  }

  formatOrderTxnHistoryForDisplay(item: TxnHistoryModel): OrderTxnHistoryDto {
    return {
      historyType: item.historyType,
      description: item.description,
      dateModified: moment(item.dateModified).format(tableDateFormat),
      modifiedBy: item.modifiedBy
    }
  }

  formatOrderItemTxnHistoryForDisplay(item: TxnHistoryModel): OrderItemTxnHistoryDto {
    return {
      txnOrderItemGroup: item.txnOrderItemGroup,
      historyType: item.historyType,
      description: item.description,
      dateModified: moment(item.dateModified).format(tableDateFormat),
      modifiedBy: item.modifiedBy,
      fromValue: item.fromValue,
      toValue: item.toValue
    }
  }

}
