import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { ASC, CLOSE, OPEN, pageSizeOptions } from 'src/app/core/constants/configuration/common.constant';
import { LoaderMessagingService } from 'src/app/core/services/messaging/loader-messaging.service';
import { CrudService } from 'src/app/core/services/data/crud.service';
import { ErrorHandlerService } from 'src/app/core/services/util/error-handler.service';
import {
  allModelsEndpoint,
  getResourceTypesEndpoint,
  getResourceGroupsEndpoint,
  getResourceOnlineAccountsEndpoint,
} from 'src/app/core/constants/endpoints.constant';
import { QueryService } from 'src/app/core/services/util/query.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { MatDialog } from "@angular/material";
import { faFilter, faTimes } from "@fortawesome/free-solid-svg-icons";
import { changeSize } from "src/app/core/constants/animations.constant";
import { DataCustomizerService } from 'src/app/core/services/util/data-customizer.service';
import { DataProperties } from 'src/app/core/models/data-properties.model';
import { Page } from "src/app/core/models/page.model";
// import { Resource } from 'src/app/core/models/resource.model';
import { Model } from 'src/app/core/models/model.model';
import { ResourceGroupForm } from 'src/app/core/models/form/resource-group-form-model';
import { ResourceHelper } from "src/app/core/services/util/resource.helper";
import { orderResourcesDataProperties } from 'src/app/core/constants/configuration/order-constants.config';
import { OrderResourceDetailsComponent } from "./order-resource-details/order-resource-details.component";
import { RESOURCE_TYPE_CODE_2 } from 'src/app/core/constants/configuration/resource-constants.config';

@Component({
  selector: 'app-order-resource-selection',
  templateUrl: './order-resource-selection.component.html',
  styleUrls: ['./order-resource-selection.component.scss'],
  animations: [changeSize]
})
export class OrderResourceSelectionComponent implements OnInit {

  @Input() isFirst: boolean;
  @Input() isLast: boolean;
  @Output() getSelectedResource = new EventEmitter<any>();
  @Output() openResourceFormModal = new EventEmitter<any>();
  @Output() closeResourceListModal = new EventEmitter<any>();
  items: any[] = [];
  itemsCount = this.items.length;
  pageIndex: number = 0;
  pageSize: number = pageSizeOptions[0];
  keyword: string = "";
  unsubscribe: Subject<any> = new Subject();
  sortedBy: string;
  sortOrder: string;
  faFilter = faFilter;
  faTimes = faTimes;
  filters: Map<String, String> = new Map<string, string>();

  resourcesGroups: any[] = [];
  clearFilters: boolean = true;
  orderResourcesDataProperties: DataProperties[] = orderResourcesDataProperties;
  filterLabels: string[] = [];
  resourceTypes: any[];
  resourceGroups: any[];
  onlineAccounts: any[];
  trigger: string = CLOSE;
  resourceDetailsDialogRef: any;
  groups: ResourceGroupForm[];

  constructor(
    private crudService: CrudService,
    private dataCustomizerService: DataCustomizerService,
    private dialog: MatDialog,
    private errorHandlerService: ErrorHandlerService,
    private loaderMessagingService: LoaderMessagingService,
    private queryService: QueryService,
    private resourceHelper: ResourceHelper,
  ) {
    this.loaderMessagingService.showPageLoader(true);
  }

  ngOnInit() {
    this.getResourceTypes();
  }

  initParams(): void {
    this.sortedBy = this.orderResourcesDataProperties[3].property;
    this.sortOrder = ASC;
    this.filterOnChange(new Map<string, string>());
  }

  search() {
    this.loaderMessagingService.showListLoader(true);
    this.items = [];

    this.crudService
      .getAll<Model>(this.createSearchEndpoint())
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(
        this.handleSearchResponse,
        this.errorHandlerService.handleError,
        this.handleCompletion
      );
  }

  // get getOrderResourcesDataProperties() {
  //   let additionalFilters = [
  //     {
  //         label: "Curriculum Name",
  //         property: "curriculumName",
  //         sortable: false,
  //         clickable: false,
  //         filterable: true
  //     },
  //     {
  //         label: "Class Name",
  //         property: "className",
  //         sortable: false,
  //         clickable: false,
  //         filterable: true
  //     }
  //   ];
  //   return [...this.orderResourcesDataProperties, ...additionalFilters];
  // }

  handleSearchResponse = (response: Page<Model>): void => {
    if (response) {
      this.itemsCount = response.totalElements;
      // let resources = response.content.filter(resource => resource.type !== RESOURCE_TYPE_CODE_2);
      // resources.forEach((resource: Resource) => {
        // this.resourcesGroups[resource.id] = this.resourceHelper
        //   .setResourceGroupFormValues(resource.resourceAssoc);
        // this.items.push(this.dataCustomizerService.formatOrderResourceDetailsDisplay(resource));
      // });
      let models = response.content;
      models.forEach((model: Model) => {
        this.items.push(this.dataCustomizerService.customizeModelListDisplay(model));
      });
    }
  };

  createSearchEndpoint(): string {
    return allModelsEndpoint.concat(
      // this.queryService.buildResourceSearchQuery(
      //   this.pageIndex,
      //   this.pageSize,
      //   this.sortedBy,
      //   this.sortOrder,
      //   this.keyword,
      //   this.filters,
      //   this.getReference(),
      //   true
      // )
      this.queryService.buildModelSearchQuery(
        this.pageIndex,
        this.pageSize,
        this.sortedBy,
        this.sortOrder,
        this.keyword,
        this.filters,
      )
    );
  }
  
  handleCompletion = (): void => {
    this.loaderMessagingService.showListLoader(false);
    this.loaderMessagingService.showPageLoader(false);
  };

  onSearch(keyword: string): void {
		this.keyword = keyword;
		this.pageIndex = 0;
		this.search();
  }

  onClickAdd() {
    this.openResourceFormModal.emit(true);
  }

  onClose() {
    this.closeResourceListModal.emit(true);
  }

  getResourceTypeName(code: string): string {
    let resourceType = this.resourceTypes.find(type => type.code === code);
    return resourceType ? resourceType.name : "";
  }

  filterOnClick() {
    if (this.trigger === CLOSE) {
      this.trigger = OPEN;
    } else {
      this.trigger = CLOSE;
      // let data = [];
      // this.getOrderResourcesDataProperties.forEach(property => {
      //   if (property.filterable)
      //     data.push({
      //       label: property.label,
      //       prop: property.property,
      //       value: this.filters.get(property.property) || ""
      //     });
      // });
    }
  }
  
  filterOnChange(filters: Map<string, string>) {
    this.filters = new Map<string, string>(filters);
    this.setFilterLabels();
		this.pageIndex = 0;
		this.search();
  }

  setFilterLabels(): void {
    this.filterLabels = [];
    this.filters.forEach((value, key) =>
      this.filterLabels.push(
        // `${
        //   this.getOrderResourcesDataProperties.find(
        //     property => property.property === key
        //   ).label
        // }: ${value}`
        `${
          this.orderResourcesDataProperties.find(
            property => property.property === key
          ).label
        }: ${value}`
      )
    );
    this.clearFilters = this.filters.size === 0;
  }

  onClearFilters(): void {
    this.clearFilters = true;
    this.keyword = "";
    this.filters.clear();
    this.filterOnChange(new Map<string, string>());
  }
  
  onPageChange($event: any): void {
		this.pageIndex = $event.pageIndex;
		this.pageSize = $event.pageSize;
		this.search();
  }
  
  onSelectResource($event: any): void {
    this.groups = this.resourcesGroups[$event.id];
    this.resourceDetailsDialogRef = this.dialog.open(OrderResourceDetailsComponent, { width: "65%", autoFocus: false });
    this.resourceDetailsDialogRef.componentInstance.selectedResource = { ...$event, type: this.getResourceTypeName($event.type) };
    this.resourceDetailsDialogRef.componentInstance.groups = this.groups;
    this.resourceDetailsDialogRef.componentInstance.closeResourceDetailsModal.subscribe(() => {
      this.resourceDetailsDialogRef.close();
    });
    this.resourceDetailsDialogRef.componentInstance.getSelectedResource.subscribe(() => {
      this.getSelectedResource.emit($event);
      this.resourceDetailsDialogRef.close();
    });
  }

  getResourceTypes(): void {
    this.crudService
      .getById<any>(getResourceTypesEndpoint)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(response => {
        this.resourceTypes = response;
        this.resourceTypes.sort((a, b) => a.code.localeCompare(b.code));
        // this.getResourceGroups();
        this.initParams();
      }, this.errorHandlerService.handleError);
  }

  // getResourceGroups(): void {
  //   this.crudService
  //     .getById<any>(getResourceGroupsEndpoint)
  //     .pipe(takeUntil(this.unsubscribe))
  //     .subscribe(response => {
  //       this.resourceGroups = response;
  //       this.resourceGroups.sort((a, b) => a.code.localeCompare(b.code));
  //       this.getOnlineAccounts();
  //     }, this.errorHandlerService.handleError);
  // }

  // getOnlineAccounts(): void {
  //   this.crudService
  //     .getById<any>(getResourceOnlineAccountsEndpoint)
  //     .pipe(takeUntil(this.unsubscribe))
  //     .subscribe(response => {
  //       this.onlineAccounts = response;
  //       this.onlineAccounts.sort((a, b) => a.code.localeCompare(b.code));
  //       this.initParams();
  //     }, this.errorHandlerService.handleError);
  // }

  // getReference(): any {
  //   return {
  //     resourceTypes: this.resourceTypes,
  //     resourceGroups: this.resourceGroups,
  //     onlineAccounts: this.onlineAccounts
  //   };
  // }

}
