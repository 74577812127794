import { Component, OnInit, OnDestroy, Output, EventEmitter, Input } from '@angular/core';
import { Subject } from 'rxjs';
import { ErrorHandlerService } from 'src/app/core/services/util/error-handler.service';
import { MatDialog } from '@angular/material';
import { takeUntil } from 'rxjs/operators';
import { SAVE, PREV } from 'src/app/core/constants/configuration/common.constant';
import { ConfirmDialogComponent } from '../../../confirm-dialog/confirm-dialog.component';
import { saveAssetConfirmation } from 'src/app/core/constants/message.constant';
import { Asset } from 'src/app/core/models/asset.model';
import { Model } from 'src/app/core/models/model.model';

@Component({
	selector: 'asset-summary',
	templateUrl: './asset-summary.component.html',
	styleUrls: ['./asset-summary.component.scss']
})
export class AssetSummaryComponent implements OnInit, OnDestroy {

	@Output() changePage = new EventEmitter<any>();
	@Input() asset: Asset;
	@Input() referenceValues: any;
	@Input() isFirst: boolean;
	@Input() isLast: boolean;
	@Input() models: any;
	@Input() forUpdate: boolean;
	unsubscribe: Subject<any> = new Subject();

	constructor(
		private errorHandlerService: ErrorHandlerService,
		public dialog: MatDialog,
	) { }

	ngOnInit() {
	}

	ngOnDestroy(): void {
		this.unsubscribe.next();
		this.unsubscribe.complete();
	}

	onClick = (label: string): void => {

		if (label === SAVE) {

			const dialogRef = this.dialog.open(ConfirmDialogComponent);
			dialogRef.componentInstance.confirmMessage = saveAssetConfirmation;
			dialogRef.afterClosed()
				.pipe(takeUntil(this.unsubscribe))
				.subscribe(result => {
					if (result) {
						this.changePage.emit({label:SAVE});
					}
				}, this.errorHandlerService.handleError);

		}

		if (label === PREV) {
			this.changePage.emit({label:PREV});
		}

	}
}


