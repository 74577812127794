import { Component, EventEmitter, OnInit, Output, Input } from '@angular/core';
import { Subject } from 'rxjs';
import { MatDialog } from '@angular/material';
import { FormControl, Validators, FormGroup } from '@angular/forms';
import { ConfirmDialogComponent } from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';
import { OrderItem } from 'src/app/core/models/order/order-item.model';
import {
  ORDER_ITEM_TYPE_2,
  ORDER_ITEM_TYPE_3,
  REFUND_TYPE_1,
  REFUND_TYPE_3,
  REFUND_TYPE_2,
  REFUND_TYPE_4
} from '../../../../core/constants/configuration/order-constants.config';
import { NumberHelper } from 'src/app/core/services/util/number-helper';

const CLASS_REFUND_TYPE_FEE = 'Class Fee';
const CLASS_REFUND_TYPE_DEPOSIT = 'Class Deposit';
@Component({
  selector: 'app-order-item-withdraw-dialog',
  templateUrl: './order-item-withdraw-dialog.component.html',
  styleUrls: ['./order-item-withdraw-dialog.component.scss']
})
export class OrderItemWithdrawDialogComponent implements OnInit {

  @Input() item: OrderItem;
  @Input() forRefund: boolean;
  @Output() close = new EventEmitter<any>();
  @Output() withdraw = new EventEmitter<any>();

  reasonLimit: number = 255;
  reason: string = "";

  unsubscribe: Subject<any> = new Subject();

  constructor(
    private dialog: MatDialog,
    private numberUtility: NumberHelper
  ) { }

  ngOnInit() {
  }

  onClose() {
    this.close.emit(null);
  }

  onChangeReason(reasonText: any) {
    this.reason = reasonText;
  }

  onWithdraw() {
    this.withdraw.emit(this.reason);
  }
}
