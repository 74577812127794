import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-hqt-details-dialog',
  templateUrl: './hqt-details-dialog.component.html',
  styleUrls: ['./hqt-details-dialog.component.scss']
})
export class HqtDetailsDialogComponent implements OnInit {

  data: any = {};

  constructor(public dialogRef: MatDialogRef<HqtDetailsDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public responseData: any) { }

  ngOnInit() {
    this.data = this.responseData;
  }

}
