import { Injectable } from "@angular/core";
import { listFilters } from "../../../core/constants/configuration/config.constant";

@Injectable({ providedIn: "root" })
export class FilterService {

	objectToMap(object: Object):  Map<string, string> {
		let listMap = new Map<string, string>();
		Object.keys(object).forEach(key => { listMap.set(key, object[key]) });
		return listMap;
	}

	mapToObject(list: Map<string, string>): Object {
		let listObject = {};
		list.forEach((value: string, key: string) => { listObject = { ...listObject, [key]: value } });
		return listObject;
  }
  
  getFilter(key: string): any {
    let listFilter = JSON.parse(localStorage.getItem(listFilters));
		return listFilter && listFilter[key] ? listFilter[key] : null;
  }
  
  setFilter(key: string, keyword: string, filters: Map<string, string>): void {
		let filterValues = { keyword, filters: this.mapToObject(filters) };
    	let listFilterValues = JSON.parse(localStorage.getItem(listFilters));
		localStorage.setItem(listFilters, JSON.stringify({ ...listFilterValues,  [key]: filterValues }));
  }

}
