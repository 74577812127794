import { Routes } from "@angular/router";

import { AuthGuard } from "src/app/core/guards/auth.guard";
import { CURRICULUMS_PATH, CLASSES_PATH, BUNDLES_PATH, RESOURCES_PATH, BUNDLE_CREATE_PATH, BUNDLE_UPDATE_PATH, CLASS_CREATE_PATH, CLASS_UPDATE_PATH, CURRICULUM_CREATE_PATH, CURRICULUM_UPDATE_PATH, RESOURCE_CREATE_PATH, RESOURCE_UPDATE_PATH } from "src/app/core/constants/routes.constant";
import { CurriculumListComponent } from "../curriculum/curriculum-list/curriculum-list.component";
import { ClassListComponent } from "../class/class-list/class-list.component";
import { CurriculumCreateComponent } from "../curriculum/curriculum-create/curriculum-create.component";
import { CurriculumUpdateComponent } from "../curriculum/curriculum-update/curriculum-update.component";
import { BundleListComponent } from "../bundle/bundle-list/bundle-list.component";
import { BundleCreateComponent } from "../bundle/bundle-create/bundle-create.component";
import { BundleUpdateComponent } from "../bundle/bundle-update/bundle-update.component";
import { ClassCreateComponent } from "../class/class-create/class-create.component";
import { ClassUpdateComponent } from "../class/class-update/class-update.component";
import { ResourceListComponent } from "../resource/resource-list/resource-list.component";
import { ResourceCreateComponent } from "../resource/resource-create/resource-create.component";
import { ResourceUpdateComponent } from "../resource/resource-update/resource-update.component";
import { CurriculumViewComponent } from "../curriculum/curriculum-view/curriculum-view.component";
import { ClassViewComponent } from "../class/class-view/class-view.component";
import { BundleViewComponent } from "../bundle/bundle-view/bundle-view.component";
import { ResourceViewComponent } from "../resource/resource-view/resource-view.component";

export const homeRoutes: Routes = [

    {
        path: BUNDLES_PATH,
        component: BundleListComponent,
        canActivate: [AuthGuard],
    },
    {
        path: BUNDLE_CREATE_PATH,
        component: BundleCreateComponent,
        canActivate: [AuthGuard],
    },
    {
        path: `${BUNDLE_UPDATE_PATH}/:id`,
        component: BundleUpdateComponent,
        canActivate: [AuthGuard],
    },
    {
        path: `${BUNDLES_PATH}/:id`,
        component: BundleViewComponent,
        canActivate: [AuthGuard],
    },


    {
        path: CLASSES_PATH,
        component: ClassListComponent,
        canActivate: [AuthGuard],
    },
    {
        path: CLASS_CREATE_PATH,
        component: ClassCreateComponent,
        canActivate: [AuthGuard],
    },
    {
        path: `${CLASS_UPDATE_PATH}/:id`,
        component: ClassUpdateComponent,
        canActivate: [AuthGuard],
    },
    {
        path: `${CLASSES_PATH}/:id`,
        component: ClassViewComponent,
        canActivate: [AuthGuard],
    },


    {
        path: CURRICULUMS_PATH,
        component: CurriculumListComponent,
        canActivate: [AuthGuard],
    },
    {
        path: CURRICULUM_CREATE_PATH,
        component: CurriculumCreateComponent,
        canActivate: [AuthGuard],
    },
    {
        path: `${CURRICULUM_UPDATE_PATH}/:id`,
        component: CurriculumUpdateComponent,
        canActivate: [AuthGuard],
    },
    {
        path: `${CURRICULUMS_PATH}/:id`,
        component: CurriculumViewComponent,
        canActivate: [AuthGuard],
    },


    {
        path: RESOURCES_PATH,
        component: ResourceListComponent,
        canActivate: [AuthGuard],
    },
    {
        path: RESOURCE_CREATE_PATH,
        component: ResourceCreateComponent,
        canActivate: [AuthGuard],
    },
    {
        path: `${RESOURCE_UPDATE_PATH}/:id`,
        component: ResourceUpdateComponent,
        canActivate: [AuthGuard],
    },
    {
        path: `${RESOURCES_PATH}/:id`,
        component: ResourceViewComponent,
        canActivate: [AuthGuard],
    },

];
