import { Injectable } from "@angular/core";
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from "@angular/common/http";
import { Observable } from "rxjs";
import { HeaderService } from "../services/util/header.service";

@Injectable()
export class HeaderInterceptor implements HttpInterceptor {

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    const updatedRequest: HttpRequest<any> =  request.clone({
      setHeaders: HeaderService.getHeaders(request.url, request.method),
    });

    return next.handle(updatedRequest);

  }

}
