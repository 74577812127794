import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  SimpleChanges,
  OnChanges
} from '@angular/core';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-object-container',
  templateUrl: './object-container.component.html',
  styleUrls: ['./object-container.component.scss']
})
export class ObjectContainerComponent implements OnInit, OnChanges {
  objectArray: any[];
  private faTimesCircle = faTimesCircle;
  @Input() objectType: string;
  @Input() inputObjectArray: any[];
  @Input() targetIndex: number;
  @Input() isForReference: boolean;
  @Output() onAddNewObject = new EventEmitter<boolean>();
  @Output() onSetTargetIndex = new EventEmitter<number>();
  @Output() onDeleteThisIndex = new EventEmitter<any>();

  constructor() { }

  ngOnInit() {
    this.initializeArray();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.initializeArray();
  }

  initializeArray(): void {
    this.objectArray = this.inputObjectArray;
  }

  onAddObject(): void {
  this.onAddNewObject.emit(true);
  }

  onDeleteObject(index: number): void {
    this.onDeleteThisIndex.emit(index);
  }

  onViewModifyObject(index: number): void {
    this.onSetTargetIndex.emit(index);
  }

}
