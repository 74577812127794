import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import * as moment from "moment";
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { faTimesCircle, faCheckCircle, faUndo, faPen, faDollarSign, faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import { MatDialog } from "@angular/material";
import { Subject } from "rxjs";
import { Order } from "src/app/core/models/order/order.model";
import { Router } from '@angular/router';
import { CrudService } from "src/app/core/services/data/crud.service";
import { ErrorHandlerService } from "src/app/core/services/util/error-handler.service";
import { LoaderMessagingService } from "src/app/core/services/messaging/loader-messaging.service";
import { ConfirmDialogComponent } from "src/app/shared/components/confirm-dialog/confirm-dialog.component";
import { DialogBoxComponent } from "src/app/shared/components/dialog-box/dialog-box.component";
import { dialogBoxSuccessTitle, withdrawnMsg } from "src/app/core/constants/message.constant";
import {
  PROCESSING_STATUS_1,
  PROCESSING_STATUS_2,
  PROCESSING_STATUS_3,
  PROCESSING_STATUS_4,
  PROCESSING_STATUS_5,
  ORDER_ITEM_TYPE_1,
  PROCESSING_STATUS_6,
  PROCESSING_STATUS_7,
  ORDER_ITEM_TYPE_2,
  ORDER_ITEM_TYPE_3,
  ORDER_ITEM_TYPE_4,
} from "src/app/core/constants/configuration/order-constants.config";
import { UserService } from "src/app/core/services/util/user.service";
import { ADMIN, SUPERVISOR, SPECIALIST } from 'src/app/core/constants/configuration/role-constants.config';
import { OrderItemAdjustmentDialogComponent } from '../order-item-adjustment-dialog/order-item-adjustment-dialog.component';
import { getOrderBundleByItem, getItemLatestStatusHistoryEndpoint, getItemAdjustmentHistoryEndpoint, getItemCreatedStatusHistoryEndpoint, getItemLatestHistoryByStatusEndpoint } from '../../../../core/constants/endpoints.constant';
import { OrderItemStatusHistory } from '../../../../core/models/order/order-item-status-history.model';
import { takeUntil } from 'rxjs/operators';
import { OrderItemAdjustmentHistory } from '../../../../core/models/order/order-item-adjustment-history.model';
import { Class } from 'src/app/core/models/class.model';
import { OrderItemWithdrawDialogComponent } from '../order-item-withdraw-dialog/order-item-withdraw-dialog.component';
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { OrderItemRefundConfirmationDialogComponent } from '../order-item-refund-confirmation-dialog/order-item-refund-confirmation-dialog.component';
import { Task } from 'src/app/core/models/order/task.model';
import { TASKS_PATH } from 'src/app/core/constants/routes.constant';
import { OrderBundle } from 'src/app/core/models/order/order-bundle.model';
import { TASK_GROUP_2 } from 'src/app/core/constants/configuration/task-constants.config';

@Component({
  selector: 'app-order-items-classes',
  templateUrl: './order-items-classes.component.html',
  styleUrls: ['./order-items-classes.component.scss'],
})
export class OrderItemsClassesComponent implements OnInit {

  @Input() canRemoveItem: boolean = true;
  @Input() orderItemAssoc: any[] = [];
  @Input() processingStatus: any[];
  @Input() forCreate: boolean;
  @Input() forTaskPage: boolean = false;
  @Output() onGetNewOrderDetails = new EventEmitter<any>();
  @Output() onCancel = new EventEmitter<any>();
  @Output() onPreComplete = new EventEmitter<any>();
  @Output() onComplete = new EventEmitter<any>();
  @Output() onPreCompleteWTB = new EventEmitter<any>();
  @Output() onCompleteWTB = new EventEmitter<any>();
  @Output() onAdjust = new EventEmitter<any>();
  @Output() requestToCancel = new EventEmitter<any>();
  @Output() undoRequestToCancel = new EventEmitter<any>();
  @Output() onWithdraw = new EventEmitter<any>();
  orderDetailsValue: Order;
  taskDetailsValue: any;
  orderItemsList: any[] = [];
  orderGroupCodes: string[] = [];
  unsubscribe: Subject<any> = new Subject();
  referenceTypes: any[];
  selectedItems: any[] = [];
  selectedItemStatusHistory: OrderItemStatusHistory;
  selectedItemAdjustmentHistory: OrderItemAdjustmentHistory;
  selectedOrderBundleByItem: OrderBundle;
  canRequestToCancel: boolean = false;
  canUndoRequestToCancel: boolean = false;

  completeIcon: IconDefinition = faCheckCircle;
  removeIcon: IconDefinition = faTimesCircle;
  undoIcon: IconDefinition = faUndo;
  editIcon: IconDefinition = faPen;
  refundIcon: IconDefinition = faDollarSign;
  withdraw: IconDefinition = faSignOutAlt;

  collapse: boolean = false;
  //ICONS
  faChevronDown = faChevronDown;
  faChevronUp = faChevronUp;

  collapsed() {
    this.collapse = !this.collapse;
  }

  REQUEST_CANCEL: any = { action: "REQUEST_CANCEL", label: ""  };
  UNDO_REQUEST_CANCEL: any = { action: "UNDO_REQUEST_CANCEL", label: ""  };
  PRE_COMPLETE: any = { action: "PRE_COMPLETE", label: "pre-completed"  };
  COMPLETE: any = { action: "COMPLETE", label: "completed"  };
  CANCEL: any = { action: "CANCEL", label: "cancelled"  };

  constructor(
    private crudService: CrudService,
    private dialog: MatDialog,
    private errorHandlerService: ErrorHandlerService,
		private loaderMessagingService: LoaderMessagingService,
		private userService: UserService,
    private router: Router,
  ) {
    this.loaderMessagingService.showPageLoader(true);
  }

  ngOnInit() {
    this.loaderMessagingService.showPageLoader(true);
    this.collapse = !!this.forCreate;
  }

  withdrawTooltipText : string = withdrawnMsg;

  @Input()
  set orderDetails(value) {
    this.orderDetailsValue = null;
    this.orderDetailsValue = value;
    this.selectedItems = [];
    this.groupItemsByCurriculum();
  }

  @Input()
  set taskDetails(value) {
    this.taskDetailsValue = value;
  }
  
  get canPreCompleteWTB(): boolean {
    return this.taskDetailsValue.status !== PROCESSING_STATUS_2;
  }

  get canCompleteWTB(): boolean {
    return this.taskDetailsValue.status !== PROCESSING_STATUS_2 && this.taskDetailsValue.status !== PROCESSING_STATUS_3;
  }

  get isWorkbookTextBook(): boolean {
    let isWorkbookTextBook = false;
    if(this.taskDetailsValue && this.taskDetailsValue.groupType === TASK_GROUP_2){
      isWorkbookTextBook = true;
    }
    return isWorkbookTextBook;
  }

  isClass(item){
    return item.referenceType === ORDER_ITEM_TYPE_1;
  }

  getReferenceTypeValue(value: string): string {
    let referenceType = this.referenceTypes ? this.referenceTypes.find(type => type.code === value) : null;
    return referenceType && referenceType.name;
  }

  groupItemsByCurriculum() {
    this.orderItemsList = this.orderDetailsValue.orderItems.filter(item => item.referenceType === ORDER_ITEM_TYPE_1);
    console.log(this.orderItemsList);
    this.orderItemsList.forEach(item => {
      this.getItemCreatedStatusDetails(item)
    });
    if (this.orderItemAssoc.length > 0) {
      let tempList = [];
      this.orderItemAssoc.forEach(itemAssoc => {
        this.orderItemsList.forEach(item => {
          if (item.id === itemAssoc.txnOrderItemId) {
            tempList.push(item);
          }
        });
      });
      this.orderItemsList = tempList;
    }

    this.orderDetailsValue.orderItems.forEach(item => {
      if (!this.orderGroupCodes.includes(item.txnOrderGroup)) this.orderGroupCodes.push(item.txnOrderGroup);
    });
    this.orderGroupCodes.sort();
    this.bindItemBundle(this.orderItemsList);
    // For each items in orderItems List: Set activeStatus:
    //this.retrieveActiveStatus();
  }

  
  private bindItemBundle(itemList) {
    if(itemList != null && this.orderDetailsValue.orderBundles != null) {
      itemList.forEach(item => {
        this.orderDetailsValue.orderBundles.forEach(bundle => {
          bundle.orderBundleXCurriculums.forEach(curr => {
            curr.orderBundleCurriculumXClasses.forEach(currItem => {
              if(currItem.orderItem.id === item.id) {
                item.orderBundle = bundle;
              }
            })
          })
        })
      });
    }
  }


  getActiveStatusEndpoint(
    referenceType: string,
    referenceCode: string
  ): string {
    let path: string;
    switch(referenceType) {
      case ORDER_ITEM_TYPE_1:
        path = "/classes/get-by-code";
      break;
      // soon if status for other types.      
    }
    return path
    .concat("?")
    .concat("code=")
    .concat(referenceCode);
  }

  retrieveActiveStatus(): void {
    this.orderItemsList.forEach(
      orderItem => {
        let path: String;
        if (orderItem.referenceType == ORDER_ITEM_TYPE_1) {
          this.crudService.getById<Class>(
            this.getActiveStatusEndpoint(
              orderItem.referenceType,
              orderItem.referenceCode
            )
          )
          .pipe(takeUntil(this.unsubscribe))
          .subscribe(
            response => {
              orderItem.activeStatus = response.status === 'STATUS_1' || response.status === 'ACTIVE'
              ? 'Active'
              : 'Inactive';
            },
            this.errorHandlerService.handleError, this.handleCompletion
          );
        }
      }
    );
  }

  formatAmount(value: number): string {
    value = value != null ? value : 0;
    return value >= 0 ? `$${value.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}`
      : `$(${(value * -1).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')})`
  }
  
  formatDate(value: Date): string {
    return moment(value).format('MMM d, y hh:mm:ss a')
  }
  
  onRemoveItem(item) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, { autoFocus: false });
    dialogRef.componentInstance.confirmMessage = "Are you sure you want to remove the class? \n" + 
      "This will automatically remove attached resources that were selected together with the class.";
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.onGetNewOrderDetails.emit(item);
        const dialogRef = this.dialog.open(DialogBoxComponent);
        dialogRef.componentInstance.dialogTitle = dialogBoxSuccessTitle;
        dialogRef.componentInstance.contentMessage = 'Class was successfully removed.';
      }
    });
  }

  get isUserTeacher(): boolean {
    return !this.userService.hasRole([ADMIN, SUPERVISOR, SPECIALIST]);
  }

  hasClass(code: string): boolean {
    return this.orderItemsList.filter(item => item.txnOrderGroup === code &&
      item.referenceType === ORDER_ITEM_TYPE_1).length > 0;
  }

  isItemSelected(item: any): boolean {
    return this.selectedItems.filter(selectedItem => selectedItem.referenceCode === item.referenceCode && selectedItem.id === item.id).length > 0
  }

  isAllItemSelected() {
    return this.selectedItems.length > 0 && this.selectedItems.length === this.orderItemsList.length
  }

  isRefunded(item) {
    return item.status === PROCESSING_STATUS_6
  }

  isAdjusted(item) {
    return item.status === PROCESSING_STATUS_7
  }

  onSelectAll($event: any) {
    if ($event.checked) {
      this.selectedItems = this.orderItemsList;
    } else {
      this.selectedItems = [];
    }
    if (this.selectedItems.length === 1 && this.selectedItems[0].forCancel &&
        [PROCESSING_STATUS_3, PROCESSING_STATUS_4, PROCESSING_STATUS_6, PROCESSING_STATUS_7].includes(this.selectedItems[0].status)) 
      this.getItemLatestStatusHistory(this.selectedItems[0].id);
      this.canRequestToCancel = this.selectedItems.length > 0 &&
        this.selectedItems.filter(selectedItem => selectedItem.forCancel || selectedItem.status === PROCESSING_STATUS_1 ||
          selectedItem.status === PROCESSING_STATUS_6 || selectedItem.withdrawn).length === 0;
      this.canUndoRequestToCancel = this.selectedItems.length > 0 && this.selectedItems.filter(selectedItem =>
      !selectedItem.forCancel || (selectedItem.resourceOrderDetails && selectedItem.resourceOrderDetails.required)).length === 0;
  }

  onSelectItem($event: any, item: any) {
    if ($event.checked) {
      this.selectedItems.push(item);
    } else {
      this.selectedItems = this.selectedItems.filter(selectedItem => selectedItem.id !== item.id);
    }
    if (this.selectedItems.length === 1 && this.selectedItems[0].forCancel &&
        [PROCESSING_STATUS_3, PROCESSING_STATUS_4, PROCESSING_STATUS_6, PROCESSING_STATUS_7].includes(this.selectedItems[0].status)) 
      this.getItemLatestStatusHistory(this.selectedItems[0].id);
      this.canRequestToCancel = this.selectedItems.length > 0 &&
        this.selectedItems.filter(selectedItem => selectedItem.forCancel || selectedItem.status === PROCESSING_STATUS_1 ||
          selectedItem.status === PROCESSING_STATUS_6 || selectedItem.withdrawn).length === 0;
      this.canUndoRequestToCancel = this.selectedItems.length > 0 && this.selectedItems.filter(selectedItem =>
        !selectedItem.forCancel || (selectedItem.resourceOrderDetails && selectedItem.resourceOrderDetails.required)).length === 0;
  }

  getStatusName(statusCode: string) {
    const statusName: any = this.processingStatus.find(status => status.code === statusCode);
    return statusName ? statusName.name : "";
  }

  getStatusClass(statusCode: string): string {
    switch (statusCode) {
      case PROCESSING_STATUS_1: return "approved status"
      case PROCESSING_STATUS_2: return "processing status"
      case PROCESSING_STATUS_3: return "pre-completed status"
      case PROCESSING_STATUS_4: return "completed status"
      case PROCESSING_STATUS_5: return "canceled status"
      case PROCESSING_STATUS_6: return "refunded status"
      case PROCESSING_STATUS_7: return "adjusted status"
      default: return "status"
    }
  }

  hasAdditionalItemDetails(item) {
    return (item.forCancel || this.isShowModifiedDate(item))
  }

  hasReason(item) {
    return item.reason != null && item.reason != '';
  }

  isShowModifiedDate(item) {
    return (item.id != 0 && item.id != null) && [PROCESSING_STATUS_3, PROCESSING_STATUS_4, PROCESSING_STATUS_6, PROCESSING_STATUS_7].includes(item.status)
  }

  get canPreComplete(): boolean {
    return this.selectedItems.length > 0 && 
      this.selectedItems.filter(item => item.status !== PROCESSING_STATUS_2 || item.forCancel).length === 0 && this.orderDetailsValue.syCode != "2019-2020";
  }

  get canComplete(): boolean {
    return this.selectedItems.length > 0 && 
      this.selectedItems.filter(item => ![PROCESSING_STATUS_2, PROCESSING_STATUS_3].includes(item.status) || item.forCancel).length === 0 && this.orderDetailsValue.syCode != "2019-2020";
  }

  get canCancel(): boolean {
    return this.isUserTeacher ? this.selectedItems.length > 0 &&
      this.selectedItems.filter(item => !(this.orderDetailsValue.status === PROCESSING_STATUS_1 && 
        item.status === PROCESSING_STATUS_1)).length === 0 && this.selectedItems.filter(item => item.inBundle).length <= 1
      : this.selectedItems.length > 0 && this.selectedItems.filter(item => item.inBundle).length <=1 && !this.isPastThirtyDays && this.orderDetailsValue.syCode != "2019-2020";
  }

  get canNewCancel(): boolean {
    if(this.selectedItems.length <= 0 || this.selectedItems.length > 1 ) {
      return true;
    } else {
      if(this.isUserTeacher) {
        return this.selectedItems.length === 1 &&
          this.selectedItems.filter(item => !(item.status === PROCESSING_STATUS_2 || item.status === PROCESSING_STATUS_3 ||
          item.status === PROCESSING_STATUS_4 || item.status === PROCESSING_STATUS_5 ||
          item.status === PROCESSING_STATUS_6 || item.status === PROCESSING_STATUS_7)).length === 0 &&
          this.selectedItems.filter(item => item.inBundle).length <= 1 && this.orderDetailsValue.syCode != "2019-2020";
      } else {
        return this.selectedItems.length === 1 &&
          this.selectedItems.filter(item => !(item.status === PROCESSING_STATUS_3 ||
          item.status === PROCESSING_STATUS_4 || item.status === PROCESSING_STATUS_5 ||
          item.status === PROCESSING_STATUS_6 || item.status === PROCESSING_STATUS_7)).length === 0 &&
          this.selectedItems.filter(item => item.inBundle).length <= 1 && this.orderDetailsValue.syCode != "2019-2020";
      }
    }
  }

  get canRefund(): boolean {
    return (this.selectedItems.length === 1 &&
      [PROCESSING_STATUS_3, PROCESSING_STATUS_4].includes(this.selectedItems[0].status) &&
      this.isPastThirtyDays && (this.selectedItems[0].newPrice === null || this.selectedItems[0].newDeposit === null || 
      this.selectedItems[0].newPerClassCost === null) && this.selectedItems[0].adjustedPrice === null && 
      this.selectedItems[0].adjustedDeposit === null && this.selectedItems[0].adjustedPerClassCost === null) && this.orderDetailsValue.syCode != "2019-2020";
  }

  get canNewRefund(): boolean {
    if(this.selectedItems.length <= 0) {
      return false;
    } else {
      return (this.selectedItems.length === 1 &&
        [PROCESSING_STATUS_3, PROCESSING_STATUS_4, PROCESSING_STATUS_6].includes(this.selectedItems[0].status) &&
        (this.selectedItems[0].newPrice === null || this.selectedItems[0].newDeposit === null || 
        this.selectedItems[0].newPerClassCost === null) && this.selectedItems[0].adjustedPrice === null && 
        this.selectedItems[0].adjustedDeposit === null && this.selectedItems[0].adjustedPerClassCost === null) && this.orderDetailsValue.syCode != "2019-2020";
    }
  }


  get canAdjust(): boolean {
    return this.selectedItems.length === 1 &&
      [PROCESSING_STATUS_3, PROCESSING_STATUS_4, PROCESSING_STATUS_6, PROCESSING_STATUS_7].includes(this.selectedItems[0].status);
  } 

  
  get canWithdraw(): boolean {
    return (this.selectedItems.length === 1 &&
      [PROCESSING_STATUS_6, PROCESSING_STATUS_7].includes(this.selectedItems[0].status) && 
      this.selectedItems[0].referenceType === ORDER_ITEM_TYPE_1 && !this.selectedItems[0].withdrawn);
  }

  withdrawConfirmDialog() {
    const dialogRef = this.dialog.open(OrderItemWithdrawDialogComponent, { width: "50%" });
    dialogRef.componentInstance.item = this.selectedItems[0];
    dialogRef.componentInstance.close.subscribe(() => {
      dialogRef.close();
    });
    dialogRef.componentInstance.withdraw.subscribe((result) => {
      const data = {};
      data["selectedItemId"] = this.selectedItems[0].id;
      data["itemName"] = this.selectedItems[0].referenceCode;
      data["itemCode"] = this.selectedItems[0].referenceName;
      data["reason"] = result; 
      this.onWithdraw.emit(data);
      dialogRef.close();
    });
  }

  get isPastThirtyDays(): boolean {
    if (this.selectedItems.length === 1 && this.selectedItemStatusHistory) {
      let dateLimit = moment(this.selectedItemStatusHistory.dateModified).add(30, 'days');
      return moment(this.selectedItems[0].rtcModifiedDate).isAfter(dateLimit);
    } else return false;
  }

  showConfirmDialogWTB(code: any){
    let message = `Are you sure you want to tag the task as ${code.label}?`;

      const dialogRef = this.dialog.open(ConfirmDialogComponent, { autoFocus: false });
      dialogRef.componentInstance.confirmMessage = message;
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          switch (code.action) {
            case this.PRE_COMPLETE.action: {
              this.onPreCompleteWTB.emit(this.taskDetailsValue);
              this.selectedItems = [];
              this.canUndoRequestToCancel = false;
              break;
            }
            case this.COMPLETE.action: {
              this.onCompleteWTB.emit(this.taskDetailsValue);
              this.selectedItems = [];
              this.canUndoRequestToCancel = false;
              break;
            }
          }
        }
      });
  }

  showConfirmDialog(code: any) {
    let selectedItemsCodes: string[] = [];
    this.selectedItems.slice().forEach(item => {
      selectedItemsCodes.push(item.referenceCode)
    });
    
    let message = `Are you sure you want to tag the selected ${this.selectedItems.length > 1 ? 'classes' : 'class'} as ${code.label}?`;
    
    if (code.action === this.REQUEST_CANCEL.action) {
      message = `Are you sure you want to submit a Request to Cancel for the selected ${this.selectedItems.length > 1 ? 'classes' : 'class'}?`
    } else if (code.action === this.UNDO_REQUEST_CANCEL.action) {
      message = `Are you sure you want to undo the Request to Cancel for the selected ${this.selectedItems.length > 1 ? 'classes' : 'class'}?`
    }

    if (this.selectedItems && this.selectedItems.length > 0) {
      const dialogRef = this.dialog.open(ConfirmDialogComponent, { autoFocus: false });
      dialogRef.componentInstance.confirmMessage = message;
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          switch (code.action) {
            case this.REQUEST_CANCEL.action: {
              this.requestToCancel.emit(selectedItemsCodes);
              this.selectedItems = [];
              this.canRequestToCancel = false;
              break;
            }
            case this.UNDO_REQUEST_CANCEL.action: {
              this.undoRequestToCancel.emit(selectedItemsCodes);
              this.selectedItems = [];
              this.canUndoRequestToCancel = false;
              break;
            }
            case this.PRE_COMPLETE.action: {
              this.onPreComplete.emit(this.selectedItems);
              this.selectedItems = [];
              this.canUndoRequestToCancel = false;
              break;
            }
            case this.COMPLETE.action: {
              this.onComplete.emit(this.selectedItems);
              this.selectedItems = [];
              this.canUndoRequestToCancel = false;
              break;
            }
            case this.CANCEL.action: {
              this.onCancel.emit(this.selectedItems);
              this.selectedItems = [];
              this.canUndoRequestToCancel = false;
              break;
            }
          }
        }
      });
    }
  }

  refundConfirmationDialog() {
    if(this.selectedItems[0].status == PROCESSING_STATUS_6) {
      this.adjustmentConfirmDialog(true);
    } else {
      const dialogRef = this.dialog.open(OrderItemRefundConfirmationDialogComponent, { width: "50%" });
      dialogRef.componentInstance.item = this.selectedItems[0];
      dialogRef.componentInstance.close.subscribe(() => {
        dialogRef.close();
      });
      dialogRef.componentInstance.cancel.subscribe(() => {
        dialogRef.close();
        this.showConfirmDialog(this.CANCEL);
      });
      dialogRef.componentInstance.refund.subscribe((result) => {
        dialogRef.close();
        this.adjustmentConfirmDialog(true);
      });
    }
  }

  adjustmentConfirmDialog(forRefund: boolean) {
    if(this.selectedItems[0].inBundle) {
      this.getBundleInfo(this.selectedItems[0].id, forRefund)
    } else {
      this.openAdjustmentDialog(forRefund, null)
    }
  }

  openAdjustmentDialog(forRefund: boolean, orderBundle: OrderBundle) {
    const dialogRef = this.dialog.open(OrderItemAdjustmentDialogComponent, { width: "50%", height: "100%" });
      dialogRef.componentInstance.item = this.selectedItems[0];
      dialogRef.componentInstance.forRefund = forRefund;
      if(orderBundle != null) {
        dialogRef.componentInstance.orderBundle = orderBundle;
      }
      dialogRef.componentInstance.close.subscribe(() => {
        dialogRef.close();
      });
      dialogRef.componentInstance.refund.subscribe((result) => {
        if (result) {
          this.onAdjust.emit({
            orderCode: this.orderDetailsValue.code,
            itemId: this.selectedItems[0].id,
            amount: result.amount,
            type: result.type,
            forRefund: forRefund,
            remarks: result.remarks,
            itemCodeName: `${this.selectedItems[0].referenceCode} ${this.selectedItems[0].referenceName}`,
          });
          this.canRequestToCancel = false;
          dialogRef.close();
        }
      });
  }

  getItemLatestStatusHistory(orderItemId) {
		this.loaderMessagingService.showPageLoader(true);
    this.crudService
      .getById<OrderItemStatusHistory>(getItemLatestStatusHistoryEndpoint.concat(`?orderItemId=${orderItemId}`))
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(response => {
        this.selectedItemStatusHistory = response;
        this.getItemAdjustmentHistory(orderItemId);
      }, this.errorHandlerService.handleError);
  }

  getBundleInfo(orderItemId, forRefund) {
		this.loaderMessagingService.showPageLoader(true);
    this.crudService
      .getById<OrderBundle>(getOrderBundleByItem.concat(`?orderItemId=${orderItemId}`))
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(response => {
        this.selectedOrderBundleByItem = response;
        this.openAdjustmentDialog(forRefund, this.selectedOrderBundleByItem);
        this.handleCompletion();
      }, this.errorHandlerService.handleError, this.handleCompletion);
  }

  getItemCreatedStatusDetails(item) {
		this.loaderMessagingService.showPageLoader(true);
    this.crudService
      .getById<OrderItemStatusHistory>(getItemCreatedStatusHistoryEndpoint.concat(`?orderItemId=${item.id}`))
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(response => {
        if(response){
          item.createdStatusHistoryModifiedDate = response.dateModified ? response.dateModified : "";
          item.createdStatusHistoryModifiedBy = response.modifiedBy ? response.modifiedBy : "";
          item.createdStatusHistoryStatus = response.status ? response.status : "";
        }
        this.getItemCancelledStatusDetails(item);
      }, this.errorHandlerService.handleError, this.handleCompletion);
  }

  getItemCancelledStatusDetails(item) {
		this.loaderMessagingService.showPageLoader(true);
    this.crudService
      .getById<OrderItemStatusHistory>(getItemLatestHistoryByStatusEndpoint.concat(`?orderItemId=${item.id}&status=PROCESSING_STATUS_5`))
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(response => {
        if(response){
          item.cancelledStatusHistoryDate = response.dateModified ? response.dateModified : "";
          item.cancelledStatusHistoryModifiedBy = response.modifiedBy ? response.modifiedBy : "";
        }
        this.getItemPreCompletedStatusDetails(item);
    }, this.errorHandlerService.handleError, this.handleCompletion);
  }

  getItemPreCompletedStatusDetails(item) {
		this.loaderMessagingService.showPageLoader(true);
    this.crudService
      .getById<OrderItemStatusHistory>(getItemLatestHistoryByStatusEndpoint.concat(`?orderItemId=${item.id}&status=PROCESSING_STATUS_3`))
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(response => {
        if(response){
          item.precompletedStatusHistoryDate = response.dateModified ? response.dateModified : "";
          item.precompletedStatusHistoryBy = response.modifiedBy ? response.modifiedBy : "";
        }
        this.getItemCompletedStatusDetails(item);
    }, this.errorHandlerService.handleError, this.handleCompletion);
  }

  getItemCompletedStatusDetails(item) {
		this.loaderMessagingService.showPageLoader(true);
    this.crudService
      .getById<OrderItemStatusHistory>(getItemLatestHistoryByStatusEndpoint.concat(`?orderItemId=${item.id}&status=PROCESSING_STATUS_4`))
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(response => {
        if(response){
          item.completedStatusHistoryDate = response.dateModified ? response.dateModified : "";
          item.completedStatusHistoryBy = response.modifiedBy ? response.modifiedBy : "";
        }
    }, this.errorHandlerService.handleError, this.handleCompletion);
  }

  getItemAdjustmentHistory(orderItemId) {
		this.loaderMessagingService.showPageLoader(true);
    this.crudService
      .getById<OrderItemAdjustmentHistory>(getItemAdjustmentHistoryEndpoint.concat(`?orderItemId=${orderItemId}`))
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(response => {
        this.selectedItemAdjustmentHistory = response;
      }, this.errorHandlerService.handleError, this.handleCompletion);
  }

	handleCompletion = (): void => {
		this.loaderMessagingService.showPageLoader(false);
  };
  
  getClassLink(item: any) {
    var task = this.taskDetailsValue.filter(task => {
      let orderItemFiltered = task.orderItemAssoc.filter(orderItem => {
        return orderItem.txnOrderItemId === item.id
      });
      if (orderItemFiltered.length > 0 && task.groupType =="TASK_GROUP_1") {
        return true;
      } else {
        return false;
      }
    })[0];
    this.router.navigate([TASKS_PATH, this.orderDetailsValue.syCode, task.id]);
  }

}
