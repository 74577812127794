import {
  Component,
  OnInit,
  Input,
  EventEmitter,
  ElementRef,
  Output,
  OnChanges,
  AfterViewInit,
} from "@angular/core";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { faMinus } from "@fortawesome/free-solid-svg-icons";
import { MatDatepickerInputEvent } from "@angular/material/datepicker";
import * as moment from "moment";
import { dateSearchFormat } from "src/app/core/constants/configuration/common.constant";
import { DataProperties } from "src/app/core/models/data-properties.model";
import { ValueTransformer } from "@angular/compiler/src/util";

@Component({
  selector: "app-filter",
  templateUrl: "./filter.component.html",
  styleUrls: ["./filter.component.scss"],
})
export class FilterComponent implements OnInit {

  faPlus = faPlus;
  faMinus = faMinus;

  @Output() filterOnChange = new EventEmitter();
  @Input() dataProperties: DataProperties[];
  @Input() curriculums: any[];
  @Input() taskGroupType: any[];
  @Input() statusFilter: Map<string, string>;
  @Input() set clearFilters(clear: boolean) {
    if (clear) this.ngOnInit();
  };

  filters: string[] = [];
  selectedFilters: Map<string, string> = new Map<string, string>();
  availableFilters: DataProperties[];
  filterLabels: Map<string, string> = new Map<string, string>();
  disable: boolean = false;
  statusFilterLabel: any;

  constructor(private element: ElementRef) {}

  ngOnInit(): void {
    this.availableFilters = Object.assign([], this.dataProperties.filter(prop => prop.filterable));
    this.filters = [];
    this.selectedFilters.clear();
    this.filterLabels.clear();
    this.disable = false;
    //this.filterByStatus();
  }

  filterByStatus() {

    this.availableFilters.sort((a, b) => a.property !== b.property ? a.property == 'status'  ? -1 : 1 : 0);
    if(!this.disable && this.availableFilters[0].property == 'status'){
      this.onAddFilter();
    }
    if(this.statusFilter) {
      this.statusFilter.forEach((value: string, key: string) => {
        this.filterParamOnChange(value, key);
        this.statusFilterLabel = {
          label: key,
          value: value,
        }
      });
    }
  }

  onAddFilter(): void {
    if (this.availableFilters.length > 0) {
      this.filters.push(this.availableFilters[0].property);
    }
    this.disable = true;
  }

  onRemoveFilter(key: string) {
    this.selectedFilters.delete(key);
    this.filters.splice(this.filters.indexOf(key), 1);
    this.filters.length > 0 ? this.availableFilters.push(this.dataProperties.find(prop => prop.property === key)) : this.ngOnInit();
    this.filterOnChange.emit(this.selectedFilters);
  }

  onChange(key: string) {
   this.filters[this.filters.length - 1] = key;
  }

  filterParamOnChange(filterValue: string, key: string) {
    console.log("filterValue",filterValue);
    if (filterValue && filterValue.trim().length !== 0) {
      this.setFilterValue(key, filterValue);
    }
  }

  onDateSelect(event: MatDatepickerInputEvent<Date>, key: string) {
    this.setFilterValue(key, moment(event.value).format(dateSearchFormat));
  }

  checkFilterExist(filter: string) {
    return this.selectedFilters.get(filter) !== "";
  }

  checkValue(index: number) {
    return this.filters[index] !== "";
  }

  private setFilterValue(key: string, filterValue: string): void {

    this.selectedFilters.set(key, filterValue);
    this.filterLabels.set(key, this.dataProperties.find(property => property.property === key).label);
    this.removeFromAvailableFilters(key);
    this.disable = false;
    this.filterOnChange.emit(this.selectedFilters);

  }

  removeFromAvailableFilters(key: string): void {
    this.availableFilters = Object.assign([], this.availableFilters.filter(filter => filter.property !== key));
  }

}
