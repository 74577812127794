import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';

@Component({
  selector: 'confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss']
})
export class ConfirmDialogComponent implements OnInit {

  constructor(public dlDialogRef: MatDialogRef<ConfirmDialogComponent>) { }

  ngOnInit() {
  }

  public confirmTitle: string;
  public confirmMessage: string;
  public cancelLabel: string;
  public confirmLabel: string;

}
