import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { HqtMainForm } from 'src/app/core/models/form/hqt-main-form.model';
import { FormGroup } from '@angular/forms';
import { FormUtil } from 'src/app/core/services/util/form.util';
import { NEXT } from 'src/app/core/constants/configuration/common.constant';
import { allHqtEndpoint, existsByEmail } from 'src/app/core/constants/endpoints.constant';

@Component({
  selector: 'app-hqt-main-form',
  templateUrl: './hqt-main-form.component.html',
  styleUrls: ['./hqt-main-form.component.scss']
})
export class HqtMainFormComponent implements OnInit {

  @Output() getFormValue = new EventEmitter<any>();
  @Output() changePage = new EventEmitter<any>();
  @Input() hqtMainFormValues: HqtMainForm;
  @Input() forUpdate: boolean;
  @Input() isFirst: boolean;
  @Input() isLast: boolean;
  @Input() status: any[];
  @Input() disableStatus: boolean;

  hqtForm: FormGroup;
  checkEndpoint: string = allHqtEndpoint.concat(existsByEmail);

  constructor(private formUtil: FormUtil) {
    this.hqtForm = formUtil.createHqtMainForm();
  }

  ngOnInit() {
    if (this.forUpdate || this.hqtMainFormValues) this.initFormValues();
  }

  onClick = (label: string): void => {

    if (label === NEXT) {

      this.validateUntouchedForms();
      if (!this.hqtForm.valid) {
        return;
      } else {
        this.changePage.emit(label);
        this.getFormValue.emit(this.hqtForm.value);
        this.hqtForm.reset();
      }
      
    }

  }

  private initFormValues(): void {
    this.hqtForm.setValue(this.formUtil.initHqtMainForm(this.hqtMainFormValues));
  }

  private validateUntouchedForms(): void {
    Object.keys(this.hqtForm.controls).forEach(control => {
      this.hqtForm.get(control).markAsTouched();
      this.hqtForm.get(control).markAsDirty();
    });
  }

  onGetResult(result: any): void {

    if (result.exists) {
      if ((this.hqtMainFormValues && result.value !== this.hqtMainFormValues.email && this.forUpdate) || !this.forUpdate) {
        this.hqtForm.controls['email'].setErrors({'emailExists': true});
      } else {
        this.hqtForm.controls['email'].updateValueAndValidity();
      }
    }
  }

}
