import { Component, EventEmitter, OnInit, Output, Input } from '@angular/core';
import { MatDialog } from '@angular/material';
import { OrderItem } from 'src/app/core/models/order/order-item.model';
import { faTimes } from "@fortawesome/free-solid-svg-icons";


@Component({
  selector: 'app-order-item-refund-confirmation-dialog',
  templateUrl: './order-item-refund-confirmation-dialog.component.html',
  styleUrls: ['./order-item-refund-confirmation-dialog.component.scss']
})
export class OrderItemRefundConfirmationDialogComponent implements OnInit {

  @Input() item: OrderItem;
  @Output() close = new EventEmitter<any>();
  @Output() cancel = new EventEmitter<any>();
  @Output() refund = new EventEmitter<any>();
  
  faTimes = faTimes;

  constructor(
    private dialog: MatDialog,
  ) { }

  ngOnInit() {
  }

  onClose() {
    this.close.emit(null);
  }

  onCancel() {
    this.cancel.emit(null);
  }

  onRefund() {
    this.refund.emit(null);
  }

}
