export const downloadConfirmationBundle: string =
  "Are you sure you want to download data? The file will contain ALL Bundle data based on filter.";
export const downloadConfirmationCurriculum: string =
  "Are you sure you want to download data? The file will contain ALL Curriculum data based on filter. ";

export const downloadConfirmationAsset: string =
  "Are you sure you want to download data? The file will contain ALL Asset data based on filter.";

export const downloadConfirmationModel: string =
  "Are you sure you want to download data? The file will contain ALL Model data based on filter.";

export const downloadConfirmationClass: string =
  "Are you sure you want to download data? The file will contain ALL Class data based on filter.";
export const downloadConfirmationResource: string =
  "Are you sure you want to download data? The file will contain ALL Resource data based on filter.";

export const downloadErrorNoDataBundle: string =
  "There is no available bundle data to be downloaded.";

export const downloadErrorNoDataCurriculum: string =
  "There is no available curriculum data to be downloaded.";
export const downloadErrorNoDataClass: string =
  "There is no available class data to be downloaded.";
export const downloadErrorNoDataResource: string =
  "There is no available resource data to be downloaded.";
export const downloadErrorNoDataModel: string =
  "There is no available model data to be downloaded.";
export const downloadErrorNoDataAsset: string =
  "There is no available asset data to be downloaded.";


export const createSuccessCurriculum: string =
  "Curriculum is successfully created.";
export const createErrorCurriculum: string =
  "There was an error in creating curriculum:" + "\n";
export const updateSuccessCurriculum: string =
  "Curriculum is successfully updated.";
export const updateErrorCurriculum: string =
  "There was an error in updating curriculum:" + "\n";

export const createdSuccessModel: string =
  "Model is successfully created.";
export const updateSuccessModel: string =
  "Model is successfully updated.";
export const updateErrorModel: string =
  "There was an error in updating model:" + "\n";

export const createdSuccessAsset: string =
  "Asset is successfully created.";
export const updateSuccessAsset: string =
  "Asset is successfully updated.";
export const updateErrorAsset: string =
  "There was an error in updating asset:" + "\n";

export const createSuccessClass: string = "Class is successfully created.";
export const createErrorClass: string =
  "There was an error in creating class:" + "\n";
export const updateSuccessClass: string = "Class is successfully updated.";
export const updateErrorClass: string =
  "There was an error in updating class:" + "\n";

export const createSuccessBundle: string = "Bundle is successfully created.";
export const createErrorBundle: string =
  "There was an error in creating bundle:" + "\n";
export const updateSuccessBundle: string = "Bundle is successfully updated.";
export const updateErrorBundle: string =
  "There was an error in updating bundle:" + "\n";

export const createSuccessResource: string =
  "Resource is successfully created.";
export const createErrorResource: string =
  "There was an error in creating resource:" + "\n";
export const updateSuccessResource: string =
  "Resource is successfully updated.";
export const updateErrorResource: string =
  "There was an error in updating resource:" + "\n";

export const createSuccessHqt: string = "HQT is successfully created.";
export const createErrorHqt: string =
  "There was an error in creating HQT:" + "\n";
export const updateSuccessHqt: string = "HQT is successfully updated.";
export const updateErrorHqt: string =
  "There was an error in updating HQT:" + "\n";
export const deleteErrorHqt: string =
  "Unable to delete HQT. Please make sure that the status of the HQT is inactive before deleting.";
export const deleteErrorModel: string =
  "Unable to delete Model. Please make sure that there are no associated asset for this model before deleting.";
export const deleteErrorAsset: string =
  "Unable to delete Asset. Please make sure that the item you selected is not Checked Out before deleting.";

export const backConfirmation: string =
  "Are you sure you want to go back? The data created in this page will be discarded.";
export const deleteClassConfirmation: string =
  "Are you sure you want to delete this class?";
export const deleteResourceConfirmation: string =
  "Are you sure you want to delete this resource?";
export const deleteHqtConfirmation: string =
  "Are you sure that your really want to delete this HQT %?";

export const deleteModelConfirmation: string =
  "Are you sure that your really want to delete this Model %?";

  export const deleteAssetConfirmation: string =
  "Are you sure that your really want to delete this Asset %?";

export const curriculumErrorNoClass: string =
  "There is no class data. Please add a class.";

export const formPatternErrorMessage: string =
  "Should only contain alphabet, numbers or !#$%&'*+-/=?^_`{|}~";

export const dialogBoxErrorTitle: string = "Error";
export const dialogBoxSuccessTitle: string = "Success";

export const unauthorizeMessage: string =
  "Your session has expired. Please login.";

export const saveCurriculumConfirmation: string =
  "Are you sure you want to save Curriculum?";
export const saveClassConfirmation: string =
  "Are you sure you want to save Class?";
export const saveBundleConfirmation: string =
  "Are you sure you want to save Bundle?";
export const saveResourceConfirmation: string =
  "Are you sure you want to save Resource?";
export const saveHqtConfirmation: string = "Are you sure you want to save HQT?";
export const saveModelConfirmation: string =
  "Are you sure you want to save Model?";
  export const saveAssetConfirmation: string =
  "Are you sure you want to save Asset?";

export const downloadConfirmationModelError =
  "Are you sure you want to download the error file for Model Upload?";

export const downloadConfirmationTemplate =
  "Are you sure you want to download the template file for %?";

export const downloadConfirmationShippingDtls =
  "Are you sure you want to download Shipping Details";

export const taskActionConfirmMessage = "Are you sure you want to ? the selected tasks ??";
export const assignTaskSuccessMessage = "? successfully assigned to ??.";
export const preCompleteTaskSuccessMessage = "? successfully tagged as pre-completed";
export const completeTaskSuccessMessage = "? successfully tagged as completed";
export const completeCreateWorkbookTextBook = "Successfully created workbook/textbook task.";
export const createWorkbookTextbookConfirmMessage = "Are you sure you want to create workbook/textbook task?";

export const downloadCurriculumReportHeader = "Download Curriculum Report";
export const downloadCurriculumReportMessage = "Are you sure you want to download Curriculum Report for %?";
export const downloadOnlineAccountReportHeader = "Download Online Account Report";
export const downloadOnlineAccountReportMessage = "Are you sure you want to download Online Account Report for %?";

export const withdrawnMsg: string = "Unenrolled in class. Refund or Adjustment amounts are still applied."
export const approveordersConfirmMessage = "Are you sure you want to approve pending orders.";
export const approveordersSuccessMessage = "Orders are succssfully approved.";
