import { Component, OnInit, Inject, Input } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDatepickerInputEvent } from '@angular/material';
import { dateSearchFormat } from 'src/app/core/constants/configuration/common.constant';
import * as moment from "moment";

@Component({
  selector: 'app-filter-modal',
  templateUrl: './filter-modal.component.html',
  styleUrls: ['./filter-modal.component.scss']
})
export class FilterModalComponent implements OnInit {

  filters: Map<string, string> = new Map<string, string>();
  startDate: Date;
  endDate: Date;
  @Input() curriculums: any[];
  @Input() taskGroupType: any[];

  constructor(
    private dialogRef: MatDialogRef<FilterModalComponent>,
    @Inject(MAT_DIALOG_DATA) private filterCriteria: any[]) {
      this.filterCriteria.forEach(this.callCriteriaConsumer);
  }

  ngOnInit(): void {
  }

  onChange(value: string, key: string): void {
    this.filters.set(key, value);
  }

  onDateChange(event: MatDatepickerInputEvent<Date>, key: string): void {
    let value = moment(event.value).format(dateSearchFormat);
    this.filters.set(key, value);
  }

  onModalButtonClick(isFilter: boolean): void {

    this.filterCriteria.forEach(data => {
      if (data.value && !this.filters.has(data.prop)) {
        this.filters.set(data.prop, data.value);
      }
    });

    this.dialogRef.close(isFilter ? this.filters : null);
  }

  callCriteriaConsumer = (criteria: any): void => {

    const value: string = String(criteria.value);
    const prop: string = String(criteria.prop);

    if (value !== null && value.trim().length !== 0) {

      if (prop === "startDate") this.startDate = new Date(value);
      if (prop === "endDate") this.endDate = new Date(value);

    }

  }

}
