import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ResourceGroupForm } from 'src/app/core/models/form/resource-group-form-model';
import { BUNDLE_GROUP_1, BUNDLE_GROUP_2 } from 'src/app/core/constants/configuration/bundle-constants.config';

@Component({
  selector: 'app-resource-groups',
  templateUrl: './resource-groups.component.html',
  styleUrls: ['./resource-groups.component.scss']
})
export class ResourceGroupsComponent implements OnInit {

  @Output() linkClick: EventEmitter<string> = new EventEmitter();
  @Input() groups: ResourceGroupForm[];
  @Input() hasLink: boolean;

  data: any;

  constructor() { }

  ngOnInit() {
    this.customizedData();
  }

  private customizedData(): void {

    const copiedGroups = Object.assign([], this.groups);

    this.data = {
      curriculums: copiedGroups.filter(group => group.type === BUNDLE_GROUP_1),
      classes: copiedGroups.filter(group => group.type === BUNDLE_GROUP_2)
    }

  }

  onViewAssociation(code: string): void {
    this.linkClick.emit(code);
  }

}
