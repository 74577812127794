import { Injectable } from '@angular/core';
import { Curriculum } from 'src/app/core/models/curriculum.model';
import { Class } from '../../models/class.model';
import { Resource } from '../../models/resource.model';
import { CurriculumMainForm } from '../../models/form/curriculum-main-form.model';
import { STATUS_1, STATUS_2, ACTIVE } from '../../constants/configuration/common.constant';

@Injectable({ providedIn: 'root' })
export class CurriculumHelper {

    curriculum: Curriculum;
    classes: Class[] = [];
    resources: Resource[] = [];

    constructor() { }

    setCurriculumModel(curriculumFormValues: CurriculumMainForm, forUpdate: boolean, original?: Curriculum): Curriculum {

        return {
            id: forUpdate ? original.id : null,
            code: forUpdate ? original.code : null,
            name: curriculumFormValues.name,
            description: curriculumFormValues.description,
            status: curriculumFormValues.status === ACTIVE ? STATUS_1 : STATUS_2,
            createdBy: forUpdate ? original.createdBy : null,
            modifiedBy: forUpdate ? original.modifiedBy : null,
            createdDate: forUpdate ? original.createdDate : null,
            modifiedDate: forUpdate ? original.modifiedDate : null,
            //activeStatus: original.activeStatus,
            startDate: curriculumFormValues.startDate,
            endDate: curriculumFormValues.endDate,
            syCode: curriculumFormValues.syCode,
            deposit: curriculumFormValues.deposit ? curriculumFormValues.deposit : 0,
            hasDeposit: curriculumFormValues.hasDeposit ? curriculumFormValues.hasDeposit : false,
            fee: curriculumFormValues.fee ? curriculumFormValues.fee : 0,
            perClassCost: curriculumFormValues.perClassCost ? curriculumFormValues.perClassCost : 0,
            hasHqt: forUpdate ? original.hasHqt : false,
            classes: null,
            bookRequired: curriculumFormValues.bookRequired ? curriculumFormValues.bookRequired : false,
            scopes: curriculumFormValues.scopes ? curriculumFormValues.scopes : [],
        };

    }

    setCurriculumMainFormValues = (curriculum: Curriculum): CurriculumMainForm => {
        return {
            name: curriculum.name,
            description: curriculum.description,
            startDate: curriculum.startDate,
            endDate: curriculum.endDate,
            syCode: curriculum.syCode,
            status: curriculum.status,
            deposit: curriculum.deposit ? curriculum.deposit : 0,
            hasDeposit: curriculum.hasDeposit ? curriculum.hasDeposit : false,
            fee: curriculum.fee ? curriculum.fee : 0,
            perClassCost: curriculum.perClassCost ? curriculum.perClassCost : 0,
            bookRequired: curriculum.bookRequired ? curriculum.bookRequired : false,
            scopes: curriculum.scopes ? curriculum.scopes : [],
        }
    }

    initCurriculumMainFormValues = (): CurriculumMainForm => {
        return {
            name: null,
            description: null,
            startDate: null,
            endDate: null,
            syCode: null,
            status: null,
            deposit: 0,
            hasDeposit: false,
            fee: 0,
            perClassCost: 0,
            bookRequired: false,
            scopes: [],
        }
    }

}
