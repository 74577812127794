import { Component, OnInit, Output, EventEmitter, Input, OnDestroy } from '@angular/core';
import { Hqt } from 'src/app/core/models/hqt.model';
import { ErrorHandlerService } from 'src/app/core/services/util/error-handler.service';
import { MatDialog } from '@angular/material';
import { SAVE } from 'src/app/core/constants/configuration/common.constant';
import { ConfirmDialogComponent } from '../../../confirm-dialog/confirm-dialog.component';
import { saveHqtConfirmation } from 'src/app/core/constants/message.constant';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-hqt-summary',
  templateUrl: './hqt-summary.component.html',
  styleUrls: ['./hqt-summary.component.scss']
})
export class HqtSummaryComponent implements OnInit, OnDestroy {

  @Output() changePage: EventEmitter<any> = new EventEmitter();
  @Input() hqt: Hqt;
  @Input() isFirst: boolean;
  @Input() isLast: boolean;

  unsubscribe: Subject<any> = new Subject();

  constructor(
    private errorHandlerService: ErrorHandlerService,
    private dialog: MatDialog
  ) {

  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  onClick = (label: string): void => {
    if (label === SAVE) {
      const dialogRef = this.dialog.open(ConfirmDialogComponent);
      dialogRef.componentInstance.confirmMessage = saveHqtConfirmation;
      dialogRef.afterClosed()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(result => {
        if (result) {
          this.changePage.emit(label);
        }
      }, this.errorHandlerService.handleError);
    } else {
      this.changePage.emit(label);
    }
  }

}
