import { BundleDetailsDialogComponent } from './../bundle-details-dialog/bundle-details-dialog.component';
import { DataCustomizerService } from "src/app/core/services/util/data-customizer.service";
import { Component, OnInit, OnDestroy } from "@angular/core";
import { HeaderMessagingService } from "src/app/core/services/messaging/header-messaging.service";
import {
  homeModuleName,
  pageSizeOptions,
  ASC,
  CLOSE,
  fullYearFormat,
  OPEN,
  SY_CODE
} from "src/app/core/constants/configuration/common.constant";
import { CrudService } from "src/app/core/services/data/crud.service";
import { ErrorHandlerService } from "src/app/core/services/util/error-handler.service";
import { QueryService } from "src/app/core/services/util/query.service";
import { Bundle } from "src/app/core/models/bundle.model";
import { Subject } from "rxjs";
import { allBundlesEndpoint, getAllSchoolYearsEndpoint, getActiveSchoolYearEndpoint, getBundleTypesEndpoint, getSchoolYearsWithHistory, getCountByFilter } from "src/app/core/constants/endpoints.constant";
import { takeUntil } from "rxjs/operators";
import { downloadBundlesEndpoint } from "../../../../core/constants/endpoints.constant";
import {
  fileNameBundle,
  baseServerUri,
  xlsxFileMediaType,
  downloadFileExtension
} from "../../../../core/constants/configuration/config.constant";
import { FileService } from "../../../../core/services/data/file.service";
import { saveAs } from "file-saver";
import { DialogBoxComponent } from "../../../../shared/components/dialog-box/dialog-box.component";
import {
  downloadErrorNoDataBundle,
  downloadConfirmationBundle,
  dialogBoxErrorTitle
} from "../../../../core/constants/message.constant";
import { MatDialog } from "@angular/material";
import { ConfirmDialogComponent } from "../../../../shared/components/confirm-dialog/confirm-dialog.component";
import { Router } from "@angular/router";
import { faFilter } from "@fortawesome/free-solid-svg-icons";
import { LoaderMessagingService } from 'src/app/core/services/messaging/loader-messaging.service';
import { FilterModalComponent } from 'src/app/shared/components/filter-modal/filter-modal.component';
import * as moment from 'moment';
import { DataProperties } from 'src/app/core/models/data-properties.model';
import { changeSize } from 'src/app/core/constants/animations.constant';
import { bundleDataProperties } from 'src/app/core/constants/configuration/bundle-constants.config';
import { homeRoutingPermissions } from 'src/app/core/constants/configuration/role-constants.config';
import { BUNDLE_CREATE_PATH, BUNDLE_UPDATE_PATH, BUNDLES_PATH } from 'src/app/core/constants/routes.constant';
import { UserService } from "src/app/core/services/util/user.service";
import { FilterService } from "src/app/core/services/util/filter.service";
import { DownloadFiltersDialogComponent } from 'src/app/shared/components/download-filters-dialog/download-filters-dialog.component';
import { DownloadFiltersTypeEnum } from 'src/app/shared/components/download-filters-dialog/enums/download-filters-type-enum';
import { DownloadFilters } from 'src/app/shared/components/download-filters-dialog/models/download-filters';
import { ReferenceSchoolYearDTO } from 'src/app/core/models/dto/referennce-school-year-dto';

@Component({
  selector: "app-bundle-list",
  templateUrl: "./bundle-list.component.html",
  styleUrls: ["./bundle-list.component.scss"],
  animations: [changeSize]
})
export class BundleListComponent implements OnInit, OnDestroy {

  unsubscribe: Subject<any> = new Subject();
  items: any[] = [];
  pageSize: number = pageSizeOptions[0];
  pageIndex: number = 0;
  faFilter = faFilter;
  filters: Map<string, string> = new Map<string, string>();
  itemsCount: number;
  keyword: string = "";
  sortOrder: string;
  sortedBy: string;
  trigger: string = CLOSE;
  isType1: boolean = false;
  filterLabels: string[] = [];
  clearFilters: boolean = true;
  schoolYears: any[] = [];
  selectedSyId: number;
  schoolYear: string;
  bundleDataProperties: DataProperties[] = bundleDataProperties;
  bundleTypes: any[];
  filtersKey: string = "bundleFilters";
  schoolYearHistory: ReferenceSchoolYearDTO[];
  activeSchoolYear: string;

  constructor(
    private crudService: CrudService,
    private errorHandlerService: ErrorHandlerService,
    private headerMessagingService: HeaderMessagingService,
    private queryService: QueryService,
    private dataCustomizerService: DataCustomizerService,
    private downloadFileService: FileService,
    private loaderMessagingService: LoaderMessagingService,
    private router: Router,
    private dialog: MatDialog,
    private userService: UserService,
    private filterService: FilterService,
	) {
		this.userService.checkRolePermission(BUNDLES_PATH, homeRoutingPermissions);
    this.headerMessagingService.setHeader(homeModuleName, "", true, homeRoutingPermissions);
    let bundleFilters = this.filterService.getFilter(this.filtersKey);
		if (bundleFilters) {
			this.keyword = bundleFilters.keyword || "";
			this.filters = bundleFilters.filters ? this.filterService.objectToMap(bundleFilters.filters) : new Map<string, string>();
		}
  }

  ngOnInit(): void {
    this.loaderMessagingService.showPageLoader(true);
    this.getBundleTypes();
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  initParams(): void {
    this.sortedBy = this.bundleDataProperties[1].property;
    this.sortOrder = ASC;
    let yearNow: string = moment((new Date()).valueOf()).format(fullYearFormat);
    this.setSchoolYear(null);
    this.getSchoolYersFromCurrentToEarliest();
  }

  onSearch(keyword: string): void {
    this.keyword = keyword;
    this.pageIndex = 0;
    this.search();
  }

  search() {

    this.loaderMessagingService.showListLoader(true);
    this.items = [];
    this.filterService.setFilter(this.filtersKey, this.keyword, this.filters);

    this.crudService
      .getAll<Bundle>(allBundlesEndpoint.concat(this.queryService.buildBundleSearchQuery(
        this.pageIndex, this.pageSize, this.sortedBy, this.sortOrder, this.keyword, this.filters)))
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(response => {
        if (response) {
          this.itemsCount = response.totalElements;
          response.content.forEach((bundle: Bundle) =>
            this.items.push(this.dataCustomizerService.formatBundleDetailsDisplay(bundle, this.bundleTypes)));
        }

      },
        this.errorHandlerService.handleError,
        this.handleCompletion
    );

  }

  getBundleTypes = (): void => {
    this.crudService
      .getById<any>(getBundleTypesEndpoint)
      .subscribe(response => {
        this.bundleTypes = response;
        this.getSchoolYears();
      }, this.errorHandlerService.handleError);
  }

  handleCompletion = (): void => {
    this.loaderMessagingService.showListLoader(false);
    this.loaderMessagingService.showPageLoader(false);
  };

  onPageChange($event: any): void {
    this.pageIndex = $event.pageIndex;
    this.pageSize = $event.pageSize;
    this.search();
  }

  onLinkClick(id: string): void {
    this.router.navigate([BUNDLES_PATH, id]);
  }

  onClickAdd() {
    this.router.navigateByUrl(BUNDLE_CREATE_PATH);
  }

  download(
    filters: DownloadFilters
  ): void {
    this.loaderMessagingService.showPageLoader(true);
    const endpoint = baseServerUri
      .concat(downloadBundlesEndpoint)
      .concat(this.queryService
        .buildDownloadQueryParams(filters)
      );
    this.downloadFileService
      .getFile(endpoint)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(
        response => {
          const blob = new Blob([response], { type: xlsxFileMediaType });
          saveAs(
            blob,
            fileNameBundle
              .concat(`_${filters.statusName}`)
              .concat(downloadFileExtension)
          );
        },
        this.errorHandlerService.handleError,
        this.handleCompletion
      );
  }

  openConfirmationDialog(): void {
    if (this.itemsCount > 0) {
			const dialogRef = this.dialog.open(
				DownloadFiltersDialogComponent,
				{
					width: '450px',
					data: {
            schoolYears: this.schoolYearHistory,
            selectedSchoolYear: this.schoolYear,
						statuses: [],
						type: DownloadFiltersTypeEnum.BUNDLE
					}
				}
			);
			dialogRef
				.afterClosed()
				.pipe(takeUntil(this.unsubscribe))
				.subscribe(
					result => {
						if (result) {
							this.getBundleSyCodeCount(result);
						}
					},
					this.errorHandlerService.handleError
				);
		} else {
			const dialogRef = this.dialog.open(DialogBoxComponent);
			dialogRef.componentInstance.dialogTitle = dialogBoxErrorTitle;
			dialogRef.componentInstance.contentMessage = downloadErrorNoDataBundle;
		}
  }

  getBundleSyCodeCount(
    filters: DownloadFilters
  ) {
    const endpoint = allBundlesEndpoint
			.concat(getCountByFilter)
			.concat(this.queryService.buildDownloadQueryParams(filters));
		this.crudService.getById<number>(
			endpoint
		)
			.pipe(takeUntil(this.unsubscribe))
			.subscribe(
				response => {
					if (response > 0) {
						this.download(filters);
					} else {
						const dialogRef = this.dialog.open(DialogBoxComponent);
						dialogRef.componentInstance.dialogTitle = dialogBoxErrorTitle;
						dialogRef.componentInstance.contentMessage = downloadErrorNoDataBundle;
					}
				}
			)
  }

  filterOnClick() {

    if (!this.isType1) {
      this.trigger = this.trigger === CLOSE ? OPEN : CLOSE;
    } else {
      this.trigger = CLOSE;
      let data = [];

      this.bundleDataProperties.forEach(property => {

        if (property.filterable)
          data.push({
            label: property.label,
            prop: property.property,
            value: this.filters.get(property.property) || "",
          });

      });

      const modalStyle = { width: "30%" };
      const dialogRef = this.dialog.open(FilterModalComponent, { ...modalStyle, data });

      dialogRef.afterClosed().subscribe(filters => {
        if (filters) this.filterOnChange(filters);
      });
    }

  }

  filterOnChange(filters: Map<string, string>) {
    this.filters = new Map<string, string>(filters);
    this.setFilterLabels();
    this.pageIndex = 0;
    this.search();
  }

  onToggleSort($event: any) {
    this.sortedBy = $event.sortedBy;
    this.sortOrder = $event.sortOrder;
    this.search();
  }

  onEditClick(id: string): void {
    this.router.navigate([BUNDLE_UPDATE_PATH, id]);
  }

  onChangeFilterType(): void {
    this.isType1 = !this.isType1;
  }

  setFilterLabels(): void {
    
    this.filterLabels = [];

    this.filters.forEach((value, key) =>
		this.filterLabels.push(`${this.bundleDataProperties.find(property => property.property === key).label}: ${value}`));

	this.filters.set(SY_CODE, this.schoolYear);

	this.clearFilters = this.filters.size === 1;

    const index: number = this.filterLabels.findIndex(label => label.includes('School Year:'));
    if (index !== -1) this.filterLabels.splice(index, 1);

    this.filterLabels.splice(0, 0, `School Year: ${this.schoolYear}`);

  }

	onClearFilters(): void {
		this.clearFilters = true;
		this.keyword = '';
    this.filters.clear();
    this.filterOnChange(new Map<string, string>());
	}

  getSchoolYears(): void {
    this.crudService
      .getById<any>(getAllSchoolYearsEndpoint)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(response => {
        this.schoolYears = response;
        this.schoolYears.sort((a, b) => b.schoolYear.localeCompare(a.schoolYear));
        this.initParams();
      }, this.errorHandlerService.handleError);
  }

  setSchoolYear(schoolYear: any): void {
    this.selectedSyId = 0;
    if(schoolYear != undefined || schoolYear != null) {
      this.selectedSyId = schoolYear.id;
      this.schoolYear = schoolYear.schoolYear;
      if (this.filters.has(SY_CODE)) this.filters.delete(SY_CODE);
      this.setFilterLabels();
      this.search();
    } else {
      this.crudService
      .getById<any>(getActiveSchoolYearEndpoint)
      .pipe(takeUntil(this.unsubscribe))
			.subscribe(response => {
				if (response) {
          this.activeSchoolYear = response.schoolYear;
		      this.schoolYear = response.schoolYear;
          this.selectedSyId = this.schoolYears.find(sy =>
            String(sy.schoolYear).trim() === this.schoolYear.trim()).id;
            
          if (this.filters.has(SY_CODE)) this.filters.delete(SY_CODE);
          this.setFilterLabels();
          this.search();
				}
    	}, this.errorHandlerService.handleError);
    }
  }

  getSchoolYersFromCurrentToEarliest() {
    const endpoint = getAllSchoolYearsEndpoint
			.concat(getSchoolYearsWithHistory);
		this.crudService.getAllBy<ReferenceSchoolYearDTO>(
			endpoint
		)
			.pipe(takeUntil(this.unsubscribe))
			.subscribe(
				response => {
					this.schoolYearHistory = response;
				}
			);
  }

  canEdit(): Boolean {
		if(this.activeSchoolYear) {
			return this.selectedSyId >= this.schoolYears.find(sy =>
				String(sy.schoolYear).trim() === this.activeSchoolYear.trim()).id;
		}	
		return false;
	}

}
