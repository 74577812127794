import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import { MatDialog } from '@angular/material';
import { OrderShippingDialogComponent } from '../order-shipping-dialog/order-shipping-dialog.component';

@Component({
	selector: 'order-shipping-details',
	templateUrl: './shipping-details.component.html',
	styleUrls: ['./shipping-details.component.scss']
})
export class ShippingDetailsComponent implements OnInit {

	@Input() selectedShippingDetail: any = {};
	@Input() shippingDetails: any = {};
	@Input() align: string;
	@Input() canEdit: boolean = false;
	@Input() withConfirmation: boolean = false;
  @Output() getNewShippingDetails = new EventEmitter<any>();
  @Output() onClose = new EventEmitter<any>();
  faPencilAlt = faPencilAlt;

	constructor(
		private dialog: MatDialog,
	) { }

	ngOnInit() {
	}

  onClickShippingEdit(): void {
    const shippingDialogRef = this.dialog.open(OrderShippingDialogComponent, {
      width: '80%',
      autoFocus: false,
      data: {
        shippingDetails: this.shippingDetails,
        selectedShippingDetail: this.selectedShippingDetail,
        withConfirmation: this.withConfirmation,
      }
    });
    
    shippingDialogRef.afterClosed().subscribe(result => {
      if (result && result.selectedItem) {
        this.selectedShippingDetail = result.selectedItem;
        this.getNewShippingDetails.emit(result.selectedItem);
      } else {
        this.onClose.emit();
      }
    });
  }

}
