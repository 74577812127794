import { Component, OnInit, Output, EventEmitter, Input, OnDestroy, OnChanges, SimpleChanges } from '@angular/core';
import { Subject } from 'rxjs';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { CrudService } from 'src/app/core/services/data/crud.service';
import { ErrorHandlerService } from 'src/app/core/services/util/error-handler.service';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-field-checker',
  templateUrl: './field-checker.component.html',
  styleUrls: ['./field-checker.component.scss']
})
export class FieldCheckerComponent implements OnInit, OnDestroy, OnChanges {

  @Output() getResult: EventEmitter<any> = new EventEmitter();
  @Input() field: string;
  @Input() value: string;
  @Input() endpoint: string;
  @Input() hasError: boolean;

  unsubscribe: Subject<any> = new Subject();
  isProcessing: boolean;
  delayTimer: NodeJS.Timer;
  timeout: number = 700;
  checkIcon: IconDefinition = faCheck;
  exists: boolean = true;

  constructor(
    private crudService: CrudService,
    private errorHandlerService: ErrorHandlerService,
  ) { }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    const value: string = String(changes.value.currentValue);
    if (value && value.trim().length > 0 && !this.hasError) this.checkValue(value);
    else this.clearStates();
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  handleCompletion = (): void => {
    this.isProcessing = false;
  }

  checkValue(value: string): void {

    this.exists = true;
    this.isProcessing = false;
    if (String(value).trim().length === 0) return;

    clearTimeout(this.delayTimer);
    this.delayTimer = setTimeout(() => {
      this.isProcessing = true;
      setTimeout(() => this.findValue(value), this.timeout);
    }, this.timeout);

  }

  private findValue(value: string): void {
    this.crudService
      .getById<JSON>(this.endpoint.concat(encodeURIComponent(value)))
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((response: any) => {

        this.exists = response.exists;
        this.getResult.emit({ exists: this.exists, field: this.field, value });

      }, (error) => {
        this.isProcessing = false;
        this.errorHandlerService.handleError(error);
      }, this.handleCompletion);

  }
  
  private clearStates(): void {
    this.isProcessing = false;
    this.exists = true;
  }

}
