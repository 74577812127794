import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MAT_CHECKBOX_CLICK_ACTION } from '@angular/material';
import { DESC, pageSizeOptions } from 'src/app/core/constants/configuration/common.constant';
import { RESOURCE_TYPE_CODE_2 } from 'src/app/core/constants/configuration/resource-constants.config';
import {
  allResourcesEndpoint,
  getResourceTypesEndpoint,
  getResourceGroupsEndpoint,
  getResourceOnlineAccountsEndpoint,
} from 'src/app/core/constants/endpoints.constant';
import { orderResourcesDataProperties } from 'src/app/core/constants/configuration/order-constants.config';
import { Resource } from 'src/app/core/models/resource.model';
import { Page } from "src/app/core/models/page.model";
import { DataProperties } from 'src/app/core/models/data-properties.model';
import { CrudService } from 'src/app/core/services/data/crud.service';
import { DataCustomizerService } from 'src/app/core/services/util/data-customizer.service';
import { ErrorHandlerService } from 'src/app/core/services/util/error-handler.service';
import { LoaderMessagingService } from 'src/app/core/services/messaging/loader-messaging.service';
import { QueryService } from 'src/app/core/services/util/query.service';

@Component({
  selector: 'app-order-class-resource-associates-list',
  templateUrl: './order-class-resource-associates-list.component.html',
  styleUrls: ['./order-class-resource-associates-list.component.scss'],
  providers: [{provide: MAT_CHECKBOX_CLICK_ACTION, useValue: 'check-indeterminate'}]
})
export class OrderClassResourceAssociatesListComponent implements OnInit {
  
  @Input() associatedTo: string;
  @Input() code: string;
  @Input() codeLabel: string;
  @Output() getSelectedClassResource = new EventEmitter<any>();

  resourceDetailsList: Resource[];
  itemsCount: number = 0;
  pageIndex: number = 0;
  pageSize: number = pageSizeOptions[0];
  keyword: string = "";
  unsubscribe: Subject<any> = new Subject();
  sortedBy: string;
  sortOrder: string;
  filters: Map<String, String> = new Map<string, string>();
  resourceTypes: any[];
  resourceGroups: any[];
  onlineAccounts: any[];
  selectedResources: any[] = [];
  orderResourcesDataProperties: DataProperties[] = orderResourcesDataProperties;
  errorMessage: string;

  constructor(
    private crudService: CrudService,
    private dataCustomizerService: DataCustomizerService,
    private errorHandlerService: ErrorHandlerService,
    private loaderMessagingService: LoaderMessagingService,
    private queryService: QueryService,
  ) {
    this.loaderMessagingService.showPageLoader(true);
  }

  ngOnInit() {
    this.filters.set(this.codeLabel, this.code);
    this.filters.set('type', 'Online Account');
    this.getResourceTypes();
    this.errorMessage = `There are no online resources associated to ${ this.associatedTo === 'Class' ?
      'this class' : 'the curriculum of this class' }.`;
  }

  @Input()
  set selectedClasses(values) {
    values.forEach(item => {
      if (this.code === item.selectedClass.code) {
        if (this.associatedTo === 'Class')
          this.selectedResources = item.selectedClassResources;
        else
          this.selectedResources = item.selectedCurriculumResources;
      }
    });
  }

  initParams(): void {
    this.sortedBy = this.orderResourcesDataProperties[1].property;
    this.sortOrder = DESC;
    this.search();
  }

  formatAmount(value: number): string {
    return "$" + value.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')
  }

  onSelectResource($event: any, selectedResource: Resource) {
    if (!this.isResourceRequired(selectedResource)) {
      let result = this.selectedResources.find(resource => resource.code === selectedResource.code);
      if (result)
        this.selectedResources = this.selectedResources.filter(resource => resource.code !== selectedResource.code);
      else
        this.selectedResources.push(selectedResource);
      this.getSelectedClassResource.emit(this.selectedResources);
    }
  }

  addAllRequiredResources() {
    let requiredResources = this.resourceDetailsList.filter(resource => this.isResourceRequired(resource));
    if (requiredResources.length > 0) {
      requiredResources.forEach(requiredResource => {
        let result = this.selectedResources.find(resource => resource.code === requiredResource.code);
        if (!result) this.selectedResources.push(requiredResource);
      });
    }
    this.getSelectedClassResource.emit(this.selectedResources);
  }

  isChecked(selectedResource: Resource) {
    return this.selectedResources.find(resource => resource.code === selectedResource.code);
  }

  search() {
    this.resourceDetailsList = [];

    this.crudService
      .getAll<Resource>(this.createSearchEndpoint())
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(
        this.handleSearchResponse,
        this.errorHandlerService.handleError,
        this.handleCompletion
      );
  }

  createSearchEndpoint(): string {
    return allResourcesEndpoint.concat(
      this.queryService.buildResourceSearchQuery(
        this.pageIndex,
        this.pageSize,
        this.sortedBy,
        this.sortOrder,
        this.keyword,
        this.filters,
        this.getReference(),
        true
      )
    );
  }

  handleSearchResponse = (response: Page<Resource>): void => {
    if (response) {
      this.itemsCount = response.totalElements;
      response.content.forEach((resource: Resource) => {
        this.resourceDetailsList.push(
          this.dataCustomizerService.formatOrderResourceDetailsDisplay(resource)
        );
      });
      this.addAllRequiredResources();
    }
  };

  isResourceRequired(resource: Resource) {
    return resource.resourceAssoc.filter(assoc => 
      assoc.isRequired && assoc.referenceCode === this.code).length > 0;
  }
  
  onPageChange($event: any): void {
		this.pageIndex = $event.pageIndex;
		this.pageSize = $event.pageSize;
		this.search();
  }

  getResourceTypesValue(value: string): string {
    let resourceType = this.resourceTypes ? this.resourceTypes.find(type => type.code === value) : null;
    return resourceType ? resourceType.name : "";
  }

  isOnlineAccount(type: string): boolean {
    return type === RESOURCE_TYPE_CODE_2
  }

  getResourceTypes(): void {
    this.crudService
      .getById<any>(getResourceTypesEndpoint)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(response => {
        this.resourceTypes = response;
        this.resourceTypes.sort((a, b) => a.code.localeCompare(b.code));
        this.getResourceGroups();
      }, this.errorHandlerService.handleError);
  }

  getResourceGroups(): void {
    this.crudService
      .getById<any>(getResourceGroupsEndpoint)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(response => {
        this.resourceGroups = response;
        this.resourceGroups.sort((a, b) => a.code.localeCompare(b.code));
        this.getOnlineAccounts();
      }, this.errorHandlerService.handleError);
  }

  getOnlineAccounts(): void {
    this.crudService
      .getById<any>(getResourceOnlineAccountsEndpoint)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(response => {
        this.onlineAccounts = response;
        this.onlineAccounts.sort((a, b) => a.code.localeCompare(b.code));
        this.initParams();
      }, this.errorHandlerService.handleError);
  }

  getReference(): any {
    return {
      resourceTypes: this.resourceTypes,
      resourceGroups: this.resourceGroups,
      onlineAccounts: this.onlineAccounts
    };
  }
  
  handleCompletion = (): void => {
    this.loaderMessagingService.showPageLoader(false);
  };

}
