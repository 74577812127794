import { Component, EventEmitter, OnInit, Output, Input } from '@angular/core';
import { Subject } from 'rxjs';
import * as moment from 'moment';
import { pageSizeOptions, tableDateFormat } from 'src/app/core/constants/configuration/common.constant';
import { orderResourcesDataProperties } from 'src/app/core/constants/configuration/order-constants.config';
import { Class } from 'src/app/core/models/class.model';
import { Curriculum } from 'src/app/core/models/curriculum.model';
import { Resource } from 'src/app/core/models/resource.model';
import { DataProperties } from 'src/app/core/models/data-properties.model';
import { LoaderMessagingService } from 'src/app/core/services/messaging/loader-messaging.service';
import { faQuestionCircle, IconDefinition} from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-order-class-resource-dialog',
  templateUrl: './order-class-resource-dialog.component.html',
  styleUrls: ['./order-class-resource-dialog.component.scss']
})
export class OrderClassResourceDialogComponent implements OnInit {
  
  @Input() classDetails: Class;
  @Input() selectedClasses: any;
  @Output() getSelectedClassResource = new EventEmitter<any>();
  @Output() closeClassResourceDetailsModal = new EventEmitter<any>();

  curriculumDetails: Curriculum;
  resourceDetailsList: Resource[];
  itemsCount: number = 0;
  pageIndex: number = 0;
  pageSize: number = pageSizeOptions[0];
  keyword: string = "";
  unsubscribe: Subject<any> = new Subject();
  sortedBy: string;
  sortOrder: string;
  filters: Map<String, String> = new Map<string, string>();
  resourceTypes: any[];
  resourceGroups: any[];
  onlineAccounts: any[];
  selectedCurriculumResources: any[] = [];
  selectedClassResources: any[] = [];
  orderResourcesDataProperties: DataProperties[] = orderResourcesDataProperties;

  faQuestionCircle: IconDefinition = faQuestionCircle;

  constructor(
    private loaderMessagingService: LoaderMessagingService,
  ) {
    // this.loaderMessagingService.showPageLoader(true);
  }

  ngOnInit() {
    this.curriculumDetails = this.classDetails.curriculum;
  }

  formatAmount(value: number): string {
    return "$" + value.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')
  }

  formatDate(value: string): string {
    return moment(value).format(tableDateFormat)
  }

  onGetSelectedCurriculumResource($event: any): void {
    $event.forEach(resource => {
      if (!this.selectedCurriculumResources.find(selectedResource => selectedResource.code === resource.code))
        this.selectedCurriculumResources.push(resource)
    });
  }

  onGetSelectedClassResource($event: any): void {
    $event.forEach(resource => {
      if (!this.selectedClassResources.find(selectedResource => selectedResource.code === resource.code))
        this.selectedClassResources.push(resource)
    });
  }

  onAddToCart(): void {
    this.getSelectedClassResource.emit({
      selectedClass: this.classDetails,
      selectedCurriculumResources: this.selectedCurriculumResources,
      selectedClassResources: this.selectedClassResources,
    });
  }
  
  onClose(): void {
    this.closeClassResourceDetailsModal.emit(true);
  }

}
