import { Component, OnInit, Output, EventEmitter, Input, ViewChild } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { NEXT, SAVE, AVAILABLE, CHECKED_OUT, LOST, FOR_DISPOSAL } from 'src/app/core/constants/configuration/common.constant';
import { FormUtil } from 'src/app/core/services/util/form.util';
import { allModelsEndpoint, allScopes, existsByCode, allAssetsEndpoint, allStudentsEndpoint } from 'src/app/core/constants/endpoints.constant';
import { CrudService } from 'src/app/core/services/data/crud.service';
import { ErrorHandlerService } from 'src/app/core/services/util/error-handler.service';
import { Model } from 'src/app/core/models/model.model';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { AssetMainForm } from 'src/app/core/models/form/asset-main-form.model';
import { ASSET_STATUSES } from 'src/app/core/constants/configuration/common.constant';
import { Student } from 'src/app/core/models/eos/student.model';
import { MatInput } from '@angular/material/input';
import { MatDialog } from '@angular/material/dialog';
import { StudentListModalComponent } from '../../../student-list-modal/student-list-modal.component';
import { QueryService } from 'src/app/core/services/util/query.service';
import { ConfirmDialogComponent } from '../../../confirm-dialog/confirm-dialog.component';
import { saveAssetConfirmation } from 'src/app/core/constants/message.constant';
import { UserService } from 'src/app/core/services/util/user.service';
import { TEACHER } from 'src/app/core/constants/configuration/role-constants.config';
import { Scope } from 'src/app/core/models/scope.model';
import { LoaderMessagingService } from "src/app/core/services/messaging/loader-messaging.service";

@Component({
	selector: 'asset-main-form',
	templateUrl: './asset-main-form.component.html',
	styleUrls: ['./asset-main-form.component.scss']
})
export class AssetMainFormComponent implements OnInit {

	@Output() changePage = new EventEmitter<any>();
	@Output() getFormValue = new EventEmitter<any>();
	@Input() isFirst: boolean;
	@Input() isLast: boolean;
	@Input() forUpdate: boolean;
	@Input() reference: any;
	@Input() assetFormValues: AssetMainForm;
	@Input() code: string;
  	@ViewChild('autoCompleteInput') autoCompleteInput: MatInput;
	existsByCodeEndpoint: string = allAssetsEndpoint.concat(existsByCode);
	
	scopeList: Scope[];

	consumableData: Array<any>;
	consumableVal: any;
	assetForm: FormGroup;
	minDate: string;
	isProcessing: boolean;
	delayTimer: NodeJS.Timer;
	timeout: number = 300;

	statuses: string[] = ASSET_STATUSES;
	currentStatus: string;
	
	isActive: boolean = false;
	models: Model;
	modelList: any[];
	studentList: any[];
	unsubscribe: Subject<any> = new Subject();
	studentListModalRef: any;
	autoCompleteKeyword: string;
	selectedStudent = "";
	isStudentActive: boolean = false;
	originalStatus: string;
	includeInactive: boolean = false;

	constructor(
    private dialog: MatDialog,
		private formsUtil: FormUtil,
		private crudService: CrudService,
		private errorHandlerService: ErrorHandlerService,
		private queryService: QueryService,
		private userService: UserService,
		private loaderMessagingService: LoaderMessagingService,
	) {
		this.loaderMessagingService.showPageLoader(true);
		this.assetForm = this.formsUtil.createAssetMainForm(this.forUpdate);
		this.includeInactive = !this.userService.hasRole([TEACHER]);
	}

	ngOnInit() {
		this.initParams();
		this.autoCompleteKeyword = "";
	}

	private initParams = (): void => {
		this.getAllScopes();
		this.minDate = new Date().toJSON().split('T')[0];
		if (this.forUpdate || this.assetFormValues) {
			this.initFormValues();
			if(this.assetFormValues.status === CHECKED_OUT) {
				this.assetForm.controls['status'].setValue(null);
			}

			this.originalStatus = this.assetFormValues.status
			this.currentStatus = this.assetFormValues.status;
			this.listPossibleStatus();
			this.setCurrentStudent();
		} 
		this.loaderMessagingService.showPageLoader(false);
	}

	canEditScope() : Boolean {
		if(this.originalStatus === CHECKED_OUT ) {
			return false;
		}
		return this.currentStatus !== CHECKED_OUT;
	}

	private setCurrentStudent(){
		if(this.assetFormValues.loanedTo) {
			let student: any;
			student = this.assetFormValues.loanedTo;
			this.search(student.districtId);
		}
	}
	
	onStatusChange(event) {
		this.currentStatus = event;
		this.assetForm.controls['status'].updateValueAndValidity();
		if(this.currentStatus === LOST || this.currentStatus === FOR_DISPOSAL) {
			this.setRequiredValidator('description');
		} else {
			this.clearValidator('description');
			this.assetForm.controls['description'].setValue('');
		}
		this.listPossibleStatus();
	}

	clearValidator(control){
		this.assetForm.controls[control].setValidators([]);
		this.assetForm.controls[control].updateValueAndValidity();
	}
	setRequiredValidator(control){
		this.assetForm.controls[control].setValidators([Validators.compose([Validators.required])]);
		this.assetForm.controls[control].updateValueAndValidity();
	}

	listPossibleStatus() {
		switch(this.assetFormValues.status) {
			case AVAILABLE:
			this.statuses = ASSET_STATUSES;
			this.clearValidator('loanedTo');
			break;

			case LOST:
				this.statuses = this.statuses.filter(status => status !== CHECKED_OUT);
				this.clearValidator('loanedTo');
			break;

			case FOR_DISPOSAL:
				this.statuses = this.statuses.filter(status => status !== CHECKED_OUT);
				this.clearValidator('loanedTo');
			break;

			case CHECKED_OUT:
			this.statuses = ASSET_STATUSES;
			this.statuses = this.statuses.filter(status => status !== CHECKED_OUT);
			if(this.originalStatus === CHECKED_OUT) {
				this.clearValidator('loanedTo');
			} else {
				this.setRequiredValidator('loanedTo');
			}
			break;

			default:
			break;
		}
	}

	private initFormValues = (): void => {
		this.assetForm.setValue(this.formsUtil.initAssetMainForm(this.assetFormValues));
	}

	onClick = (label: string): void => {
		this.validateUntouchedForms();
		if (!this.assetForm.valid) {
			return;
		} else {
			if(!this.forUpdate) {
				this.assetForm.controls['status'].setValue(AVAILABLE);
				this.assetForm.controls['description'].setValue('');
			} else {
				this.assetFormValues.status = this.currentStatus;
				if(this.currentStatus !== CHECKED_OUT){
					this.assetForm.controls['loanedTo'].setValue(null);
				}
			}
		}
		if (label === NEXT) {
			if (!this.assetForm.valid) {
				return;
			} else {
				this.changePage.emit({label: NEXT});

				this.getFormValue.emit({
					formValues: this.assetForm.value,
					model: this.models
				});
				this.changePage.emit(label);
				this.assetForm.reset();
			}
		} else if (label === SAVE) {
			if(!this.assetForm.valid){
				return;
			} else {
			this.getFormValue.emit({
				formValues: this.assetForm.value,
				model: this.models
			});
			const dialogRef = this.dialog.open(ConfirmDialogComponent);
			dialogRef.componentInstance.confirmMessage = saveAssetConfirmation;
			dialogRef.afterClosed()
				.pipe(takeUntil(this.unsubscribe))
				.subscribe(result => {
					if (result) {
						this.changePage.emit({label:SAVE});
					}
				}, this.errorHandlerService.handleError);
			}
		}
	}

	private validateUntouchedForms(): void {
		Object.keys(this.assetForm.controls).forEach(control => {
			this.assetForm.get(control).markAsTouched();
			this.assetForm.get(control).markAsDirty();
		});
	}

	onGetCodeResult(result: any): void {

		if (result.exists) {

			if ((this.assetFormValues && result.value !== this.assetFormValues.code && this.forUpdate) || !this.forUpdate) {
				this.assetForm.controls['code'].setErrors({'codeExists': true});
			} else {
				this.assetForm.controls['code'].updateValueAndValidity();
			}

		}

 	}

  getModelList(keyword: string) {
    this.crudService
      .getAllBy<Model>(`${allModelsEndpoint}/get-by-code-or-name?keyword=${encodeURIComponent(keyword)}`)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(response => {
		this.isActive = false;
        if (response) {
          if (response.length > 0) {
            response.map(model => this.modelList.push(model));
          } else {
            this.assetForm.get('modelCodeName').setErrors({ 'invalidModel': true });
          }
        }
      },
        this.errorHandlerService.handleError, this.handleCompletion
    );
  }

  onModelSelect(index: number, selectedModelName: string) : void {
		const selectedModel = this.modelList.find(model => (model.name.trim() + ' (' + model.code + ')') === selectedModelName);
		
		this.models = { ...selectedModel, name: selectedModel.name.trim() };

		this.assetForm.controls['modelCodeName'].updateValueAndValidity();
  }

  onModelChange(keyword : string, index : number) : void {
    this.modelList = [];

    if (!keyword || keyword.trim().length === 0) {
			this.assetForm.markAsTouched();
			this.assetForm.markAsDirty();
			return;
		};

    clearTimeout(this.delayTimer);
    this.delayTimer = setTimeout(() => {
		this.isActive = true;
      setTimeout(() => this.getModelList(keyword.trim()), this.timeout);
    }, this.timeout);
	}

	showStudentListModal() {
		this.studentListModalRef = this.dialog.open(StudentListModalComponent, { width: "70%" });
		this.studentListModalRef.componentInstance.closeStudentListModal.subscribe(() =>{
			this.studentListModalRef.close();
		});
		this.studentListModalRef.componentInstance.getFormValue.subscribe(student => {
			if(student) {
				this.selectedStudent = student.name + " " + "(" + student.districtId + ")";
				this.assetForm.controls['loanedTo'].setValue(student);
				this.assetForm.controls['loanedTo'].updateValueAndValidity();
				this.studentListModalRef.close();
			}
		});
	}

	onClearStudent(){
		this.selectedStudent = "";
		this.assetForm.controls['loanedTo'].setValue(null);
		this.assetForm.controls['loanedTo'].updateValueAndValidity();
	}

	search(districtId) {
	this.isStudentActive = true;
	const filter = "districtId";
    this.crudService
      .getAll<Student>(allStudentsEndpoint.concat(this.queryService.buildStudentSearchQuery(
        0, 10, this.includeInactive, districtId, filter)))
      .pipe(takeUntil
      (this.unsubscribe))
		.subscribe(response => {
			if (response) {
				let student: any;
				student = response.content[0];
				this.selectedStudent = student.firstName 
					+ " " + student.lastName 
					+ " " + "(" + student.districtId + ")";
			}
			this.isStudentActive = false;	
			this.loaderMessagingService.showPageLoader(false);
		},
			this.errorHandlerService.handleError, this.handleCompletion
		);
	}

	//SCOPE

	getAllScopes(): void {
		allScopes
		this.crudService
			.getById<Scope>(allScopes)
			.pipe(takeUntil(this.unsubscribe))
			.subscribe((response: any) => {
				if(response) {
					this.scopeList = response;
				}
			}, this.errorHandlerService.handleError, this.handleCompletion);
	}

	
	private handleCompletion = (): void => {
		this.loaderMessagingService.showPageLoader(false);
	}
}
