import { Component, OnInit, Output, Input, EventEmitter } from "@angular/core";
import { FormUtil } from "src/app/core/services/util/form.util";
import { FormGroup } from "@angular/forms";
import { RESOURCE_TYPE_CODE_1 } from "src/app/core/constants/configuration/resource-constants.config";

@Component({
  selector: "resource-modal-form",
  templateUrl: "./resource-modal-form.component.html",
  styleUrls: ["./resource-modal-form.component.scss"]
})
export class ResourceModalFormComponent implements OnInit {
  @Output() saveResource = new EventEmitter<any>();
  @Output() closeResourceFormModal = new EventEmitter<any>();
  @Input() resourceTypes: any[];

  resourceForm: FormGroup;
  codes: any[];

  constructor(
    private formUtil: FormUtil,
  ) {
    this.resourceForm = formUtil.createOrderResourceForm();
    this.resourceForm.get("type").setValue(RESOURCE_TYPE_CODE_1);
  }

  ngOnInit() {
  }

  onClose() {
    this.closeResourceFormModal.emit(true);
  }

	onSaveResource() {
    this.validateUntouchedForms();
    if (!this.resourceForm.valid) {
      return;
    } else {
      let resource = this.resourceForm.value;
      this.saveResource.emit({
        ...resource,
        price: resource.price && resource.price !== "" ? resource.price : 0,
        deposit: resource.deposit && resource.deposit !== "" ? resource.deposit : 0,
      });
      this.resourceForm.reset();
    }
  }

  validateUntouchedForms(): void {
    Object.keys(this.resourceForm.controls).forEach(control => {
      this.resourceForm.get(control).markAsTouched();
      this.resourceForm.get(control).markAsDirty();
    });
  }

  onChangeNumber(type: string): void {
    const value: number = this.resourceForm.controls[type].value;
    const max = 999999999;
		if (value === null){
			this.resourceForm.controls[type].setValue("");
		} else if (value === -0) {
			this.resourceForm.controls[type].setValue(0);
    } else if (value > max) {
      this.resourceForm.controls[type].setValue(String(value).substring(0,9));
    } else if (value % 1 != 0 && String(value).split('.').slice(-1)[0].length > 2) {
      this.resourceForm.controls[type].setValue(value.toFixed(2));
    }
  }
  
}
