import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { Class } from 'src/app/core/models/class.model';
import { Subject } from 'rxjs';
import { ErrorHandlerService } from 'src/app/core/services/util/error-handler.service';
import { MatDialog } from '@angular/material';
import { SAVE, PREV } from 'src/app/core/constants/configuration/common.constant';
import { ConfirmDialogComponent } from '../../../confirm-dialog/confirm-dialog.component';
import { saveClassConfirmation } from 'src/app/core/constants/message.constant';
import { takeUntil } from 'rxjs/operators';
import { CrudService } from 'src/app/core/services/data/crud.service';
import { getClassOrderedCount, getProcessedOrderedCount } from 'src/app/core/constants/endpoints.constant';

@Component({
  selector: 'class-summary',
  templateUrl: './class-summary.component.html',
  styleUrls: ['./class-summary.component.scss']
})
export class ClassSummaryComponent implements OnInit, OnDestroy {
  
	@Output() changePage = new EventEmitter<any>();
	@Input() clazz: Class;
	@Input() referenceValues: any;
	@Input() isFirst: boolean;
	@Input() isLast: boolean;

	classOrderedCount : number = 0;
	classProcessedOrderedCount : number = 0;

	unsubscribe: Subject<any> = new Subject();

	constructor(
		private errorHandlerService: ErrorHandlerService,
		public dialog: MatDialog,
		private crudService: CrudService,
	) { }

	ngOnInit() {
		this.getClassOrderedCount();
	}

	private getClassOrderedCount(): void {
		this.crudService
			.getById<any>(getClassOrderedCount + "?classCode=" + this.clazz.code)
			.pipe(takeUntil(this.unsubscribe))
			.subscribe((response) => {
				if(response) {
					this.classOrderedCount = response
				}
				this.getClassProcessedOrderedCount();
		}, this.errorHandlerService.handleError);
	}

	private getClassProcessedOrderedCount(): void {
		this.crudService
            .getById<any>(getProcessedOrderedCount + "?classCode=" + this.clazz.code)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((response) => {
				if(response) {
					this.classProcessedOrderedCount = response;
				}
        }, this.errorHandlerService.handleError);
	}

	ngOnDestroy(): void {
		this.unsubscribe.next();
		this.unsubscribe.complete();
	}

	onClick = (label: string): void => {

		if (label === SAVE) {

			const dialogRef = this.dialog.open(ConfirmDialogComponent);
			dialogRef.componentInstance.confirmMessage = saveClassConfirmation;
			dialogRef.afterClosed()
				.pipe(takeUntil(this.unsubscribe))
				.subscribe(result => {
					if (result) {
					this.changePage.emit(SAVE);
					}
				}, this.errorHandlerService.handleError);

		}

		if (label === PREV) {
			this.changePage.emit(PREV);
		}

	}

}
