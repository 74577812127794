import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";

@Injectable({ providedIn: 'root' })
export class LoaderMessagingService {

  private listLoaderSource: Subject<boolean> = new Subject<boolean>();
  private pageLoaderSource: Subject<boolean> = new Subject<boolean>();

  listLoader$: Observable<boolean> = this.listLoaderSource.asObservable();
  pageLoader$: Observable<boolean> = this.pageLoaderSource.asObservable();

  showListLoader(show: boolean): void {
    this.listLoaderSource.next(show);
  }

  showPageLoader(show: boolean): void {
    this.pageLoaderSource.next(show);
  }

}
