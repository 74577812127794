import { Component, OnInit } from '@angular/core';
import { navSideUpload, settingsModuleName } from 'src/app/core/constants/configuration/common.constant';
import { HeaderMessagingService } from 'src/app/core/services/messaging/header-messaging.service';
import { UploadMessageService } from 'src/app/core/services/messaging/upload-message.service';
import { settingsRoutingPermissions } from 'src/app/core/constants/configuration/role-constants.config';
import { UserService } from 'src/app/core/services/util/user.service';
import { UPLOADS_PATH } from "src/app/core/constants/routes.constant";

@Component({
	selector: 'upload',
	templateUrl: './upload.component.html',
	styleUrls: ['./upload.component.scss']
})
export class UploadComponent implements OnInit {

	toggle: number = 0;
	navSide : any [];
	selectedNav: string;

	constructor(
		private headerMessagingService: HeaderMessagingService,
		private uploadMessageService: UploadMessageService,
		private userService: UserService,
	) {
		this.userService.checkRolePermission(UPLOADS_PATH, settingsRoutingPermissions);
		this.headerMessagingService.setHeader(settingsModuleName, "", true, settingsRoutingPermissions);
		this.navSide = navSideUpload.filter(navSide => this.userService.hasRole(navSide.roles)).map(navSide => navSide.name);
		this.selectedNav = this.navSide[this.toggle];
	}

	ngOnInit() {
		this.uploadMessageService.sendMessage(this.toggle);
	}

}
