import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { LoginMessagingService } from 'src/app/core/services/messaging/login-messaging.service';
import { HomeService } from 'src/app/core/services/data/home.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AuthService } from 'src/app/core/services/data/auth.service';
import { CrudService } from 'src/app/core/services/data/crud.service';
import { Resource } from 'src/app/core/models/resource.model';
import { Page } from 'src/app/core/models/page.model';

import { allCurriculumsEndpoint } from 'src/app/core/constants/endpoints.constant';
import { Curriculum } from 'src/app/core/models/curriculum.model';
import { ErrorHandlerService } from 'src/app/core/services/util/error-handler.service';
import { LOGIN_PATH } from 'src/app/core/constants/routes.constant';

@Component({
  selector: 'home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, OnDestroy {

  unsubscribe: Subject<any> = new Subject();
  message: string;
  resources: Page<Resource>;
  header: string = 'Curriculum';
  type: string = 'CURRICULUM';
  curriculums: Page<Curriculum>;

  constructor(
    private router: Router,
    private loginMessagingService: LoginMessagingService,
    private homeService: HomeService,
    private authService: AuthService,
    private crudService: CrudService,
    private errorHandlerService: ErrorHandlerService,
  ) { 
  }

  ngOnInit() {

    this.crudService
      .getAll<Curriculum>(allCurriculumsEndpoint)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(response => {
      
      if(response) this.curriculums = response;

    }, this.errorHandlerService.handleError);

  }

  getMessage(): void {

    this.homeService
      .getAll()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(response => {
        this.message = response;
      }, this.errorHandlerService.handleError);

    }

  logout(): void {

    this.authService
      .logout()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(response => {

        if (response) {

          localStorage.clear();
          this.loginMessagingService.sendMessage('');
          this.router.navigateByUrl(LOGIN_PATH);

        }

      }, this.errorHandlerService.handleError);

  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

}
