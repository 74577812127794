import { Component, OnInit } from "@angular/core";
import { HeaderMessagingService } from "src/app/core/services/messaging/header-messaging.service";
import {
  pageSizeOptions,
  ASC,
  settingsModuleName,
  CLOSE,
  OPEN,
  SUCCESS,
  ERROR
} from "src/app/core/constants/configuration/common.constant";
import { faFilter } from "@fortawesome/free-solid-svg-icons";
import { Subject, Observable } from "rxjs";
import { DataProperties } from "src/app/core/models/data-properties.model";
import { settingsRoutingPermissions, TEACHER } from "src/app/core/constants/configuration/role-constants.config";
import { DataCustomizerService } from "src/app/core/services/util/data-customizer.service";
import { QueryService } from "src/app/core/services/util/query.service";
import { ErrorHandlerService } from "src/app/core/services/util/error-handler.service";
import { CrudService } from "src/app/core/services/data/crud.service";
import { LoaderMessagingService } from "src/app/core/services/messaging/loader-messaging.service";
import { Router } from "@angular/router";
import { MatDialog } from "@angular/material";
import { Page } from "src/app/core/models/page.model";
import {
  allAssetsEndpoint,
  allStudentsEndpoint,
  downloadAssetsEndpoint,
  downloadCurriculumsEndpoint,
  getCountByFilter
} from 'src/app/core/constants/endpoints.constant';
import { takeUntil } from "rxjs/operators";
import { ASSET_PATH, ASSET_UPDATE_PATH, ASSET_CREATE_PATH } from "src/app/core/constants/routes.constant";
import { FilterModalComponent } from "src/app/shared/components/filter-modal/filter-modal.component";
import { changeSize } from "src/app/core/constants/animations.constant";
import { ConfirmDialogComponent } from "src/app/shared/components/confirm-dialog/confirm-dialog.component";
import {
  deleteAssetConfirmation,
  deleteErrorAsset,
  dialogBoxErrorTitle,
  downloadErrorNoDataAsset
} from 'src/app/core/constants/message.constant';
import { DialogMessagingService } from "src/app/core/services/messaging/dialog-messaging.service";
import { assetDataProperties } from "src/app/core/constants/configuration/asset-constants.config";
import { Asset } from "src/app/core/models/asset.model";
import { UserService } from "src/app/core/services/util/user.service";
import { Student } from "src/app/core/models/eos/student.model";
import { FilterService } from "src/app/core/services/util/filter.service";
import { DialogBoxComponent } from "src/app/shared/components/dialog-box/dialog-box.component";
import { baseServerUri, xlsxFileMediaType, fileNameAssets, downloadFileExtension } from "src/app/core/constants/configuration/config.constant";
import { saveAs } from 'file-saver';
import { DownloadFiltersDialogComponent } from "src/app/shared/components/download-filters-dialog/download-filters-dialog.component";
import { DownloadFiltersTypeEnum } from "src/app/shared/components/download-filters-dialog/enums/download-filters-type-enum";
import { DownloadFilters } from "src/app/shared/components/download-filters-dialog/models/download-filters";

import { FileService } from "src/app/core/services/data/file.service";
@Component({
  selector: "asset-list",
  templateUrl: "./asset-list.component.html",
  styleUrls: ["./asset-list.component.scss"],
  animations: [changeSize]
})
export class AssetListComponent implements OnInit {
  unsubscribe: Subject<any> = new Subject();
  items: any[] = [];
  asset: Page<Asset>;
  pageSize: number = pageSizeOptions[0];
  faFilter = faFilter;
  filters: Map<string, string> = new Map<string, string>();
  itemsCount: number;
  keyword: string = "";
  pageIndex: number = 0;
  assetDataProperties: DataProperties[] = assetDataProperties;
  isType1: boolean = false;
  trigger: string = CLOSE;
  sortOrder: string;
  sortedBy: string;
  filterLabels: string[] = [];
  clearFilters: boolean = true;
  statusList: any;
  filtersKey: string = "assetFilters";
  includeInactive: boolean = false;

  constructor(
    private crudService: CrudService,
    private errorHandlerService: ErrorHandlerService,
    private headerMessagingService: HeaderMessagingService,
    private queryService: QueryService,
    private dataCustomizerService: DataCustomizerService,
    private loaderMessagingService: LoaderMessagingService,
    private dialogMessagingService: DialogMessagingService,
    private router: Router,
    private dialog: MatDialog,
    private userService: UserService,
    private filterService: FilterService,
    private downloadFileService: FileService
	) {
		this.userService.checkRolePermission(ASSET_PATH, settingsRoutingPermissions);
    this.headerMessagingService.setHeader(settingsModuleName, "", true, settingsRoutingPermissions);
    this.includeInactive = !this.userService.hasRole([TEACHER]);
		let assetFilters = this.filterService.getFilter(this.filtersKey);
		if (assetFilters) {
			this.keyword = assetFilters.keyword || "";
			this.filters = assetFilters.filters ? this.filterService.objectToMap(assetFilters.filters) : new Map<string, string>();
		}
	}

  ngOnInit() {
    this.initParams();
  }

  initParams(): void {
    this.sortedBy = this.assetDataProperties[0].property;
    this.sortOrder = ASC;
    this.getAllStatus();
  }

  onSearch(keyword: string): void {
    this.keyword = keyword;
    this.pageIndex = 0;
    this.search();
  }

  getAllStatus() {
    this.crudService
    .getAll<any>(
      allAssetsEndpoint.concat("/status"))
    .pipe(takeUntil(this.unsubscribe))
    .subscribe((response : any) => {
      if (response) {
        this.statusList = response;
        this.search();
        }
      },
      this.errorHandlerService.handleError,
    );
  }

  search(): void {

    this.loaderMessagingService.showListLoader(true);
    this.items = [];
		this.filterService.setFilter(this.filtersKey, this.keyword, this.filters);

    this.crudService
      .getAll<Asset>(
        allAssetsEndpoint.concat(this.queryService.buildAssetSearchQuery(
            this.pageIndex, this.pageSize, this.sortedBy, this.sortOrder, this.keyword, this.filters)))
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((response : any) => {
        if (response) {
            this.itemsCount = response.totalElements;
            response.content.forEach(asset =>{
              let item = this.dataCustomizerService.customizeAssetListDisplay(asset, this.statusList);
              item.itemModelName = asset.itemModel.name;
              item.itemModelId = asset.itemModel.code;
              if(item.status === "Checked Out") {
                this.searchStudent(item);
              }
              this.items.push(item);
            });
          }
        },
        this.errorHandlerService.handleError,
        this.handleCompletion
      );
  }

  handleCompletion = (): void => {
    this.loaderMessagingService.showListLoader(false);
    this.loaderMessagingService.showPageLoader(false);
  };

  onPageChange($event: any): void {
    this.pageIndex = $event.pageIndex;
    this.pageSize = $event.pageSize;
    this.search();
  }

  onEditClick(id: string): void {
		this.router.navigate([ASSET_UPDATE_PATH, id]);
	}

  onClickAdd() {
    this.router.navigateByUrl(ASSET_CREATE_PATH);
  }

  filterOnClick(): void {

		if (!this.isType1) {
			this.trigger = this.trigger === CLOSE ? OPEN : CLOSE;
		} else {
			this.trigger = CLOSE;
			let data = [];

			this.assetDataProperties.forEach(property => {

				if (property.filterable)
				data.push({
					label: property.label,
					prop: property.property,
					value: this.filters.get(property.property) || "",
				});

			});

			const modalStyle = { width: "30%" };
			const dialogRef = this.dialog.open(FilterModalComponent, { ...modalStyle, data });

			dialogRef.afterClosed().subscribe(filters => {
				if (filters) this.filterOnChange(filters);
			});
    }

  }

  filterOnChange(filters: Map<string, string>) {
		this.filters = new Map<string, string>(filters);
		this.setFilterLabels();
		this.pageIndex = 0;
    this.search();
  }

  setFilterLabels(): void {

    this.filterLabels = [];

    this.filters.forEach((value, key) =>
      this.filterLabels.push(`${this.assetDataProperties.find(property => property.property === key).label}: ${value}`));

    this.clearFilters = this.filters.size === 0;

  }

  onChangeFilterType(): void {
    this.isType1 = !this.isType1;
  }

  onClearFilters(): void {
    this.clearFilters = true;
    this.keyword = "";
    this.filters.clear();
    this.filterOnChange(new Map<string, string>());
  }

  onLinkClick(id: string): void {
    this.router.navigate([ASSET_PATH, id]);
  }

  onDeleteAsset(id: string): void {

    const name = (this.items.find(item => item.id === id).itemModelName);
    const code = (this.items.find(item => item.id === id).code);

    this.findAsset(id).subscribe(response => {

        if(response.status === 'ASSET_STATUS_2') {
          this.dialogMessagingService.sendMessage({ status: ERROR, value: deleteErrorAsset });
        } else {
          const dialogRef = this.dialog.open(ConfirmDialogComponent);
          dialogRef.componentInstance.confirmMessage = deleteAssetConfirmation.replace("%", `${code} (${name})`);
          dialogRef.afterClosed()
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(result => {
              if (result) this.deleteAsset(id);
            }, this.errorHandlerService.handleError);
        }

    }, this.errorHandlerService.handleError, this.handleCompletion);

  }

  findAsset(id: string): Observable<Asset> {

    return this.crudService
      .getById<Asset>(allAssetsEndpoint.concat(`/${id}`))
      .pipe(takeUntil(this.unsubscribe));

  }

  deleteAsset(id: string): void {
    this.loaderMessagingService.showPageLoader(true);
    this.crudService
      .delete(allAssetsEndpoint.concat(`/${id}`))
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(response =>{
        this.dialogMessagingService.sendMessage({ status: SUCCESS, value: response });
        this.search();
      },
      this.errorHandlerService.handleError, this.handleCompletion);
  }


  onToggleSort($event: any) {
    this.sortedBy = $event.sortedBy;
    this.sortOrder = $event.sortOrder;
    this.search();
  }

  searchStudent(item) {
		const filter = "districtId";

    this.crudService
      .getAll<Student>(allStudentsEndpoint.concat(this.queryService.buildStudentSearchQuery(
        0, 10, this.includeInactive, item.loanedTo, filter)))
      .pipe(takeUntil
      (this.unsubscribe))
      .subscribe((response : any) => {
        if (response) {
          let student: any;
					student = response.content[0];
          item.studentName = student.firstName + " " + student.lastName;
				}
      },
        this.errorHandlerService.handleError,
    );
  }

  openConfirmationDialog() {
    if (this.itemsCount > 0) {
			const dialogRef = this.dialog.open(
				DownloadFiltersDialogComponent,
				{
					width: '450px',
					data: {
						schoolYears: [],
						statuses: this.statusList,
						type: DownloadFiltersTypeEnum.ASSET
					}
				}
			);
			dialogRef
				.afterClosed()
				.pipe(takeUntil(this.unsubscribe))
				.subscribe(
					result => {
						if (result) {
							this.getCountByFilters(result);
						}
					},
					this.errorHandlerService.handleError
				);
		} else {
			const dialogRef = this.dialog.open(DialogBoxComponent);
			dialogRef.componentInstance.dialogTitle = dialogBoxErrorTitle;
			dialogRef.componentInstance.contentMessage = downloadErrorNoDataAsset;
		}
  }

  getCountByFilters(
    filters: DownloadFilters
  ) {
    const endpoint = allAssetsEndpoint
			.concat(getCountByFilter)
			.concat(this.queryService.buildDownloadQueryParams(filters));
		this.crudService.getById<number>(
			endpoint
		)
			.pipe(takeUntil(this.unsubscribe))
			.subscribe(
				response => {
					if (response > 0) {
						this.download(filters);
					} else {
						const dialogRef = this.dialog.open(DialogBoxComponent);
						dialogRef.componentInstance.dialogTitle = dialogBoxErrorTitle;
						dialogRef.componentInstance.contentMessage = downloadErrorNoDataAsset;
					}
				}
			);
  }

  private download(
    filters: DownloadFilters
  ) {
    this.loaderMessagingService.showPageLoader(true);
    const endpoint = baseServerUri
      .concat(downloadAssetsEndpoint)
      .concat(this.queryService
        .buildDownloadQueryParams(filters)
      );
    let fileName = filters.statusName == 'all'
      ? fileNameAssets.concat(downloadFileExtension)
      : fileNameAssets.concat(`_${filters.statusName}`).concat(downloadFileExtension)
    this.downloadFileService.getFile(endpoint)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(
        response => {
          const blob = new Blob([response], {type: xlsxFileMediaType});
          saveAs(
            blob,
            fileName
          );
        },
        this.errorHandlerService.handleError,
        this.handleCompletion
      );
  }
}
