import { Component, OnInit, OnDestroy } from '@angular/core';
import { allBundlesEndpoint, getActiveSchoolYearEndpoint } from 'src/app/core/constants/endpoints.constant';
import { Subject } from 'rxjs';
import { Bundle } from 'src/app/core/models/bundle.model';
import { ActivatedRoute, Router } from '@angular/router';
import { CrudService } from 'src/app/core/services/data/crud.service';
import { LoaderMessagingService } from 'src/app/core/services/messaging/loader-messaging.service';
import { ErrorHandlerService } from 'src/app/core/services/util/error-handler.service';
import { HeaderMessagingService } from 'src/app/core/services/messaging/header-messaging.service';
import { BUNDLE } from 'src/app/core/constants/configuration/bundle-constants.config';
import { VIEW_MODULE_NAME } from 'src/app/core/constants/configuration/common.constant';
import { takeUntil } from 'rxjs/operators';
import { CURRICULUMS_PATH, BUNDLES_PATH, BUNDLE_UPDATE_PATH } from 'src/app/core/constants/routes.constant';
import { FabButtonMessagingService } from 'src/app/core/services/messaging/fab-button-messaging.service';
import { UserService } from 'src/app/core/services/util/user.service';
import { homeRoutingPermissions } from 'src/app/core/constants/configuration/role-constants.config';

@Component({
	selector: 'app-bundle-view',
	templateUrl: './bundle-view.component.html',
	styleUrls: ['./bundle-view.component.scss']
})
export class BundleViewComponent implements OnInit, OnDestroy {
	
	unsubscribe: Subject<any> = new Subject();
	bundle: Bundle;
	hasInitialized: boolean = false;

	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private crudService: CrudService,
		private loaderMessagingService: LoaderMessagingService,
		private errorHandlerService: ErrorHandlerService,
		private headerMessagingService: HeaderMessagingService,
		private fabButtonMessagingService: FabButtonMessagingService,
    private userService: UserService,
	) {
		this.userService.checkRolePermission(BUNDLES_PATH, homeRoutingPermissions);
		this.headerMessagingService.setHeader(BUNDLE, VIEW_MODULE_NAME, false, null);
	}

	ngOnInit() {
		this.loaderMessagingService.showPageLoader(true);
		this.findBundle(this.route.snapshot.paramMap.get('id'));
	}

	ngOnDestroy(): void {
		this.fabButtonMessagingService.sendMessage({ canEdit: false, path: null, id: null });
		this.unsubscribe.next();
		this.unsubscribe.complete();
	}

	handleCompletion = (): void => {
		this.loaderMessagingService.showPageLoader(false);
	};

	get canView(): boolean {
		return this.bundle && this.hasInitialized;
	}

	onLinkClick(id: number): void {
		this.router.navigate([CURRICULUMS_PATH, id]);
	}

	// PUT ALL API CALL BELOW
	private findBundle(id: string): void {

		this.crudService
			.getById<Bundle>(allBundlesEndpoint.concat(`/${id}`))
			.pipe(takeUntil(this.unsubscribe))
			.subscribe(response => {
				if (response) {
					this.bundle = response;
					this.checkActiveSchoolYear();
				}
			}, this.errorHandlerService.handleError, this.handleCompletion);

	}

	private initFabMessage(): void {
		let roles = homeRoutingPermissions.find(route => route.path === BUNDLES_PATH);
		const message: any = { canEdit: this.userService.hasRole(roles ? roles.roles : []), path: BUNDLE_UPDATE_PATH, id: this.bundle.id };
		this.fabButtonMessagingService.sendMessage(message);
		this.hasInitialized = true;
	}

	private checkActiveSchoolYear() {
		this.crudService
		.getById<any>(getActiveSchoolYearEndpoint)
		.pipe(takeUntil(this.unsubscribe))
		.subscribe(response => {
			if (response) {
				if(this.bundle.syCode >= response.schoolYear) {
					this.initFabMessage();
				} else {
					this.hasInitialized = true;
				}
			}
		}, this.errorHandlerService.handleError);
	}

}
