import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Hqt } from 'src/app/core/models/hqt.model';
import { Subject } from 'rxjs';
import { LoaderMessagingService } from 'src/app/core/services/messaging/loader-messaging.service';
import { ErrorHandlerService } from 'src/app/core/services/util/error-handler.service';
import { SAVE } from 'src/app/core/constants/configuration/common.constant';
import { PageForm } from 'src/app/core/models/form/page-form.model';
import { HqtMainForm } from 'src/app/core/models/form/hqt-main-form.model';
import { FormUtil } from 'src/app/core/services/util/form.util';
import { CrudService } from 'src/app/core/services/data/crud.service';
import { MatDialog } from '@angular/material';
import { Router } from '@angular/router';
import { baseServerUri } from 'src/app/core/constants/configuration/config.constant';
import { allHqtEndpoint, getStatusEndpoint, allClassesEndpoint } from 'src/app/core/constants/endpoints.constant';
import { takeUntil } from 'rxjs/operators';
import { DialogBoxComponent } from '../../dialog-box/dialog-box.component';
import { dialogBoxSuccessTitle, createSuccessHqt, updateSuccessHqt } from 'src/app/core/constants/message.constant';
import { HqtHelper } from 'src/app/core/services/util/hqt.helper';
import { Class } from 'src/app/core/models/class.model';
import { sideNavLabelHqt } from 'src/app/core/constants/configuration/hqt-constants.config';
import { HQTS_PATH } from 'src/app/core/constants/routes.constant';

@Component({
  selector: 'app-hqt-form',
  templateUrl: './hqt-form.component.html',
  styleUrls: ['./hqt-form.component.scss']
})
export class HqtFormComponent implements OnInit, OnDestroy {

  @Input() forUpdate: boolean;
  @Input() hqtId: number;

  unsubscribe: Subject<any> = new Subject();
  hqtMainFormValues: HqtMainForm;
  activeIndex: number = 0;
  originalHqt: Hqt;
  modifiedHqt: Hqt;
  sideNavLabels: string[] = [];
  isFirst: boolean = true;
  isLast: boolean = false;
  status: any[];
  disableStatus: boolean = false;

  constructor(
    private loaderMessagingService: LoaderMessagingService,
    private errorHandlerService: ErrorHandlerService,
    private formUtil: FormUtil,
    private crudService: CrudService,
    private hqtHelper: HqtHelper,
    private dialog: MatDialog,
    private router: Router,
  ) {
    this.sideNavLabels = sideNavLabelHqt;
  }

  ngOnInit() {
    this.loaderMessagingService.showPageLoader(true);
    this.isFirst = true;
    this.isLast = false;
    this.getStatus();
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  handleCompletion = (): void => {
    this.loaderMessagingService.showPageLoader(false);
  }

  onChangePage(label: string): void {

    let pageForm: PageForm = this.formUtil.setPageForm(label, this.sideNavLabels.length, this.activeIndex);

    this.activeIndex = pageForm.activeIndex;
    this.isFirst = pageForm.isFirst;
    this.isLast = pageForm.isLast;

    if (label === SAVE) {
      this.forUpdate ? this.update() : this.save();
    }

  }

  onFormSubmit($event: HqtMainForm, form: string): void {
    switch (form) {
      case "MAIN": {
        this.hqtMainFormValues = $event;
        this.modifiedHqt = this.hqtHelper.toHqtModel(this.hqtMainFormValues, this.forUpdate, this.originalHqt);
        break;
      }

      default:
        break;
    }
  }

  save(): void {
    this.loaderMessagingService.showPageLoader(true);
    this.crudService
      .add<Hqt>(
        allHqtEndpoint,
        this.modifiedHqt
      )
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(response => {
        this.router.navigate([HQTS_PATH, response.id]);
        const dialogRef = this.dialog.open(DialogBoxComponent);
        dialogRef.componentInstance.dialogTitle = dialogBoxSuccessTitle;
        dialogRef.componentInstance.contentMessage = createSuccessHqt;
      },
      this.errorHandlerService.handleError,
      this.handleCompletion);
  }

  update(): void {
    this.loaderMessagingService.showPageLoader(true);
    this.crudService
      .edit<Hqt>(
        allHqtEndpoint.concat(`/${this.modifiedHqt.id}`),
        this.modifiedHqt
      )
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(response => {
        this.router.navigate([HQTS_PATH, this.modifiedHqt.id]);
        const dialogRef = this.dialog.open(DialogBoxComponent);
        dialogRef.componentInstance.dialogTitle = dialogBoxSuccessTitle;
        dialogRef.componentInstance.contentMessage = updateSuccessHqt;
      },
      this.errorHandlerService.handleError,
      this.handleCompletion);
  }

  initHqtForms(): void {
    if (this.forUpdate) {
      this.crudService
        .getById<Hqt>(allHqtEndpoint.concat(`/${this.hqtId}`))
        .pipe(takeUntil(this.unsubscribe))
        .subscribe(data => {
          if (data) this.findAssociatedClasses(data);
        },
        this.errorHandlerService.handleError, this.handleCompletion);
    }
  }

  findAssociatedClasses(hqt: Hqt): void {

    this.crudService
      .getAllBy<Class>(allClassesEndpoint.concat(`/hqts/${hqt.email}`))
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(response => {

        if (response.length > 0) this.disableStatus = true;
        this.originalHqt = hqt;
        this.hqtMainFormValues = this.hqtHelper.toHqtMainFormValues(hqt);

      }, this.errorHandlerService.handleError, this.handleCompletion);

  }

  getStatus(): void {
    this.crudService
    .getById<any>(getStatusEndpoint)
    .pipe(takeUntil(this.unsubscribe))
    .subscribe(
      response => {
        this.status = response;
        this.status.sort((a,b) => a.code.localeCompare(b.code));

        if (this.forUpdate) this.initHqtForms();
      },
      this.errorHandlerService.handleError,
      () => {
        if (!this.forUpdate) this.handleCompletion();
      });
  }

}
