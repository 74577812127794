import {
  trigger,
  state,
  style,
  animate,
  transition
} from "@angular/animations";

// Each unique animation requires its own trigger. The first argument of the trigger function is the name
export const rotatingIcon: any = trigger("rotatedState", [
  state("default", style({ transform: "rotate(0)" })),
  state("rotated", style({ transform: "rotate(-180deg)" })),
  transition("rotated => default", animate("400ms ease-out")),
  transition("default => rotated", animate("400ms ease-in"))
]);

export const changeSize: any = trigger("changeSize", [
  state("OPEN", style({ height: "auto", width: "100%" })),
  state("CLOSE", style({ height: "0px", width: "0px" })),
  transition("CLOSE => OPEN", animate("500ms ease-out")),
  transition("OPEN => CLOSE", animate("500ms ease-in"))
]);
