import { PaginationDto } from "./pagination-model";

// Group queries together for calling backend:
export class QueryDto {
    _pagination: PaginationDto;
    _sortedBy: string;
    _sortOrder: string;
    _keyword: string;

    constructor(
        pagination: PaginationDto,
        sortedBy: string,
        sortOrder: string,
        keyword: string
    ) {
        this._pagination = pagination;
        this._sortedBy = sortedBy;
        this._sortOrder = sortOrder;
        this._keyword = keyword;
    }

    get pagination() {
        return this._pagination;
    }

    get sortedBy() {
        return this._sortedBy;
    }

    get sortOrder() {
        return this._sortOrder;
    }

    get keyword() {
        return this._keyword;
    }
}