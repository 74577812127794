import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';
import { PREV, NEXT, SAVE } from 'src/app/core/constants/configuration/common.constant';

@Component({
  selector: 'form-nav-btn',
  templateUrl: './form-nav-btn.component.html',
  styleUrls: ['./form-nav-btn.component.scss']
})
export class FormNavBtnComponent implements OnInit {

  @Output() onClick = new EventEmitter();
  @Input()
  isFirst: boolean = true;
  @Input()
  isLast: boolean = false;
  @Input()
  disableNextButton: boolean = false;


  constructor() { }

  prev: string = PREV;
  next: string = NEXT;

  onClicked(item: string) {
    this.onClick.emit(item)
  }

  ngOnInit() {
    if(this.isLast) {
      this.next = SAVE;
    }
  }

}
