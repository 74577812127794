import { Component, OnInit, OnDestroy } from '@angular/core';
import { Hqt } from 'src/app/core/models/hqt.model';
import { HQT } from 'src/app/core/constants/configuration/hqt-constants.config';
import { allHqtEndpoint, allClassesEndpoint } from 'src/app/core/constants/endpoints.constant';
import { Subject } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { CrudService } from 'src/app/core/services/data/crud.service';
import { LoaderMessagingService } from 'src/app/core/services/messaging/loader-messaging.service';
import { ErrorHandlerService } from 'src/app/core/services/util/error-handler.service';
import { HeaderMessagingService } from 'src/app/core/services/messaging/header-messaging.service';
import { VIEW_MODULE_NAME } from 'src/app/core/constants/configuration/common.constant';
import { takeUntil } from 'rxjs/operators';
import { Class } from 'src/app/core/models/class.model';
import { DataCustomizerService } from 'src/app/core/services/util/data-customizer.service';
import { CLASSES_PATH, HQT_UPDATE_PATH, HQTS_PATH } from 'src/app/core/constants/routes.constant';
import { FabButtonMessagingService } from 'src/app/core/services/messaging/fab-button-messaging.service';
import { UserService } from 'src/app/core/services/util/user.service';
import { settingsRoutingPermissions } from 'src/app/core/constants/configuration/role-constants.config';

@Component({
	selector: 'app-hqt-view',
	templateUrl: './hqt-view.component.html',
	styleUrls: ['./hqt-view.component.scss']
})
export class HqtViewComponent implements OnInit, OnDestroy {

	unsubscribe: Subject<any> = new Subject();
	data: any = {};
	hasInitialized: boolean = false;

	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private crudService: CrudService,
		private loaderMessagingService: LoaderMessagingService,
		private errorHandlerService: ErrorHandlerService,
		private headerMessagingService: HeaderMessagingService,
		private dataCustomizerService: DataCustomizerService,
		private fabButtonMessagingService: FabButtonMessagingService,
    private userService: UserService,
	) {
		this.userService.checkRolePermission(HQTS_PATH, settingsRoutingPermissions);
		this.headerMessagingService.setHeader(HQT, VIEW_MODULE_NAME, false, null);
	}

	ngOnInit() {
		this.loaderMessagingService.showPageLoader(true);
		this.findHqt(this.route.snapshot.paramMap.get('id'));
	}

	ngOnDestroy(): void {
		this.fabButtonMessagingService.sendMessage({ canEdit: false, path: null, id: null });
		this.unsubscribe.next();
		this.unsubscribe.complete();
	}

	handleCompletion = (): void => {
		this.loaderMessagingService.showPageLoader(false);
	};

	get canView(): boolean {
		return this.data.hqt && this.data.customizedData && this.hasInitialized;
	}

	onLinkClick(id: number): void {
		this.router.navigate([CLASSES_PATH, id]);
	}

	// PUT ALL API CALL BELOW
	private findHqt(id: string): void {

		this.crudService
			.getById<Hqt>(allHqtEndpoint.concat(`/${id}`))
			.pipe(takeUntil(this.unsubscribe))
			.subscribe(response => {
				if (response) {
					this.data.hqt = response;
					this.findAssociatedClasses(response.email);
				}
			}, this.errorHandlerService.handleError);

	}

	private findAssociatedClasses(hqtUsername: string): void {

		this.crudService
			.getAllBy<Class>(allClassesEndpoint.concat(`/hqts/${hqtUsername}`))
			.pipe(takeUntil(this.unsubscribe))
			.subscribe(response => {
				const customizedData: any[] = this.dataCustomizerService.customizeClassesForHqtDisplay(response);
				this.data.customizedData = customizedData;
				this.data.totalClasses = response.length;
				this.initFabMessage();
			}, this.errorHandlerService.handleError, this.handleCompletion);

	}

	private initFabMessage(): void {
		let roles = settingsRoutingPermissions.find(route => route.path === HQTS_PATH);
		const message: any = { canEdit: this.userService.hasRole(roles ? roles.roles : []), path: HQT_UPDATE_PATH, id: this.data.hqt.id };
		this.fabButtonMessagingService.sendMessage(message);
		this.hasInitialized = true;
	}

}
