import { NgModule } from "@angular/core";
import { HttpClientModule } from '@angular/common/http';
import { httpInterceptorProviders } from "./interceptors/index.interceptor";

@NgModule({
  imports: [
    HttpClientModule,
  ],
  providers: [httpInterceptorProviders],
})
export class CoreModule {}
