import {Component, OnInit} from '@angular/core';
import {HeaderMessagingService} from 'src/app/core/services/messaging/header-messaging.service';
import {ASC, CLOSE, ERROR, OPEN, pageSizeOptions, settingsModuleName, SUCCESS} from 'src/app/core/constants/configuration/common.constant';
import {saveAs} from 'file-saver';
import {faFilter} from '@fortawesome/free-solid-svg-icons';
import {Observable, Subject} from 'rxjs';
import {DataProperties} from 'src/app/core/models/data-properties.model';
import {modelDataProperties} from 'src/app/core/constants/configuration/model-constants.config';
import {settingsRoutingPermissions} from 'src/app/core/constants/configuration/role-constants.config';
import {DataCustomizerService} from 'src/app/core/services/util/data-customizer.service';
import {QueryService} from 'src/app/core/services/util/query.service';
import {ErrorHandlerService} from 'src/app/core/services/util/error-handler.service';
import {CrudService} from 'src/app/core/services/data/crud.service';
import {LoaderMessagingService} from 'src/app/core/services/messaging/loader-messaging.service';
import {Router} from '@angular/router';
import {MatDialog} from '@angular/material';
import {Page} from 'src/app/core/models/page.model';
import {Model} from 'src/app/core/models/model.model';
import {allModelsEndpoint, downloadModelsEndpoint, getCountByFilter} from 'src/app/core/constants/endpoints.constant';
import {takeUntil} from 'rxjs/operators';
import {MODEL_CREATE_PATH, MODEL_PATH, MODEL_UPDATE_PATH} from 'src/app/core/constants/routes.constant';
import {FilterModalComponent} from 'src/app/shared/components/filter-modal/filter-modal.component';
import {changeSize} from 'src/app/core/constants/animations.constant';
import {ConfirmDialogComponent} from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';
import {
  deleteModelConfirmation,
  deleteErrorModel,
  dialogBoxErrorTitle,
  downloadErrorNoDataModel
} from 'src/app/core/constants/message.constant';
import {DialogMessagingService} from 'src/app/core/services/messaging/dialog-messaging.service';
import {UserService} from 'src/app/core/services/util/user.service';
import {FilterService} from 'src/app/core/services/util/filter.service';
import {DialogBoxComponent} from '../../../../shared/components/dialog-box/dialog-box.component';
import {
  baseServerUri,
  downloadFileExtension,
  fileNameModel,
  xlsxFileMediaType
} from '../../../../core/constants/configuration/config.constant';
import {FileService} from '../../../../core/services/data/file.service';
import { DownloadFiltersDialogComponent } from 'src/app/shared/components/download-filters-dialog/download-filters-dialog.component';
import { DownloadFiltersTypeEnum } from 'src/app/shared/components/download-filters-dialog/enums/download-filters-type-enum';
import { DownloadFilters } from 'src/app/shared/components/download-filters-dialog/models/download-filters';

@Component({
  selector: "app-model-list",
  templateUrl: "./model-list.component.html",
  styleUrls: ["./model-list.component.scss"],
  animations: [changeSize]
})
export class ModelListComponent implements OnInit {
  unsubscribe: Subject<any> = new Subject();
  items: any[] = [];
  model: Page<Model>;
  pageSize: number = pageSizeOptions[0];
  faFilter = faFilter;
  filters: Map<string, string> = new Map<string, string>();
  itemsCount: number;
  keyword: string = "";
  pageIndex: number = 0;
  modelDataProperties: DataProperties[] = modelDataProperties;
  isType1: boolean = false;
  trigger: string = CLOSE;
  sortOrder: string;
  sortedBy: string;
  filterLabels: string[] = [];
  clearFilters: boolean = true;
	filtersKey: string = "modelFilters";

  constructor(
    private crudService: CrudService,
    private errorHandlerService: ErrorHandlerService,
    private headerMessagingService: HeaderMessagingService,
    private queryService: QueryService,
    private dataCustomizerService: DataCustomizerService,
    private loaderMessagingService: LoaderMessagingService,
    private dialogMessagingService: DialogMessagingService,
    private router: Router,
    private dialog: MatDialog,
    private userService: UserService,
    private filterService: FilterService,
    private downloadFileService: FileService
	) {
		this.userService.checkRolePermission(MODEL_PATH, settingsRoutingPermissions);
    this.headerMessagingService.setHeader(settingsModuleName, "", true, settingsRoutingPermissions);
		let modelFilters = this.filterService.getFilter(this.filtersKey);
		if (modelFilters) {
			this.keyword = modelFilters.keyword || "";
			this.filters = modelFilters.filters ? this.filterService.objectToMap(modelFilters.filters) : new Map<string, string>();
		}
	}

  ngOnInit() {
    this.initParams();
  }

  initParams(): void {
    this.sortedBy = this.modelDataProperties[0].property;
    this.sortOrder = ASC;
    this.search();
  }

  onSearch(keyword: string): void {
    this.keyword = keyword;
    this.pageIndex = 0;
    this.search();
  }

  search(): void {

    this.loaderMessagingService.showListLoader(true);
    this.items = [];
		this.filterService.setFilter(this.filtersKey, this.keyword, this.filters);

    this.crudService
      .getAll<Model>(
        allModelsEndpoint.concat(this.queryService.buildModelSearchQuery(
            this.pageIndex, this.pageSize, this.sortedBy, this.sortOrder, this.keyword, this.filters)))
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(response => {

        if (response) {
            this.itemsCount = response.totalElements;
            response.content.forEach(model => this.items.push(this.dataCustomizerService.customizeModelListDisplay(model)));
          }
        },
        this.errorHandlerService.handleError,
        this.handleCompletion
      );
  }

  handleCompletion = (): void => {
    this.loaderMessagingService.showListLoader(false);
    this.loaderMessagingService.showPageLoader(false);
  };

  onPageChange($event: any): void {
    this.pageIndex = $event.pageIndex;
    this.pageSize = $event.pageSize;
    this.search();
  }

  onEditClick(id: string): void {
		this.router.navigate([MODEL_UPDATE_PATH, id]);
	}

  onClickAdd() {
    this.router.navigateByUrl(MODEL_CREATE_PATH);
  }

  filterOnClick(): void {

		if (!this.isType1) {
			this.trigger = this.trigger === CLOSE ? OPEN : CLOSE;
		} else {
			this.trigger = CLOSE;
			let data = [];

			this.modelDataProperties.forEach(property => {

				if (property.filterable)
				data.push({
					label: property.label,
					prop: property.property,
					value: this.filters.get(property.property) || "",
				});

			});

			const modalStyle = { width: "30%" };
			const dialogRef = this.dialog.open(FilterModalComponent, { ...modalStyle, data });

			dialogRef.afterClosed().subscribe(filters => {
				if (filters) this.filterOnChange(filters);
			});
    }

  }

  filterOnChange(filters: Map<string, string>) {
		this.filters = new Map<string, string>(filters);
		this.setFilterLabels();
		this.pageIndex = 0;
    this.search();
  }

  setFilterLabels(): void {

    this.filterLabels = [];

    this.filters.forEach((value, key) =>
      this.filterLabels.push(`${this.modelDataProperties.find(property => property.property === key).label}: ${value}`));

    this.clearFilters = this.filters.size === 0;

  }

  onChangeFilterType(): void {
    this.isType1 = !this.isType1;
  }

  onClearFilters(): void {
    this.clearFilters = true;
    this.keyword = "";
    this.filters.clear();
    this.filterOnChange(new Map<string, string>());
  }

  onLinkClick(id: string): void {
    this.router.navigate([MODEL_PATH, id]);
  }

  onDeleteModel(id: string): void {

    const name: string = String(this.items.find(item => item.id === id).name);

    this.findModel(id).subscribe(response => {

      if(response.total != 0){
        this.dialogMessagingService.sendMessage({ status: ERROR, value: deleteErrorModel });
      } else {
        const dialogRef = this.dialog.open(ConfirmDialogComponent);
        dialogRef.componentInstance.confirmMessage = deleteModelConfirmation.replace("%", `(${name})`);
        dialogRef.afterClosed()
          .pipe(takeUntil(this.unsubscribe))
          .subscribe(result => {
            if (result) this.deleteModel(id);
          }, this.errorHandlerService.handleError);
      }

    }, this.errorHandlerService.handleError, this.handleCompletion);

  }

  findModel(id: string): Observable<Model> {

    return this.crudService
      .getById<Model>(allModelsEndpoint.concat(`/${id}`))
      .pipe(takeUntil(this.unsubscribe));

  }

  deleteModel(id: string): void {
    this.loaderMessagingService.showPageLoader(true);
    this.crudService
      .delete(allModelsEndpoint.concat(`/${id}`))
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(response =>{
        this.dialogMessagingService.sendMessage({ status: SUCCESS, value: response });
        this.search();
      },
      this.errorHandlerService.handleError, this.handleCompletion);
  }


  onToggleSort($event: any) {
    this.sortedBy = $event.sortedBy;
    this.sortOrder = $event.sortOrder;
    this.search();
  }

  openConfirmationDialog() {
    if (this.itemsCount > 0) {
			const dialogRef = this.dialog.open(
				DownloadFiltersDialogComponent,
				{
					width: '450px',
					data: {
						schoolYears: [],
						statuses: [
              {
                "code": 'false',
                "name": 'Nonconsumable'
              },
              {
                "code": 'true',
                "name": 'Consumable'
              },
              {
                "code": 'all',
                "name": 'All'
              }
            ],
						type: DownloadFiltersTypeEnum.MODEL
					}
				}
			);
			dialogRef
				.afterClosed()
				.pipe(takeUntil(this.unsubscribe))
				.subscribe(
					result => {
						if (result) {
							this.getCountByFilters(result);
						}
					},
          this.errorHandlerService.handleError
				);
		} else {
			const dialogRef = this.dialog.open(DialogBoxComponent);
			dialogRef.componentInstance.dialogTitle = dialogBoxErrorTitle;
			dialogRef.componentInstance.contentMessage = downloadErrorNoDataModel;
		}
  }

  getCountByFilters(
    filters: DownloadFilters
  ) {
    const endpoint = allModelsEndpoint
			.concat(getCountByFilter)
			.concat(this.queryService.buildDownloadQueryParams(filters));
		this.crudService.getById<number>(
			endpoint
		)
			.pipe(takeUntil(this.unsubscribe))
			.subscribe(
				response => {
					if (response > 0) {
						this.download(filters);
					} else {
						const dialogRef = this.dialog.open(DialogBoxComponent);
						dialogRef.componentInstance.dialogTitle = dialogBoxErrorTitle;
						dialogRef.componentInstance.contentMessage = downloadErrorNoDataModel;
					}
				}
			);
  }

  private download(
    filters: DownloadFilters
  ) {
    this.loaderMessagingService.showPageLoader(true);
    const endpoint = baseServerUri
      .concat(downloadModelsEndpoint)
      .concat(this.queryService
        .buildDownloadQueryParams(filters)
      );
    this.downloadFileService.getFile(endpoint)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(
        response => {
          const blob = new Blob([response], {type: xlsxFileMediaType});
          let fileName = filters.status.findIndex( x => x == 'all') == 0
            ? fileNameModel.concat(downloadFileExtension)
            : fileNameModel
                .concat(`_${filters.statusName}`)
                .concat(downloadFileExtension);
          saveAs(
            blob,
            fileName
          );
        },
        this.errorHandlerService.handleError,
        this.handleCompletion
      );
  }
}
