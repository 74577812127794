import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Bundle } from 'src/app/core/models/bundle.model';
import * as moment from "moment";
import { tableDateFormatWithTime, ACTIVE, INACTIVE, STATUS_1, STATUS_2 } from 'src/app/core/constants/configuration/common.constant';

@Component({
	selector: 'app-bundle-details',
	templateUrl: './bundle-details.component.html',
	styleUrls: ['./bundle-details.component.scss']
})
export class BundleDetailsComponent implements OnInit {

	@Output() linkClink: EventEmitter<number> = new EventEmitter();
	@Input() bundle: Bundle;

	bundleHQTLeadTag: string;
	createdDate: string;
	modifiedDate: string;
	status: string;
	mainDetails: any;

	constructor() { 
	}

	ngOnInit() {
		const { code, name , createdBy, createdDate, modifiedBy, modifiedDate } = this.bundle;
		const status = this.bundle.status == "ACTIVE" ? "STATUS_1" : this.bundle.status == "INACTIVE" ? "STATUS_2" : this.bundle.status;
		this.mainDetails = { code, name, createdBy, createdDate, modifiedBy, modifiedDate, status };
		this.bundleHQTLeadTag = this.bundle.curriculumAssoc.filter(bundleCurriculum => bundleCurriculum.curriculum.hasHqt).length > 0 ? "Yes" : "No";
		this.createdDate = moment(this.bundle.createdDate).format(tableDateFormatWithTime);
		this.modifiedDate = moment(this.bundle.modifiedDate).format(tableDateFormatWithTime);
		this.initializeStatus();
	}

	initializeStatus() {
		const startDate = moment(this.bundle.startDate).hours(0).minutes(0).seconds(0);
		const endDate = moment(this.bundle.endDate).hours(23).minutes(59).seconds(59);

		if (moment().isBetween(startDate, endDate)) {
		this.status = ACTIVE;
		} else {
		this.status = INACTIVE;
		}
	}

	onViewCurriculum(id: number): void {
		this.linkClink.emit(id);
	}

}
