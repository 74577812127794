import { Injectable } from "@angular/core";
import { Subject, Observable } from "rxjs";
import { map, tap } from "rxjs/operators";

@Injectable({ providedIn: 'root' })
export class WorkTextBookSelectedService {

    private subject = new Subject<String[]>();
    private isCheckedAllSubject = new Subject<Boolean>();
    private assetTags : String[] = [];
    private isSelectedAll: Boolean;

    setIsCheckedAll(isChecked: boolean) {
        this.isSelectedAll = isChecked;
        this.isCheckedAllSubject.next(this.isSelectedAll);
    }

    getIsCheckedAll(): Observable<Boolean> {
        return this.isCheckedAllSubject.asObservable();
    }

    setSelected(assetTag: string) {
        let index: number = this.assetTags.indexOf(assetTag);
        if (!this.isFound(
                this.assetTags,
                assetTag
            )) {
                this.assetTags.push(assetTag);
                this.subject.next(this.assetTags);
        }
    }

    removeWorkTextBook(assetTag: string) {
        if (this.isFound(this.assetTags, assetTag)) {
            this.assetTags.splice(
                this.assetTags.indexOf(assetTag),
                1
            );
            this.subject.next(this.assetTags);
        }
    }

    clearSelected() {
        this.subject.next();
    }

    getSelected(): Observable<String[]> {
        return this.subject.asObservable();
    }

    protected isFound(
        assetTags: String[],
        assetTag: string
    ): boolean {
        return assetTags.includes(assetTag);
    }

}