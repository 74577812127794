import { Component, OnInit, AfterViewInit, ChangeDetectorRef, ViewChild, EventEmitter } from '@angular/core';
import { LoaderMessagingService } from 'src/app/core/services/messaging/loader-messaging.service';
import {
	allOrdersEndpoint,
	getAllSchoolYearsEndpoint,
	getProcessingStatusEndpoint,
	getCountByStatus,
	getActiveSchoolYearEndpoint,
	oldOrdersEndpoint,
	updatePendingorderstatus,
} from 'src/app/core/constants/endpoints.constant';
import { Subject } from 'rxjs';
import { fullYearFormat, ASC, SY_CODE, homeModuleName, pageSizeOptions, CLOSE, OPEN, DESC, PAGE_INDEX, PAGE_SIZE } from 'src/app/core/constants/configuration/common.constant';
import { CrudService } from 'src/app/core/services/data/crud.service';
import { takeUntil } from 'rxjs/operators';
import { ErrorHandlerService } from 'src/app/core/services/util/error-handler.service';
import { QueryService } from "src/app/core/services/util/query.service";
import { DataCustomizerService } from "src/app/core/services/util/data-customizer.service";
import * as moment from 'moment';
import { ORDERS_PATH, ORDER_CREATE_PATH } from 'src/app/core/constants/routes.constant';
import { Router } from '@angular/router';
import { HeaderMessagingService } from 'src/app/core/services/messaging/header-messaging.service';
import { homeRoutingPermissions } from 'src/app/core/constants/configuration/role-constants.config';
import { faFilter } from '@fortawesome/free-solid-svg-icons';
import { Order } from 'src/app/core/models/order/order.model';
import { DataProperties } from 'src/app/core/models/data-properties.model';
import { orderDataProperties } from 'src/app/core/constants/configuration/order-constants.config';
import { MatDialog } from '@angular/material';
import { FilterModalComponent } from 'src/app/shared/components/filter-modal/filter-modal.component';
import { changeSize } from 'src/app/core/constants/animations.constant';
import { UserService } from "src/app/core/services/util/user.service";
import { DialogMessagingService } from 'src/app/core/services/messaging/dialog-messaging.service';
import { FilterComponent } from 'src/app/shared/components/filter/filter.component';
import {
  PROCESSING_STATUS_1,
  PROCESSING_STATUS_2,
  PROCESSING_STATUS_3,
  PROCESSING_STATUS_4,
  PROCESSING_STATUS_5,
  PROCESSING_STATUS_8,
} from 'src/app/core/constants/configuration/order-constants.config';
import { FilterService } from "src/app/core/services/util/filter.service";
import SortUtil from 'src/app/shared/components/utils/sort-utils/sort-util';
import { FieldTransformationToEndpointImpl } from 'src/app/shared/components/utils/sort-utils/transform-as-query';
import {
	approveordersSuccessMessage,
	approveordersConfirmMessage,
} from 'src/app/core/constants/message.constant';
import { ConfirmDialogComponent } from "src/app/shared/components/confirm-dialog/confirm-dialog.component";

@Component({
  selector: 'app-orders',
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.component.scss'],
  animations: [changeSize]
})
export class OrdersComponent implements OnInit, FieldTransformationToEndpointImpl {

	@ViewChild(FilterComponent) filterComponent: FilterComponent;
	items: any[] = [];
	itemsCount = this.items.length;
	pageIndex: number = 0;
	pageSize: number = pageSizeOptions[0];
	faFilter = faFilter;
	keyword: string = "";
	filters: Map<string, string> = new Map<string, string>();
	unsubscribe: Subject<any> = new Subject();
	reference: any = {};
	selectedSyId: number;
	schoolYear: string;
	filterLabels: string[] = [];
	orderDataProperties: DataProperties[] = orderDataProperties;
	schoolYears: any[] = [];
	processingStatus: any[] = [];
	clearFilters: boolean = true;
	sortedBy: string;
	sortOrder: string;
	isType1: boolean = false;
	trigger: string = CLOSE;
	statusFilter = new Map<string, string>();
	statusListCount = new Array<any>();
	filtersKey: string = "orderFilters";

	constructor(
		private crudService: CrudService,
		private dataCustomizerService: DataCustomizerService,
		private dialog: MatDialog,
		private errorHandlerService: ErrorHandlerService,
		private headerMessagingService: HeaderMessagingService,
		private loaderMessagingService: LoaderMessagingService,
		private queryService: QueryService,
		private router: Router,
		private userService: UserService,
		private filterService: FilterService,
		private dialogMessagingService: DialogMessagingService,
	) {
		this.userService.checkRolePermission(ORDERS_PATH, homeRoutingPermissions);
		this.headerMessagingService.setHeader(homeModuleName, "", true, homeRoutingPermissions);
		let orderFilters = this.filterService.getFilter(this.filtersKey);
		if (orderFilters) {
			this.keyword = orderFilters.keyword || "";
			this.filters = orderFilters.filters ? this.filterService.objectToMap(orderFilters.filters) : new Map<string, string>();
			if (orderFilters.filters.Size) {
				this.pageSize = orderFilters.filters.Size;
			}
			if (orderFilters.filters.Page) {
				this.pageIndex = orderFilters.filters.Page;
			}
		}
  }

  	ngOnInit() {
		this.loaderMessagingService.showPageLoader(true);
		this.getSchoolYears();
	}

	onClickAdd() {
		this.router.navigateByUrl(ORDER_CREATE_PATH);
	}

	onSearch(keyword: string): void {
		this.keyword = keyword;
		this.pageIndex = 0;
		this.search();
	}

	search() : void {
		this.loaderMessagingService.showListLoader(true);
		this.items = [];
		this.filterService.setFilter(this.filtersKey, this.keyword, this.filters);

		var syCode = this.filters.get("syCode");
		if(syCode != null && syCode == "2019-2020") {
			this.crudService
			.getAll<Order>(oldOrdersEndpoint.concat(this.queryService.buildOrderSearchQuery(
					this.pageIndex, this.pageSize, this.sortedBy, this.sortOrder, this.keyword, this.filters)))
			.pipe(takeUntil(this.unsubscribe))
			.subscribe(response => {
					if (response) {
						this.itemsCount = response.totalElements;
						response.content.forEach(order =>
							this.items.push(this.dataCustomizerService.createCustomizedOrder(order, this.getReferences()))
						);
					}
				},
				this.errorHandlerService.handleError,
				this.handleCompletion
			);
		} else {
			this.crudService
			.getAll<Order>(allOrdersEndpoint.concat(this.queryService.buildOrderSearchQuery(
					this.pageIndex, this.pageSize, this.sortedBy, this.sortOrder, this.keyword, this.filters)))
			.pipe(takeUntil(this.unsubscribe))
			.subscribe(response => {
					if (response) {
						this.itemsCount = response.totalElements;
						response.content.forEach(order =>
							this.items.push(this.dataCustomizerService.createCustomizedOrder(order, this.getReferences()))
						);
					}
				},
				this.errorHandlerService.handleError,
				this.handleCompletion
			);
		}

	}

	getReferences() {
		return {
			processingStatus: this.processingStatus,
		}
	}

	setSchoolYear(schoolYear: any): void {
		this.selectedSyId = 0;
		if(schoolYear != undefined || schoolYear != null) {
		  this.selectedSyId = schoolYear.id;
		  this.schoolYear = schoolYear.schoolYear;
		  if (this.filters.has(SY_CODE)) this.filters.delete(SY_CODE);
		  this.setFilterLabels();
		  this.getFilterCount();
		  this.search();
		} else {
		  this.crudService
		  .getById<any>(getActiveSchoolYearEndpoint)
		  .pipe(takeUntil(this.unsubscribe))
				.subscribe(response => {
					if (response) {
						this.schoolYear = response.schoolYear;
						this.selectedSyId = this.schoolYears.find(sy =>
						String(sy.schoolYear).trim() === this.schoolYear.trim()).id;

						if (this.filters.has(SY_CODE)) this.filters.delete(SY_CODE);
						this.setFilterLabels();
						this.getFilterCount();
						this.search();
					}
			}, this.errorHandlerService.handleError);
		}
	}

  setFilterLabels(): void {
		this.filterLabels = [];
		this.filters.forEach((value, key) => {
			let prop = this.orderDataProperties.find(property => property.property === key);
			if (prop && prop.label) this.filterLabels.push(`${prop.label}: ${value}`)
		});
		this.filters.set(SY_CODE, this.schoolYear);
		this.clearFilters = this.filters.size === 1;
		const index: number = this.filterLabels.findIndex(label => label.includes('School Year:'));
		if (index !== -1) this.filterLabels.splice(index, 1);
		this.filterLabels.splice(0, 0, `School Year: ${this.schoolYear}`);
  }

  getSchoolYears(): void {
		this.crudService
			.getById<any>(getAllSchoolYearsEndpoint)
			.pipe(takeUntil(this.unsubscribe))
			.subscribe(response => {
				this.schoolYears = response;
				this.schoolYears.sort((a, b) => b.schoolYear.localeCompare(a.schoolYear));
				this.getProcessingStatus();
			}, this.errorHandlerService.handleError);
  }

  getProcessingStatus(): void {
		this.crudService
			.getById<any>(getProcessingStatusEndpoint)
			.pipe(takeUntil(this.unsubscribe))
			.subscribe(response => {
				this.processingStatus = response;
				this.processingStatus.sort((a, b) => b.code.localeCompare(a.code));
				this.initParams();
			}, this.errorHandlerService.handleError);
  }

  handleCompletion = (): void => {
		this.loaderMessagingService.showListLoader(false);
		this.loaderMessagingService.showPageLoader(false);
  };

  initParams(): void {
		this.sortedBy = this.orderDataProperties[0].property;
		this.sortOrder = ASC;
		let yearNow: string = moment((new Date()).valueOf()).format(fullYearFormat);
		this.setSchoolYear(null);
  }

	onChangeFilterType(): void {
		this.isType1 = !this.isType1;
	}

	filterOnClick(): void {
		if (!this.isType1) {
			this.trigger = this.trigger === CLOSE ? OPEN : CLOSE;
		} else {
			this.trigger = CLOSE;
			let data = [];
			this.orderDataProperties.forEach(property => {
				if (property.filterable)
				data.push({
					label: property.label,
					prop: property.property,
					value: this.filters.get(property.property) || "",
				});
			});
			const modalStyle = { width: "30%" };
			const dialogRef = this.dialog.open(FilterModalComponent, { ...modalStyle, data });
			dialogRef.afterClosed().subscribe(filters => {
				if (filters) this.filterOnChange(filters);
			});
		}
	}

	filterOnChange(filters: Map<string, string>) {
		this.filters = new Map<string, string>(filters);
		this.setFilterLabels();
		this.pageIndex = 0;
		this.search();
	}

  	onClearFilters(): void {
		this.clearFilters = true;
		this.keyword = '';
		this.filters.clear();
		this.filterOnChange(new Map<string, string>());
	}

  	onPageChange($event: any): void {
		this.pageIndex = $event.pageIndex;
		this.pageSize = $event.pageSize;
		this.setPageSizeFilter(this.pageSize);
		this.setPageIndexFilter(this.pageIndex);
		this.search();
	}

	onFilterChange(event: any) {
		this.statusFilter = event;
		this.trigger = OPEN;
		setTimeout(() =>{
		this.filterComponent.filterByStatus();
    },200);
	}

	getFilterCount() : void {
		let statusFilter : Map<string, string> = new Map<string, string>();
		let apiUrl = allOrdersEndpoint
		statusFilter.set(SY_CODE, this.schoolYear);
		if(this.schoolYear == "2019-2020") {
			apiUrl = oldOrdersEndpoint.concat(getCountByStatus);
		} else {
			apiUrl = allOrdersEndpoint.concat(getCountByStatus).concat("?syCode=" + this.schoolYear);
		}
		this.statusListCount = [];
		this.crudService
			.getById<Map<string, number>>(apiUrl)
			.pipe(takeUntil(this.unsubscribe))
			.subscribe(response => {
				this.processingStatus.reverse().forEach((status:any) => {
					let statusCount = {};
					statusCount = {
						name: status.name,
						code: status.code,
						count: response[status.code]
					}
					console.log("statusCount",statusCount);
					console.log("response",response);
					this.statusListCount.push(statusCount);
					this.statusListCount.sort((a, b) => a.code !== b.code ? a.code < b.code ? -1 : 1 : 0);
				});
		}, this.errorHandlerService.handleError);
	}

	setPageIndexFilter(pageIndex: number): void {
		this.filters.set(PAGE_INDEX, String(pageIndex));
		this.filterService.setFilter(this.filtersKey, this.keyword, this.filters);
	}

	setPageSizeFilter(pageSize: number): void {
		this.filters.set(PAGE_SIZE, String(pageSize));
		this.filterService.setFilter(this.filtersKey, this.keyword, this.filters);
	}

	onToggleSort($event: any) {
		this.sortedBy = this.getSortedByAsQuery($event.sortedBy);
		this.sortOrder = $event.sortOrder;
		this.search();
	}

	// Transforms header as accepted parameter in the backend for search.
	getSortedByAsQuery(
		sortedBy: string
	): string {
		let response: string;
		switch(sortedBy) {
			case 'scope':
				response = 'description';
			break;
			case 'dateSubmitted':
				response = 'createdDate';
			break;
			case 'totalAmount':
				response = 'total';
			break;
			default:
				response = sortedBy;
			break;
		}
		return response;
	}
	onBulkAction($event: any): void {
		let successMessage: string;
		let confirmMessage: string;
		let newStatus: string;
		let specialist: string;

				specialist = $event.selectedSpecialist;
				successMessage = approveordersSuccessMessage.replace("??", specialist);
				confirmMessage = approveordersConfirmMessage.replace("?", "assign").replace("??", `to ${specialist}?`);
				newStatus = PROCESSING_STATUS_2;

		this.openConfirmDialog(confirmMessage, successMessage, $event, newStatus, specialist);
	}
	openConfirmDialog(confirmMessage: string, successMessage: string, event: any, newStatus: string, specialist: string) {
		let selectedItems: any[] = event.items;
		const dialogRef = this.dialog.open(ConfirmDialogComponent, { autoFocus: false });
		dialogRef.componentInstance.confirmMessage = confirmMessage;
		dialogRef.afterClosed().subscribe(result => {
      	if (result) {
			console.log("selectedItems",selectedItems);
				let items = selectedItems
				// .map(item => {
				// 	let orderItems = [];
				// 	// item.orderItemAssoc.map(itemAssoc => {
				// 	// 	if (itemAssoc.status == PROCESSING_STATUS_8) {
				// 	// 		orderItems.push({ ...itemAssoc, status: newStatus })
				// 	// 	}
				// 	// });

				// 	return items;
				// });
				this.updateTaskStatus(successMessage, items, event.action);
			}
		});
  }
  updateTaskStatus(successMessage: string, selectedItems: any[], action: string) {
	this.loaderMessagingService.showPageLoader(true);
	let taskEndpoint = "";
		taskEndpoint = updatePendingorderstatus;
	this.crudService
		.edit<any>(taskEndpoint, { tasks: selectedItems })
		.pipe(takeUntil(this.unsubscribe))
		.subscribe(response => {
			if (response) {
				this.dialogMessagingService.sendMessage({
					status: "SUCCESS",
					value: successMessage,
				});
				this.statusListCount = [];
				this.onSearch("");
			}
	}, this.errorHandlerService.handleError, this.handleCompletion);
}
}
