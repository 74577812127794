import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { HeaderMessagingService } from 'src/app/core/services/messaging/header-messaging.service';
import { ErrorHandlerService } from 'src/app/core/services/util/error-handler.service';
import { CrudService } from 'src/app/core/services/data/crud.service';
import { LoaderMessagingService } from 'src/app/core/services/messaging/loader-messaging.service';
import { MatDialog } from '@angular/material';
import { Router } from '@angular/router';
import { Class } from 'src/app/core/models/class.model';
import { baseServerUri } from 'src/app/core/constants/configuration/config.constant';
import { allClassesEndpoint } from 'src/app/core/constants/endpoints.constant';
import { takeUntil } from 'rxjs/operators';
import { CLASSES_PATH } from 'src/app/core/constants/routes.constant';
import { DialogBoxComponent } from 'src/app/shared/components/dialog-box/dialog-box.component';
import { dialogBoxSuccessTitle, createSuccessClass } from 'src/app/core/constants/message.constant';
import { sideNavLabelClass, CLASS } from 'src/app/core/constants/configuration/class-constants.config';
import { CREATE_MODULE_NAME } from 'src/app/core/constants/configuration/common.constant';
import { UserService } from 'src/app/core/services/util/user.service';
import { homeRoutingPermissions } from 'src/app/core/constants/configuration/role-constants.config';

@Component({
  selector: 'app-class-create',
  templateUrl: './class-create.component.html',
  styleUrls: ['./class-create.component.scss']
})
export class ClassCreateComponent implements OnInit, OnDestroy {

	activeIndex: number = 0;
	sideNavLabels: string[] = [];
	unsubscribe: Subject<any> = new Subject();

	constructor(
		private headerMessagingService: HeaderMessagingService,
		private crudService: CrudService,
		private errorHandlerService: ErrorHandlerService,
		private loaderMessagingService: LoaderMessagingService,
		private dialog: MatDialog,
		private router: Router,
    private userService: UserService,
	) {
		this.userService.checkRolePermission(CLASSES_PATH, homeRoutingPermissions);
		this.headerMessagingService.setHeader(CLASS, CREATE_MODULE_NAME, false, null);
		this.sideNavLabels = sideNavLabelClass;
	}

	ngOnInit() {
	}

	ngOnDestroy(): void {
		this.unsubscribe.next();
		this.unsubscribe.complete();
	}

	onSaveClass($event: Class) {

		this.loaderMessagingService.showPageLoader(true);
		this.crudService
			.add<Class>(allClassesEndpoint, $event)
			.pipe(takeUntil(this.unsubscribe))
			.subscribe(response => {

				if (response) {
					this.router.navigate([CLASSES_PATH, response.id]);
					const dialogRef = this.dialog.open(DialogBoxComponent);
					dialogRef.componentInstance.dialogTitle = dialogBoxSuccessTitle;
					dialogRef.componentInstance.contentMessage = createSuccessClass;
				}

			}, this.errorHandlerService.handleError, this.handleCompletion);
		
	}

	handleCompletion = (): void => {
		this.loaderMessagingService.showPageLoader(false);
	}

}
