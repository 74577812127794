import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgModule } from "@angular/core";

import { AppComponent } from "./app.component";
import { CoreModule } from "./core/core.module";
import { SharedModule } from "./shared/shared.module";
import { LoginModule } from "./domains/login/login.module";
import { AppRoutingModule } from "./app-route/app-routing.module";
import { HomeModule } from "./domains/home/home.module";
import { OrderModule } from "./domains/order/order.module";
import { SettingsModule } from "./domains/settings/settings.module";
import { HashLocationStrategy, LocationStrategy } from "@angular/common";

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CoreModule,
    SharedModule,

    // config
    LoginModule,
    HomeModule,
    SettingsModule,

    // ordering
    OrderModule,

    AppRoutingModule // <-- must be the last to import
  ],
  providers: [{ provide: LocationStrategy, useClass: HashLocationStrategy }],
  bootstrap: [AppComponent]
})
export class AppModule {}
