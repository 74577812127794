import { Injectable } from "@angular/core";
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from "@angular/router";
import { Observable } from "rxjs";
import { accessToken } from "../constants/configuration/config.constant";
import { AUTHORIZE_REDIRECT_PATH } from "../constants/routes.constant";

@Injectable({ providedIn: 'root' })
export class LoginGuard implements CanActivate {

  constructor(private router: Router) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {

    const token: string = localStorage.getItem(accessToken);

    if (token && token.trim() !== '') {
      this.router.navigateByUrl(AUTHORIZE_REDIRECT_PATH);
      return false;
    }

    return true;

  }

}
