import { QueryDto } from "../../../../core/models/dto/query-dto";

export class TxnHistoryItemDto {
    private _query: QueryDto;
    private _itemCode: string;
    private _itemType: string;

    constructor(
        queryDto: QueryDto,
        itemCode: string,
        itemType: string,
    ) {
        this._query = queryDto;
        this._itemCode = itemCode;
        this._itemType = itemType;
    }

    get query() {
        return this._query;
    }

    get itemCode() {
        return this._itemCode;
    }

    get itemType() {
        return this._itemType;
    }
}