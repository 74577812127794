import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { baseServerUri } from "../../constants/configuration/config.constant";

@Injectable({ providedIn: 'root' })
export class HomeService {

  private baseApi: string = `${baseServerUri}/test`;

  constructor(private http: HttpClient) {}

  getAll(): Observable<string> {
    let options: Object = { responseType: 'text' };
    return this.http.get<string>(`${this.baseApi}/home`, options);
  }

}
