import { ASC, DESC } from "src/app/core/constants/configuration/common.constant";
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { faSortUp, faSortDown } from "@fortawesome/free-solid-svg-icons";

export default class SortUtil {
    static getSortOrder(
		sortOrder: string,
		isSortedByChanged: boolean
	): string {
		return isSortedByChanged
			? ASC
			: this.toggleSortOrder(sortOrder);
    }
    
    static toggleSortOrder(
		sortOrder: string
	): string {
		return sortOrder == DESC
			? ASC
			: DESC;
    }
    
    static toggleSortIcon(
        sortOrder: string
    ): IconDefinition {
        return sortOrder == DESC
            ? faSortDown
            : faSortUp;
    }
}