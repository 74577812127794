import { Injectable } from "@angular/core";
import { Bundle } from "../../models/bundle.model";
import { BundleXCurriculum } from "../../models/bundlexcurriculum.model";
import { Curriculum } from "../../models/curriculum.model";
import { BundleMainForm } from "../../models/form/bundle-main-form.model";
import { BundleCurriculumForm } from "../../models/form/bundle-curriculum-form.model";
import { CurriculumForm } from "../../models/form/curriculum-form.model";
import { PER_YEAR } from "../../constants/configuration/common.constant";

@Injectable({ providedIn: "root" })
export class BundleHelper {
  constructor() {}

  setBundleModel(
    bundleFormValues: BundleMainForm,
    bundleCurriculumFormValues: BundleCurriculumForm,
    forUpdate: boolean,
    original?: Bundle
  ): Bundle {
    return {
      id: forUpdate ? original.id : null,
      code: bundleFormValues.code,
      name: bundleFormValues.name,
      description: bundleFormValues.description,
      status: bundleFormValues.availability,
      availability: bundleFormValues.availability,
      createdBy: forUpdate ? original.createdBy : null,
      modifiedBy: forUpdate ? original.modifiedBy : null,
      createdDate: forUpdate ? original.createdDate : null,
      modifiedDate: forUpdate ? original.modifiedDate : null,
      //activeStatus: original.activeStatus,
      startDate: bundleFormValues.startDate,
      endDate: bundleFormValues.endDate,
      minimumClasses: bundleCurriculumFormValues.minimumClasses,
      maximumClasses: bundleCurriculumFormValues.maximumClasses,
      price: bundleFormValues.price ? bundleFormValues.price : 0,
      syCode: bundleFormValues.syCode,
      activePeriodType: bundleFormValues.activePeriodType,
      bundleType: bundleCurriculumFormValues.bundleType,
      curriculumAssoc: bundleCurriculumFormValues.curriculums.map(curriculum =>
        this.createCurriculumAssoc(curriculum)
      )
    };
  }

  private createCurriculumAssoc(curriculum: CurriculumForm): BundleXCurriculum {
    return {
      minimumClasses: curriculum.minimumClasses,
      curriculum: this.createCurriculum(curriculum),
      bundle: null
    };
  }

  private createCurriculum(value: CurriculumForm): Curriculum {
    return {
      id: value.id,
      code: value.code,
      name: value.name,
      description: null,
      status: null,
      createdBy: null,
      modifiedBy: null,
      createdDate: null,
      modifiedDate: null,
      //activeStatus: null,
      startDate: null,
      endDate: null,
      deposit: null,
      hasDeposit: null,
      fee: null,
      perClassCost: null,
      hasHqt: null,
      classes: value.classes,
      syCode: null,
      bookRequired: null,
      scopes: value.scopes,
    };
  }

  setBundleMainFormValues(bundle: Bundle): BundleMainForm {
    return {
      code: bundle.code,
      name: bundle.name,
      description: bundle.description,
      price: bundle.price ? bundle.price : 0,
      periodCheckbox: bundle.activePeriodType === PER_YEAR ? true : false,
      activePeriodType: bundle.activePeriodType,
      syCode: bundle.syCode,
      availability: bundle.availability,
      startDate: bundle.startDate,
      endDate: bundle.endDate,
    };
  }

  setBundleCurriculumFormValues(bundle: Bundle): BundleCurriculumForm {
    return {
      minimumClasses: bundle.minimumClasses,
      maximumClasses: bundle.maximumClasses,
      bundleType: bundle.bundleType,
      curriculums: this.setBundleCurriculumsValues(bundle.curriculumAssoc)
    };
  }

  setBundleCurriculumsValues(
    bundleXcurriculum: BundleXCurriculum[]
  ): CurriculumForm[] {
    let curriculums: any[] = [];
    bundleXcurriculum.map(bundCurri => {
      curriculums.push({
        id: bundCurri.curriculum.id,
        name: bundCurri.curriculum.name,
        code: bundCurri.curriculum.code,
        minimumClasses: bundCurri.minimumClasses
      });
    });
    return curriculums;
  }
}
