import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";

@Injectable({ providedIn: 'root' })
export class UploadMessageService {

	private uploadMessageSource: Subject<number> = new Subject<number>();

  uploadMessage$: Observable<number> = this.uploadMessageSource.asObservable();

  sendMessage(message: number): void {
    this.uploadMessageSource.next(message);
  }

}
