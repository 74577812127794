import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import * as moment from "moment";
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { faTimesCircle, faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { MatDialog } from "@angular/material";
import { Subject } from "rxjs";
import { Order } from "src/app/core/models/order/order.model";
import { CrudService } from "src/app/core/services/data/crud.service";
import { ErrorHandlerService } from "src/app/core/services/util/error-handler.service";
import { LoaderMessagingService } from "src/app/core/services/messaging/loader-messaging.service";
import { ConfirmDialogComponent } from "src/app/shared/components/confirm-dialog/confirm-dialog.component";
import { DialogBoxComponent } from "src/app/shared/components/dialog-box/dialog-box.component";
import { dialogBoxSuccessTitle } from "src/app/core/constants/message.constant";
import {
  PROCESSING_STATUS_1,
  PROCESSING_STATUS_2,
  PROCESSING_STATUS_3,
  PROCESSING_STATUS_4,
  PROCESSING_STATUS_5,
  ORDER_ITEM_TYPE_1,
} from "src/app/core/constants/configuration/order-constants.config";
import { UserService } from "src/app/core/services/util/user.service";
import { ADMIN, SUPERVISOR, SPECIALIST } from 'src/app/core/constants/configuration/role-constants.config';
import { OrderBundle } from 'src/app/core/models/order/order-bundle.model';

@Component({
  selector: 'app-order-items-bundles',
  templateUrl: './order-items-bundles.component.html',
  styleUrls: ['./order-items-bundles.component.scss']
})
export class OrderItemsBundlesComponent implements OnInit {

  @Input() canRemoveItem: boolean = true;
  @Input() orderItemAssoc: any[] = [];
  @Input() processingStatus: any[];
  @Output() onGetNewOrderDetails = new EventEmitter<any>();
  @Input() forCreate: boolean;
  orderDetailsValue: any;
  orderItemsList: any[] = [];
  orderBundlesList: any[] = [];
  orderGroupCodes: string[] = [];
  unsubscribe: Subject<any> = new Subject();
  referenceTypes: any[];
  selectedItems: any[] = [];

  removeIcon: IconDefinition = faTimesCircle;

  collapse: boolean = this.forCreate;
  //ICONS
  faChevronDown = faChevronDown;
  faChevronUp = faChevronUp;

  collapsed() {
    this.collapse = !this.collapse;
  }

  constructor(
    private crudService: CrudService,
    private dialog: MatDialog,
    private errorHandlerService: ErrorHandlerService,
		private loaderMessagingService: LoaderMessagingService,
		private userService: UserService,
  ) {
    this.loaderMessagingService.showPageLoader(true);
  }

  ngOnInit() {
    this.loaderMessagingService.showPageLoader(false);
    this.collapse = !!this.forCreate;
  }

  @Input()
  set orderDetails(value) {
    this.orderDetailsValue = value;
    this.selectedItems = [];
    //this.groupItemsByCurriculum();
    this.orderBundlesList = this.orderDetailsValue.orderBundles;
  }



  // groupItemsByCurriculum() {
  //   this.orderItemsList = this.orderDetailsValue.orderItems.filter(item => item.referenceType === ORDER_ITEM_TYPE_1);
  //   this.orderDetailsValue.orderItems.forEach(item => {
  //     if (!this.orderGroupCodes.includes(item.txnOrderGroup))
  //       this.orderGroupCodes.push(item.txnOrderGroup);
  //     if (item.orderBundle && !this.orderBundlesList.find(orderBundle => orderBundle.id === item.orderBundle.id))
  //       this.orderBundlesList.push({...item.orderBundle, order: this.orderDetailsValue});
  //   });
  //   this.orderGroupCodes.sort();
  //   this.orderBundlesList.sort();
  // }

  // setOrderGroupCodes() {
  //   this.orderGroupCodes = this.orderBundlesList.
  // }

  formatAmount(value: number): string {
    return "$" + value.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')
  }

  
  getPrice(orderBundle: OrderBundle): string {
    let value = (orderBundle.adjustedPrice != null ? orderBundle.adjustedPrice : orderBundle.price);
    return "$" + value.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')
  }

  formatDate(value: Date): string {
    return moment(value).format('MMM DD, YYYY')
  }

  hasBundle(code: string): boolean {
    return this.orderBundlesList.filter(bundle => bundle.txnOrderGroup === code).length > 0;
  }

  getStatusName(statusCode: string) {
    const statusName: any = this.processingStatus.find(status => status.code === statusCode);
    return statusName ? statusName.name : "";
  }

  getStatusClass(statusCode: string): string {
    switch (statusCode) {
      case PROCESSING_STATUS_1: return "approved status"
      case PROCESSING_STATUS_2: return "processing status"
      case PROCESSING_STATUS_3: return "pre-completed status"
      case PROCESSING_STATUS_4: return "completed status"
      case PROCESSING_STATUS_5: return "canceled status"
      default: return "status"
    }
  }

	handleCompletion(): void {
		this.loaderMessagingService.showPageLoader(false);
  }
}
