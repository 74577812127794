import { CurriculumSummaryComponent } from "./components/form/curriculum-form/curriculum-summary/curriculum-summary.component";
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";

import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { far } from "@fortawesome/free-regular-svg-icons";

import { MDBBootstrapModule } from "angular-bootstrap-md";

import {
  MatFormFieldModule,
  MatOptionModule,
  MatSelectModule,
  MatMenuModule,
  MatInputModule,
  MatButtonModule,
  MatToolbarModule,
  MatIconModule,
  MatCardModule,
  MatButtonToggleModule,
  MatProgressBarModule,
  MatTabsModule,
  MatGridListModule,
  MatListModule,
  MatTooltipModule,
  MatPaginatorModule,
  MatDialogModule,
  MatSidenavModule,
  MatCheckboxModule,
  MatDatepickerModule,
  MatNativeDateModule,
  MatRadioModule,
  MatProgressSpinnerModule,
  MatChipsModule,
  MatAutocompleteModule,
  MatExpansionModule,
  MatSlideToggleModule,
  MatStepperModule,
  MatRippleModule,
} from "@angular/material";
import { HeaderComponent } from "./layout/header/header.component";
import { ListComponent } from "./components/list/list.component";
import { FloatingDropdownComponent } from "./components/floating-dropdown/floating-dropdown.component";
import { InfoDisplayComponent } from "./components/info-display/info-display.component";
import { NavigationTabComponent } from "./layout/navigation-tab/navigation-tab.component";
import { SearchComponent } from "./components/search/search.component";
import { PaginatorComponent } from "./components/paginator/paginator.component";
import { SideNavLabelComponent } from "./components/side-nav-label/side-nav-label.component";
import { FormNavBtnComponent } from "./components/form-nav-btn/form-nav-btn.component";
import { FilterComponent } from "./components/filter/filter.component";
import { ConfirmDialogComponent } from "./components/confirm-dialog/confirm-dialog.component";
import { CurriculumFormComponent } from "./components/form/curriculum-form/curriculum-form.component";
import { CurriculumMainFormComponent } from "src/app/shared/components/form/curriculum-form/curriculum-main-form/curriculum-main-form.component";
import { CurriculumDetailsComponent } from "./components/form/curriculum-details/curriculum-details.component";
import { ObjectContainerComponent } from "./components/object-container/object-container.component";
import { BundleFormComponent } from "./components/form/bundle-form/bundle-form.component";
import { BundleMainFormComponent } from "./components/form/bundle-form/bundle-main-form/bundle-main-form.component";
import { BundleAddCurriculumComponent } from "./components/form/bundle-form/bundle-add-curriculum/bundle-add-curriculum.component";
import { BundleSummaryComponent } from "./components/form/bundle-form/bundle-summary/bundle-summary.component";
import { BundleDetailsComponent } from "./components/form/bundle-details/bundle-details.component";
import { DialogBoxComponent } from "./components/dialog-box/dialog-box.component";
import { ResourceDetailsComponent } from './components/form/resource-details/resource-details.component';
import { ResourceMainFormComponent } from './components/form/resource-form/resource-main-form/resource-main-form.component';
import { ResourceFormComponent } from './components/form/resource-form/resource-form.component';
import { ResourceGroupFormComponent } from './components/form/resource-form/resource-group-form/resource-group-form.component';
import { ResourceSummaryComponent } from './components/form/resource-form/resource-summary/resource-summary.component';
import { FilterModalComponent } from "./components/filter-modal/filter-modal.component";
import { CurriculumClassFormComponent } from "./components/form/curriculum-form/curriculum-class-form/curriculum-class-form.component";
import { ClassFormComponent } from "./components/form/class-form/class-form.component";
import { ClassMainFormComponent } from "./components/form/class-form/class-main-form/class-main-form.component";
import { HqtFormComponent } from "./components/form/hqt-form/hqt-form.component";
import { HqtDetailsComponent } from "./components/form/hqt-details/hqt-details.component";
import { HqtMainFormComponent } from "./components/form/hqt-form/hqt-main-form/hqt-main-form.component";
import { HqtSummaryComponent } from "./components/form/hqt-form/hqt-summary/hqt-summary.component";
import { ClassDetailsComponent } from "./components/form/class-details/class-details.component";
import { ClassSummaryComponent } from "./components/form/class-form/class-summary/class-summary.component";
import { CurriculumViewDialogComponent } from "./components/view/curriculum-view-dialog/curriculum-view-dialog.component";
import { MainDetailsComponent } from "./components/details/main-details/main-details.component";
import { ResourceGroupsComponent } from "./components/details/resource-groups/resource-groups.component";
import { FieldCheckerComponent } from "./components/utils/field-checker/field-checker.component";
import { ModelFormComponent } from "./components/form/model-form/model-form.component";
import { ModelMainFormComponent } from "./components/form/model-form/model-main-form/model-main-form.component";
import { ModelDetailsComponent } from "./components/form/model-details/model-details.component";
import { ModelSummaryComponent } from "./components/form/model-form/model-summary/model-summary.component";
import { ResourceModalFormComponent } from "./components/form/resource-modal-form/resource-modal-form.component"
import { FieldCheckerSpecialComponent } from "./components/utils/field-checker-special/field-checker-special.component";
import { AssetFormComponent } from "./components/form/asset-form/asset-form.component";
import { AssetMainFormComponent } from "./components/form/asset-form/asset-main-form/asset-main-form.component";
import { AssetDetailsComponent } from "./components/form/asset-details/asset-details.component";
import { AssetSummaryComponent } from "./components/form/asset-form/asset-summary/asset-summary.component";
import { AutoCompleteFilterComponent } from "./components/auto-complete-filter/auto-complete-filter.component";
import { AutoCompleteFilterModalComponent } from "./components/auto-complete-filter-modal/auto-complete-filter-modal.component";
import { StudentListModalComponent } from "./components/student-list-modal/student-list-modal.component";
import { StudentDetailsListComponent } from "./components/student-list-modal/student-details-list/student-details-list.component";
import { ResourceTaskModalFormComponent } from './components/form/resource-task-modal-form/resource-task-modal-form.component';
import { DownloadFiltersDialogComponent } from './components/download-filters-dialog/download-filters-dialog.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,

    // material design modules
    MatFormFieldModule,
    MatOptionModule,
    MatSelectModule,
    MatMenuModule,
    MatInputModule,
    MatButtonModule,
    MatToolbarModule,
    MatIconModule,
    MatCardModule,
    MatProgressBarModule,
    MatListModule,
    MatPaginatorModule,
    MatTabsModule,
    MatButtonToggleModule,
    MatGridListModule,
    MatTooltipModule,
    MatDialogModule,
    FontAwesomeModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatRadioModule,
    MatProgressSpinnerModule,
    MatChipsModule,
    MatCheckboxModule,
    MatAutocompleteModule,
    MatExpansionModule,
    MatSlideToggleModule,
    MatStepperModule,
    MatRippleModule,

    MDBBootstrapModule.forRoot()
  ],
  declarations: [
    HeaderComponent,
    ListComponent,
    FloatingDropdownComponent,
    InfoDisplayComponent,
    NavigationTabComponent,
    SearchComponent,
    PaginatorComponent,
    SideNavLabelComponent,
    FormNavBtnComponent,
    FilterComponent,
    AutoCompleteFilterComponent,
    ConfirmDialogComponent,
    DialogBoxComponent,
    CurriculumFormComponent,
    CurriculumClassFormComponent,
    CurriculumMainFormComponent,
    CurriculumSummaryComponent,
    CurriculumDetailsComponent,
    ObjectContainerComponent,
    BundleFormComponent,
    BundleMainFormComponent,
    BundleAddCurriculumComponent,
    BundleSummaryComponent,
    BundleDetailsComponent,
    ResourceDetailsComponent,
    ResourceMainFormComponent,
    ResourceFormComponent,
    ResourceGroupFormComponent,
    ResourceSummaryComponent,
    FilterModalComponent,
    AutoCompleteFilterModalComponent,
    ClassFormComponent,
    ClassMainFormComponent,
    HqtFormComponent,
    HqtMainFormComponent,
    HqtDetailsComponent,
    HqtSummaryComponent,
    ModelFormComponent,
    ModelMainFormComponent,
    ModelDetailsComponent,
    ModelSummaryComponent,
    AssetFormComponent,
    AssetMainFormComponent,
    AssetDetailsComponent,
    AssetSummaryComponent,
    ClassDetailsComponent,
    ClassSummaryComponent,
    CurriculumViewDialogComponent,
    MainDetailsComponent,
    ResourceGroupsComponent,
    FieldCheckerComponent,
    FieldCheckerSpecialComponent,
    ResourceModalFormComponent,
    StudentDetailsListComponent,
    StudentListModalComponent,
    ResourceTaskModalFormComponent,
    DownloadFiltersDialogComponent
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,

    // material design modules
    MatFormFieldModule,
    MatOptionModule,
    MatSelectModule,
    MatMenuModule,
    MatInputModule,
    MatButtonModule,
    MatToolbarModule,
    MatIconModule,
    MatCardModule,
    MatProgressBarModule,
    MatListModule,
    MatPaginatorModule,
    MatTooltipModule,
    FontAwesomeModule,
    MatDialogModule,
    MatGridListModule,
    MatSidenavModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatRadioModule,
    MatProgressSpinnerModule,
    MatChipsModule,
    MatAutocompleteModule,
    MatExpansionModule,
    MatSlideToggleModule,
    MatStepperModule,
    MatRippleModule,

    // components
    HeaderComponent,
    ListComponent,
    FloatingDropdownComponent,
    InfoDisplayComponent,
    NavigationTabComponent,
    SearchComponent,
    PaginatorComponent,
    SideNavLabelComponent,
    FormNavBtnComponent,
    FilterComponent,
    AutoCompleteFilterComponent,
    ConfirmDialogComponent,
    DialogBoxComponent,
    CurriculumFormComponent,
    CurriculumMainFormComponent,
    CurriculumSummaryComponent,
    CurriculumDetailsComponent,
    ObjectContainerComponent,
    BundleFormComponent,
    BundleMainFormComponent,
    BundleAddCurriculumComponent,
    BundleSummaryComponent,
    BundleDetailsComponent,
    ResourceDetailsComponent,
    ResourceFormComponent,
    FilterModalComponent,
    AutoCompleteFilterModalComponent,
    ClassFormComponent,
    ClassMainFormComponent,
    HqtFormComponent,
    HqtMainFormComponent,
    HqtDetailsComponent,
    HqtSummaryComponent,
    ModelFormComponent,
    ModelMainFormComponent,
    ModelDetailsComponent,
    ModelSummaryComponent,
    AssetFormComponent,
    AssetMainFormComponent,
    AssetDetailsComponent,
    AssetSummaryComponent,
    ClassDetailsComponent,
    ClassSummaryComponent,
    CurriculumViewDialogComponent,
    MainDetailsComponent,
    ResourceGroupsComponent,
    FieldCheckerComponent,
    FieldCheckerSpecialComponent,
    ResourceModalFormComponent,
    StudentDetailsListComponent,
    StudentListModalComponent
  ],
  entryComponents: [
    ConfirmDialogComponent,
    DialogBoxComponent,
    FilterModalComponent, 
    AutoCompleteFilterModalComponent, 
    StudentListModalComponent,
    ResourceTaskModalFormComponent,
    DownloadFiltersDialogComponent
  ]
})
export class SharedModule {
  constructor() {
    library.add(fas, far);
  }
}
