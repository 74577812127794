import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-auto-complete-filter-modal',
  templateUrl: './auto-complete-filter-modal.component.html',
  styleUrls: ['./auto-complete-filter-modal.component.scss']
})
export class AutoCompleteFilterModalComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
