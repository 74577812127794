import { Component, OnInit, Output, Input, EventEmitter, OnDestroy } from '@angular/core';
import { CurriculumClassForm } from 'src/app/core/models/form/curriculum-class-form.model';
import { Subject } from 'rxjs';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { FormUtil } from 'src/app/core/services/util/form.util';
import { NEXT, STATUS_2, PERIOD_TYPE_1, PERIOD_TYPE_2, regexNumericFormat } from 'src/app/core/constants/configuration/common.constant';
import { Curriculum } from 'src/app/core/models/curriculum.model';
import { allCurriculumsEndpoint, existsByName, allHqtEndpoint, allClassesEndpoint, getClassOrderedCount, getProcessedOrderedCount } from 'src/app/core/constants/endpoints.constant';
import { takeUntil } from 'rxjs/operators';
import { ErrorHandlerService } from 'src/app/core/services/util/error-handler.service';
import { CrudService } from 'src/app/core/services/data/crud.service';
import { Hqt } from 'src/app/core/models/hqt.model';
import { NumberHelper } from 'src/app/core/services/util/number-helper';

@Component({
  selector: 'class-main-form',
  templateUrl: './class-main-form.component.html',
  styleUrls: ['./class-main-form.component.scss']
})
export class ClassMainFormComponent implements OnInit, OnDestroy {

	@Output() changePage: EventEmitter<any> = new EventEmitter();
	@Output() getFormValue: EventEmitter<any> = new EventEmitter();
	@Input() isFirst: boolean;
	@Input() isLast: boolean;
	@Input() forUpdate: boolean;
	@Input() reference: any;
	@Input() classFormValues: CurriculumClassForm;
	@Input() curriculum: any;
	@Input() hqt: any;
	@Input() classCode: string;

	classForm: FormGroup;
	curriculums: any[] = [];
	hqts: any[] = [];
	unsubscribe: Subject<any> = new Subject();
	isProcessing: boolean;
	delayTimer: NodeJS.Timer;
	timeout: number = 300;
	existsByNameEndpoint: string = allClassesEndpoint.concat(existsByName);
	fromGradeLevels: any[] = [];
	toGradeLevels: any[] = [];
	hasHqt: boolean = false;
	perYear: boolean = true;
	field: string;
	exists: boolean = true;
	orderedCount: number = 0;
	processedOrderedCount: number = 0;
	withClassMaximum: boolean = false;
	
	constructor(
		private formUtil: FormUtil,
		private crudService: CrudService,
		private errorHandlerService: ErrorHandlerService,
		private numberHelper: NumberHelper,
	) {
		this.classForm = formUtil.createClassForm();    
	}
  
	ngOnInit() {

		this.fromGradeLevels = Object.assign([], this.reference.gradeLevels);
		this.toGradeLevels = Object.assign([], this.reference.gradeLevels);

		this.fromGradeLevels.sort((a, b) => +a.name - +b.name);
		this.toGradeLevels.sort((a, b) => +a.name - +b.name);

		if (this.forUpdate || this.classFormValues) {
			this.initFormValues();
		}

		this.checkClassOrderedCount();

	}

	ngOnDestroy(): void {
		this.unsubscribe.next();
		this.unsubscribe.complete();
	}

	onClick = (label: string): void => {

		if (label === NEXT) {
			//this.onChangeMaxSlot("maxSlot");
			this.setClassMaximumValidator();
			this.validateUntouchedForms();
			this.validateName().then(() => {
				setTimeout(() => 
				{
					if (!this.classForm.valid) {
						return;
					} else {
						this.getFormValue.emit({
							formValues: this.classForm.value,
							curriculum: this.curriculum,
							hqt: this.hqt
						});
						this.changePage.emit(label);
						this.classForm.reset();
					}
				}, this.timeout);
			});
		} 

	}

	onChangeNumber(type: string): void {
		const value: number = this.classForm.controls[type].value;
		const max = 999999999;
			if (value === null){
				this.classForm.controls[type].setValue("");
			} else if (value === -0) {
				this.classForm.controls[type].setValue(0);
		} else if (value > max) {
		this.classForm.controls[type].setValue(String(value).substring(0,9));
		} else if (
			this.numberHelper.isNegativeNumber(value)
			&& this.numberHelper.getCentsValue(value).toString().length > 2
		) {
			this.classForm.controls[type].setValue(value.toFixed(2));
		}
	}

	onChangeMaxSlot(type: string) {
		let value = this.classForm.controls[type].value;
		this.crudService
			.getById<JSON>(getClassOrderedCount + "?classCode="+this.classCode)
			.pipe(takeUntil(this.unsubscribe))
			.subscribe((response: any) => {
				this.orderedCount = response;
				this.setClassMaximumValidator();
		}, this.errorHandlerService.handleError);
	}

	onCurriculumSelect(code: string): void {

		this.curriculum = {};
		this.curriculum.code = code;
		this.curriculum.name = this.curriculums.find(curr => curr.code === code).name;
		this.classForm.get("curriculumCode").setValue(`${this.curriculum.name} (${this.curriculum.code})`);

	}

	onHqtSelect(email: string): void {

		this.hqt = {};
		this.hqt.email = email;
		this.hqt.name = this.hqts.find(hqt => hqt.email === email).name;
		this.hqt.status = this.hqts.find(hqt => hqt.email === email).status;
		this.classForm.get("hqtUsername").setValue(`${this.hqt.name} (${this.hqt.email})`);
		this.classForm.controls['hqtUsername'].setErrors(
			this.hqt.status === STATUS_2 ? { 'inactive': true } : null
		);

	}

	onCurriculumChange(keyword: string): void {

		this.field = "CURRICULUM";
		this.curriculums = [];
		this.isProcessing = false;

		if (!keyword || keyword.trim().length === 0) {
			this.classForm.controls['curriculumCode'].markAsTouched();
			this.classForm.controls['curriculumCode'].markAsDirty();
			return;
		};
		
		clearTimeout(this.delayTimer);
		this.delayTimer = setTimeout(() => {
			this.isProcessing = true;
			setTimeout(() => this.findCurriculums(keyword.trim()), this.timeout);
		}, this.timeout);

	}

	onHqtChange(keyword: string): void {

		this.field = "HQT";
		this.hqts = [];
		this.isProcessing = false;

		if (!keyword || keyword.trim().length === 0) {
			this.classForm.controls['hqtUsername'].markAsTouched();
			this.classForm.controls['hqtUsername'].markAsDirty();
			this.removeHqt();
			return;
		}

		clearTimeout(this.delayTimer);
		this.delayTimer = setTimeout(() => {
			this.isProcessing = true;
			setTimeout(() => this.findHqts(keyword.trim()), this.timeout);
		}, this.timeout);

	}

	findCurriculums(keyword: string): void {

		this.crudService
			.getAllBy<Curriculum>(`${allCurriculumsEndpoint}/get-by-code-or-name?keyword=${keyword}`)
			.pipe(takeUntil(this.unsubscribe))
			.subscribe(response => {

				if (response) {

					if (response.length > 0) {
						response.map(curriculum => this.curriculums.push({ code: curriculum.code, name: curriculum.name }));
					} else {
						this.classForm.controls['curriculumCode'].setErrors({ 'notExists': true });
					}

				}

			}, (error) => {
				this.isProcessing = false;
				this.errorHandlerService.handleError(error)
			}, this.handleCompletion);

	}

	findHqts(keyword: string): void {

		this.crudService
			.getAllBy<Hqt>(`${allHqtEndpoint}/get-by-name-or-email?keyword=${keyword}`)
			.pipe(takeUntil(this.unsubscribe))
			.subscribe(response => {
				let isCurrentValue = this.hqt && keyword === String(this.hqt.name + ' (' + this.hqt.email + ')');
				if (response) {
					if (!isCurrentValue) {
						response.map(hqt => this.hqts.push({ email: hqt.email, name: hqt.name, status: hqt.status }));
						this.classForm.controls['hqtUsername'].setErrors({ 'notExists': true });
					} else {
						this.classForm.controls['hqtUsername'].setErrors(
							this.hqt.status === STATUS_2 ? { 'inactive': true } : null
						);
					}
				}
			}, (error) => {
				this.isProcessing = false;
				this.errorHandlerService.handleError(error)
			}, this.handleCompletion);

	}

	onChangeFromGradeLevel(code: string): void {

		this.toGradeLevels = [];
		const name: number = +this.fromGradeLevels.find(level => level.code === code).name;
		this.toGradeLevels = Object.assign([], this.fromGradeLevels.filter(level => +level.name >= name));

		const toGradeLevel = this.classForm.controls['toGradeLevel'].value;
		if (toGradeLevel &&
		!this.toGradeLevels.some(level => level.code === toGradeLevel)) {
			this.classForm.controls['toGradeLevel'].setValue("");
			this.classForm.controls['toGradeLevel'].markAsDirty();
			this.classForm.controls['toGradeLevel'].markAsTouched();
		}

	}

	onTogglePerYear(): void {
		this.perYear = !this.perYear;

		if (this.perYear) {
			this.classForm.get("activePeriodType").setValue(PERIOD_TYPE_1);
		} else {
			this.classForm.get("activePeriodType").setValue(PERIOD_TYPE_2);
		}

	}

	onToggleWithClassMaximum(): void {
		this.withClassMaximum = !this.withClassMaximum;
		this.setClassMaximumValidator();
	}

	private setClassMaximumValidator() {
		if(!this.withClassMaximum) {
			this.classForm.get("maxSlot").setValue(null);
			this.classForm.controls['maxSlot'].clearValidators();
			this.classForm.controls['maxSlot'].setValidators([]);
		} else {
			this.classForm.controls['maxSlot'].setValidators(
				[Validators.compose([Validators.required]),
				Validators.compose([Validators.min(1)]),
				Validators.compose([Validators.pattern(regexNumericFormat)])]
			);
		}
		this.classForm.controls['maxSlot'].updateValueAndValidity();
	}

	// private setClassMaximumValidator() {
	// 	if(!this.withClassMaximum) {
	// 		this.classForm.get("maxSlot").setValue(null);
	// 		this.classForm.controls['maxSlot'].clearValidators();
	// 		this.classForm.controls['maxSlot'].setValidators([]);
	// 	} else {
	// 		this.classForm.controls['maxSlot'].setValidators(
	// 			[Validators.compose([Validators.required]),
	// 			Validators.compose([this.orderedCount <= 0 ? Validators.min(1) : Validators.min(this.orderedCount)]),
	// 			Validators.compose([Validators.pattern(regexNumericFormat)])]
	// 		);
	// 	}
	// 	this.classForm.controls['maxSlot'].updateValueAndValidity();
	// }

	onToggleHQT(): void {
		this.hasHqt = !this.hasHqt;
		if (!this.hasHqt) {
			this.removeHqt();
		}
	}

	private initFormValues(): void {

		this.perYear = this.classFormValues.activePeriodType === PERIOD_TYPE_1;

		this.classForm.setValue(this.formUtil.initClassForm(this.classFormValues));
		this.classForm.get("curriculumCode").setValue(`${this.curriculum.name} (${this.curriculum.code})`);

		if (this.containsHqt) {
			this.classForm.get("hqtUsername").setValue(`${this.hqt.name} (${this.hqt.email})`);
			this.hasHqt = true;
		}

		this.initClassMaximum();
	}

	private initClassMaximum() {
		this.withClassMaximum = this.classForm.get("maxSlot").value || false;
		//this.onChangeMaxSlot("maxSlot");
	}

	private get containsHqt(): boolean {
		return this.hqt && String(this.hqt.email).trim().length > 0 && String(this.hqt.name).trim().length > 0;
	}

	private validateUntouchedForms(): void {
		Object.keys(this.classForm.controls).forEach(control => {
			this.classForm.get(control).markAsTouched();
			this.classForm.get(control).markAsDirty();
		});
	}

	private handleCompletion = (): void => {
		this.isProcessing = false;
	}

	private removeHqt(): void {
		this.classForm.get("hqtUsername").setValue(null);
		this.hqt = null;
	}
	
	async validateName() {
		this.exists = true;
		this.classForm.controls['name'].updateValueAndValidity();
		
		const name = this.classForm.get("name").value;
		const curriculumCode = this.curriculum.code;
		if (name && curriculumCode) {
			this.crudService
			.getById<JSON>(this.existsByNameEndpoint.concat(`
				${encodeURIComponent(this.classForm.get("name").value)}
				&curriculumCode=
				${encodeURIComponent(this.curriculum.code)}
				&code=
				${encodeURIComponent(this.classCode ? this.classCode : "")}`)
			)
			.pipe(takeUntil(this.unsubscribe))
			.subscribe((response: any) => {
				response.value = this.classForm.get("name").value;
				this.checkExistingResult(response);
				this.exists = response.exists;
			}, this.errorHandlerService.handleError);
		}
	}

		checkExistingResult(result: any): void {
		if (result.exists) {
			this.classForm.controls['name'].setErrors({'nameExists': true});
		} else {
			this.classForm.controls['name'].updateValueAndValidity();
		}
	}

	checkClassOrderedCount(): void {
		this.crudService
			.getById<JSON>(getClassOrderedCount + "?classCode="+this.classCode)
			.pipe(takeUntil(this.unsubscribe))
			.subscribe((response: any) => {
				this.orderedCount = response;
				this.checkClassProcessedOrderedCount();
			}, this.errorHandlerService.handleError);
	}

	checkClassProcessedOrderedCount(): void {
		this.crudService
			.getById<JSON>(getProcessedOrderedCount + "?classCode="+this.classCode)
			.pipe(takeUntil(this.unsubscribe))
			.subscribe((response: any) => {
				this.processedOrderedCount = response;
			}, this.errorHandlerService.handleError);
	}

}
