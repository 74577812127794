import { Component, OnInit } from '@angular/core';
import { HeaderMessagingService } from 'src/app/core/services/messaging/header-messaging.service';
import { HQT } from 'src/app/core/constants/configuration/hqt-constants.config';
import { CREATE_MODULE_NAME } from 'src/app/core/constants/configuration/common.constant';
import { settingsRoutingPermissions } from "src/app/core/constants/configuration/role-constants.config";
import { HQTS_PATH } from "src/app/core/constants/routes.constant";
import { UserService } from "src/app/core/services/util/user.service";

@Component({
  selector: 'app-hqt-create',
  templateUrl: './hqt-create.component.html',
  styleUrls: ['./hqt-create.component.scss']
})
export class HqtCreateComponent implements OnInit {

  constructor(
    private headerMessagingService: HeaderMessagingService,
    private userService: UserService,
	) {
		this.userService.checkRolePermission(HQTS_PATH, settingsRoutingPermissions);
    this.headerMessagingService.setHeader(HQT, CREATE_MODULE_NAME, false, null);
  }

  ngOnInit() {
  }

}
