import { Component, OnInit } from "@angular/core";
import { Subject } from "rxjs";
import { Bundle } from "src/app/core/models/bundle.model";
import { ActivatedRoute } from "@angular/router";
import { HeaderMessagingService } from "src/app/core/services/messaging/header-messaging.service";
import { takeUntil } from "rxjs/operators";
import { sideNavLabelBundle, BUNDLE } from "src/app/core/constants/configuration/bundle-constants.config";
import { UPDATE_MODULE_NAME } from "src/app/core/constants/configuration/common.constant";
import { BUNDLES_PATH } from 'src/app/core/constants/routes.constant';
import { UserService } from 'src/app/core/services/util/user.service';
import { homeRoutingPermissions } from 'src/app/core/constants/configuration/role-constants.config';

@Component({
  selector: "app-bundle-update",
  templateUrl: "./bundle-update.component.html",
  styleUrls: ["./bundle-update.component.scss"]
})
export class BundleUpdateComponent implements OnInit {
  unsubscribe: Subject<any> = new Subject();
  activeIndex: number = 0;
  sideNavLabels: string[] = [];
  bundle: Bundle;
  bundleId: number;

  constructor(
    private route: ActivatedRoute,
    private headerMessagingService: HeaderMessagingService,
    private userService: UserService,
	) {
		this.userService.checkRolePermission(BUNDLES_PATH, homeRoutingPermissions);
    this.headerMessagingService.setHeader(BUNDLE, UPDATE_MODULE_NAME, false, null);
    this.sideNavLabels = sideNavLabelBundle;
  }

  ngOnInit() {
    this.initBundle();
  }

  initBundle(): void {
    this.route.params.pipe(takeUntil(this.unsubscribe)).subscribe(params => {
      this.bundleId = params["id"];
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

}
