import { OnInit, OnDestroy, EventEmitter, Component, Output, Input } from "@angular/core";
import { Subject } from "rxjs";
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { CrudService } from "src/app/core/services/data/crud.service";
import { ErrorHandlerService } from "src/app/core/services/util/error-handler.service";
import { takeUntil } from "rxjs/operators";

@Component({
  selector: 'app-field-checker-special',
  templateUrl: './field-checker-special.component.html',
  styleUrls: ['./field-checker-special.component.scss']
})
export class FieldCheckerSpecialComponent implements OnInit, OnDestroy {

  @Output() getResult: EventEmitter<any> = new EventEmitter();
  @Input() field: string;
  @Input() value: string;
  @Input() endpoint: string;
  @Input() hasError: boolean;
  @Input() exists: boolean = true;

  unsubscribe: Subject<any> = new Subject();
  isProcessing: boolean;
  delayTimer: NodeJS.Timer;
  timeout: number = 700;
  checkIcon: IconDefinition = faCheck;

  constructor(
    private crudService: CrudService,
    private errorHandlerService: ErrorHandlerService,
  ) { }

  ngOnInit() {
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  handleCompletion = (): void => {
    this.isProcessing = false;
  }

  checkValue(value: string): void {

    this.exists = true;
    this.isProcessing = false;
    if (String(value).trim().length === 0) return;

    clearTimeout(this.delayTimer);
    this.delayTimer = setTimeout(() => {
      this.isProcessing = true;
      setTimeout(() => this.findValue(value), this.timeout);
    }, this.timeout);

  }

  private findValue(value: string): void {
    this.crudService
      .getById<JSON>(this.endpoint.concat(encodeURIComponent(value)))
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((response: any) => {

        this.exists = response.exists;
        this.getResult.emit({ exists: this.exists, field: this.field, value });

      }, (error) => {
        this.isProcessing = false;
        this.errorHandlerService.handleError(error);
      }, this.handleCompletion);

  }
  
  private clearStates(): void {
    this.isProcessing = false;
    this.exists = true;
  }

}
