import { Component, OnInit, Input } from '@angular/core';

@Component({
	selector: 'app-navigation-tab',
	templateUrl: './navigation-tab.component.html',
	styleUrls: ['./navigation-tab.component.scss'],
	exportAs: "navigationTabs"
})
export class NavigationTabComponent implements OnInit {

	@Input() routes: any[];
	@Input() isMain: boolean;
	@Input() moduleName: string;

	constructor() {}

	ngOnInit() {}

}
