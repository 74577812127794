import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/core/services/util/user.service';
import { HeaderMessagingService } from 'src/app/core/services/messaging/header-messaging.service';
import { TASKS_PATH } from 'src/app/core/constants/routes.constant';
import { homeRoutingPermissions } from 'src/app/core/constants/configuration/role-constants.config';
import { TASK } from 'src/app/core/constants/configuration/task-constants.config';

@Component({
  selector: 'app-download-reports',
  templateUrl: './download-reports.component.html',
  styleUrls: ['./download-reports.component.scss']
})
export class DownloadReportsComponent implements OnInit {

  selectedReport: any;
  reportList = [];
  isOnlineAccountReport = false;
  isCurriculumReport = false;
  defaultReport = "Online Accounts";


  constructor(
    private userService: UserService,
    private headerMessagingService: HeaderMessagingService
  ) { 
    this.userService.checkRolePermission(TASKS_PATH, homeRoutingPermissions);
		this.headerMessagingService.setHeader(TASK, "Download Reports", false, null);
  }

  ngOnInit() {
    this.selectedReport = this.defaultReport;
    this.setReportList();
    this.onSelectReport(this.selectedReport);
  }

  private setReportList() {
    this.reportList.push("Online Accounts");
    // this.reportList.push("Curriculum Reports");
  }

  onSelectReport(report) {
    if(report === "Online Accounts") {
      this.isCurriculumReport = false;
      this.isOnlineAccountReport = true;
    } else if(report === "Curriculum Reports"){
      // this.isCurriculumReport = true;
      // this.isOnlineAccountReport = false;
    }
  }

}
