import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { AuthService } from 'src/app/core/services/data/auth.service';
import { LoginMessagingService } from 'src/app/core/services/messaging/login-messaging.service';
import { Credential } from 'src/app/core/models/credential.model';
import { authorized, accessToken, loginUser, listFilters, bearerToken, unauthorized } from 'src/app/core/constants/configuration/config.constant';
import { HttpErrorResponse } from '@angular/common/http';
import { AUTHORIZE_REDIRECT_PATH } from 'src/app/core/constants/routes.constant';
import { GoogleAnalyticsService } from 'src/app/core/services/google-analytics/google-analytics.service';

@Component({
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {

  unsubscribe: Subject<any> = new Subject();
  message: string;
  isProcessing: boolean;

  constructor(
    private authService: AuthService,
    private loginMessagingService: LoginMessagingService,
    private router: Router,
    private gtag: GoogleAnalyticsService,
  ) {}

  ngOnInit() {
    localStorage.clear();
    this.loginMessagingService.sendMessage(unauthorized);
  }

  onLogin(credential: Credential): void {

    this.isProcessing = true;

    this.authService
      .authenticate(credential)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(response => {

        if (response) {
          localStorage.setItem(accessToken, bearerToken.concat(response.access_token));
          this.getLoginUser();
        }

      }, (error: HttpErrorResponse) => {
        this.message = error.error.error_description;
        this.isProcessing = false;
      });

  }

  getLoginUser(): void {

    this.authService.getLoginUser().pipe(takeUntil(this.unsubscribe)).subscribe(response => {

      if (response) {
        localStorage.setItem(listFilters, JSON.stringify({}));
        localStorage.setItem(loginUser, JSON.stringify(response));
        this.loginMessagingService.sendMessage(authorized);
        this.gtag.eventEmitter("login_", "login", "Logging Credentials", "click", 1);
        this.isProcessing = false;
        this.router.navigateByUrl(AUTHORIZE_REDIRECT_PATH);
      }

    }, (error: HttpErrorResponse) => {
      this.message = error.error.error_description;
      this.isProcessing = false;
    });

  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

}
