import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NEXT, PREV, PERIOD_TYPE_1, PERIOD_TYPE_2, GRADE_LEVELS } from '../../../../../core/constants/configuration/common.constant';
import {
  deleteClassConfirmation,
  curriculumErrorNoClass,
  dialogBoxErrorTitle
} from 'src/app/core/constants/message.constant';
import { ConfirmDialogComponent } from '../../../confirm-dialog/confirm-dialog.component';
import { MatDialog } from '@angular/material';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ErrorHandlerService } from 'src/app/core/services/util/error-handler.service';
import { DialogBoxComponent } from '../../../dialog-box/dialog-box.component';
import { CrudService } from 'src/app/core/services/data/crud.service';
import { CurriculumClassForm } from 'src/app/core/models/form/curriculum-class-form.model';
import { FormUtil } from 'src/app/core/services/util/form.util';
import { Hqt } from 'src/app/core/models/hqt.model';
import { allHqtEndpoint, allClassesEndpoint } from 'src/app/core/constants/endpoints.constant';
import { LoaderMessagingService } from 'src/app/core/services/messaging/loader-messaging.service';

@Component({
  selector: 'curriculum-class-form',
  templateUrl: './curriculum-class-form.component.html',
  styleUrls: ['./curriculum-class-form.component.scss']
})
export class CurriculumClassFormComponent implements OnInit {

  @Input() isFirst: boolean;
  @Input() isLast: boolean;
  @Input() forUpdate: boolean;
  @Input() classFormValuesArray: CurriculumClassForm[];
  @Input() classSubjects: any[];
  @Input() schoolYears: any[];
  @Input() activePeriods: any[];
  @Input() status: any[];
  @Output() changePage = new EventEmitter<any>();
  @Output() getFormValue = new EventEmitter<any>();
  unsubscribe: Subject<any> = new Subject();
  objectType: string = "Class";
  classForm: FormGroup;
  classArray: CurriculumClassForm[] = [];
  targetIndex: number = 0;
  isProcessing: boolean;
  hqts: Hqt[];
  delayTimer: NodeJS.Timer;
  timeout: number = 1000;
  selectedHqts: string[] = [];

  gradeLevels: string[] = GRADE_LEVELS;
  toGradeLevels: string[] = this.gradeLevels.slice(0);
  
  constructor(
    private fb: FormBuilder,
    private errorHandlerService: ErrorHandlerService,
    public dialog: MatDialog,
    private crudService: CrudService,
    private formUtil: FormUtil,
    private loaderMessagingService: LoaderMessagingService,
  ) {
    this.createClassForm();
  }

  createClassForm(): void {
    this.classForm = this.formUtil.createClassForm();
  }

  onCreateNewClass() {
    this.validateUntouchedForms();
    if (this.classArray.length > 0) {
      this.classArray[this.targetIndex] = this.classForm.value;
    }
    if (!this.classForm.valid && this.classArray.length > 0) {
      return;
    } else {
      this.createClassForm();
      this.classArray.push(this.classForm.value);
      this.targetIndex = this.classArray.length - 1;
    }
  }

  ngOnInit() {
    this.initParams();
    this.initializeGradeLevel();
  }

  handleCompletion = (): void => {
    this.isProcessing = false;
    this.loaderMessagingService.showPageLoader(false);
  };

  initParams(): void {
    if (this.forUpdate || this.classFormValuesArray) {
      this.initFormArray();
    }
  }

  initFormArray(): void {
    if (this.classFormValuesArray.length > 0) {
      this.classFormValuesArray.forEach(classFormValue => this.classArray.push(classFormValue));
      this.loadTargetIndexValueToForm();
      this.initializeGradeLevel();
    } else {
      this.classArray = [];
    }
  }

  onSetTargetIndex($event: number) {
    this.validateUntouchedForms();
    if (!this.classForm.valid ) {
      return;
    } else {
      this.classArray[this.targetIndex] = this.classForm.value;
      const newTargetIndex = $event;
      this.targetIndex = newTargetIndex;
      this.loadTargetIndexValueToForm();
      this.initializeGradeLevel();
    }
  }

  loadTargetIndexValueToForm(): void {
    this.createClassForm();
    this.classForm.setValue(this.formUtil.initClassForm(this.classArray[this.targetIndex]));
  }

  onDeleteTargetIndex($event: number) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent);
      dialogRef.componentInstance.confirmMessage = deleteClassConfirmation;
      dialogRef.afterClosed()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(result => {
        if (result) {
          const deleteIndex = $event;
          this.classArray.splice(deleteIndex, 1);
          this.targetIndex = 0;
          if (this.classArray.length > 0) {
            this.loadTargetIndexValueToForm();
          }
        }
      }, this.errorHandlerService.handleError);
  }

  onClick = (label: string): void => {
    if(label === NEXT) {
      this.validateUntouchedForms();
      if (this.classArray.length < 1) {
        const dialogRef = this.dialog.open(DialogBoxComponent);
        dialogRef.componentInstance.dialogTitle = dialogBoxErrorTitle;
        dialogRef.componentInstance.contentMessage = curriculumErrorNoClass;
      } else if (!this.classForm.valid ) {
        return;
      } else {
        this.classArray[this.targetIndex] = this.classForm.value;
        this.changePage.emit(NEXT);
        this.getFormValue.emit(this.classArray);
      }
    }else if(label === PREV) {
      this.changePage.emit(PREV);
    }
  }

  onPeriodCheckboxChange($event: any): void {
    const checkBoxStatus: boolean = $event.checked;
    if (checkBoxStatus) {
      this.classForm.controls['activePeriodType'].patchValue(PERIOD_TYPE_1);
    } else {
      this.classForm.controls['activePeriodType'].patchValue(PERIOD_TYPE_2);
    }
  }

  onSchoolYearChange($event: any): void {
    const schoolYear = $event;
    this.classForm.patchValue({schoolYear: schoolYear.schoolYear});
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  validateUntouchedForms(): void {
    Object.keys(this.classForm.controls).forEach(control => {
      this.classForm.get(control).markAsTouched();
      this.classForm.get(control).markAsDirty();
    })
  }

  onHqtCheckboxChange($event: any) {
    const checkBoxStatus: boolean = $event.checked;
    if (checkBoxStatus) {
      this.classForm.controls['hqtUsername'].setValidators([Validators.required]);
    } else {
      this.classForm.controls['hqtUsername'].setValidators(null);
    }
    this.classForm.controls['hqtUsername'].updateValueAndValidity();

    return checkBoxStatus;
  }

  findHqt(keyword: string): void {

    this.hqts = [];

    this.crudService
    .getAll<Hqt>(allHqtEndpoint.concat(`/search?keyword=${keyword}`))
    .pipe(takeUntil(this.unsubscribe))
    .subscribe(response => {
      if (response) this.hqts = response.content;
      },
      this.errorHandlerService.handleError,
      this.handleCompletion
    );
  }

  onHqtChange($event: any): void {

    const keyword: string = String($event.target.value).trim();

    if (keyword !== "" && !this.isProcessing) {
      clearTimeout(this.delayTimer);
      this.delayTimer = setTimeout(() => {
        this.isProcessing = true;
        setTimeout(() => this.findHqt(keyword), this.timeout);
      }, this.timeout);
    }

  }

  onHqtSelect(hqtEmail: string): void {
    const hqt = this.hqts.find(hqt => hqt.email === hqtEmail);
    this.selectedHqts[this.targetIndex] = `${hqt.name}|${hqt.email}`;
  }

  onNameChange($event: any) {
    this.loaderMessagingService.showPageLoader(true);
    const keyword = $event.target.value;
    this.crudService
    .getById<JSON>(allClassesEndpoint.concat(`/exists-by-name?name=${keyword}`))
    .pipe(takeUntil(this.unsubscribe))
    .subscribe(
      (data: any) => {
        if (data.exists) {
          if ((this.classArray[this.targetIndex] && keyword !== this.classArray[this.targetIndex].name && this.forUpdate) || !this.forUpdate) {
            this.classForm.controls['name'].setErrors({'nameExists': true});
          } else {
            this.classForm.controls['name'].updateValueAndValidity();
          }
        }
      },
      this.errorHandlerService.handleError,
      this.handleCompletion
    );
  }

  initializeGradeLevel() : void {
    if (this.classForm.get('fromGradeLevel').value) {
      const targetIndex = this.gradeLevels.findIndex(gradeLevel => this.classForm.get('fromGradeLevel').value === gradeLevel);
      this.toGradeLevels = this.gradeLevels.slice(targetIndex);
    }
    if (this.classForm.get('toGradeLevel').value && !this.toGradeLevels.includes(this.classForm.get('toGradeLevel').value)) {
      this.classForm.controls['toGradeLevel'].reset();
    }
  }

  onSelectGradeLevel($event: any, index: number): void {
    this.toGradeLevels = this.gradeLevels.slice(index);
    if (this.classForm.get('toGradeLevel').value && !this.gradeLevels.includes(this.classForm.get('toGradeLevel').value)) {
      this.classForm.controls['toGradeLevel'].reset();
    }
  }
  
}
