import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { Curriculum } from 'src/app/core/models/curriculum.model';
import { CurriculumHelper } from 'src/app/core/services/util/curriculum.helper';
import { SAVE } from "src/app/core/constants/configuration/common.constant";
import { Subject } from 'rxjs';
import { CrudService } from 'src/app/core/services/data/crud.service';
import { getSchoolYearsEndpoint, getStatusEndpoint } from 'src/app/core/constants/endpoints.constant';
import { takeUntil } from 'rxjs/operators';
import { ErrorHandlerService } from 'src/app/core/services/util/error-handler.service';
import { FormUtil } from 'src/app/core/services/util/form.util';
import { LoaderMessagingService } from 'src/app/core/services/messaging/loader-messaging.service';
import { PageForm } from 'src/app/core/models/form/page-form.model';
import { CurriculumMainForm } from 'src/app/core/models/form/curriculum-main-form.model';
import { sideNavLabelCurriculum } from 'src/app/core/constants/configuration/curriculum-constants.config';

@Component({
	selector: 'curriculum-form',
	templateUrl: './curriculum-form.component.html',
	styleUrls: ['./curriculum-form.component.scss']
})
export class CurriculumFormComponent implements OnInit, OnDestroy {

	@Output() saveCurriculum: EventEmitter<Curriculum> = new EventEmitter();
	@Input() forUpdate: boolean;
	@Input() originalCurriculum: Curriculum;

	unsubscribe: Subject<any> = new Subject();
	modifiedCurriculum: Curriculum;
	curriculumFormValues: CurriculumMainForm;
	activeIndex: number = 0;
	sideNavLabels: string[] = [];
	isFirst:boolean = true;
	isLast:boolean = false;
	reference: any = {};
	referenceValues: any = {};
	schoolYears: any;
	hasInitialized: boolean = false;

	constructor(
		private errorHandlerService: ErrorHandlerService,
		private loaderMessagingService: LoaderMessagingService,
		private formUtil: FormUtil,
		private crudService: CrudService,
		private curriculumHelper : CurriculumHelper,
	) {
		this.sideNavLabels = sideNavLabelCurriculum;		
	}

	ngOnInit(): void {

		this.hasInitialized = false;
		this.loaderMessagingService.showPageLoader(true);

		if (this.forUpdate) {
			this.curriculumFormValues = this.curriculumHelper.setCurriculumMainFormValues(this.originalCurriculum);
		} else {
			this.curriculumFormValues = this.curriculumHelper.initCurriculumMainFormValues();
		}

		this.initParams();

	}

	ngOnDestroy(): void {
		this.unsubscribe.next();
		this.unsubscribe.complete();
	}

	private handleCompletion = (): void => {
		this.loaderMessagingService.showPageLoader(false);
	}

	initParams(): void {
		this.setReferenceSchoolYears();
	}

	get isLoadMainForm(): boolean {
		return (this.activeIndex === 0 && this.hasInitialized) && (!this.forUpdate || (this.forUpdate && this.curriculumFormValues !== null));
	}

	onChangePage(label: any) {

		let pageForm: PageForm = this.formUtil.setPageForm(label, this.sideNavLabels.length, this.activeIndex);

		this.activeIndex = pageForm.activeIndex;
		this.isFirst = pageForm.isFirst;
		this.isLast = pageForm.isLast;

		if (label === SAVE) {
			this.saveCurriculum.emit(this.modifiedCurriculum);
		}
	}

	onFormSubmit($event: any): void {
		this.curriculumFormValues = Object.assign({}, $event.formValues);
		this.setReferenceValues($event.schoolYears);
		this.setCurriculum();
	}

	private setReferenceValues(schoolYears: any): void {

		const schoolYear: string = this.reference.schoolYears.find(sy => this.curriculumFormValues.syCode === sy.schoolYear).code;
		const status: string = this.reference.status.find(status =>
			this.curriculumFormValues.status.toUpperCase() === status.name.toUpperCase()).name;
		this.schoolYears = schoolYears;

		this.referenceValues = { schoolYear, status, schoolYears };

	}

	private setCurriculum(): void {
		this.modifiedCurriculum = this.curriculumHelper.setCurriculumModel(this.curriculumFormValues, this.forUpdate, this.originalCurriculum);
	}

	
	// API CALLS
	
	private setReferenceSchoolYears(): void {

		this.crudService
			.getById<any>(getSchoolYearsEndpoint)
			.pipe(takeUntil(this.unsubscribe))
			.subscribe(response => {

				if (response) {
					this.reference.schoolYears = response;
					this.reference.schoolYears.sort((a, b) => a.schoolYear.localeCompare(b.schoolYear));
					this.setReferenceStatus();
				}

			}, this.errorHandlerService.handleError);

	}

	private setReferenceStatus(): void {

		this.crudService
			.getById<any>(getStatusEndpoint)
			.pipe(takeUntil(this.unsubscribe))
			.subscribe(response => {

				if (response) {
					this.reference.status = response;
					this.reference.status.sort((a, b) => a.code.localeCompare(b.code));
					this.hasInitialized = true;
				}
				
			}, this.errorHandlerService.handleError, this.handleCompletion);

	}

}
