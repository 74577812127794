import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { DataProperties } from 'src/app/core/models/data-properties.model';
import { LoaderMessagingService } from 'src/app/core/services/messaging/loader-messaging.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { MAT_CHECKBOX_CLICK_ACTION } from '@angular/material';
import { orderResourcesDataProperties } from 'src/app/core/constants/configuration/order-constants.config';

@Component({
  selector: 'app-order-resource-selection-list',
  templateUrl: './order-resource-selection-list.component.html',
  styleUrls: ['./order-resource-selection-list.component.scss'],
  providers: [{provide: MAT_CHECKBOX_CLICK_ACTION, useValue: 'noop'}]
})
export class OrderResourceSelectionListComponent implements OnInit {

  @Input() items: any[] = [];
  @Input() resourceTypes: any[] = [];
  @Output() getSelectedResource = new EventEmitter<any>();

  dataProperties: DataProperties[];
  headersLength: number;
  isLoading: boolean = false;
  unsubscribe: Subject<any> = new Subject();

  constructor(
    private loaderMessagingService: LoaderMessagingService,
  ) {
    this.subscribeToLoaderMessagingService();
  }

  ngOnInit() {
    this.dataProperties = orderResourcesDataProperties;
    this.headersLength = this.dataProperties.length;
  }

  private subscribeToLoaderMessagingService(): void {
    this.loaderMessagingService.listLoader$
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(show => setTimeout(() => (this.isLoading = show)));
  }

  getResourceTypeName(code: string): string {
    let resourceType = this.resourceTypes.find(type => type.code === code);
    return resourceType ? resourceType.name : "";
  }

  onCheckboxCheck($event: any, selectedItem: any) {
    this.items.forEach(item => {
      if(item.id !== selectedItem.id) {
        item.check = false;
      }
    });
    this.getSelectedResource.emit(selectedItem);
  }

}
