import { NgModule } from "@angular/core";
import { SharedModule } from "src/app/shared/shared.module";
import { HomeComponent } from "./home.component";
import { BundleCreateComponent } from "./bundle/bundle-create/bundle-create.component";
import { BundleListComponent } from "./bundle/bundle-list/bundle-list.component";
import { BundleDetailsDialogComponent } from "./bundle/bundle-details-dialog/bundle-details-dialog.component";
import { BundleUpdateComponent } from "./bundle/bundle-update/bundle-update.component";
import { ClassListComponent } from "./class/class-list/class-list.component";
import { ClassCreateComponent } from "./class/class-create/class-create.component";
import { ClassUpdateComponent } from "./class/class-update/class-update.component";
import { ClassDetailsDialogComponent } from "./class/class-details-dialog/class-details-dialog.component";
import { CurriculumCreateComponent } from "./curriculum/curriculum-create/curriculum-create.component";
import { CurriculumListComponent } from "./curriculum/curriculum-list/curriculum-list.component";
import { CurriculumDetailsDialogComponent } from "./curriculum/curriculum-details-dialog/curriculum-details-dialog.component";
import { CurriculumUpdateComponent } from "./curriculum/curriculum-update/curriculum-update.component";
import { HomeRoutingModule } from "./route/home-routing.module";
import { ResourceCreateComponent } from "./resource/resource-create/resource-create.component";
import { ResourceListComponent } from "./resource/resource-list/resource-list.component";
import { ResourceDetailsDialogComponent } from "./resource/resource-details-dialog/resource-details-dialog.component";
import { ResourceUpdateComponent } from "./resource/resource-update/resource-update.component";
import { CurriculumViewComponent } from "./curriculum/curriculum-view/curriculum-view.component";
import { ClassViewComponent } from "./class/class-view/class-view.component";
import { BundleViewComponent } from "./bundle/bundle-view/bundle-view.component";
import { ResourceViewComponent } from "./resource/resource-view/resource-view.component";

@NgModule({
	imports: [
		SharedModule,
		HomeRoutingModule,
	],
	declarations: [
		HomeComponent,
		BundleCreateComponent,
		BundleListComponent,
		BundleDetailsDialogComponent,
		BundleUpdateComponent,
		BundleViewComponent,

		ClassListComponent,
		ClassCreateComponent,
		ClassUpdateComponent,
		ClassDetailsDialogComponent,
		ClassViewComponent,

		CurriculumCreateComponent,
		CurriculumListComponent,
		CurriculumDetailsDialogComponent,
		CurriculumUpdateComponent,
		CurriculumViewComponent,

		ResourceCreateComponent,
		ResourceListComponent,
		ResourceDetailsDialogComponent,
		ResourceUpdateComponent,
		ResourceViewComponent,
	],
	entryComponents: [
		BundleDetailsDialogComponent,
		CurriculumDetailsDialogComponent,
		ResourceDetailsDialogComponent,
	],
	exports: [
		HomeComponent,

		BundleCreateComponent,
		BundleListComponent,
		BundleDetailsDialogComponent,
		BundleUpdateComponent,
		BundleViewComponent,

		ClassListComponent,
		ClassCreateComponent,
		ClassUpdateComponent,
		ClassDetailsDialogComponent,
		ClassViewComponent,

		CurriculumCreateComponent,
		CurriculumListComponent,
		CurriculumDetailsDialogComponent,
		CurriculumUpdateComponent,
		CurriculumViewComponent,

		ResourceCreateComponent,
		ResourceListComponent,
		ResourceDetailsDialogComponent,
		ResourceUpdateComponent,
		ResourceViewComponent,
	],
})
export class HomeModule {}
