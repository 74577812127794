import { Injectable } from "@angular/core";
import { STATUS_1, STATUS_2, ACTIVE, INACTIVE, AVAILABLE, CHECKED_OUT, FOR_DISPOSAL, LOST } from "../../constants/configuration/common.constant";
import { ASSET_STATUS_1, ASSET_STATUS_2, ASSET_STATUS_3, ASSET_STATUS_4 } from "../../constants/configuration/asset-constants.config";
import { DownloadFilters } from "src/app/shared/components/download-filters-dialog/models/download-filters";
import { DownloadFiltersTypeEnum } from "src/app/shared/components/download-filters-dialog/enums/download-filters-type-enum";

@Injectable({ providedIn: 'root' })
export class QueryService {
	buildDownloadQueryParams(
		filters: DownloadFilters
	): string {
		return '?'.concat(`filters=${filters.status}`);
	}

	buildBaseQuery(page: number, pageSize: number, sortedBy: string, sortOrder: string, keyword: string): string {
		let query: string = `/search?page=${page}&size=${pageSize}&sort=${sortedBy},${sortOrder}`;
		if (keyword && keyword.trim() !== '') query = query.concat(`&keyword=${this.refineURI(keyword)}`);
		return query;
	}

	buildBaseQueryWithoutSort(page: number, pageSize: number): string {
		let query: string = `/all?page=${page}&size=${pageSize}`;
		return query;
	}

	refineURI(query: String): String {
		return encodeURIComponent(String(query));
	}

	buildBundleSearchQuery(
		page: number,
		pageSize: number,
		sortedBy: string,
		sortOrder: string,
		keyword: string,
		filters: Map<String, String>
	): string {

		if (sortedBy.trim() === 'curriculumList') sortedBy = 'curriculumAssoc.curriculum.name';
		if (sortedBy.trim() === 'hqtLeadTag') sortedBy = 'curriculumAssoc.curriculum.hasHqt';
		let query: string = this.buildBaseQuery(page, pageSize, sortedBy, sortOrder, keyword);

		const updatedFilters = new Map<String, String>(filters);
		let status: string = String(updatedFilters.get("status"));

		if (status && status.trim().length > 0 && status !== "undefined") {
			if (status.toUpperCase().trim() === ACTIVE) status = STATUS_1;
			else if (status.toUpperCase().trim() === INACTIVE) status = STATUS_2;
			updatedFilters.set("status", status);
		}
		
		if (updatedFilters.size > 0) updatedFilters.forEach((value, key) => query = query.concat(`&${key}=${this.refineURI(value)}`));

		return query.trim();

	}

	buildResourceSearchQuery(
		page: number,
		pageSize: number,
		sortedBy: string,
		sortOrder: string,
		keyword: string,
		filters: Map<String, String>,
		reference: any,
		forOrder: boolean = false,
	): string {

		if (sortedBy.trim() === 'group') sortedBy = 'resourceAssoc.referenceType';
		let query: string = this.buildBaseQuery(page, pageSize, sortedBy, sortOrder, keyword);

		const updatedFilters = new Map<String, String>(filters);
		let status: string = String(updatedFilters.get("status"));
		let prop: string;

		if (status && status.trim().length > 0 && status !== "undefined") {
			if (status.toUpperCase().trim() === ACTIVE) status = STATUS_1;
			else if (status.toUpperCase().trim() === INACTIVE) status = STATUS_2;
			updatedFilters.set("status", status);
		}

		if (updatedFilters.has("onlineAccountType")) {
			prop = "onlineAccountType";
			updatedFilters.set(prop, this.getCode(String(updatedFilters.get(prop)), reference.onlineAccounts));
		}

		if (forOrder) {
			updatedFilters.set("forOrder", "TRUE");
		}
		
		if (updatedFilters.size > 0) updatedFilters.forEach((value, key) => query = query.concat(`&${key}=${this.refineURI(value)}`));

		return query.trim();

	}

	private getCode(value: string, referenceValues: any[]): string {
		const refValue: any = referenceValues.find(refValue => String(refValue.name).toLowerCase().includes(value.toLowerCase()));
		return refValue ? refValue.code : "";
	}

	buildHqtSearchQuery(
		page: number,
		pageSize: number,
		sortedBy: string,
		sortOrder: string,
		keyword: string,
		filters: Map<String, String>
	): string {

		let query: string = this.buildBaseQuery(page, pageSize, sortedBy, sortOrder, keyword);

		const updatedFilters = new Map<String, String>(filters);
		let status: string = String(updatedFilters.get("status"));

		if (status && status.trim().length > 0 && status !== "undefined") {
			if (status.toUpperCase().trim() === ACTIVE) status = STATUS_1;
			else if (status.toUpperCase().trim() === INACTIVE) status = STATUS_2;
			updatedFilters.set("status", status);
		}
		
		if (updatedFilters.size > 0) updatedFilters.forEach((value, key) => query = query.concat(`&${key}=${this.refineURI(value)}`));

		return query.trim();

	}

	buildCurriculumSearchQuery(
		page: number,
		pageSize: number,
		sortedBy: string,
		sortOrder: string,
		keyword: string,
		filters: Map<String, String>
	): string {

		let query: string = this.buildBaseQuery(page, pageSize, sortedBy, sortOrder, keyword);

		const updatedFilters = new Map<String, String>(filters);
		let status: string = String(updatedFilters.get("status"));
		updatedFilters.set("bookRequired", String(updatedFilters.get("bookRequired")).trim());

		if (status && status.trim().length > 0 && status !== "undefined") {
			if (status.toUpperCase().trim() === ACTIVE) status = STATUS_1;
			else if (status.toUpperCase().trim() === INACTIVE) status = STATUS_2;
			updatedFilters.set("status", status);
		}

		if (updatedFilters.size > 0) updatedFilters.forEach((value, key) => query = query.concat(`&${key}=${this.refineURI(value)}`));
		
		return query.trim();
	}

	buildClassSearchQuery(
		page: number,
		pageSize: number,
		sortedBy: string,
		sortOrder: string,
		keyword: string,
		filters: Map<String, String>
	): string {

		let query: string = this.buildBaseQuery(page, pageSize, sortedBy, sortOrder, keyword);

		const updatedFilters = new Map<String, String>(filters);
		let status: string = String(updatedFilters.get("status"));

		if (status && status.trim().length > 0 && status !== "undefined") {
			if (status.toUpperCase().trim() === ACTIVE) status = STATUS_1;
			else if (status.toUpperCase().trim() === INACTIVE) status = STATUS_2;
			updatedFilters.set("status", status);
		}

		if (updatedFilters.size > 0) updatedFilters.forEach((value, key) => query = query.concat(`&${key}=${this.refineURI(value)}`));
		
		return query.trim();
	}

	buildModelSearchQuery(
		page: number,
		pageSize: number,
		sortedBy: string,
		sortOrder: string,
		keyword: string,
		filters: Map<String, String>
	): string {

		let query: string = this.buildBaseQuery(page, pageSize, sortedBy, sortOrder, keyword);

		if (filters.size > 0) filters.forEach((value, key) => query = query.concat(`&${key}=${this.refineURI(value)}`));
		
		return query.trim();
	}

	buildAssetSearchQuery(
		page: number,
		pageSize: number,
		sortedBy: string,
		sortOrder: string,
		keyword: string,
		filters: Map<String, String>
	): string {

		let query: string = this.buildBaseQuery(page, pageSize, sortedBy, sortOrder, keyword);
		const updatedFilters = new Map<String, String>(filters);
		let status: string = String(updatedFilters.get("status"));

		if (status && status.trim().length > 0 && status !== "undefined") {
			if (status.toUpperCase().trim() === AVAILABLE.toUpperCase()) status = ASSET_STATUS_1;
			else if (status.toUpperCase().trim() === CHECKED_OUT.toUpperCase()) status = ASSET_STATUS_2;
			else if (status.toUpperCase().trim() === FOR_DISPOSAL.toUpperCase()) status = ASSET_STATUS_3;
			else if (status.toUpperCase().trim() === LOST.toUpperCase()) status = ASSET_STATUS_4;
			updatedFilters.set("status", status);
		}

		if (updatedFilters.size > 0) updatedFilters.forEach((value, key) => query = query.concat(`&${key}=${this.refineURI(value)}`));
		
		return query.trim();
	}

	buildStudentSearchQuery(
		page: number,
		pageSize: number,
		includeInactive: boolean,
		keyword: string,
		filter: string,
	): string {

		let query: string = `/all?page=${page}&size=${pageSize}&includeInactive=${includeInactive ? '1' : '0'}`;

		if (keyword && keyword.trim() !== '') query = query.concat(`&keyword=${this.refineURI(keyword)}`);
		if (filter) query = query.concat(`&filterBy=${this.refineURI(filter)}`);
		
		return query.trim();

	}

	buildTaskSearchQuery(
		page: number,
		pageSize: number,
		sortedBy: string,
		sortOrder: string,
		keyword: string,
		filters: Map<String, String>
	): string {

		let query: string = this.buildBaseQuery(page, pageSize, sortedBy, sortOrder, keyword);

		const updatedFilters = new Map<String, String>(filters);
		let status: string = String(updatedFilters.get("status"));

		if (status && status.trim().length > 0 && status !== "undefined") {
			if (status.toUpperCase().trim() === ACTIVE) status = STATUS_1;
			else if (status.toUpperCase().trim() === INACTIVE) status = STATUS_2;
			updatedFilters.set("status", status);
		}

		if (updatedFilters.size > 0) updatedFilters.forEach((value, key) => query = query.concat(`&${key}=${this.refineURI(value)}`));
		
		return query.trim();
	}

	buildOrderSearchQuery(
		page: number,
		pageSize: number,
		sortedBy: string,
		sortOrder: string,
		keyword: string,
		filters: Map<String, String>,
	): string {

		if (sortedBy.trim() === 'group') sortedBy = 'resourceAssoc.referenceType';
		let query: string = this.buildBaseQuery(page, pageSize, sortedBy, sortOrder, keyword);

		const updatedFilters = new Map<String, String>(filters);
		let status: string = String(updatedFilters.get("status"));

		if (status && status.trim().length > 0 && status !== "undefined") {
			if (status.toUpperCase().trim() === ACTIVE) status = STATUS_1;
			else if (status.toUpperCase().trim() === INACTIVE) status = STATUS_2;
			updatedFilters.set("status", status);
		}
		
		if (updatedFilters.size > 0) updatedFilters.forEach((value, key) => query = query.concat(`&${key}=${this.refineURI(value)}`));

		return query.trim();

	}

}
