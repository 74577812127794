import { DataProperties } from "../../models/data-properties.model";

export const RESOURCE: string = "RESOURCE";
export const resourceTab: string = "RESOURCE";

export const RESOURCE_TYPE_CODE_1: string = "RESOURCE_TYPE_1";
export const RESOURCE_TYPE_CODE_2: string = "RESOURCE_TYPE_2";
export const RESOURCE_TYPE_CODE_3: string = "RESOURCE_TYPE_3";
export const RESOURCE_TYPE_1: string = "Workbook/Text Book";
export const RESOURCE_TYPE_2: string = "Online Accounts";
export const RESOURCE_TYPE_3: string = "Others";
export const RESOURCE_GROUP_TYPE: string[] = ["CURRICULUM", "CLASS"];
export const ONLINE_ACCOUNT_TYPE_1: string = "ONLINE_ACC_TYPE_1";

export const sideNavLabelResource: string[] = [
    "Main Form",
    "Add Curriculum",
    "Summary"
];

export const resourceDataProperties: DataProperties[] = [
    {
        label: "Code",
        property: "code",
        sortable: true,
        clickable: true,
        filterable: true,
        autocomplete: false,
        endpoint: ""
    },
    {
        label: "Name",
        property: "name",
        sortable: true,
        clickable: false,
        filterable: true,
        autocomplete: false,
        endpoint: ""
    },
    // {
    //     label: "ISBN or Serial Number",
    //     property: "physicalIdCode",
    //     sortable: true,
    //     clickable: false,
    //     filterable: true
    // },
    {
        label: "Type",
        property: "type",
        sortable: true,
        clickable: false,
        filterable: true,
        autocomplete: false,
        endpoint: ""
    },
    // {
    //     label: "Online Platform",
    //     property: "onlineAccountType",
    //     sortable: false,
    //     clickable: false,
    //     filterable: true
    // },
    {
        label: "Resource Association",
        property: "group",
        sortable: false,
        clickable: false,
        filterable: true,
        autocomplete: false,
        endpoint: ""
    }
];
