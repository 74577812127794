import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CrudService } from 'src/app/core/services/data/crud.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { HeaderMessagingService } from 'src/app/core/services/messaging/header-messaging.service';
import { MatDialog } from '@angular/material';
import { allAssetsEndpoint, allStudentsEndpoint } from 'src/app/core/constants/endpoints.constant';
import {
	dialogBoxSuccessTitle,
	updateSuccessAsset,
} from 'src/app/core/constants/message.constant';
import { DialogBoxComponent } from 'src/app/shared/components/dialog-box/dialog-box.component';
import { ASSET_PATH } from 'src/app/core/constants/routes.constant';
import { ErrorHandlerService } from 'src/app/core/services/util/error-handler.service';
import { LoaderMessagingService } from 'src/app/core/services/messaging/loader-messaging.service';
import { sideNavLabelModel } from 'src/app/core/constants/configuration/asset-constants.config';
import { UPDATE_MODULE_NAME } from 'src/app/core/constants/configuration/common.constant';
import { Asset } from 'src/app/core/models/asset.model';
import { settingsRoutingPermissions, TEACHER } from "src/app/core/constants/configuration/role-constants.config";
import { UserService } from "src/app/core/services/util/user.service";
import { Student } from 'src/app/core/models/eos/student.model';
import { QueryService } from 'src/app/core/services/util/query.service';
import { assetModuleName } from 'src/app/core/constants/configuration/asset-constants.config';

@Component({
	selector: 'app-asset-update',
	templateUrl: './asset-update.component.html',
	styleUrls: ['./asset-update.component.scss']
})
export class AssetUpdateComponent implements OnInit {

	unsubscribe: Subject<any> = new Subject();
	activeIndex: number = 0;
	sideNavLabels: string[] = [];
	asset: Asset;
	includeInactive: boolean = false;

	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private crudService: CrudService,
		private headerMessagingService: HeaderMessagingService,
		private errorHandlerService: ErrorHandlerService,
		private loaderMessagingService: LoaderMessagingService,
		private dialog: MatDialog,
		private userService: UserService,
		private queryService: QueryService
	) {
		this.loaderMessagingService.showPageLoader(true);
		this.userService.checkRolePermission(ASSET_PATH, settingsRoutingPermissions);
		this.headerMessagingService.setHeader(assetModuleName, UPDATE_MODULE_NAME, false, null);
		this.sideNavLabels = sideNavLabelModel;
		this.includeInactive = !this.userService.hasRole([TEACHER]);
	}

	ngOnInit() {
		this.route.params.pipe(takeUntil(this.unsubscribe)).subscribe(params => this.findAsset(params["id"]));
	}

	ngOnDestroy(): void {
		this.unsubscribe.next();
		this.unsubscribe.complete();
	}

	private findAsset(id: number): void {

		this.crudService
			.getById<Asset>(allAssetsEndpoint.concat(`/${id}`))
			.pipe(takeUntil(this.unsubscribe))
			.subscribe(response => {
				if (response) {
					this.transformStatus(response);
				}
			}, this.errorHandlerService.handleError, this.handleCompletion);

	}

	transformStatus(asset) {
		this.crudService
			.getAll<any>(
				allAssetsEndpoint.concat("/status"))
			.pipe(takeUntil(this.unsubscribe))
			.subscribe((response: any) => {
				if (response) {
					response.forEach(status => {
						if (status.code === asset.status) {
							asset.status = status.name;
						} else if (status.name === asset.status) {
							asset.status = status.code;
						}
					});
					if (asset.loanedTo) {
						this.searchStudent(asset);
					} else {
						this.asset = asset.item && asset.item.name ?
							{ ...asset, item: { ...asset.item, name: asset.item.name.trim() } }
							: asset;
					}
				}
			},
				this.errorHandlerService.handleError,
			);
	}

	onSaveAsset($event: Asset) {
		this.loaderMessagingService.showPageLoader(true);
		this.customizeStatusToSave($event);
	}

	saveAsset($event) {
		this.crudService
			.edit<Asset>(allAssetsEndpoint.concat(`/${$event.id}`), $event)
			.pipe(takeUntil(this.unsubscribe))
			.subscribe((response) => {
				this.router.navigate([ASSET_PATH, $event.id]);
				const dialogRef = this.dialog.open(DialogBoxComponent);
				dialogRef.componentInstance.dialogTitle = dialogBoxSuccessTitle;
				dialogRef.componentInstance.contentMessage = updateSuccessAsset;
			}, this.errorHandlerService.handleError, this.handleCompletion);
	}

	handleCompletion = (): void => {
		this.loaderMessagingService.showPageLoader(false);
	}

	searchStudent(asset) {
		const filter = "districtId";

		this.crudService
			.getAll<Student>(allStudentsEndpoint.concat(this.queryService.buildStudentSearchQuery(
				0, 10, this.includeInactive, asset.loanedTo, filter)))
			.pipe(takeUntil
				(this.unsubscribe))
			.subscribe(response => {
				if (response) {
					let student: any;
					student = response.content[0];
					asset.loanedTo = student;
					asset.loanedTo.name = student.firstName
						+ " " + student.lastName
						+ " " + "(" + student.districtId + ")";
				}
				this.asset = asset;
			},
				this.errorHandlerService.handleError
			);

	}

	customizeStatusToSave(asset) {
		this.crudService
			.getAll<any>(
				allAssetsEndpoint.concat("/status"))
			.pipe(takeUntil(this.unsubscribe))
			.subscribe((response: any) => {
				if (response) {
					response.forEach(status => {
						if (status.name === asset.status) {
							asset.status = status.code;
						}
					});
				}
				this.saveAsset(asset);
			},
				this.errorHandlerService.handleError,
			);
	}

}
