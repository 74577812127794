import { Component, OnInit, Inject } from '@angular/core';
import { Class } from 'src/app/core/models/class.model';
import { DataCustomizerService } from 'src/app/core/services/util/data-customizer.service';
import { LoaderMessagingService } from 'src/app/core/services/messaging/loader-messaging.service';
import { CrudService } from 'src/app/core/services/data/crud.service';
import { ErrorHandlerService } from 'src/app/core/services/util/error-handler.service';
import { Subject, forkJoin } from 'rxjs';
import { getActivePeriodStatusEndpoint, getClassSubjectsEndpoint, allHqtEndpoint } from 'src/app/core/constants/endpoints.constant';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { takeUntil } from 'rxjs/operators';
import { Hqt } from 'src/app/core/models/hqt.model';

@Component({
  selector: 'app-class-details-dialog',
  templateUrl: './class-details-dialog.component.html',
  styleUrls: ['./class-details-dialog.component.scss']
})
export class ClassDetailsDialogComponent implements OnInit {

	classDisplay: any = {};
	curriculumDisplay: any ={};

	activePeriods: any[];
	classSubjects: any[];
	hqts: any;
	unsubscribe: Subject<any> = new Subject();


	constructor(
		public dialogRef: MatDialogRef<ClassDetailsDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: Class,
		private dataCustomizerService: DataCustomizerService,
		private loaderMessagingService: LoaderMessagingService,
		private crudService: CrudService,
		private errorHandlerService: ErrorHandlerService,
		private dialog: MatDialog,
	) { }

	ngOnInit() {
		this.curriculumDisplay = this.dataCustomizerService.formatCurriculumDetailsDisplay(this.data.classCurriculum);
		this.classDisplay = this.dataCustomizerService.formatClassDetailsDisplay(this.data);
		this.classDisplay.classCurriculum = this.curriculumDisplay;
		this.getSubjectsPeriods();
	}

	getSubjectsPeriods(): void {
		this.loaderMessagingService.showPageLoader(true);
		forkJoin([
		this.classDisplay.hqtUsername ? 
		this.crudService
		.getById<Hqt>(allHqtEndpoint.concat(`/get-by-email?email=${this.classDisplay.hqtUsername}`))
		.pipe(takeUntil(this.unsubscribe))
		.subscribe(response => {
			this.hqts = response;
			},
			this.errorHandlerService.handleError) : 
		null,
		this.crudService
		.getById<any>(getActivePeriodStatusEndpoint)
		.pipe(takeUntil(this.unsubscribe))
		.subscribe(
			response => {
			this.activePeriods = response;
			}, this.errorHandlerService.handleError),
		this.crudService
		.getById<any>(getClassSubjectsEndpoint)
		.pipe(takeUntil(this.unsubscribe))
		.subscribe(
			response => {
			this.classSubjects = response;
			}, this.errorHandlerService.handleError),
			this.handleCompletion()
		]);
	}

	handleCompletion(): void {
		this.loaderMessagingService.showPageLoader(false);
	}

}
