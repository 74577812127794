import { Injectable } from '@angular/core';
import { Model } from 'src/app/core/models/model.model';
import { Class } from '../../models/class.model';
import { Resource } from '../../models/resource.model';
import { ModelMainForm } from '../../models/form/model-main-form.model';
import { STATUS_1, STATUS_2, ACTIVE } from '../../constants/configuration/common.constant';

@Injectable({ providedIn: 'root' })
export class ModelHelper {

    model: Model;
    classes: Class[] = [];
    resources: Resource[] = [];

    constructor() { }

    setModel(modelFormValues: ModelMainForm, forUpdate: boolean, original?: Model): Model {
        return {
            id: forUpdate ? original.id : null,
            code: modelFormValues.code,
            name: modelFormValues.name,
            total: forUpdate ? original.total : null,
            availableCount: forUpdate ? original.availableCount : null,
            checkOutCount: forUpdate ? original.checkOutCount : null,
            forDisposalCount: forUpdate ? original.forDisposalCount: null,
            lostCount: forUpdate ? original.lostCount : null,
            consumable: modelFormValues.consumable,
            createdBy: forUpdate ? original.createdBy : null,
            modifiedBy: forUpdate ? original.modifiedBy : null,
            createdDate: forUpdate ? original.createdDate : null,
            modifiedDate: forUpdate ? original.modifiedDate : null,
            price: modelFormValues.price ? modelFormValues.price : 0,
            deposit: modelFormValues.deposit ? modelFormValues.deposit : 0,
            hasDeposit: modelFormValues.hasDeposit,
        };

    }

    setModelMainFormValues = (model: Model): ModelMainForm => {
        return {
            code: model.code,
            name: model.name,
            consumable: model.consumable,
            price: model.price,
            deposit: model.deposit,
            hasDeposit: model.hasDeposit,
        }
    }

}
