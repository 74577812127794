import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { Class } from "src/app/core/models/class.model";
import { Curriculum } from "src/app/core/models/curriculum.model";
import { DataCustomizerService } from 'src/app/core/services/util/data-customizer.service';
import { LoaderMessagingService } from 'src/app/core/services/messaging/loader-messaging.service';
import { CrudService } from 'src/app/core/services/data/crud.service';
import { ErrorHandlerService } from 'src/app/core/services/util/error-handler.service';
import { forkJoin, Subject } from 'rxjs';
import { getActivePeriodStatusEndpoint, getByCurriculumIdClassEndpoint, getClassSubjectsEndpoint } from 'src/app/core/constants/endpoints.constant';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-curriculum-details-dialog',
  templateUrl: './curriculum-details-dialog.component.html',
  styleUrls: ['./curriculum-details-dialog.component.scss']
})
export class CurriculumDetailsDialogComponent implements OnInit {

  curriculumDisplay: any = {};

  curriculum: Curriculum;

  classes: Class[];
  activePeriods: any[];
  classSubjects: any[];
  unsubscribe: Subject<any> = new Subject();

  constructor(public dialogRef: MatDialogRef<CurriculumDetailsDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: Curriculum,
              private dataCustomizerService: DataCustomizerService,
              private loaderMessagingService: LoaderMessagingService,
              private crudService: CrudService,
              private errorHandlerService: ErrorHandlerService,
              private dialog: MatDialog,
              ) { }

  ngOnInit() {
    this.curriculumDisplay = this.dataCustomizerService.formatCurriculumDetailsDisplay(this.data);
    this.curriculum = this.data;
    this.getClasses();
    this.getSubjectsPeriods();
  }

  getClasses(): void {
    this.loaderMessagingService.showPageLoader(true);
    this.crudService
    .getAllBy<Class>(getByCurriculumIdClassEndpoint.concat(`?curriculumId=${this.curriculum.id}`))
    .pipe(takeUntil(this.unsubscribe))
    .subscribe(
      response => {
        this.classes = response;
      }, this.errorHandlerService.handleError);
    this.loaderMessagingService.showPageLoader(false);
  }

  getSubjectsPeriods(): void {
    this.loaderMessagingService.showPageLoader(true);
    forkJoin([
      this.crudService
      .getById<any>(getActivePeriodStatusEndpoint)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(
        response => {
          this.activePeriods = response;
        }, this.errorHandlerService.handleError),
      this.crudService
      .getById<any>(getClassSubjectsEndpoint)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(
        response => {
          this.classSubjects = response;
        }, this.errorHandlerService.handleError),
        this.handleCompletion()
    ]);
  }

  handleCompletion(): void {
    this.loaderMessagingService.showPageLoader(false);
  }

}
