import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { HeaderMessagingService } from 'src/app/core/services/messaging/header-messaging.service';
import { homeModuleName, pageSizeOptions, CLOSE, SY_CODE, ASC, fullYearFormat, OPEN, SUCCESS, PAGE_SIZE, PAGE_INDEX, ERROR } from 'src/app/core/constants/configuration/common.constant';
import { homeRoutingPermissions } from 'src/app/core/constants/configuration/role-constants.config';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { faFilter, faDownload } from '@fortawesome/free-solid-svg-icons';
import { Subject } from 'rxjs';
import { Router } from "@angular/router";
import { DataProperties } from 'src/app/core/models/data-properties.model';
import { taskDataProperties, ASSIGN_OTHERS, ASSIGN_SELF, PRE_COMPLETE, COMPLETE, WORKBOOKTEXTBOOK, TASK_GROUP_2, CREATE_WORKBOOK_TEXTBOOK } from 'src/app/core/constants/configuration/task-constants.config';
import { LoaderMessagingService } from 'src/app/core/services/messaging/loader-messaging.service';
import { CrudService } from 'src/app/core/services/data/crud.service';
import { ErrorHandlerService } from 'src/app/core/services/util/error-handler.service';
import { MatDialog } from '@angular/material';
import {
	updateTaskStatusEndpoint,
	getAllSchoolYearsEndpoint,
	getProcessingStatusEndpoint,
	allTasksEndpoint,
	getTaskGroupTypeEndpoint,
	getCountByStatus,
	getUserAssignedCurriculumEndpoint,
	getActiveSchoolYearEndpoint,
	oldEndpoint,
	updateWTBTaskStatusEndpoint, allCurriculumsEndpoint,
	createWorkbookTextbook,
} from 'src/app/core/constants/endpoints.constant';
import { takeUntil } from 'rxjs/operators';
import * as moment from 'moment';
import { FilterModalComponent } from 'src/app/shared/components/filter-modal/filter-modal.component';
import { changeSize } from 'src/app/core/constants/animations.constant';
import { DialogMessagingService } from 'src/app/core/services/messaging/dialog-messaging.service';
import {
	taskActionConfirmMessage,
	assignTaskSuccessMessage,
	preCompleteTaskSuccessMessage,
	completeTaskSuccessMessage,
	completeCreateWorkbookTextBook,
	createWorkbookTextbookConfirmMessage,
} from 'src/app/core/constants/message.constant';
import { Task } from 'src/app/core/models/order/task.model';
import { QueryService } from 'src/app/core/services/util/query.service';
import { DataCustomizerService } from 'src/app/core/services/util/data-customizer.service';
import { TASKS_PATH, DOWNLOAD_ONLINE_ACCOUNTS_PATH, DOWNLOAD_REPORTS_PATH } from 'src/app/core/constants/routes.constant';
import { UserService } from "src/app/core/services/util/user.service";
import { FilterComponent } from 'src/app/shared/components/filter/filter.component';
import { ConfirmDialogComponent } from "src/app/shared/components/confirm-dialog/confirm-dialog.component";
import {
	PROCESSING_STATUS_2,
	PROCESSING_STATUS_3,
	PROCESSING_STATUS_4,
	PROCESSING_STATUS_5,
	PROCESSING_STATUS_1,
} from 'src/app/core/constants/configuration/order-constants.config';
import { FilterService } from "src/app/core/services/util/filter.service";

@Component({
	selector: 'app-tasks',
	templateUrl: './tasks.component.html',
	styleUrls: ['./tasks.component.scss'],
	animations: [changeSize]
})
export class TasksComponent implements OnInit, OnDestroy {

	@ViewChild(FilterComponent) filterComponent: FilterComponent;
	tasks: any[] = [];
	items: any[] = [];
	curriculums: any[] = [];
	itemsCount: number = 0;
	pageIndex: number = 0;
	pageSize: number = pageSizeOptions[0];
	keyword: string = "";
	filters: Map<string, string> = new Map<string, string>();
	unsubscribe: Subject<any> = new Subject();
	reference: any = {};
	selectedSyId: number;
	selectedAssignedTo: string;
	schoolYear: string;
	filterLabels: string[] = [];
	taskDataProperties: DataProperties[] = taskDataProperties;
	schoolYears: any[] = [];
	processingStatus: any[] = [];
	taskGroupType: any[] = [];
	specialists: any[] = [];
	clearFilters: boolean = true;
	sortedBy: string;
	sortOrder: string;
	isType1: boolean = false;
	withRequestToCancel: boolean = false;
	showAssignToSpecialist: boolean = false;
	assignedToSelf: boolean = false;
	trigger: string = CLOSE;
	statusFilter = new Map<string, string>();
	statusListCount = new Array<any>();

	filterAssignedToKey: string = "assignedTo";
	filterAssignedToSelfKey: string = "assignedToSelf";
	filterWithRequestToCancelKey: string = "withRequestToCancel";
	filterSelectedSpecialistKey: string = "selectedSpecialist";
	selectedSpecialist: any;
	filtersKey: string = "taskFilters";

	filterIcon: IconDefinition = faFilter;
	downloadIcon: IconDefinition = faDownload;

	constructor(
		private crudService: CrudService,
		private dataCustomizerService: DataCustomizerService,
		private dialog: MatDialog,
		private dialogMessagingService: DialogMessagingService,
		private errorHandlerService: ErrorHandlerService,
		private headerMessagingService: HeaderMessagingService,
		private loaderMessagingService: LoaderMessagingService,
		private queryService: QueryService,
		private router: Router,
		private userService: UserService,
		private filterService: FilterService,
	) {
		this.userService.checkRolePermission(TASKS_PATH, homeRoutingPermissions);
		this.headerMessagingService.setHeader(homeModuleName, "", true, homeRoutingPermissions);
		let taskFilters = this.filterService.getFilter(this.filtersKey);
		if (taskFilters) {
			this.keyword = taskFilters.keyword || "";
			this.filters = taskFilters.filters ? this.filterService.objectToMap(taskFilters.filters) : new Map<string, string>();
			if (this.filters.has(this.filterWithRequestToCancelKey)) {
				this.filters.delete(this.filterWithRequestToCancelKey);
				this.onToggleWithRequestToCancel(false);
			}
			if (this.filters.has(this.filterAssignedToSelfKey)) {
				this.filters.delete(this.filterAssignedToSelfKey);
				this.onToggleAssignedToSelf(false);
			}
			if (this.filters.has(this.filterSelectedSpecialistKey)) {
				this.onSelectSpecialist({ username: this.filters.get(this.filterSelectedSpecialistKey) }, false);
			}
			if (this.filters.has(this.filterAssignedToKey)) {
				this.selectedAssignedTo = this.filters.get(this.filterAssignedToKey);
			}
		}
	}

	initializeTaskFilters(
		taskFilters: any
	) {
		if (taskFilters != null) {
			if (taskFilters.filters.Size) {
				this.pageSize = taskFilters.filters.Size;
			}
			if (taskFilters.filters.Page) {
				this.pageIndex = taskFilters.filters.Page;
			}
		}
		// else let be on default values:
	}

	ngOnInit() {
		this.loaderMessagingService.showPageLoader(true);
		this.initializeTaskFilters(
			this.filterService
				.getFilter(this.filtersKey)
		);
		this.getSchoolYears();
	}
	
	setPageIndexFilter(pageIndex: number): void {
		this.filters.set(PAGE_INDEX, String(pageIndex));
	  	this.filterService.setFilter(this.filtersKey, this.keyword, this.filters);
	}
  
	setPageSizeFilter(pageSize: number): void {
		this.filters.set(PAGE_SIZE, String(pageSize));
	  	this.filterService.setFilter(this.filtersKey, this.keyword, this.filters);
	}
  

	ngOnDestroy(): void {
		this.unsubscribe.next();
		this.unsubscribe.complete();
	}

	handleCompletion = (): void => {
		this.loaderMessagingService.showListLoader(false);
		this.loaderMessagingService.showPageLoader(false);
	};
	
	onSearch(keyword: string): void {
		this.keyword = keyword;
		this.pageIndex = 0;
		this.search();
	}

	search(): void {
		let statusFilter: string = String(this.filters.get("status"));
		let syCode: string = String(this.filters.get("syCode"));
		let hstApprovedStatus = this.processingStatus.find(status => status.code === PROCESSING_STATUS_1);
		this.showAssignToSpecialist = statusFilter && hstApprovedStatus && statusFilter.toLowerCase() === hstApprovedStatus.name.toLowerCase();
		
		this.loaderMessagingService.showListLoader(true);
		this.items = [];
		this.filterService.setFilter(this.filtersKey, this.keyword, this.filters);

		let urlString = allTasksEndpoint.concat(this.queryService.buildTaskSearchQuery(
			this.pageIndex, this.pageSize, this.sortedBy, this.sortOrder, this.keyword, this.filters));
		if(syCode == "2019-2020") {
			urlString = oldEndpoint.concat(allTasksEndpoint.concat(this.queryService.buildTaskSearchQuery(
				this.pageIndex, this.pageSize, this.sortedBy, this.sortOrder, this.keyword, this.filters)));
		}
		this.crudService
			.getAll<Task>(urlString)
			.pipe(takeUntil(this.unsubscribe))
			.subscribe(response => {
				if (response) {
					this.itemsCount = response.totalElements;
					this.tasks = response.content;
					response.content.forEach(task =>
						this.items.push(this.dataCustomizerService.createCustomizedTaskForList(task, this.getReferences())));
				}
				
				this.getCurriculums();
			},
			this.errorHandlerService.handleError,
			this.handleCompletion
		);
	}
	
	getReferences() {
		return {
			processingStatus: this.processingStatus,
			taskGroupType: this.taskGroupType,
		}
	}
	
	goToDownloadReportsPage() {
		this.router.navigateByUrl(DOWNLOAD_REPORTS_PATH);
	}

	setSchoolYear(schoolYear: any): void {
		this.selectedSyId = 0;
		if(schoolYear != undefined || schoolYear != null) {
		  this.selectedSyId = schoolYear.id;
		  this.schoolYear = schoolYear.schoolYear;
		  if (this.filters.has(SY_CODE)) this.filters.delete(SY_CODE);
		  this.setFilterLabels();
		  this.getFilterCount();
		  this.search();
		} else {
		  this.crudService
		  .getById<any>(getActiveSchoolYearEndpoint)
		  .pipe(takeUntil(this.unsubscribe))
				.subscribe(response => {
					if (response) {
				  		this.schoolYear = response.schoolYear;
						this.selectedSyId = this.schoolYears.find(sy =>
							String(sy.schoolYear).trim() === this.schoolYear.trim()).id;
							
						if (this.filters.has(SY_CODE)) this.filters.delete(SY_CODE);
						this.setFilterLabels();
						this.getFilterCount();
						this.search();
						this.getCurriculums();
					}
			}, this.errorHandlerService.handleError);
		}
	}

	setFilterLabels(): void {
		this.filterLabels = [];
		this.filters.forEach((value, key) => {
			let prop = this.taskDataProperties.find(property => property.property === key);
			if (prop && prop.label) this.filterLabels.push(`${prop.label}: ${value}`)
		});
		this.filters.set(SY_CODE, this.schoolYear);
		const index: number = this.filterLabels.findIndex(label => label.includes('School Year:'));
		if (index !== -1) this.filterLabels.splice(index, 1);
		this.filterLabels.splice(0, 0, `School Year: ${this.schoolYear}`);

		let statusFilter: string = String(this.filters.get("status"));
		let hstApprovedStatus = this.processingStatus.find(status => status.code === PROCESSING_STATUS_1);

		const selectedSpecialistIndex: number = this.filterLabels.findIndex(label => label.includes('Selected Specialist'));
		if (statusFilter && statusFilter.toLowerCase() === hstApprovedStatus.name.toLowerCase() && this.selectedSpecialist) {
			this.filters.set(this.filterSelectedSpecialistKey, this.selectedSpecialist.username);
			if (selectedSpecialistIndex === -1) this.filterLabels.splice(1, 0, `Selected Specialist: ${this.selectedSpecialist.username}`);
		} else {
			this.selectedSpecialist = null;
			this.filters.delete(this.selectedSpecialist);
			if (selectedSpecialistIndex !== -1) this.filterLabels.splice(selectedSpecialistIndex, 1);
		}

		const withRequestToCancelIndex: number = this.filterLabels.findIndex(label => label === 'With Request to Cancel: Yes');
		if (this.withRequestToCancel) {
			this.filters.set(this.filterWithRequestToCancelKey, "Yes");
			if (withRequestToCancelIndex === -1) this.filterLabels.splice(1, 0, 'With Request to Cancel: Yes');
		} else {
			this.filters.delete(this.filterWithRequestToCancelKey);
			if (withRequestToCancelIndex !== -1) this.filterLabels.splice(withRequestToCancelIndex, 1);
		}

		const withAssignedToSelfIndex: number = this.filterLabels.findIndex(label => label === 'Assigned to Self: Yes');
		if (this.assignedToSelf) {
			this.filters.set(this.filterAssignedToSelfKey, "Yes");
			if (withAssignedToSelfIndex === -1) this.filterLabels.splice(1, 0, 'Assigned to Self: Yes');
		} else {
			this.filters.delete(this.filterAssignedToSelfKey);
			if (withAssignedToSelfIndex !== -1) this.filterLabels.splice(withAssignedToSelfIndex, 1);
		}
		
		const selectedAssignedToIndex: number = this.filterLabels.findIndex(label => label.includes('Assigned To'));
		if (this.selectedAssignedTo) {
			this.filters.set(this.filterAssignedToKey, this.selectedAssignedTo);
			if (selectedAssignedToIndex === -1) {
				this.filterLabels.splice(1, 0, `Assigned To: ${this.selectedAssignedTo}`);
			} else {
				this.filterLabels.splice(selectedAssignedToIndex, 1);
				this.filterLabels.splice(1, 0, `Assigned To: ${this.selectedAssignedTo}`);
			}
		} else {
			this.selectedAssignedTo = null;
			this.filters.delete(this.filterAssignedToKey);
			if (selectedAssignedToIndex !== -1) this.filterLabels.splice(selectedAssignedToIndex, 1);
		}

		this.clearFilters = this.filters.size === 1;
	}
  
	getSchoolYears(): void {
		this.crudService
			.getById<any>(getAllSchoolYearsEndpoint)
			.pipe(takeUntil(this.unsubscribe))
			.subscribe(response => {
				if (response) {
					this.schoolYears = response;
					this.schoolYears.sort((a, b) => b.schoolYear.localeCompare(a.schoolYear));
					this.getProcessingStatus();
				}
			}, this.errorHandlerService.handleError);
	}
	
	getProcessingStatus(): void {
		this.crudService
			.getById<any>(getProcessingStatusEndpoint)
			.pipe(takeUntil(this.unsubscribe))
			.subscribe(response => {
				this.processingStatus = response;
				this.processingStatus.sort((a, b) => b.code.localeCompare(a.code));
				this.getTaskGroupType();
			}, this.errorHandlerService.handleError);
	}
	
	getTaskGroupType(): void {
		this.crudService
			.getById<any>(getTaskGroupTypeEndpoint)
			.pipe(takeUntil(this.unsubscribe))
			.subscribe(response => {
				this.taskGroupType = response;
				this.taskGroupType.sort((a, b) => b.code.localeCompare(a.code));
				this.getSpecialists();
			}, this.errorHandlerService.handleError);
	}
	
	getSpecialists(): void {
		this.crudService
			.getById<any>(getUserAssignedCurriculumEndpoint)
			.pipe(takeUntil(this.unsubscribe))
			.subscribe(response => {
				this.specialists = response;
				this.specialists.sort((a, b) => b.username.localeCompare(a.username));
				this.initParams();
			}, this.errorHandlerService.handleError);
	}

	getCurriculums(): void {
		this.crudService
			.getById<any>(allCurriculumsEndpoint+"/all?syCode=" + this.schoolYear)
			.pipe(takeUntil(this.unsubscribe))
			.subscribe(response => {
				this.curriculums = response;
			}, this.errorHandlerService.handleError);
	}
  
	initParams(): void {
		this.sortedBy = this.taskDataProperties[0].property;
		this.sortOrder = ASC;
		let yearNow: string = moment((new Date()).valueOf()).format(fullYearFormat);
		this.setSchoolYear(null);
	}

	onSelectAssignedTo(specialist: any): void {
		this.selectedAssignedTo = specialist.username;
		this.setFilterLabels();
		this.search();
  }

	onSelectSpecialist(specialist: any, willSearch: boolean) {
		this.selectedSpecialist = specialist;
		if (willSearch) {
			this.setFilterLabels();
			this.search();
		}
	}
  
	onToggleWithRequestToCancel(willSearch: boolean): void {
		this.withRequestToCancel = !this.withRequestToCancel;
		if (willSearch) {
			this.setFilterLabels();
			this.search();
		}
	}

	onToggleSort($event: any) {
		this.sortedBy = this.getSortedByAsQuery($event.sortedBy);
		this.sortOrder = $event.sortOrder;
		this.search();
	}

	// Transforms header as accepted parameter in the backend for search.
	getSortedByAsQuery(
		sortedBy: string
	): string {
		let response: string;
		switch(sortedBy) {
			case 'cosOrderCode':
				response = 'txnOrderCode';
			break;
			case 'requestedBy':
				response = 'createdBy';
			break;
			case 'districtId':
				response = 'studentDistrictId';
			break;
			case 'type':
				response = 'groupType';
			break;
			default:
				response = sortedBy;
			break;
		}
		return response;
	}
  
	onToggleAssignedToSelf(willSearch: boolean): void {
		this.assignedToSelf = !this.assignedToSelf;
		if (willSearch) {
			this.setFilterLabels();
			this.search();
		}
	}
  
	onChangeFilterType(): void {
		this.isType1 = !this.isType1;
	}
  
	filterOnClick(): void {
		if (!this.isType1) {
			this.trigger = this.trigger === CLOSE ? OPEN : CLOSE;
		} else {
			this.trigger = CLOSE;
			let data = [];
			this.taskDataProperties.forEach(property => {
				if (property.filterable)
					data.push({
						label: property.label,
						prop: property.property,
						value: this.filters.get(property.property) || "",
					});
			});
			const modalStyle = { width: "30%", autoFocus: false, maxHeight: '90vh' };
			const dialogRef = this.dialog.open(FilterModalComponent, { ...modalStyle, data });
			dialogRef.componentInstance.curriculums = this.curriculums;
			dialogRef.componentInstance.taskGroupType = this.taskGroupType;
			dialogRef.afterClosed().subscribe(filters => {
				if (filters) this.filterOnChange(filters);
			}); 
		}
	}
  
	filterOnChange(filters: Map<string, string>) {
		this.filters = new Map<string, string>(filters);
		this.setFilterLabels();
		this.pageIndex = 0;
		this.search();
	}
  
  onClearFilters(): void {
		this.clearFilters = true;
		this.assignedToSelf = false;
		this.withRequestToCancel = false;
		this.selectedSpecialist = null;
		this.selectedAssignedTo = null;
		this.keyword = '';
		this.filters.clear();
		this.filterOnChange(new Map<string, string>());
	}
  
  onPageChange($event: any): void {
		this.pageIndex = $event.pageIndex;
		this.pageSize = $event.pageSize;
		this.setPageSizeFilter(this.pageSize);
		this.setPageIndexFilter(this.pageIndex);
		this.search();
	}
  
	onBulkAction($event: any): void {
		let successMessage: string;
		let confirmMessage: string;
		let newStatus: string;
		let specialist: string;
		switch ($event.action) {
			case ASSIGN_OTHERS: {
				specialist = $event.selectedSpecialist;
				successMessage = assignTaskSuccessMessage.replace("??", specialist);
				confirmMessage = taskActionConfirmMessage.replace("?", "assign").replace("??", `to ${specialist}?`);
				newStatus = PROCESSING_STATUS_2;
				break;
			}
			case ASSIGN_SELF: {
				successMessage = assignTaskSuccessMessage.replace("??", this.userService.getLoginUser().username);
				confirmMessage = taskActionConfirmMessage.replace("?", "assign").replace("??", "to yourself?");
				newStatus = PROCESSING_STATUS_2;
				specialist = null;
				break;
			}
			case PRE_COMPLETE: {
				successMessage = preCompleteTaskSuccessMessage;
				confirmMessage = taskActionConfirmMessage.replace("?", "tag").replace("??", "as pre-completed?").replace("*", "");
				newStatus = PROCESSING_STATUS_3;
				break;
			}
			case COMPLETE: {
				successMessage = completeTaskSuccessMessage;
				confirmMessage = taskActionConfirmMessage.replace("?", "tag").replace("??", "as completed?").replace("*", "");
				newStatus = PROCESSING_STATUS_4;
				break;
			}
			case CREATE_WORKBOOK_TEXTBOOK: {
				successMessage = completeCreateWorkbookTextBook;
				confirmMessage = createWorkbookTextbookConfirmMessage;
				newStatus = null;
				break;
			}
			default: break;
		}
		this.openConfirmDialog(confirmMessage, successMessage, $event, newStatus, specialist);
	}

  openConfirmDialog(confirmMessage: string, successMessage: string, event: any, newStatus: string, specialist: string) {
		let selectedItems: any[] = event.items;
		const dialogRef = this.dialog.open(ConfirmDialogComponent, { autoFocus: false });
		dialogRef.componentInstance.confirmMessage = confirmMessage;
		dialogRef.afterClosed().subscribe(result => {
      	if (result) {
				let items = selectedItems.map(item => {
					let orderItems = [];
					item.orderItemAssoc.map(itemAssoc => {
						if (itemAssoc.status !== PROCESSING_STATUS_5) {
							orderItems.push({ ...itemAssoc, status: newStatus })
						}
					});
					let task = this.tasks.find(currentTask => currentTask.code === item.code);
					if(task.groupType === TASK_GROUP_2 && (event.action === PRE_COMPLETE || event.action === COMPLETE)){
						task.status = newStatus;
					}
	
					let emptyListTempItem = {
						id: null,
						task: null,
						txnOrderItemId: null,
						status: newStatus,
						forCancel: false,
					};
					orderItems = orderItems.length > 0 ? orderItems : [emptyListTempItem];
					if (newStatus === PROCESSING_STATUS_2) return { ...task, orderItemAssoc: orderItems, assignedTo: specialist }
					else return { ...task, orderItemAssoc: orderItems }
				});
				if(newStatus != null) {
					this.updateTaskStatus(successMessage, items, event.action);
				} else {
					this.createWorkbookTextbook(successMessage, items);
				}
			}
		});
  }

	onFilterChange(event: any) {
		this.statusFilter = event;
		this.trigger = OPEN;
		setTimeout(() =>{
			this.filterComponent.filterByStatus();
    },200);
	}

	getFilterCount() : void {
		let statusFilter : Map<string, string> = new Map<string, string>();
		statusFilter.set(SY_CODE, this.schoolYear);
		let urlApi = allTasksEndpoint.concat(getCountByStatus);
		if(this.schoolYear == "2019-2020") {
			urlApi = oldEndpoint.concat(allTasksEndpoint.concat(getCountByStatus));
		}
		
		this.statusListCount = [];
		this.crudService
		.getById<Task>(urlApi)
		.pipe(takeUntil(this.unsubscribe))
		.subscribe(response => {
			this.processingStatus.reverse().forEach((status:any) => {
				let statusCount;
				statusCount = {
					name: status.name,
					code: status.code,
					count: response[status.code]
				}
				this.statusListCount.push(statusCount);
				this.statusListCount.sort((a, b) => a.code !== b.code ? a.code < b.code ? -1 : 1 : 0);
	
			});
		}, this.errorHandlerService.handleError);
	}

	updateTaskStatus(successMessage: string, selectedItems: any[], action: string) {
		this.loaderMessagingService.showPageLoader(true);
		let taskEndpoint = "";
		if(selectedItems[0].groupType === TASK_GROUP_2 && (action === PRE_COMPLETE || action === COMPLETE)){
			taskEndpoint = updateWTBTaskStatusEndpoint;
		} else {
			taskEndpoint = updateTaskStatusEndpoint;
		}
		this.crudService
			.edit<any>(taskEndpoint, { tasks: selectedItems })
			.pipe(takeUntil(this.unsubscribe))
			.subscribe(response => {
				if (response) {
					this.dialogMessagingService.sendMessage({
						status: SUCCESS,
						value: successMessage.replace("?",
							`${selectedItems.length} ${selectedItems.length > 1 ? 'tasks were ' : 'task was'}`),
					});
					this.statusListCount = [];
					this.onSearch("");
				}
		}, this.errorHandlerService.handleError, this.handleCompletion);
	}

	createWorkbookTextbook(successMessage: string, tasks: any[]) {
		this.loaderMessagingService.showPageLoader(true);
		this.crudService
			.add<any>(createWorkbookTextbook, tasks[0])
			.pipe(takeUntil(this.unsubscribe))
			.subscribe(response => {
				if (response) {
					this.dialogMessagingService.sendMessage({
						status: SUCCESS,
						value: successMessage,
					});
					this.statusListCount = [];
					this.onSearch("");
				} else {
					this.dialogMessagingService.sendMessage({
						status: ERROR,
						value: "Unable to create workbook/textbook task.",
					});
				}
		}, this.errorHandlerService.handleError, this.handleCompletion);
	}

}