import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { ConfirmDialogComponent } from '../../../confirm-dialog/confirm-dialog.component';
import { saveCurriculumConfirmation } from 'src/app/core/constants/message.constant';
import { MatDialog } from '@angular/material';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Class } from 'src/app/core/models/class.model';
import { Curriculum } from 'src/app/core/models/curriculum.model';
import { CrudService } from 'src/app/core/services/data/crud.service';
import { ErrorHandlerService } from 'src/app/core/services/util/error-handler.service';
import { SAVE, PREV } from 'src/app/core/constants/configuration/common.constant';
import { getByCurriculumIdClassEndpoint } from 'src/app/core/constants/endpoints.constant';
import { LoaderMessagingService } from 'src/app/core/services/messaging/loader-messaging.service';

@Component({
	selector: 'curriculum-summary',
	templateUrl: './curriculum-summary.component.html',
	styleUrls: ['./curriculum-summary.component.scss']
})
export class CurriculumSummaryComponent implements OnInit, OnDestroy {

	@Output() changePage = new EventEmitter<any>();
	@Input() curriculum: Curriculum;
	@Input() referenceValues: any;
	@Input() isFirst: boolean;
	@Input() isLast: boolean;

	unsubscribe: Subject<any> = new Subject();
  classes: Class[];
	
	constructor(
		private errorHandlerService: ErrorHandlerService,
		private loaderMessagingService: LoaderMessagingService,
		private crudService: CrudService,
		public dialog: MatDialog,
	) { }

	ngOnInit() {
    	this.getClasses();
  	}

	ngOnDestroy(): void {
		this.unsubscribe.next();
		this.unsubscribe.complete();
	}

	getClasses(): void {
			this.loaderMessagingService.showPageLoader(true);
			if (this.curriculum && this.curriculum.id) {
				this.crudService
				.getAllBy<Class>(getByCurriculumIdClassEndpoint.concat(`?curriculumId=${this.curriculum.id}`))
				.pipe(takeUntil(this.unsubscribe))
				.subscribe(
					response => {
						this.classes = response;
					}, this.errorHandlerService.handleError);
			}
			this.loaderMessagingService.showPageLoader(false);
	}

	onClick = (label: string): void => {

		if (label === SAVE) {
			const dialogRef = this.dialog.open(ConfirmDialogComponent);
			dialogRef.componentInstance.confirmMessage = saveCurriculumConfirmation;
			dialogRef.afterClosed()
				.pipe(takeUntil(this.unsubscribe))
				.subscribe(result => {
					if (result) {
						this.changePage.emit(SAVE);
					}
				}, this.errorHandlerService.handleError);
		}

		if (label === PREV) {
			this.changePage.emit(PREV);
		}

	}

}
