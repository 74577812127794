import { Component, OnInit, Output, EventEmitter, ChangeDetectorRef, AfterViewInit, Input } from '@angular/core';
import { findIconDefinition } from '@fortawesome/fontawesome-svg-core';
import { faThumbsUp, IconDefinition, faBell, faCogs, faCheckCircle, faTimesCircle,faClock } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-order-statistics',
  templateUrl: './order-statistics.component.html',
  styleUrls: ['./order-statistics.component.scss']
})
export class OrderStatisticsComponent implements OnInit {

  @Output() filterChange = new EventEmitter();
  @Input() statusListCount: any;
  filterStatus = new Map<string, string>();
	approvedIcon: IconDefinition = faThumbsUp;
	processingIcon: IconDefinition = faCogs;
	preCompletedIcon: IconDefinition = faBell;
	completedIcon: IconDefinition = faCheckCircle;
  canceledIcon: IconDefinition = faTimesCircle;
  pendingIcon: IconDefinition = faClock;

  orderStats: any[] = [
    {name: 'pending',   label: 'Pending',    value: 0},
    {name: 'hstApproved',   label: 'HST Approved',    value: 0},
    {name: 'processing',    label: 'Processing',      value: 0},
    {name: 'preCompleted', label: 'Pre-Completed',  value: 0},
    {name: 'completed',     label: 'Completed',       value: 0},
    {name: 'cancelled',     label: 'Cancelled',       value: 0},
  ]

  constructor(
  ) { }

  ngOnInit() {
  }


  filter(value: string){
    this.filterStatus.set('status', value);
    this.filterChange.emit(this.filterStatus);
  }

}
