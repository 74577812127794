import { Component, OnInit } from '@angular/core';
import { HeaderMessagingService } from 'src/app/core/services/messaging/header-messaging.service';
import { modelModuleName } from 'src/app/core/constants/configuration/model-constants.config';
import { CREATE_MODULE_NAME } from 'src/app/core/constants/configuration/common.constant';
import { Model } from 'src/app/core/models/model.model';
import { allModelsEndpoint } from 'src/app/core/constants/endpoints.constant';
import { takeUntil } from 'rxjs/operators';
import { MODEL_PATH } from 'src/app/core/constants/routes.constant';
import { DialogBoxComponent } from 'src/app/shared/components/dialog-box/dialog-box.component';
import { dialogBoxSuccessTitle, createdSuccessModel } from 'src/app/core/constants/message.constant';
import { CrudService } from 'src/app/core/services/data/crud.service';
import { ErrorHandlerService } from 'src/app/core/services/util/error-handler.service';
import { LoaderMessagingService } from 'src/app/core/services/messaging/loader-messaging.service';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { settingsRoutingPermissions } from "src/app/core/constants/configuration/role-constants.config";
import { UserService } from 'src/app/core/services/util/user.service';

@Component({
  selector: 'app-model-create',
  templateUrl: './model-create.component.html',
  styleUrls: ['./model-create.component.scss']
})
export class ModelCreateComponent implements OnInit {

  unsubscribe: Subject<any> = new Subject();

  constructor(
		private router: Router,
    private crudService: CrudService,
		private headerMessagingService: HeaderMessagingService,
    private errorHandlerService: ErrorHandlerService,
    private dialog: MatDialog,
		private loaderMessagingService: LoaderMessagingService,
		private userService: UserService,
	) {
		this.userService.checkRolePermission(MODEL_PATH, settingsRoutingPermissions);
    this.headerMessagingService.setHeader(modelModuleName, CREATE_MODULE_NAME, false, null);
  }

  ngOnInit() {
  }

  ngOnDestroy(): void {
		this.unsubscribe.next();
		this.unsubscribe.complete();
	}

  onSaveModel($event: Model) {
		this.loaderMessagingService.showPageLoader(true);
		this.crudService
			.add<Model>(allModelsEndpoint, $event)
			.pipe(takeUntil(this.unsubscribe))
			.subscribe((response) => {
				this.router.navigate([MODEL_PATH, response.id]);
				const dialogRef = this.dialog.open(DialogBoxComponent);
				dialogRef.componentInstance.dialogTitle = dialogBoxSuccessTitle;
				dialogRef.componentInstance.contentMessage = createdSuccessModel;
			}, this.errorHandlerService.handleError, this.handleCompletion);
		
  }
  
  handleCompletion = (): void => {
		this.loaderMessagingService.showPageLoader(false);
	}

}
