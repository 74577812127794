import { Component, OnInit } from '@angular/core';
import { Subject } from "rxjs";
import { takeUntil } from 'rxjs/operators';
import { FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { FormUtil } from '../../../../core/services/util/form.util';
import { Page } from '../../../../core/models/page.model';
import { Resource } from '../../../../core/models/resource.model';
import { ASC } from '../../../../core/constants/configuration/common.constant';
import { CrudService } from '../../../../core/services/data/crud.service';
import { UserService } from '../../../../core/services/util/user.service';
import { QueryService } from '../../../../core/services/util/query.service';
import { ErrorHandlerService } from '../../../../core/services/util/error-handler.service';
import { DataCustomizerService } from '../../../../core/services/util/data-customizer.service';
import { HeaderMessagingService } from '../../../../core/services/messaging/header-messaging.service';
import { LoaderMessagingService } from '../../../../core/services/messaging/loader-messaging.service';
import { saveAs } from "file-saver";
import {
  allResourcesEndpoint,
  getProcessingStatusEndpoint,
  allCurriculumsEndpoint,
} from '../../../../core/constants/endpoints.constant';
import { baseServerUri, xlsxFileMediaType, fileNameBundle, downloadFileExtension, csvMediaType, csvFileExtension } from 'src/app/core/constants/configuration/config.constant';
import { FileService } from 'src/app/core/services/data/file.service';
import { formatDate } from '@angular/common';
import { Curriculum } from 'src/app/core/models/curriculum.model';
import { ConfirmDialogComponent } from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';
import { downloadCurriculumReportHeader, downloadCurriculumReportMessage } from 'src/app/core/constants/message.constant';

@Component({
  selector: 'app-download-curriculum-reports',
  templateUrl: './download-curriculum-reports.component.html',
  styleUrls: ['./download-curriculum-reports.component.scss']
})
export class DownloadCurriculumReportsComponent implements OnInit {

 
	unsubscribe: Subject<any> = new Subject();
  downloadOnlineAccountForm: FormGroup;
  reportTypeList: any[] = [];
  processingStatus: any[] = [];
  selectedStatus: any;
  fromDate: any;
  toDate: any;
  curriculums: any;
  field: any;
  isProcessing: boolean;
  delayTimer: NodeJS.Timer;
  timeout: number = 300;
  curriculum: any;
  selectedCurriculum: any;

	downloadIcon: IconDefinition = faDownload;

  constructor(
		private crudService: CrudService,
    private dataCustomizerService: DataCustomizerService,
    private dialog: MatDialog,
		private errorHandlerService: ErrorHandlerService,
		private formsUtil: FormUtil,
		private headerMessagingService: HeaderMessagingService,
		private loaderMessagingService: LoaderMessagingService,
    private queryService: QueryService,
    private userService: UserService,
    private downloadFileService: FileService,
    private formUtil: FormUtil,
  ) {
  }

  ngOnInit() {
		this.downloadOnlineAccountForm = this.formsUtil.createDownloadOnlineAccountForm();
		this.initParams();
  }

  initParams() {
		this.loaderMessagingService.showPageLoader(true);
    this.getReportTypeList();
    this.fromDate = new Date;
    this.downloadOnlineAccountForm.get("dateFrom").setValue(this.fromDate);
    this.toDate = new Date;
    this.downloadOnlineAccountForm.get("dateTo").setValue(this.toDate);
    this.clearValidator('onlineAccount');
    this.clearValidator('status');
  }

  onSelectStatus(value: any) {
    this.selectedStatus = value;
  }

  getReportTypeList(): void {
    this.reportTypeList.push("Order");
    this.reportTypeList.push("Count");
    this.handleCompletion();
  }

	handleCompletion = (): void => {
    this.loaderMessagingService.showPageLoader(false);
    this.isProcessing = false;
  }

  getReference(): any {
    return {
      resourceTypes: [],
      resourceGroups: [],
      onlineAccounts: [],
    };
  }

  onDownloadOnlineAccounts() {
    this.formatDates();

    this.validateUntouchedForms();

    if (this.downloadOnlineAccountForm.valid) {

      const params = new Map<String, String>();
      params.set("curriculumCode", this.selectedCurriculum);
      params.set("fromDate", this.fromDate + " 00:00:00");
      params.set("toDate", this.toDate + " 23:59:59");

      const dialogRef = this.dialog.open(ConfirmDialogComponent);
      dialogRef.componentInstance.confirmTitle = downloadCurriculumReportHeader;
      dialogRef.componentInstance.confirmMessage = downloadCurriculumReportMessage.replace("%",this.curriculum.name);
      dialogRef.afterClosed().subscribe(result => {
        if(result) this.download(params);
      });
    }
  }

  formatDates() {
    this.fromDate = this.downloadOnlineAccountForm.controls['dateFrom'].value;
    this.toDate = this.downloadOnlineAccountForm.controls['dateTo'].value;

    const format = "yyyy-MM-dd";
    this.fromDate = formatDate(this.fromDate, format, 'en-US');
    this.toDate = formatDate(this.toDate, format, 'en-US');
  }

  refineURI(query: String): String {
		return encodeURIComponent(String(query));
	}

  download(params: any): void {
    this.loaderMessagingService.showPageLoader(true);
    const endpoint = baseServerUri.concat("/resources/downloadCurriculumReport");
    let query = "";
    if (params.size > 0) params.forEach((value, key) => query = query.concat(`&${key}=${this.refineURI(value)}`));
    this.downloadFileService
      .getFile(endpoint.concat('?' + query))
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(
        response => {
          let date = new Date();
          const format = "yyyyMMdd_HHmm";
          const formattedDate = formatDate(date, format, 'en-US');
          const fileName = this.curriculum.name + " " + formattedDate;
          const blob = new Blob([response], { type: xlsxFileMediaType });
          saveAs(blob, fileName.concat(downloadFileExtension));
        },
        this.errorHandlerService.handleError,
        this.handleCompletion
      );
  }

  onCurriculumChange(keyword: string): void {

		this.field = "CURRICULUM";
		this.curriculums = [];
		this.isProcessing = false;
		
		clearTimeout(this.delayTimer);
		this.delayTimer = setTimeout(() => {
			this.isProcessing = true;
			setTimeout(() => this.findCurriculums(keyword.trim()), this.timeout);
		}, this.timeout);

  }
  
  findCurriculums(keyword: string): void {

		this.crudService
			.getAllBy<Curriculum>(`${allCurriculumsEndpoint}/get-by-code-or-name?keyword=${keyword}`)
			.pipe(takeUntil(this.unsubscribe))
			.subscribe(response => {

				if (response) {

					if (response.length > 0) {
						response.map(curriculum => this.curriculums.push({ code: curriculum.code, name: curriculum.name }));
					} else {
						this.downloadOnlineAccountForm.controls['curriculumCode'].setErrors({ 'notExists': true });
					}

				}

			}, (error) => {
				this.isProcessing = false;
				this.errorHandlerService.handleError(error)
			}, this.handleCompletion);

  }
  
  onCurriculumSelect(code: string): void {
		this.curriculum = {};
		this.curriculum.code = code;
		this.curriculum.name = this.curriculums.find(curr => curr.code === code).name;
    this.downloadOnlineAccountForm.get("curriculumCode").setValue(`${this.curriculum.name} (${this.curriculum.code})`);
    this.selectedCurriculum = code;

  }
  
  private validateUntouchedForms(): void {
		Object.keys(this.downloadOnlineAccountForm.controls).forEach(control => {
			this.downloadOnlineAccountForm.get(control).markAsTouched();
      this.downloadOnlineAccountForm.get(control).markAsDirty();
		});
  }

  clearValidator(control){
		this.downloadOnlineAccountForm.controls[control].setValidators([]);
		this.downloadOnlineAccountForm.controls[control].updateValueAndValidity();
	}


}
