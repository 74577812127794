import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormArray } from '@angular/forms';
import { BundleCurriculumForm } from 'src/app/core/models/form/bundle-curriculum-form.model';
import { FormUtil } from 'src/app/core/services/util/form.util';
import { Curriculum } from 'src/app/core/models/curriculum.model';
import { NEXT, PREV } from 'src/app/core/constants/configuration/common.constant';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CrudService } from 'src/app/core/services/data/crud.service';
import { ErrorHandlerService } from 'src/app/core/services/util/error-handler.service';
import { allCurriculumsEndpoint } from 'src/app/core/constants/endpoints.constant';
import { faPlus, faMinus } from "@fortawesome/free-solid-svg-icons";

@Component({
  selector: 'app-bundle-add-curriculum',
  templateUrl: './bundle-add-curriculum.component.html',
  styleUrls: ['./bundle-add-curriculum.component.scss']
})
export class BundleAddCurriculumComponent implements OnInit {

  @Output() getFormValue = new EventEmitter<any>();
  @Output() changePage = new EventEmitter<any>();
  @Output() rawSend: EventEmitter<BundleCurriculumForm> = new EventEmitter();
  @Input() bundleCurriculumFormValues: BundleCurriculumForm;
  @Input() forUpdate: boolean;
  @Input() isFirst: boolean;
  @Input() isLast: boolean;
  @Input() bundleTypes: any[];

  faPlus = faPlus;
  faMinus = faMinus;

  bundleCurriculumForm: FormGroup;
  curriculums: FormArray;
  isSingle: boolean = true;
  invalidCurriculums: boolean = false;
  curriculumsList: any[];
  delayTimer: NodeJS.Timer;
  timeout: number = 1000;
  isActive: boolean[] = [false, false];

  unsubscribe: Subject<any> = new Subject();

  constructor(private formUtil: FormUtil,
    private crudService: CrudService,
    private errorHandlerService: ErrorHandlerService,) {
    this.bundleCurriculumForm = formUtil.createBundleCurriculumForm();
    this.curriculums = this.bundleCurriculumForm.get('curriculums') as FormArray;
  }

  ngOnInit(): void {

    this.isSingle = true;
    this.bundleCurriculumForm.get('bundleType').setValue(this.bundleTypes[0].code);

    if (this.forUpdate || this.bundleCurriculumFormValues) {
      this.curriculums = this.bundleCurriculumForm.get('curriculums') as FormArray;

      while (this.curriculums.length > 0)
        this.curriculums.removeAt(0);

      this.bundleCurriculumFormValues.curriculums.forEach(curr => {
        this.curriculums.push(this.formUtil.createCurriculumsForm());
      });

      this.isSingle = this.curriculums.length === 1;
      this.initFormValues();
    }

  }

  onAddCurriculum() :void {
    this.curriculums.push(this.formUtil.createCurriculumsForm());
  }

  onMinusCurriculum(index: number) :void {
    this.curriculums.removeAt(index);
  }

  onCurriculumChange(keyword : string, index : number) : void {
    this.curriculumsList = [];

    if (!keyword || keyword.trim().length === 0) {
			this.curriculums.controls[index].markAsTouched();
			this.curriculums.controls[index].markAsDirty();
			return;
		};

    clearTimeout(this.delayTimer);
    this.delayTimer = setTimeout(() => {
      this.isActive[index] = true;
      setTimeout(() => this.getCurriculumList(keyword.trim(), index), this.timeout);
    }, this.timeout);
  }

  getCurriculumList(keyword: string, index : number) {
    this.crudService
      .getAllBy<Curriculum>(`${allCurriculumsEndpoint}/get-by-code-or-name?keyword=${encodeURIComponent(keyword)}`)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(response => {
        this.isActive[index] = false;
        if (response) {
          if (response.length > 0) {
            response.map(curriculum => this.curriculumsList.push({ code: curriculum.code, name: curriculum.name }));
          } else {
            this.curriculums.controls[index].get('curriculumCodeName').setErrors({ 'invalidCurriculum': true });
          }
        }
      },
        this.errorHandlerService.handleError,
    );
  }

  onCurriculumSelect(index: number, selectedCurriculumName: string) : void {
    this.curriculums = this.bundleCurriculumForm.get('curriculums') as FormArray;

    const selectedCurriculum = this.curriculumsList.find(curri => (curri.name + ' (' + curri.code + ')') === selectedCurriculumName);
    this.curriculums.controls[0].get('name').updateValueAndValidity();

    const id = selectedCurriculum.id;
    const code = selectedCurriculum.code;
    const classes = selectedCurriculum.classes;
    const name = selectedCurriculum.name;

    this.curriculums.controls[index].patchValue({ id, code, name, classes });
  }

  toggleType(isSingle: boolean): void {

    this.clearValidations();

    this.isSingle = isSingle;
    this.curriculums = this.bundleCurriculumForm.get('curriculums') as FormArray;

    if (isSingle) {
      while(this.curriculums.length !== 1) this.curriculums.removeAt(1);
    } else {
      if (this.curriculums.length < 2)
        this.curriculums.push(this.formUtil.createCurriculumsForm());
      this.isSingle = false;
    }

    this.bundleCurriculumForm.get('bundleType')
      .setValue(isSingle ? this.bundleTypes[0].code : this.bundleTypes[1].code);
    
  }

  onClick = (label: string): void => {

    if (label === PREV) {
      this.changePage.emit(label);
      this.rawSend.emit(this.bundleCurriculumForm.value);
    } else {

      this.curriculums = this.bundleCurriculumForm.get('curriculums') as FormArray;

      if (label === NEXT) {
        this.validateUntouchedForms();
        this.validateCurriMinClasses();
      }
      
      if (!this.isSingle) {
        this.invalidCurriculums = false;
        for(var i = 0; i < this.curriculums.length; i++) {
          for(var k = i + 1; k < this.curriculums.length; k++) {
            if(this.curriculums.controls[i].get('code').value === this.curriculums.controls[k].get('code').value) {
              this.curriculums.controls[k].get('curriculumCodeName').setErrors({'duplicateCode': true});
            } else {
              this.curriculums.controls[k].get('curriculumCodeName').updateValueAndValidity();
            }
          }
        }
      }

      if (this.curriculums.valid && this.bundleCurriculumForm.valid) {
        this.changePage.emit(label);
        this.getFormValue.emit(this.bundleCurriculumForm.value);
        this.bundleCurriculumForm.reset();
      }

    }

  }
    
  private initFormValues(): void {

    this.bundleCurriculumForm.patchValue(
      this.formUtil.initBundleCurriculumForm(this.bundleCurriculumFormValues));

  }

  validateUntouchedForms(): void {
    Object.keys(this.bundleCurriculumForm.controls).forEach(control => {
      this.bundleCurriculumForm.get(control).markAsTouched();
      this.bundleCurriculumForm.get(control).markAsDirty();
    });
    this.curriculums.controls.forEach(curriculumForm => {
      curriculumForm.get('name').markAsTouched();
      curriculumForm.get('name').markAsDirty();
      curriculumForm.get('minimumClasses').markAsTouched();
      curriculumForm.get('minimumClasses').markAsDirty();
      curriculumForm.get('curriculumCodeName').markAsTouched();
      curriculumForm.get('curriculumCodeName').markAsDirty();
    });
  }

  clearValidations(): void {
    Object.keys(this.bundleCurriculumForm.controls).forEach(control => {
      this.bundleCurriculumForm.get(control).markAsUntouched();
      this.bundleCurriculumForm.get(control).markAsUntouched();
    });

    if (this.curriculums && this.curriculums.length > 0) {

      this.curriculums.controls.forEach(curriculumForm => {
        curriculumForm.get('name').markAsUntouched();
        curriculumForm.get('name').markAsUntouched();
        curriculumForm.get('minimumClasses').markAsUntouched();
        curriculumForm.get('minimumClasses').markAsUntouched();
      });

    }

  }

  validateSelectedCurriculum(index: number) : void {
    this.curriculums = this.bundleCurriculumForm.get('curriculums') as FormArray;
    const curriculumName = this.curriculums.controls[index].get('name').value;

    this.crudService
    .getById<JSON>(allCurriculumsEndpoint.concat(`/exists-by-name?name=${encodeURIComponent(curriculumName)}`))
    .pipe(takeUntil(this.unsubscribe))
    .subscribe(
      (data: any) => {
        if (data) {
          if (data.exists) {
            this.curriculums.controls[index].get('curriculumCodeName').updateValueAndValidity();
          } else {
            this.curriculums.controls[index].get('curriculumCodeName').setErrors({'invalidCurriculum': true});
          }
        }
      },
      this.errorHandlerService.handleError,
    );
  }

  onBundleMinClassesChange(input: number): void {
    const maxClasses = this.bundleCurriculumForm.controls['maximumClasses'].value;

    if (input > maxClasses) {
      this.bundleCurriculumForm.controls['minimumClasses'].setErrors({'exceedRange': true});
      this.bundleCurriculumForm.controls['maximumClasses'].updateValueAndValidity();
    } else if (input <= maxClasses) {
      this.bundleCurriculumForm.controls['minimumClasses'].updateValueAndValidity();
      this.bundleCurriculumForm.controls['maximumClasses'].updateValueAndValidity();
    } 
  }

  onBundleMaxClassesChange(input: number): void {
    const minClasses = this.bundleCurriculumForm.controls['minimumClasses'].value;

    if (input < minClasses) {
      this.bundleCurriculumForm.controls['minimumClasses'].updateValueAndValidity();
      this.bundleCurriculumForm.controls['maximumClasses'].setErrors({'exceedRange': true});
    } else if (input >= minClasses) {
      this.bundleCurriculumForm.controls['minimumClasses'].updateValueAndValidity();
      this.bundleCurriculumForm.controls['maximumClasses'].updateValueAndValidity();
    } 

    this.validateCurriMinClasses();
  }

  onCurriMinClassesChange($event: any): void {
    this.validateCurriMinClasses();
  }

  validateCurriMinClasses(): void {
    let minClassSum = 0;
    for (let curriculum of this.curriculums.controls) {
      minClassSum+= curriculum.get('minimumClasses').value;
    }

    if (minClassSum > this.bundleCurriculumForm.get('maximumClasses').value && !this.isSingle) {
      for (let curriculum of this.curriculums.controls) {
        curriculum.get('minimumClasses').setErrors({'exceedRange': true});
      }
    }
    else {
      for (let curriculum of this.curriculums.controls) {
        curriculum.get('minimumClasses').updateValueAndValidity();
       }
    }
  }

}
