import { Component, OnInit, OnDestroy, HostListener } from "@angular/core";
import { Subject } from "rxjs";
import { AuthService } from "./core/services/data/auth.service";
import { takeUntil } from "rxjs/operators";
import { HeaderMessagingService } from "./core/services/messaging/header-messaging.service";

import { SUCCESS } from "src/app/core/constants/configuration/common.constant";
import { Router } from "@angular/router";
import { authorized, environmentUat, environmentProd } from "./core/constants/configuration/config.constant";
import { LOGIN_PATH } from "./core/constants/routes.constant";
import { LoginMessagingService } from "./core/services/messaging/login-messaging.service";
import { ErrorHandlerService } from "./core/services/util/error-handler.service";
import { MatDialog } from "@angular/material";
import { DialogBoxComponent } from "./shared/components/dialog-box/dialog-box.component";
import { dialogBoxErrorTitle, dialogBoxSuccessTitle } from "./core/constants/message.constant";
import { LoaderMessagingService } from "./core/services/messaging/loader-messaging.service";
import { DialogMessagingService } from "./core/services/messaging/dialog-messaging.service";
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { faPencilAlt } from "@fortawesome/free-solid-svg-icons";
import { FabButtonMessagingService } from "./core/services/messaging/fab-button-messaging.service";
import { UserService } from "./core/services/util/user.service";

@Component({
	selector: "app-root",
	templateUrl: "./app.component.html",
	styleUrls: ["./app.component.scss"]
})
export class AppComponent implements OnInit, OnDestroy {

	unsubscribe: Subject<any> = new Subject();
	isAuthenticated: boolean = false;
	innerWidth: number;
	isLoading: boolean = false;
	title: string = "";
	isMain: boolean = false;
	moduleName: string = "";
	routes: any[] = [];
	editIcon: IconDefinition = faPencilAlt;
	canEdit: boolean = false;
	path: string;
	id: number;

	constructor(
		private headerMessagingService: HeaderMessagingService,
		private loginMessagingService: LoginMessagingService,
		private authService: AuthService,
		private userService: UserService,
		private router: Router,
		private errorHandlerService: ErrorHandlerService,
		private loaderMessagingService: LoaderMessagingService,
		private dialogMessagingService: DialogMessagingService,
		private fabButtonMessagingService: FabButtonMessagingService,
		private dialog: MatDialog,
	) {
		this.subscribeToLoaderMessagingService();
		this.subscribeToLoginMessagingService();
		this.subscribeToErrorHandlerService();
		this.subscribeToDialogMessagingService();
		this.subscribeToHeaderMessagingService();
		this.subscribeToFabButtonMessagingService();
	}

	ngOnInit(): void {
		this.authService.checkAuthentication(hasToken => {
			if (hasToken) {
				this.isAuthenticated = true;
				this.checkSession();
			}
		});

		if (environmentUat || environmentProd) {
			if (location.protocol === 'http:') {
				window.location.href = location.href.replace('http', 'https');
			}
		} 
	}

	ngOnDestroy(): void {
		this.unsubscribe.next();
		this.unsubscribe.complete();
	}

	@HostListener("window:resize", ["$event"])
	onResize(event) {
		this.innerWidth = window.innerWidth;
	}

	onEdit(): void {
		this.router.navigate([this.path, this.id]);
	}

	private checkSession(): void {
		this.authService.getLoginUser()
			.pipe(takeUntil(this.unsubscribe))
			.subscribe(response => {
				if (!response) {
					this.isAuthenticated = false;
					this.router.navigateByUrl(LOGIN_PATH);
				}
			},
			this.errorHandlerService.handleError);
	}

	private subscribeToLoginMessagingService(): void {

		this.loginMessagingService.loginMessage$
			.pipe(takeUntil(this.unsubscribe))
			.subscribe(message => {
				this.isAuthenticated = message && message.trim() === authorized;
			}, this.errorHandlerService.handleError);

	}

	private subscribeToDialogMessagingService(): void {

		this.dialogMessagingService.dialogMessage$
			.pipe(takeUntil(this.unsubscribe))
			.subscribe(message => this.showMessageDialog(message), this.errorHandlerService.handleError);

	}

	private subscribeToErrorHandlerService(): void {

		this.errorHandlerService.errors$
			.pipe(takeUntil(this.unsubscribe))
			.subscribe(error => {

				this.handleError(error.message);

				if (error.status !== null && error.status === 401) {
					this.isAuthenticated = false;
					this.router.navigateByUrl(LOGIN_PATH);
				}

			});

	}

	private subscribeToHeaderMessagingService(): void {

		this.headerMessagingService.headerMessage$.subscribe(message => {
			this.isMain = message.isMain;
			this.title = message.title;
			this.moduleName = message.moduleName;
			this.routes = Object.assign([], message.routes ? message.routes.filter(route => this.userService.hasRole(route.roles)) : null);
		});

	}

	private subscribeToLoaderMessagingService(): void {

		this.loaderMessagingService.pageLoader$
			.pipe(takeUntil(this.unsubscribe))
			.subscribe(show => setTimeout(() => this.isLoading = show));

	}

	private subscribeToFabButtonMessagingService(): void {

		this.fabButtonMessagingService.fabButtonMessage$
			.pipe(takeUntil(this.unsubscribe))
			.subscribe(message => {
				this.canEdit = message.canEdit;
				this.path = message.path;
				this.id = message.id;
			});

	}

	private handleError(message: string): void {

		this.clearLoaders();

		const dialogRef = this.dialog.open(DialogBoxComponent);
		dialogRef.componentInstance.dialogTitle = dialogBoxErrorTitle;
		dialogRef.componentInstance.contentMessage = message;

	}

	private showMessageDialog(message: any): void {

		this.clearLoaders();

		const dialogRef = this.dialog.open(DialogBoxComponent);
		dialogRef.componentInstance.dialogTitle = message.status === SUCCESS ? dialogBoxSuccessTitle : dialogBoxErrorTitle;
		dialogRef.componentInstance.contentMessage = message.value;

	}

	private clearLoaders(): void {
		this.loaderMessagingService.showListLoader(false);
		this.loaderMessagingService.showPageLoader(false);
	}

}
