import { Component, OnInit, Output, Input, EventEmitter } from "@angular/core";
import { FormUtil } from "src/app/core/services/util/form.util";
import { ResourceMainForm } from "src/app/core/models/form/resource-main-form.model";
import { FormGroup, Validators, FormControl } from "@angular/forms";
import { NEXT } from "src/app/core/constants/configuration/common.constant";
import {
  allResourcesEndpoint,
  existsByName,
  existsByCode,
  searchByCodeModelEndpoint,
  allModelsEndpoint,
} from "src/app/core/constants/endpoints.constant";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { CrudService } from "src/app/core/services/data/crud.service";
import { ErrorHandlerService } from "src/app/core/services/util/error-handler.service";
import { LoaderMessagingService } from "src/app/core/services/messaging/loader-messaging.service";
import { ResourceGroupForm } from "src/app/core/models/form/resource-group-form-model";
import { Model } from "src/app/core/models/model.model";
import { RESOURCE_TYPE_CODE_2 } from "src/app/core/constants/configuration/resource-constants.config";

@Component({
  selector: "resource-main-form",
  templateUrl: "./resource-main-form.component.html",
  styleUrls: ["./resource-main-form.component.scss"]
})
export class ResourceMainFormComponent implements OnInit {
  @Output() getFormValue = new EventEmitter<any>();
  @Output() changePage = new EventEmitter<any>();
  @Output() getAssociation = new EventEmitter<boolean>();
  @Input() resourceFormValues: ResourceMainForm;
  @Input() resourceGroupFormValues: ResourceGroupForm[];
  @Input() resourceTypes: any[];
  @Input() onlineAccounts: any[];
  @Input() hasAssociations: boolean;
  @Input() forUpdate: boolean;
  @Input() isFirst: boolean;
  @Input() isLast: boolean;

  resourceForm: FormGroup;
  existsByCodeEndpoint: string = allResourcesEndpoint.concat(existsByCode);
  existsByNameEndpoint: string = allResourcesEndpoint.concat(existsByName);
  unsubscribe: Subject<any> = new Subject();
  codes: any[];
  modelList: any[];
  delayTimer: NodeJS.Timer;
  timeout: number = 300;
  isActive: boolean = false;
  modelName: string = "";

  constructor(
    private crudService: CrudService,
    private errorHandlerService: ErrorHandlerService,
    private formUtil: FormUtil,
    private loaderMessagingService: LoaderMessagingService,
  ) {
    this.resourceForm = formUtil.createResourceMainForm();
  }

  ngOnInit() {
    if (this.forUpdate || this.resourceFormValues) {
      if (!this.hasAssociations) {
        this.hasAssociations =
          this.resourceGroupFormValues &&
          this.resourceGroupFormValues.length > 0;
      }
      this.initFormValues();
    }
    this.setDefaultResourceType(
      this.resourceTypes
        .find(c => c.code === RESOURCE_TYPE_CODE_2)
        .code
    );
  }

  setDefaultResourceType(resourceType: string): void {
    this.resourceForm
      .get('type')
      .setValue(resourceType);
  }

  onClick = (label: string): void => {
    if (label === NEXT) {
      this.validateUntouchedForms();
      if (!this.resourceForm.valid) {
        return;
      } else {
        this.getFormValue.emit(this.resourceForm.value);
        this.getAssociation.emit(this.hasAssociations);
        this.changePage.emit(label);
        this.resourceForm.reset();
      }
    }
  };

  private initFormValues = (): void => {
    this.resourceForm.setValue(
      this.formUtil.initResourceMainForm(this.resourceFormValues)
    );
    this.modelName = this.resourceFormValues.name;
  };

  validateUntouchedForms(): void {
    Object.keys(this.resourceForm.controls).forEach(control => {
      this.resourceForm.get(control).markAsTouched();
      this.resourceForm.get(control).markAsDirty();
    });
  }

  getResourceType(value: string): string {
    return this.resourceTypes.find(account => account.code === value).name;
  }

  getOnlineAccountName(value: string): string {
    return this.onlineAccounts.find(account => account.code === value).name;
  }

  onOnlineAccountSelect(code: string, name: string): void {
    this.resourceForm.get("code").setValue(code);
    this.resourceForm.get("name").setValue(name);
  }

  // isbnKeyDown(value: any): void {
  //   if (/^[0-9]+$/.test(value)) {
  //     if (value.length > 2) setTimeout(this.searchModel(value), 500);
  //     let code = this.resourceForm.get("code");
  //     this.resourceForm.controls["physicalIdCode"].setErrors(
  //       code && code.value && code.value.length > 0 && value === code.value ? null :
  //         value.length > 0 ? { doesNotExist: true } : { required: true }
  //     );
  //   } else {
  //     let filteredValue = value.split('').filter(char => /^[0-9]+$/.test(char));
  //     this.resourceForm.get("physicalIdCode").setValue(filteredValue.join(''));
  //   }
  // }

  // onCodeSelect(selected: any, index: number): void {
  //   this.resourceForm.get("code").setValue(selected);
  //   this.resourceForm.controls["physicalIdCode"].setErrors(null);
  // }

  onGetResult(result: any): void {
    if (result.exists) {
      if (result.field === "name") {
        if (
          (this.resourceFormValues &&
            result.value !== this.resourceFormValues.name &&
            this.forUpdate) ||
          !this.forUpdate
        ) {
          this.resourceForm.controls["name"].setErrors({ nameExists: true });
        } else {
          this.resourceForm.controls["name"].updateValueAndValidity();
        }
      } else if (result.field === "onlineAccountType") {
        if (
          (this.resourceFormValues &&
            result.value !== this.resourceFormValues.code &&
            this.forUpdate) ||
          !this.forUpdate
        ) {
          this.resourceForm.controls["onlineAccountType"].setErrors({
            codeExists: true
          });
        } else {
          this.resourceForm.controls[
            "onlineAccountType"
          ].updateValueAndValidity();
        }
      }
    }
  }

  onAssociationChange(checked: boolean): void {
    this.hasAssociations = checked;
  }

  handleCompletion = (): void => {
    this.loaderMessagingService.showPageLoader(false);
  };

  onChangeNumber(type: string): void {
		const value: number = this.resourceForm.controls[type].value;
    const max = 999999999;
		if (value === null){
			this.resourceForm.controls[type].setValue("");
		} else if (value === -0) {
			this.resourceForm.controls[type].setValue(0);
    } else if (value > max) {
      this.resourceForm.controls[type].setValue(String(value).substring(0,9));
    } else if (value % 1 != 0 && String(value).split('.').slice(-1)[0].length > 2) {
      this.resourceForm.controls[type].setValue(value.toFixed(2));
    }
	}

  searchModel(searchParam: string): void {
    this.loaderMessagingService.showPageLoader(false);
    this.crudService
      .getById<any>(searchByCodeModelEndpoint.concat(`?KEYWORD=${searchParam}`))
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(
        response => {
          this.codes = response.map(model => model.code);
        },
        this.errorHandlerService.handleError,
        this.handleCompletion
      );
  }

  // onModelChange(keyword : string) : void {
  //   this.modelList = [];

  //   if (!keyword || keyword.trim().length === 0) {
	// 		this.resourceForm.markAsTouched();
	// 		this.resourceForm.markAsDirty();
	// 		return;
	// 	};

  //   clearTimeout(this.delayTimer);
  //   this.delayTimer = setTimeout(() => {
	// 	this.isActive = true;
  //     setTimeout(() => this.getModelList(keyword.trim()), this.timeout);
  //   }, this.timeout);
  // }
  
  // getModelList(keyword: string) {
  //   this.crudService
  //     .getAllBy<Model>(`${allModelsEndpoint}/get-by-code-or-name?keyword=${encodeURIComponent(keyword)}`)
  //     .pipe(takeUntil(this.unsubscribe))
  //     .subscribe(response => {
	// 	this.isActive = false;
  //       if (response) {
  //         if (response.length > 0) {
  //           response.map(model => this.modelList.push(model));
  //         } else {
  //           if(keyword.length !== 0){
  //             this.resourceForm.get('physicalIdCode').setErrors({ 'doesNotExist': true });
  //           }
  //         }
  //       }
  //     },
  //       this.errorHandlerService.handleError,
  //   );
  // }

  // onModelSelect(selectedModelCode: string) : void {
  //   const selectedModel = this.modelList.find(model => model.code === selectedModelCode);
  //     if(selectedModel){
  //       this.crudService
  //       .getAllBy<any>(`${allResourcesEndpoint}/exists-by-code?code=${encodeURIComponent(selectedModel.code)}`)
  //       .pipe(takeUntil(this.unsubscribe))
  //       .subscribe((response: any) => {
  //         if(response.exists) {
  //           this.resourceForm.get('physicalIdCode').setErrors({ 'duplicateCode': true });
  //           this.resourceForm.get("name").setValue(selectedModel.name);
  //         } else {
  //           this.resourceForm.get("code").setValue(selectedModel.code);
  //           this.resourceForm.get("name").setValue(selectedModel.name);
  //           this.resourceForm.controls['physicalIdCode'].updateValueAndValidity();
  //         }
            
  //       },
  //         this.errorHandlerService.handleError,
  //     );
  //   } else {
  //     this.resourceForm.get('physicalIdCode').setErrors({ 'doesNotExist': true });
  //     this.resourceForm.get("name").setValue("");
  //   }
  // }
  // onFocusOut(event) {
  //   this.onModelSelect(event);
  // }
}
