import { Component, OnInit, Input, ChangeDetectorRef, AfterViewInit, Output } from '@angular/core';
import { DataProperties } from 'src/app/core/models/data-properties.model';
import { Subject } from 'rxjs';
import { LoaderMessagingService } from 'src/app/core/services/messaging/loader-messaging.service';
import {
  orderDataProperties,
  PROCESSING_STATUS_1,
  PROCESSING_STATUS_2,
  PROCESSING_STATUS_3,
  PROCESSING_STATUS_4,
  PROCESSING_STATUS_5,
  PROCESSING_STATUS_6,
  PROCESSING_STATUS_7,
  PROCESSING_STATUS_8,
} from 'src/app/core/constants/configuration/order-constants.config';
import { takeUntil } from 'rxjs/operators';
import { faPencilAlt, faSort, faSortUp, faSortDown } from "@fortawesome/free-solid-svg-icons";
import { Router } from '@angular/router';
import { ORDERS_PATH, ORDER_DEPOSIT_PATH, ORDER_CURRICULUM_FEE_PATH, OLD_ORDERS_PATH } from 'src/app/core/constants/routes.constant';
import { EventEmitter } from '@angular/core';
import { DESC, ASC } from 'src/app/core/constants/configuration/common.constant';
import SortUtil from 'src/app/shared/components/utils/sort-utils/sort-util';
import { UserService } from '../../../../core/services/util/user.service';
import { ADMIN, SPECIALIST, SUPERVISOR } from 'src/app/core/constants/configuration/role-constants.config';

@Component({
  selector: 'app-order-list',
  templateUrl: './order-list.component.html',
  styleUrls: ['./order-list.component.scss']
})
export class OrderListComponent implements OnInit {

  @Input() items: any[] = [];
  @Input() sortedBy: string;
  @Input() sortOrder: string;
  @Input() orderStatus: any[] = [];
  @Output() toggleSortOnClick : EventEmitter<any> = new EventEmitter();
  @Output() bulkAction: EventEmitter<any> = new EventEmitter();
  dataProperties: DataProperties[];
  headersLength: number;
  isLoading: boolean = false;
  unsubscribe: Subject<any> = new Subject();
  faPencilAlt = faPencilAlt;
  faSortDefaultIcon = faSort;
  faSortIcon = faSort;
  faSort = faSort;
  updatedSortOrder: string;
  checkedItems: any[] = [];

  constructor(
    private loaderMessagingService: LoaderMessagingService,
    private router: Router,
    private userService: UserService,
  ) {
    this.subscribeToLoaderMessagingService();
  }

  // How can I bind the updated value of parent's field to child without this loc?
  ngOnChanges() {
    this.updatedSortOrder = this.sortOrder;
    this.faSortIcon = SortUtil.toggleSortIcon(this.updatedSortOrder);
  }

  ngOnInit() {
    this.dataProperties = orderDataProperties;
    console.log("dataProperties",this.dataProperties)
    this.headersLength = this.dataProperties.length;
  }

  onLinkClick(item: any, $event: any, id: string): void {
    if(item.code.includes("-DEPOSIT")) {
      this.router.navigate([ORDER_DEPOSIT_PATH, id]);
    } else if(item.code.includes("-FEE")) {
      this.router.navigate([ORDER_CURRICULUM_FEE_PATH, id]);
    } else if(item.syCode == "2019-2020"){
      this.router.navigate([OLD_ORDERS_PATH, id]);
    } else {
      this.router.navigate([ORDERS_PATH, id]);
    }
  }

  private subscribeToLoaderMessagingService(): void {
    this.loaderMessagingService.listLoader$
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(show => setTimeout(() => (this.isLoading = show)));
  }

  getStatusClass(statusName: string): string {
    let currentStatus = this.orderStatus.find(status => status.name === statusName);
    if (currentStatus) {
      switch (currentStatus.code) {
        case PROCESSING_STATUS_1: return "approved status"
        case PROCESSING_STATUS_2: return "processing status"
        case PROCESSING_STATUS_3: return "pre-completed status"
        case PROCESSING_STATUS_4: return "completed status"
        case PROCESSING_STATUS_5: return "canceled status"
        case PROCESSING_STATUS_6: return "refunded status"
        case PROCESSING_STATUS_7: return "adjusted status"
        case PROCESSING_STATUS_8: return "pending"
        default: return "status"
      }
    }
  }

  toggleSort(property: string) {
    if (property === this.sortedBy) {
      if (this.sortOrder === DESC) {
        this.faSort = faSortUp;
        this.sortOrder = ASC;
      } else if (this.sortOrder === ASC) {
        this.faSort = faSortDown;
        this.sortOrder = DESC;
      } else if (this.faSort === faSortDown) {
        this.faSort = faSort;
        this.sortedBy = "";
        this.sortOrder = ASC;
      }
    } else {
      this.sortedBy = property;
      if (!this.sortOrder && property === 'name') {
        this.faSort =  faSortDown;
        this.sortOrder = DESC;
      } else {
        this.faSort =  faSortUp;
        this.sortOrder = ASC;
      }
    }

    this.toggleSortOnClick.emit({ sortedBy: this.sortedBy, sortOrder: this.sortOrder });
  }
  onCheckedAll(): void {
    this.items.forEach(item => {
      if (this.isPending(item)) {
            item.checked = !this.isAllChecked;
          }
    });
    if (!this.isAllChecked) {
        this.checkedItems = Object.assign([], this.items.filter(item =>
          this.isPending(item)));
    } else {
        this.checkedItems = [];
    }
}

onChecked(currentItem: any): void {
    if (this.checkedItems.find(item => item.code === currentItem.code)) {
        this.items.find(item => item.code === currentItem.code).checked = false;
        this.checkedItems = this.checkedItems.filter(item => item.code !== currentItem.code);
    } else {
        this.items.find(item => item.code === currentItem.code).checked = true;
        this.checkedItems.push(currentItem);
    }
}
get isAllChecked(): boolean {
  return this.items.length > 0 && this.checkedItems.length > 0 &&
      this.checkedItems.length === this.items.filter(item =>
        this.isPending(item)).length;
}
isHSTApproved(item: any): boolean {
  //let cancelledStatus = this.processingStatus.find(status => status.code === PROCESSING_STATUS_5);
  return item.status == "HST Approved";
}
isProcessing(item: any): boolean {
  //let cancelledStatus = this.processingStatus.find(status => status.code === PROCESSING_STATUS_5);
  return item.status == "Processing";
}
isPreCompleted(item: any): boolean {
  //let cancelledStatus = this.processingStatus.find(status => status.code === PROCESSING_STATUS_5);
  return item.status == "Pre-Completed";
}
isCompleted(item: any): boolean {
  //let cancelledStatus = this.processingStatus.find(status => status.code === PROCESSING_STATUS_5);
  return item.status == "Completed";
}
isCancelled(item: any): boolean {
  //let cancelledStatus = this.processingStatus.find(status => status.code === PROCESSING_STATUS_5);
  return item.status == "Cancelled";
}
isPending(item: any): boolean {
  //let cancelledStatus = this.processingStatus.find(status => status.code === PROCESSING_STATUS_5);
  return item.status == "Pending";
}

onBulkAction(action: string): void {
  this.bulkAction.emit({
      action,
      items: Object.assign([], this.checkedItems),
  });
}
get canApprovePendingOrders(): boolean {
  return this.checkedItems.length >= 1 && this.checkedItems.filter(item => this.isPending(item)).length >= 1
      && this.userService.hasRole([ADMIN, SUPERVISOR, SPECIALIST]);
}
}
