import { Component, OnInit, Input } from '@angular/core';
import { UploadMessageService } from 'src/app/core/services/messaging/upload-message.service';

@Component({
  selector: 'upload-sidebar',
  templateUrl: './upload-sidebar.component.html',
  styleUrls: ['./upload-sidebar.component.scss']
})
export class UploadSidebarComponent implements OnInit {
  @Input()
  navSide: any[];
  toggle: number = 0;
  
  constructor(private uploadMessageService: UploadMessageService) { }

  ngOnInit() {
  }

  clickEvent(name){
    this.toggle = this.navSide.indexOf(name);
    this.uploadMessageService.sendMessage(this.toggle);
  }
}
