import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { StudentDetails } from "src/app/core/models/eos/student-details.model";
import { TeacherDetails } from "src/app/core/models/eos/teacher-details.model";
import { Order } from "src/app/core/models/order/order.model";
import { InputUtilitiesModule } from 'angular-bootstrap-md';

@Component({
  selector: 'app-order-summary',
  templateUrl: './order-summary.component.html',
  styleUrls: ['./order-summary.component.scss']
})
export class OrderSummaryComponent implements OnInit {

  @Input() studentDetails: StudentDetails;
  @Input() teacherDetails: TeacherDetails;
  @Input() shippingDetails: any = {};
  @Input() selectedShippingDetail: any = {};
  @Input() orderDetails: any;
  @Input() processingStatus: any[] = [];
  @Output() getNewOrderDetails = new EventEmitter<any>();
  @Output() onGetNewShippingDetails = new EventEmitter<any>();
  @Output() getTotalAmount = new EventEmitter<any>();

  type1 : string = "ORDER_ITEM_TYPE_2";
  type2 : string = "ORDER_ITEM_TYPE_3";

  constructor() { }

  ngOnInit() {
  }

  onGetNewOrderDetails($event) {
    this.getNewOrderDetails.emit($event);
  }

  onGetTotalAmount($event) {
    this.getTotalAmount.emit($event);
  }

  getNewShippingDetails($event) {
    this.onGetNewShippingDetails.emit($event);
  }

  formatAmount(value: number): string {
    if(!value) return "$" + 0.00;
    return "$" + value.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')
  }

}
