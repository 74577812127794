import { Component, OnInit, Input } from '@angular/core';
import * as moment from "moment";
import { UserService } from 'src/app/core/services/util/user.service';
import { ADMIN, SUPERVISOR, SPECIALIST } from 'src/app/core/constants/configuration/role-constants.config';

@Component({
  selector: 'app-order-student-details',
  templateUrl: './order-student-details.component.html',
  styleUrls: ['./order-student-details.component.scss']
})
export class OrderStudentDetailsComponent implements OnInit {

  @Input() studentDetails: any;
  @Input() teacherDetails: any;

  constructor(private userService: UserService) { }

  ngOnInit() {
  }

  formatDate(date: any): string {
    return date ? moment(date).format('MMM DD, YYYY') : "";
  }

  formatAmount(value: number): string {
    return value < 0 ? `$ (${(value * -1).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')})`
      : `$ ${value.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}`
  }

  get canViewAvailableBalance(): boolean {
    return this.userService.hasRole([ADMIN, SUPERVISOR, SPECIALIST]);
  }

}
