import { Component, OnInit } from '@angular/core';
import { CrudService } from 'src/app/core/services/data/crud.service';
import { ErrorHandlerService } from 'src/app/core/services/util/error-handler.service';
import { HeaderMessagingService } from 'src/app/core/services/messaging/header-messaging.service';
import { LoaderMessagingService } from 'src/app/core/services/messaging/loader-messaging.service';
import { ActivatedRoute } from '@angular/router';
import { ORDER, PROCESSING_STATUS_1, PROCESSING_STATUS_2, PROCESSING_STATUS_3, PROCESSING_STATUS_4, PROCESSING_STATUS_5, PROCESSING_STATUS_6, PROCESSING_STATUS_7 } from 'src/app/core/constants/configuration/order-constants.config';
import { VIEW_MODULE_NAME } from 'src/app/core/constants/configuration/common.constant';
import { getOrderDepositItemsEndpoint, getProcessingStatusEndpoint, allStudentsEndpoint } from 'src/app/core/constants/endpoints.constant';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { StudentInfo } from 'src/app/core/models/eos/student-info.model';

@Component({
  selector: 'order-deposit-view',
  templateUrl: './order-deposit-view.component.html',
  styleUrls: ['./order-deposit-view.component.scss']
})
export class OrderDepositViewComponent implements OnInit {

  unsubscribe: Subject<any> = new Subject();
  orderDepositDetails: any;
  orderDetails: any;
  processingStatus: any;
  studentInfo: any;
  orderList: any;

  constructor(    
    private crudService: CrudService,
		private errorHandlerService: ErrorHandlerService,
		private headerMessagingService: HeaderMessagingService,
		private loaderMessagingService: LoaderMessagingService,
		private route: ActivatedRoute,) {
      this.headerMessagingService.setHeader(ORDER, VIEW_MODULE_NAME, false, null);
    }

  ngOnInit() {
    this.loaderMessagingService.showPageLoader(true);
    this.getOrderDepositDetails(this.route.snapshot.paramMap.get('id'));
  }

  getOrderDepositDetails(id: string) {
    this.crudService.getById<any>(getOrderDepositItemsEndpoint.concat(`/${id}`))
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(response => {
        this.orderDepositDetails = response;
        this.orderDetails = response.orderDeposit;
        this.orderList = response.orderDepositList;
        this.getStudentById(this.orderDetails.studentId);
      }, this.errorHandlerService.handleError);
  }

  getStudentById(id: string): void {
    this.crudService.getById<StudentInfo>(allStudentsEndpoint.concat(`/${id}`))
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(response => {
        this.studentInfo = response;
        this.getProcessingStatus();
      }, this.errorHandlerService.handleError);
  }

  getStatusName(statusCode: string) {
    const statusName: any = this.processingStatus ? this.processingStatus.find(status => status.code === statusCode) : "";
    return statusName ? statusName.name : "";
  }

  getProcessingStatus(): void {
		this.crudService
			.getById<any>(getProcessingStatusEndpoint)
			.pipe(takeUntil(this.unsubscribe))
			.subscribe(response => {
        this.processingStatus = response;
				this.processingStatus.sort((a, b) => b.code.localeCompare(a.code));
			}, this.errorHandlerService.handleError, this.handleCompletion);
  }

  handleCompletion = (): void => {
		this.loaderMessagingService.showPageLoader(false);
  };

  getStatusClass(statusCode: string): string {
    switch (statusCode) {
      case PROCESSING_STATUS_1: return "approved status"
      case PROCESSING_STATUS_2: return "processing status"
      case PROCESSING_STATUS_3: return "pre-completed status"
      case PROCESSING_STATUS_4: return "completed status"
      case PROCESSING_STATUS_5: return "canceled status"
      case PROCESSING_STATUS_6: return "refunded status"
      case PROCESSING_STATUS_7: return "adjusted status"
      default: return "status"
    }
  }

  formatAmount(value: number): string {
    return "$" + value.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')
  }

}
