import { LOGIN_PATH } from "./../core/constants/routes.constant"

import { Routes } from '@angular/router';

export const appRoutes: Routes = [

  {
    path: '',
    redirectTo: LOGIN_PATH,
    pathMatch: 'full',
  },

  {
    path: '**',
    redirectTo: LOGIN_PATH,
  },

];
