import { Component, OnInit } from "@angular/core";
import { HeaderMessagingService } from "src/app/core/services/messaging/header-messaging.service";
import {
  homeModuleName,
  pageSizeOptions,
  ASC,
  fullYearFormat,
  OPEN,
  CLOSE,
  SY_CODE
} from "src/app/core/constants/configuration/common.constant";
import { DataCustomizerService } from "src/app/core/services/util/data-customizer.service";
import { QueryService } from "src/app/core/services/util/query.service";
import { ErrorHandlerService } from "src/app/core/services/util/error-handler.service";
import { CrudService } from "src/app/core/services/data/crud.service";
import { Router } from "@angular/router";
import { FileService } from "src/app/core/services/data/file.service";
import { MatDialog } from "@angular/material";
import { Resource } from "src/app/core/models/resource.model";
import { faFilter } from "@fortawesome/free-solid-svg-icons";
import { Subject } from "rxjs";
import { Page } from "src/app/core/models/page.model";
import {
  allResourcesEndpoint,
  downloadResourcesEndpoint,
  getAllSchoolYearsEndpoint,
  getResourceTypesEndpoint,
  getResourceOnlineAccountsEndpoint,
  getResourceGroupsEndpoint,
  getActiveSchoolYearEndpoint
} from "src/app/core/constants/endpoints.constant";
import { takeUntil } from "rxjs/operators";
import {
  downloadFileExtension,
  baseServerUri,
  xlsxFileMediaType,
  fileNameResource
} from "src/app/core/constants/configuration/config.constant";
import { DialogBoxComponent } from "src/app/shared/components/dialog-box/dialog-box.component";
import {
  dialogBoxErrorTitle,
  downloadErrorNoDataResource,
  downloadConfirmationResource
} from "src/app/core/constants/message.constant";
import { ConfirmDialogComponent } from "src/app/shared/components/confirm-dialog/confirm-dialog.component";
import { saveAs } from "file-saver";
import { LoaderMessagingService } from "src/app/core/services/messaging/loader-messaging.service";
import { FilterModalComponent } from "src/app/shared/components/filter-modal/filter-modal.component";
import * as moment from "moment";
import { DataProperties } from "src/app/core/models/data-properties.model";
import { changeSize } from "src/app/core/constants/animations.constant";
import { resourceDataProperties } from "src/app/core/constants/configuration/resource-constants.config";
import { homeRoutingPermissions } from "src/app/core/constants/configuration/role-constants.config";
import { RESOURCE_CREATE_PATH, RESOURCE_UPDATE_PATH, RESOURCES_PATH } from "src/app/core/constants/routes.constant";
import { UserService } from "src/app/core/services/util/user.service";
import { FilterService } from "src/app/core/services/util/filter.service";

@Component({
  selector: "app-resource-list",
  templateUrl: "./resource-list.component.html",
  styleUrls: ["./resource-list.component.scss"],
  animations: [changeSize]
})
export class ResourceListComponent implements OnInit {
  unsubscribe: Subject<any> = new Subject();
  items: any[] = [];
  pageSize: number = pageSizeOptions[0];
  pageIndex: number = 0;
  faFilter = faFilter;
  filters: Map<string, string> = new Map<string, string>();
  itemsCount: number;
  keyword: string = "";
  sortOrder: string;
  sortedBy: string;
  trigger: string = CLOSE;
  isType1: boolean = false;
  resources: Page<Resource>;
  filterLabels: string[] = [];
  clearFilters: boolean = true;
  schoolYears: any[] = [];
  selectedSyId: number;
  schoolYear: string;
  resourceDataProperties: DataProperties[] = resourceDataProperties;
  resourceTypes: any[];
  resourceGroups: any[];
  onlineAccounts: any[];
	filtersKey: string = "resourceFilters";

  constructor(
    private crudService: CrudService,
    private errorHandlerService: ErrorHandlerService,
    private headerMessagingService: HeaderMessagingService,
    private queryService: QueryService,
    private dataCustomizerService: DataCustomizerService,
    private downloadFileService: FileService,
    private loaderMessagingService: LoaderMessagingService,
    private router: Router,
    private dialog: MatDialog,
    private userService: UserService,
    private filterService: FilterService,
	) {
		this.userService.checkRolePermission(RESOURCES_PATH, homeRoutingPermissions);
    this.headerMessagingService.setHeader(homeModuleName, "", true, homeRoutingPermissions);
    let resourceFilters = this.filterService.getFilter(this.filtersKey);
		if (resourceFilters) {
			this.keyword = resourceFilters.keyword || "";
			this.filters = resourceFilters.filters ? this.filterService.objectToMap(resourceFilters.filters) : new Map<string, string>();
		}
  }

  ngOnInit() {
    this.loaderMessagingService.showPageLoader(true);
    this.getResourceTypes();
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  initParams(): void {
    this.sortedBy = this.resourceDataProperties[1].property;
    this.sortOrder = ASC;
    let yearNow: string = moment(new Date().valueOf()).format(fullYearFormat);
    this.setSchoolYear(null);
  }

  onSearch(keyword: string): void {
    this.keyword = keyword;
    this.pageIndex = 0;
    this.search();
  }

  search(): void {
    this.loaderMessagingService.showListLoader(true);
    this.items = [];
    this.filterService.setFilter(this.filtersKey, this.keyword, this.filters);

    this.crudService
      .getAll<Resource>(this.createSearchEndpoint())
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(
        this.handleSearchResponse,
        this.errorHandlerService.handleError,
        this.handleCompletion
      );
  }

  createSearchEndpoint(): string {
    return allResourcesEndpoint.concat(
      this.queryService.buildResourceSearchQuery(
        this.pageIndex,
        this.pageSize,
        this.sortedBy,
        this.sortOrder,
        this.keyword,
        this.filters,
        this.getReference()
      )
    );
  }

  handleSearchResponse = (response: Page<Resource>): void => {
    if (response) {
      this.itemsCount = response.totalElements;
      response.content.forEach((resource: Resource) =>
        this.items.push(
          this.dataCustomizerService.formatResourceDetailsDisplay(
            resource,
            true,
            this.getReference()
          )
        )
      );
    }
  };

  handleCompletion = (): void => {
    this.loaderMessagingService.showListLoader(false);
    this.loaderMessagingService.showPageLoader(false);
  };

  onPageChange($event: any): void {
    this.pageIndex = $event.pageIndex;
    this.pageSize = $event.pageSize;
    this.search();
  }

  onLinkClick(id: string): void {
    this.router.navigate([RESOURCES_PATH, id]);
  }

  onClickAdd() {
    this.router.navigateByUrl(RESOURCE_CREATE_PATH);
  }

  download(): void {
    this.loaderMessagingService.showPageLoader(true);
    const endpoint = baseServerUri.concat(downloadResourcesEndpoint);
    this.downloadFileService
      .getFile(endpoint)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(
        response => {
          const blob = new Blob([response], { type: xlsxFileMediaType });
          saveAs(blob, fileNameResource.concat(downloadFileExtension));
        },
        this.errorHandlerService.handleError,
        this.handleCompletion
      );
  }

  openConfirmationDialog(): void {
    if (this.itemsCount === 0 || !this.itemsCount) {
      const dialogRef = this.dialog.open(DialogBoxComponent);
      dialogRef.componentInstance.dialogTitle = dialogBoxErrorTitle;
      dialogRef.componentInstance.contentMessage = downloadErrorNoDataResource;
    } else if (this.itemsCount > 0) {
      const dialogRef = this.dialog.open(ConfirmDialogComponent);
      dialogRef.componentInstance.confirmMessage = downloadConfirmationResource;
      dialogRef
        .afterClosed()
        .pipe(takeUntil(this.unsubscribe))
        .subscribe(result => {
          if (result) {
            this.download();
          }
        }, this.errorHandlerService.handleError);
    }
  }

  filterOnClick() {
    if (!this.isType1) {
      this.trigger = this.trigger === CLOSE ? OPEN : CLOSE;
    } else {
      this.trigger = CLOSE;
      let data = [];

      this.resourceDataProperties.forEach(property => {
        if (property.filterable)
          data.push({
            label: property.label,
            prop: property.property,
            value: this.filters.get(property.property) || ""
          });
      });

      const modalStyle = { width: "30%" };
      const dialogRef = this.dialog.open(FilterModalComponent, {
        ...modalStyle,
        data
      });

      dialogRef.afterClosed().subscribe(filters => {
        if (filters) this.filterOnChange(filters);
      });
    }
  }

  filterOnChange(filters: Map<string, string>) {
    this.filters = new Map<string, string>(filters);
    this.setFilterLabels();
    this.pageIndex = 0;
    this.search();
  }

  onToggleSort($event: any) {
    this.sortedBy = $event.sortedBy;
    this.sortOrder = $event.sortOrder;
    this.search();
  }

  onEditClick(id: string): void {
    this.router.navigate([RESOURCE_UPDATE_PATH, id]);
  }

  onChangeFilterType(): void {
    this.isType1 = !this.isType1;
  }

  setFilterLabels(): void {
    this.filterLabels = [];

    this.filters.forEach((value, key) =>
      this.filterLabels.push(
        `${
          this.resourceDataProperties.find(
            property => property.property === key
          ).label
        }: ${value}`
      )
    );

    this.filters.set(SY_CODE, this.schoolYear);

    this.clearFilters = this.filters.size === 1;

    const index: number = this.filterLabels.findIndex(label =>
      label.includes("School Year:")
    );
    if (index !== -1) this.filterLabels.splice(index, 1);

    this.filterLabels.splice(0, 0, `School Year: ${this.schoolYear}`);
  }

  onClearFilters(): void {
    this.clearFilters = true;
    this.keyword = "";
    this.filters.clear();
    this.filterOnChange(new Map<string, string>());
  }

  getSchoolYears(): void {
    this.crudService
      .getById<any>(getAllSchoolYearsEndpoint)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(response => {
        this.schoolYears = response;
        this.schoolYears.sort((a, b) =>
          b.schoolYear.localeCompare(a.schoolYear)
        );
        this.initParams();
      }, this.errorHandlerService.handleError);
  }

  setSchoolYear(schoolYear: any): void {
		this.selectedSyId = 0;
		if(schoolYear != undefined || schoolYear != null) {
		  this.selectedSyId = schoolYear.id;
		  this.schoolYear = schoolYear.schoolYear;
		  if (this.filters.has(SY_CODE)) this.filters.delete(SY_CODE);
		  this.setFilterLabels();
		  this.search();
		} else {
		  this.crudService
		  .getById<any>(getActiveSchoolYearEndpoint)
		  .pipe(takeUntil(this.unsubscribe))
				.subscribe(response => {
					if (response) {
				  this.schoolYear = response.schoolYear;
			  this.selectedSyId = this.schoolYears.find(sy =>
				String(sy.schoolYear).trim() === this.schoolYear.trim()).id;
				
			  if (this.filters.has(SY_CODE)) this.filters.delete(SY_CODE);
			  this.setFilterLabels();
			  this.search();
					}
			}, this.errorHandlerService.handleError);
		}
	}

  getResourceTypes(): void {
    this.crudService
      .getById<any>(getResourceTypesEndpoint)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(response => {
        this.resourceTypes = response;
        this.resourceTypes.sort((a, b) => a.code.localeCompare(b.code));
        this.getResourceGroups();
      }, this.errorHandlerService.handleError);
  }

  getResourceGroups(): void {
    this.crudService
      .getById<any>(getResourceGroupsEndpoint)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(response => {
        this.resourceGroups = response;
        this.resourceGroups.sort((a, b) => a.code.localeCompare(b.code));
        this.getOnlineAccounts();
      }, this.errorHandlerService.handleError);
  }

  getOnlineAccounts(): void {
    this.crudService
      .getById<any>(getResourceOnlineAccountsEndpoint)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(response => {
        this.onlineAccounts = response;
        this.onlineAccounts.sort((a, b) => a.code.localeCompare(b.code));
        this.getSchoolYears();
      }, this.errorHandlerService.handleError);
  }

  getReference(): any {
    return {
      resourceTypes: this.resourceTypes,
      resourceGroups: this.resourceGroups,
      onlineAccounts: this.onlineAccounts
    };
  }
}
