import { Component, OnInit } from "@angular/core";
import { HeaderMessagingService } from "src/app/core/services/messaging/header-messaging.service";
import {
  pageSizeOptions,
  ASC,
  settingsModuleName,
  CLOSE,
  OPEN,
  STATUS_1,
  ERROR,
  SUCCESS
} from "src/app/core/constants/configuration/common.constant";
import { DataCustomizerService } from "src/app/core/services/util/data-customizer.service";
import { QueryService } from "src/app/core/services/util/query.service";
import { ErrorHandlerService } from "src/app/core/services/util/error-handler.service";
import { CrudService } from "src/app/core/services/data/crud.service";
import { Router } from "@angular/router";
import { MatDialog } from "@angular/material";
import { Hqt } from "src/app/core/models/hqt.model";
import { faFilter } from "@fortawesome/free-solid-svg-icons";
import { Subject, Observable } from "rxjs";
import { Page } from "src/app/core/models/page.model";
import {
  allHqtEndpoint, allClassesEndpoint
} from "src/app/core/constants/endpoints.constant";
import { takeUntil } from "rxjs/operators";
import { LoaderMessagingService } from "src/app/core/services/messaging/loader-messaging.service";
import { FilterModalComponent } from "src/app/shared/components/filter-modal/filter-modal.component";
import { DataProperties } from "src/app/core/models/data-properties.model";
import { HqtDetailsDialogComponent } from "../hqt-details-dialog/hqt-details-dialog.component";
import { changeSize } from "src/app/core/constants/animations.constant";
import { Class } from "src/app/core/models/class.model";
import { DialogMessagingService } from "src/app/core/services/messaging/dialog-messaging.service";
import { deleteErrorHqt, deleteHqtConfirmation } from "src/app/core/constants/message.constant";
import { ConfirmDialogComponent } from "src/app/shared/components/confirm-dialog/confirm-dialog.component";
import { hqtDataProperties } from "src/app/core/constants/configuration/hqt-constants.config";
import { settingsRoutingPermissions } from "src/app/core/constants/configuration/role-constants.config";
import { HQT_CREATE_PATH, HQT_UPDATE_PATH, HQTS_PATH } from "src/app/core/constants/routes.constant";
import { UserService } from "src/app/core/services/util/user.service";
import { FilterService } from "src/app/core/services/util/filter.service";

@Component({
  selector: "app-hqt-list",
  templateUrl: "./hqt-list.component.html",
  styleUrls: ["./hqt-list.component.scss"],
  animations: [changeSize]
})
export class HqtListComponent implements OnInit {

  unsubscribe: Subject<any> = new Subject();
  items: any[] = [];
  model: Page<Hqt>;
  pageSize: number = pageSizeOptions[0];
  pageIndex: number = 0;
  faFilter = faFilter;
  filters: Map<string, string> = new Map<string, string>();
  itemsCount: number;
  keyword: string = "";
  sortOrder: string;
  sortedBy: string;
  trigger: string = CLOSE;
  isType1: boolean = false;
  filterLabels: string[] = [];
  clearFilters: boolean = true;
  hqtDataProperties: DataProperties[] = hqtDataProperties;
  filtersKey: string = "hqtFilters";

  constructor(
    private crudService: CrudService,
    private errorHandlerService: ErrorHandlerService,
    private headerMessagingService: HeaderMessagingService,
    private queryService: QueryService,
    private dataCustomizerService: DataCustomizerService,
    private loaderMessagingService: LoaderMessagingService,
    private dialogMessagingService: DialogMessagingService,
    private router: Router,
    private dialog: MatDialog,
    private userService: UserService,
    private filterService: FilterService,
	) {
		this.userService.checkRolePermission(HQTS_PATH, settingsRoutingPermissions);
    this.headerMessagingService.setHeader(settingsModuleName, "", true, settingsRoutingPermissions);
    let hqtFilters = this.filterService.getFilter(this.filtersKey);
		if (hqtFilters) {
			this.keyword = hqtFilters.keyword || "";
			this.filters = hqtFilters.filters ? this.filterService.objectToMap(hqtFilters.filters) : new Map<string, string>();
		}
	}

  ngOnInit() {
    this.loaderMessagingService.showPageLoader(true);
    this.initParams();
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  initParams(): void {
    this.sortedBy = this.hqtDataProperties[0].property;
    this.sortOrder = ASC;
    this.search();
  }

  onSearch(keyword: string): void {
    this.keyword = keyword;
    this.pageIndex = 0;
    this.search();
  }

  search(): void {

    this.loaderMessagingService.showListLoader(true);
    this.items = [];
    this.filterService.setFilter(this.filtersKey, this.keyword, this.filters);

    this.crudService
      .getAll<Hqt>(
        allHqtEndpoint.concat(this.queryService.buildHqtSearchQuery(
            this.pageIndex, this.pageSize, this.sortedBy, this.sortOrder, this.keyword, this.filters)))
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(response => {

        if (response) {
            this.itemsCount = response.totalElements;
            response.content.forEach(hqt => this.items.push(this.dataCustomizerService.customizeHqtListDisplay(hqt)));
          }

        },
        this.errorHandlerService.handleError,
        this.handleCompletion
      );
  }

  handleCompletion = (): void => {
    this.loaderMessagingService.showListLoader(false);
    this.loaderMessagingService.showPageLoader(false);
  };

  onPageChange($event: any): void {
    this.pageIndex = $event.pageIndex;
    this.pageSize = $event.pageSize;
    this.search();
  }

  findHqt(id: string): Observable<Hqt> {

    return this.crudService
      .getById<Hqt>(allHqtEndpoint.concat(`/${id}`))
      .pipe(takeUntil(this.unsubscribe));

    }

  openDetails(id: string, customizedData: any[], totalClasses: number): void {

    this.findHqt(id)
      .subscribe(response => {
        if (response) {

          const dialogRef = this.dialog.open(HqtDetailsDialogComponent, {
            width: "70%",
            data: { hqt: response, customizedData, totalClasses }
          });
      
          dialogRef.afterClosed().subscribe();

        }
      }, this.errorHandlerService.handleError, this.handleCompletion);
    
  }

  onLinkClick(id: string): void {
    this.router.navigate([HQTS_PATH, id]);
  }

  deleteHqt(id: string): void {
    this.loaderMessagingService.showPageLoader(true);
    this.crudService
      .delete(allHqtEndpoint.concat(`/${id}`))
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(response =>{
        this.dialogMessagingService.sendMessage({ status: SUCCESS, value: response });
        this.search();
      },
      this.errorHandlerService.handleError, this.handleCompletion);
  }

  onDeleteItem(id: string): void {

    const email: string = String(this.items.find(item => item.id === id).email);

    this.findHqt(id).subscribe(response => {

      if (response.status === STATUS_1) {
        this.dialogMessagingService.sendMessage({ status: ERROR, value: deleteErrorHqt })
      } else {
        const dialogRef = this.dialog.open(ConfirmDialogComponent);
        dialogRef.componentInstance.confirmMessage = deleteHqtConfirmation.replace("%", `(${email})`);
        dialogRef.afterClosed()
          .pipe(takeUntil(this.unsubscribe))
          .subscribe(result => {
            if (result) this.deleteHqt(id);
          }, this.errorHandlerService.handleError);
      }

    }, this.errorHandlerService.handleError, this.handleCompletion);

  }

  onClickAdd() {
    this.router.navigateByUrl(HQT_CREATE_PATH);
  }

  getFilterValue(prop: String, value: String): String {
    if (value && value.trim().length > 0 && prop.trim().toLowerCase() === "status") {
      return value === STATUS_1 ? "Active" : "Inactive";
    }
    return value;
  }

  filterOnClick() {

    if (!this.isType1) {
      this.trigger = this.trigger === CLOSE ? OPEN : CLOSE;
    } else {
      this.trigger = CLOSE;
      let data = [];

      this.hqtDataProperties.forEach(property => {

        if (property.filterable)
          data.push({
            label: property.label,
            prop: property.property,
            value: this.getFilterValue(property.property, this.filters.get(property.property) || "")
          });

      });

      const modalStyle = { width: "30%" };
      const dialogRef = this.dialog.open(FilterModalComponent, { ...modalStyle, data });

      dialogRef.afterClosed().subscribe(filters => {
        if (filters) this.filterOnChange(filters);
      });
    }
  }

  filterOnChange(filters: Map<string, string>) {
    this.filters = new Map<string, string>(filters);
    this.setFilterLabels();
    this.pageIndex = 0;
    this.search();
  }

  onToggleSort($event: any) {
    this.sortedBy = $event.sortedBy;
    this.sortOrder = $event.sortOrder;
    this.search();
  }

  onEditClick(id: string): void {
    this.router.navigate([HQT_UPDATE_PATH, id]);
  }

  onChangeFilterType(): void {
    this.isType1 = !this.isType1;
  }

  setFilterLabels(): void {

    this.filterLabels = [];

    this.filters.forEach((value, key) =>
      this.filterLabels.push(`${this.hqtDataProperties.find(property => property.property === key).label}: ${value}`));

    this.clearFilters = this.filters.size === 0;

  }

  onClearFilters(): void {
    this.clearFilters = true;
    this.keyword = "";
    this.filters.clear();
    this.filterOnChange(new Map<string, string>());
  }

}
