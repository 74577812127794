import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { orderTxnHistoryDataProperties } from 'src/app/core/constants/configuration/order-constants.config';
import { TxnHistoryModel } from 'src/app/core/models/order/txn-history-model';
import { pageSizeOptions } from 'src/app/core/constants/configuration/common.constant';
import { PaginationDto } from '../../../../core/models/dto/pagination-model';
import { SortDto } from '../../../../core/models/dto/sort-dto';
import { faFilter } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-txn-history',
  templateUrl: './txn-history.component.html',
  styleUrls: ['./txn-history.component.scss']
})
export class TxnHistoryComponent implements OnInit {
  dataProperties = orderTxnHistoryDataProperties;
  childTxnHistoryItems: TxnHistoryModel[];
  txnhistoryItemsCount: number;
  @Input() set txnHistoryItems(value) {
    this.childTxnHistoryItems = value;
  }
  @Input() set txnHistoryItemsCount(value) {
    this.txnhistoryItemsCount = value;
  }
  @Input() itemType: string;
  pageSize: number = pageSizeOptions[0];
  @Input() pageIndex: number;
  keyword: string;
  @Output() pageOnChangeEventEmitter = new EventEmitter<PaginationDto>();
  @Output() sortOnChangeEventEmitter = new EventEmitter<SortDto>();
  @Output() searchOnChangeEventEmitter = new EventEmitter<string>();
  faFilter = faFilter;
  clearFilters: boolean = true;
  @Input() orderDetails: any;
  constructor() { }

  ngOnInit() {
    this.keyword = "";
    this.pageIndex = 0;

  }

  onPageChange(page: PaginationDto) {
    this.pageOnChangeEventEmitter.emit(page);
  }

  onToggleSort(value: SortDto) {
    this.sortOnChangeEventEmitter.emit(value);
  }

  onSearch(keyword: string) {
    this.searchOnChangeEventEmitter.emit(keyword);
  }

}
