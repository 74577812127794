import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { HeaderMessagingService } from 'src/app/core/services/messaging/header-messaging.service';
import { takeUntil } from 'rxjs/operators';
import { sideNavLabelHqt, HQT } from 'src/app/core/constants/configuration/hqt-constants.config';
import { UPDATE_MODULE_NAME } from 'src/app/core/constants/configuration/common.constant';
import { settingsRoutingPermissions } from "src/app/core/constants/configuration/role-constants.config";
import { HQTS_PATH } from "src/app/core/constants/routes.constant";
import { UserService } from "src/app/core/services/util/user.service";

@Component({
  selector: 'app-hqt-update',
  templateUrl: './hqt-update.component.html',
  styleUrls: ['./hqt-update.component.scss']
})
export class HqtUpdateComponent implements OnInit {

  unsubscribe: Subject<any> = new Subject();
  activeIndex: number = 0;
  sideNavLabels: string[] = [];
  hqtId: number;

  constructor(
    private route: ActivatedRoute,
    private headerMessagingService: HeaderMessagingService,
    private userService: UserService,
	) {
		this.userService.checkRolePermission(HQTS_PATH, settingsRoutingPermissions);
    this.headerMessagingService.setHeader(HQT, UPDATE_MODULE_NAME, false, null);
    this.sideNavLabels = sideNavLabelHqt;
  }

  ngOnInit(): void {
    this.initHqt();
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  initHqt(): void {
    this.route.params
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(params => this.hqtId = +params["id"]);
  }

}
