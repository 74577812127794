import {
  Component,
  EventEmitter,
  OnInit,
  Input,
  Output,
  OnDestroy
} from "@angular/core";
import {
  DESC,
  ASC,
} from "src/app/core/constants/configuration/common.constant";
import {
  faSort,
  faSortUp,
  faSortDown,
  faPencilAlt,
  faTrashAlt,
  faTimes
} from "@fortawesome/free-solid-svg-icons";
import { LoaderMessagingService } from "src/app/core/services/messaging/loader-messaging.service";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { DataProperties } from "src/app/core/models/data-properties.model";
import { curriculumTab, curriculumDataProperties } from "src/app/core/constants/configuration/curriculum-constants.config";
import { classTab, classDataProperties } from "src/app/core/constants/configuration/class-constants.config";
import { bundleTab, bundleDataProperties } from "src/app/core/constants/configuration/bundle-constants.config";
import { resourceTab, resourceDataProperties } from "src/app/core/constants/configuration/resource-constants.config";
import { hqtTab, hqtDataProperties } from "src/app/core/constants/configuration/hqt-constants.config";
import { modelTab, modelDataProperties } from "src/app/core/constants/configuration/model-constants.config";
import { forEach } from "@angular/router/src/utils/collection";
import { assetDataProperties, assetTab, orderTxnHistoryTab, orderItemTxnHistoryTab } from "src/app/core/constants/configuration/asset-constants.config";
import { orderTxnHistoryDataProperties, PROCESSING_STATUS_1, PROCESSING_STATUS_2, PROCESSING_STATUS_3, PROCESSING_STATUS_4, PROCESSING_STATUS_5, PROCESSING_STATUS_6, PROCESSING_STATUS_7,PROCESSING_STATUS_8, orderItemTxnHistoryDataProperties } from "src/app/core/constants/configuration/order-constants.config";

@Component({
  selector: "app-list",
  templateUrl: "./list.component.html",
  styleUrls: ["./list.component.scss"]
})
export class ListComponent implements OnInit, OnDestroy {

  @Input()
  type: string;

  @Input()
  includeAction: boolean;

  @Input()
  items: any[] = [];

  @Output()
  linkClick: EventEmitter<string> = new EventEmitter();

  @Output()
  classLinkClick: EventEmitter<string> = new EventEmitter();

  @Output()
  editClick: EventEmitter<string> = new EventEmitter();

  @Output()
  editClassClick: EventEmitter<string> = new EventEmitter();

  @Output()
  deleteItem: EventEmitter<string> = new EventEmitter();

  @Output()
  toggleSorting: EventEmitter<any> = new EventEmitter();

  unsubscribe: Subject<any> = new Subject();
  headersLength: number;
  sortedBy: string;
  sortOrder: string;
  faSortIcon = faSort;
  faSort = faSort;
  faPencilAlt = faPencilAlt;
  faTrashAlt = faTrashAlt;
  faTimes = faTimes;
  isLoading: boolean = false;
  dataProperties: DataProperties[];

  constructor(private loaderMessagingService: LoaderMessagingService) {
    this.subscribeToLoaderMessagingService();
  }

  ngOnInit(): void {
    switch (this.type) {

      case curriculumTab: {
        this.dataProperties = curriculumDataProperties;
        break;
      }

      case classTab: {
        this.dataProperties = classDataProperties;
        break;
      }

      case bundleTab: {
        this.dataProperties = bundleDataProperties;
        break;
      }

      case resourceTab: {
        this.dataProperties = resourceDataProperties;
        break;
      }

      case hqtTab: {
        this.dataProperties = hqtDataProperties;
        break;
      }

      case modelTab: {
        this.dataProperties = modelDataProperties;
        break;
      }

      case assetTab: {
        this.dataProperties = assetDataProperties;
        break;
      }

      case orderTxnHistoryTab: {
        this.dataProperties = orderItemTxnHistoryDataProperties;
        break;
      }

      case orderItemTxnHistoryTab: {
        this.dataProperties = orderItemTxnHistoryDataProperties;
        break;
      }
    }

    this.headersLength = this.includeAction ? 1 : 0;

    this.dataProperties.forEach(prop => {
      if (prop.property === 'name' && this.type !== hqtTab) {
        this.headersLength += 3;
      } else if (prop.property === 'status') {
        this.headersLength += 1;
      } else {
        this.headersLength += 2;
      }
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  onLinkClick($event: any, id: string): void {
    this.linkClick.emit(id);
  }

  onClassLinkClick($event: any, className: string): void {
    this.classLinkClick.emit(className);
  }

  onDeleteItem(id: string): void {
    this.deleteItem.emit(id);
  }

  toggleSort(property: string) {
    if (property === this.sortedBy) {
      if (this.sortOrder === DESC) {
        this.faSort = faSortUp;
        this.sortOrder = ASC;
      } else if (this.sortOrder === ASC) {
        this.faSort = faSortDown;
        this.sortOrder = DESC;
      } else if (this.faSort === faSortDown) {
        this.faSort = faSort;
        this.sortedBy = "";
        this.sortOrder = ASC;
      }
    } else {
      this.sortedBy = property;
      if (!this.sortOrder && property === 'name') {
        this.faSort =  faSortDown;
        this.sortOrder = DESC;
      } else {
        this.faSort =  faSortUp;
        this.sortOrder = ASC;
      }
    }

    this.toggleSorting.emit({ sortedBy: this.sortedBy, sortOrder: this.sortOrder });
  }

  onEditClick($event: any, id: string) : void {
    this.editClick.emit(id);
  }

  onEditClassClick($event: any, id: string) : void {
    this.editClassClick.emit(id);
  }

  isDeleteEnable(type: any) : boolean {
    let isDeleteEnable = false;

    if(type === 'HQT' || type === 'MODEL' || type === 'ASSET') {
      isDeleteEnable = true;
    }

    return isDeleteEnable;
  }

  getColSpan(property: any) : number {
    let colSpan = 0;

    switch(property) {
      case 'availableCount':
      case 'checkOutCount':
      case 'forDisposalCount':
      case 'total':
      case 'lostCount':
        colSpan = 1;
        break;
      case 'name':
        colSpan = 3;
        break;
      default:
        colSpan = 2;
        break;
    }

    return colSpan;
  }

  hasTooltipAndEllipses(property: string): boolean {
    let list = ['name', 'curriculumName', 'hqtUsername', 'curriculumList', 'modelName', 'description', 'scopeName', 'modifiedBy'];
    if (this.type === modelTab) list.push('code');
    return list.includes(property);
  }

  private subscribeToLoaderMessagingService(): void {
    this.loaderMessagingService.listLoader$
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(show => setTimeout(() => (this.isLoading = show)));
  }

  // Get scss class of the item-col: ex. processing, approved, etc.
  getStatusClass(statusName: string): string {
    if (statusName) {
      switch (statusName) {
        case PROCESSING_STATUS_1: return "approved"
        case PROCESSING_STATUS_2: return "processing"
        case PROCESSING_STATUS_3: return "pre-completed"
        case PROCESSING_STATUS_4: return "completed"
        case PROCESSING_STATUS_5: return "canceled"
        case PROCESSING_STATUS_6: return "refunded"
        case PROCESSING_STATUS_7: return "adjusted"
        default: return "status"
      }
    }
  }

  getTxnHistoryStatusValue(statusName: string): string {
    if (statusName) {
      switch (statusName) {
        case PROCESSING_STATUS_1: return "Hst Approved"
        case PROCESSING_STATUS_2: return "Processing"
        case PROCESSING_STATUS_3: return "Pre-completed"
        case PROCESSING_STATUS_4: return "Completed"
        case PROCESSING_STATUS_5: return "Canceled"
        case PROCESSING_STATUS_6: return "Refunded"
        case PROCESSING_STATUS_7: return "Adjusted"
        case PROCESSING_STATUS_8: return "Pending"
        default: return statusName
      }
    }
  }

  isTxnHistoryField(type: string) : boolean {
    return type == orderItemTxnHistoryTab || type == orderTxnHistoryTab;
  }
}
