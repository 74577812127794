import { DataProperties } from "../../models/data-properties.model";

export const modelModuleName: string = "MODEL";
export const modelTab: string = "MODEL";
export const sideNavLabelModel: string[] = ["Main Form", "Summary"];

export const modelDataProperties: DataProperties[] = [
    {
        label: "Model ID or ISBN",
        property: "code",
        sortable: true,
        clickable: true,
        filterable: true,
        autocomplete: false,
        endpoint: ""
    },
    {
        label: "Model Name",
        property: "name",
        sortable: true,
        clickable: false,
        filterable: true,
        autocomplete: false,
        endpoint: ""
    },
    {
        label: "Total",
        property: "total",
        sortable: true,
        clickable: false,
        filterable: false,
        autocomplete: false,
        endpoint: ""
    },
    {
        label: "Available",
        property: "availableCount",
        sortable: true,
        clickable: false,
        filterable: false,
        autocomplete: false,
        endpoint: ""
    },
    {
        label: "Checked Out",
        property: "checkOutCount",
        sortable: true,
        clickable: false,
        filterable: false,
        autocomplete: false,
        endpoint: ""
    },
    {
        label: "For Disposal",
        property: "forDisposalCount",
        sortable: true,
        clickable: false,
        filterable: false,
        autocomplete: false,
        endpoint: ""
    },
    {
        label: "Lost",
        property: "lostCount",
        sortable: true,
        clickable: false,
        filterable: false,
        autocomplete: false,
        endpoint: ""
    }
];
