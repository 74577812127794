import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { Class } from 'src/app/core/models/class.model';
import { Asset } from 'src/app/core/models/asset.model';
import { faCopy, IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { LoaderMessagingService } from "src/app/core/services/messaging/loader-messaging.service";

@Component({
  selector: 'asset-details',
  templateUrl: './asset-details.component.html',
  styleUrls: ['./asset-details.component.scss']
})
export class AssetDetailsComponent implements OnInit {


	@Output() linkClink: EventEmitter<number> = new EventEmitter();
	@Input() asset: any;
	@Input() classes: Class[];
	@Input() referenceValues: any;
	@Input() forUpdate: boolean;

	faCopy: IconDefinition = faCopy;

	mainDetails: any;

	constructor(
		private loaderMessagingService: LoaderMessagingService,
	) { 
	}
		
	ngOnInit() {
		this.loaderMessagingService.showPageLoader(true);
		const { code, name, createdBy, createdDate, modifiedBy, modifiedDate, status, description, loanedTo, item, itemModel, itemId, cosOrderId, scope } = this.asset;
			this.mainDetails = { code, name, status, createdBy, createdDate, modifiedBy, modifiedDate, description, loanedTo, item, itemModel, itemId, cosOrderId, scope };
		this.handleCompletion();
	}

	onViewClass(id: number): void {
		this.linkClink.emit(id);
	}

	copyToClipboard(item): void {
		let listener = (e: ClipboardEvent) => {
			e.clipboardData.setData('text/plain', (item));
			e.preventDefault();
		};

		document.addEventListener('copy', listener);
		document.execCommand('copy');
		document.removeEventListener('copy', listener);
	}

	private handleCompletion = (): void => {
		this.loaderMessagingService.showPageLoader(false);
	}

}
