import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subject, BehaviorSubject } from "rxjs";
import { takeUntil } from 'rxjs/operators';
import { CrudService } from "src/app/core/services/data/crud.service";
import { LoaderMessagingService } from 'src/app/core/services/messaging/loader-messaging.service';
import { ErrorHandlerService } from 'src/app/core/services/util/error-handler.service';
import { HeaderMessagingService } from 'src/app/core/services/messaging/header-messaging.service';
import {
  STUDENT,
  studentBooksListDataProperties,
  studentBundlesListDataProperties,
  studentClassesListDataProperties,
  studentOnlineAccountsListDataProperties,
  refundListDataProperties,
} from 'src/app/core/constants/configuration/student-constants.config';
import { VIEW_MODULE_NAME, SY_CODE } from 'src/app/core/constants/configuration/common.constant';
import { StudentInfo } from "src/app/core/models/eos/student-info.model";
import {
  allStudentsEndpoint,
  getProcessingStatusEndpoint,
  getShippingDetailsEndpoint,
  getStudentOrderedClasses,
  getRefundListPerStudentEndpoint,
  allModelsEndpoint,
  getAllSchoolYearsEndpoint,
  getActiveSchoolYearEndpoint,
  getStudentOrderedWorkbooks,
  getStudentOrderedOtherBooks,
  getStudentOrderedInventoryBooks,
  getStudentOrderedBundle,
  getStudentOldOrderedItems,
  getStudentItemsByItemTypeEndpoint,
  getStudentOldItemsByItemTypeEndpoint,
  oldModelsEndpoint,
  getAllStudentOrderedWorkbooks, getStudentOrderedOnlineAccountEndpoint
} from 'src/app/core/constants/endpoints.constant';
import { STUDENTS_PATH } from 'src/app/core/constants/routes.constant';
import { homeRoutingPermissions } from 'src/app/core/constants/configuration/role-constants.config';
import { UserService } from "src/app/core/services/util/user.service";
import { GuardianShippingDetails } from 'src/app/core/models/eos/guardian-shipping-details.model';
import { DataCustomizerService } from 'src/app/core/services/util/data-customizer.service';
import {
  ORDER_ITEM_TYPE_2,
  ORDER_ITEM_TYPE_3,
  ORDER_ITEM_TYPE_4,
} from 'src/app/core/constants/configuration/order-constants.config';
import {
  RESOURCE_TYPE_CODE_1,
  RESOURCE_TYPE_CODE_2,
} from 'src/app/core/constants/configuration/resource-constants.config';
import { Asset } from 'src/app/core/models/asset.model';
import { Model } from 'src/app/core/models/model.model';

@Component({
  selector: 'app-student-view',
  templateUrl: './student-view.component.html',
  styleUrls: ['./student-view.component.scss']
})
export class StudentViewComponent implements OnInit {

	unsubscribe: Subject<any> = new Subject();
  studentInfo: StudentInfo;
  shippingDetails: any[] = [];
  processingStatus: any[] = [];

  // Class Fields --START--
  classes: any[] = [];
  classesTitle: string = "Classes";
  classesPage: number = 0;
  classesSize: number = 10;
  classesCount: number = 0;

  withdrawnClasses: any[] = [];
  withdrawnClassesTitle: string = "Withdrawn Classes";
  withdrawnClassesPage: number = 0;
  withdrawnClassesSize: number = 10;
  withdrawnClassesCount: number = 0;
  // Class Fields --END--

  bundles: any[] = [];
  bundlesTitle: string = "Bundles";
  bundlesPage: number = 0;
  bundlesSize: number = 10;
  bundlesCount: number = 0;

  //Books Fields --START--
  books: any[] = [];
  invBooks: any[] = [];
  otherBooks: any[] = [];
  booksTitle: string = "Workbooks/Text Books";
  otherBooksTitle: string = "Other Books";
  invBooksTitle: string = "Assigned Books";
  booksPage: number = 0;
  booksSize: number = 10;
  booksCount: number = 0;
  otherBooksPage: number = 0;
  otherBooksSize: number = 10;
  otherBooksCount: number = 0;
  invBooksPage: number = 0;
  invBooksSize: number = 10;
  invBooksCount: number = 0;
  //Books Fields --END--

  onlineAccounts: any[] = []
  onlineAccountsTitle: string = "Online Accounts";
  onlineAccountsPage: number = 0;
  onlineAccountsSize: number = 10;
  onlineAccountsCount: number = 0;
  classesDataProperties: any[] = studentClassesListDataProperties;
  bundlesDataProperties: any[] = studentBundlesListDataProperties;
  booksDataProperties: any[] = studentBooksListDataProperties;
  onlineAccountsDataProperties: any[] = studentOnlineAccountsListDataProperties;
  refundedListTitle: string = "Refunded List";
  refundedListPage: number = 0;
  refundedListSize: number = 10;
  refundedListCount: number = 0;
  refundedListDataProperties: any[] = refundListDataProperties;
  refundedList: any[] = [];
  isDoneSubject = new BehaviorSubject<boolean>(false);
  @Input() keyword: string = '';
  otherBookKeyword: string = '';
  invBookKeyword: string = '';
  
  schoolYears: any[] = [];
  selectedSyId: number;
  schoolYear: string;
  filters: Map<string, string> = new Map<string, string>();
  filterLabels: string[] = [];

  constructor(
    private crudService: CrudService,
    private dataCustomizerService: DataCustomizerService,
		private errorHandlerService: ErrorHandlerService,
		private headerMessagingService: HeaderMessagingService,
		private loaderMessagingService: LoaderMessagingService,
		private route: ActivatedRoute,
    private userService: UserService,
	) {
		this.userService.checkRolePermission(STUDENTS_PATH, homeRoutingPermissions);
    this.headerMessagingService.setHeader(STUDENT, VIEW_MODULE_NAME, false, null);
  }

  ngOnInit() { 
    this.loaderMessagingService.showPageLoader(true);
    this.getSchoolYears();
  }

  // SY FILTER -- START

  getSchoolYears(): void {
    this.crudService
      .getById<any>(getAllSchoolYearsEndpoint)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(response => {
        this.schoolYears = response;
        this.schoolYears.sort((a, b) => b.schoolYear.localeCompare(a.schoolYear));
        this.setSchoolYear(null);
      }, this.errorHandlerService.handleError);
  }

  setSchoolYear(schoolYear: any): void {
    this.selectedSyId = 0;
    if(schoolYear != undefined || schoolYear != null) {
      this.selectedSyId = schoolYear.id;
      this.schoolYear = schoolYear.schoolYear;
      if (this.filters.has(SY_CODE)) this.filters.delete(SY_CODE);
      if(this.schoolYear === '2019-2020') {
        this.getOldClassItems();
      } else { 
        this.getClassItems();
      }
    } else {
      this.crudService
      .getById<any>(getActiveSchoolYearEndpoint)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(response => {
        if (response) {
          this.schoolYear = response.schoolYear;
          this.selectedSyId = this.schoolYears.find(sy =>
            String(sy.schoolYear).trim() === this.schoolYear.trim()).id;
          if (this.filters.has(SY_CODE)) this.filters.delete(SY_CODE);
        }
        this.getStudentById(this.route.snapshot.paramMap.get('id'));
      }, this.errorHandlerService.handleError);
    }
  }

  // SY FILTER -- END

  getStudentById(id: string): void {
    this.crudService.getById<StudentInfo>(allStudentsEndpoint.concat(`/${id}`))
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(response => {
        this.studentInfo = response;
        this.getProcessingStatus();
        if(this.schoolYear === '2019-2020') {
          this.getOldClassItems();
        } else { 
          this.getClassItems();
        }
      }, this.errorHandlerService.handleError);
  }

  getProcessingStatus(): void {
		this.crudService
			.getById<any>(getProcessingStatusEndpoint)
			.pipe(takeUntil(this.unsubscribe))
			.subscribe(response => {
				this.processingStatus = response;
				this.processingStatus.sort((a, b) => b.code.localeCompare(a.code));
        this.getGuardianShippingDetails();
			}, this.errorHandlerService.handleError);
  }

  getGuardianShippingDetails(): void {
    this.shippingDetails = [];
    this.crudService
      .getAllBy<GuardianShippingDetails>(getShippingDetailsEndpoint.concat(`/${this.studentInfo.studentDetails.id}`))
      .pipe(takeUntil(this.unsubscribe))
			.subscribe(response => {
				if (response) {
          response.forEach(shippingDetail =>
            this.shippingDetails.push(this.dataCustomizerService.formatShippingDetailDisplay(shippingDetail))
          );
				}
    	}, this.errorHandlerService.handleError);
  }

  getRefundedList(districtId: string): void {
    this.crudService.getById<any>(getRefundListPerStudentEndpoint
      .concat(`?districtId=${districtId}`)
      .concat(`&page=${this.refundedListPage}&size=${this.refundedListSize}`))
    .pipe(takeUntil(this.unsubscribe))
    .subscribe(response => {
      this.refundedList = response.content;
      this.refundedListCount = response.totalElements;
    }, this.errorHandlerService.handleError);
  }
  
  
  getBundleItems(): void {
    this.bundles = [];
		this.crudService
      .getAll<any>(getStudentOrderedBundle
        .concat(`?studentDistrictId=${this.studentInfo.studentDetails.districtId}`)
        .concat(`&page=${this.bundlesPage}&size=${this.bundlesSize}`)
      )
			.pipe(takeUntil(this.unsubscribe))
			.subscribe(response => {
        let tempClasses = response.content;
        this.bundlesCount = response.totalElements;
        tempClasses.forEach(bundle => {
          let classes = [];
          let orderCodes = [];
          bundle.orderBundleXCurriculums.forEach(bundleCurr => {
            bundleCurr.orderBundleCurriculumXClasses.forEach(currClass => {
              classes.push(currClass.orderItem);
              orderCodes.push({ code: currClass.orderItem.tempOrderCode, id: currClass.orderItem.tempOrderId });
            });
          });
          
          tempClasses.forEach(bundle => {
            this.bundles.push({
              ...bundle,
              codeName: `${bundle.bundle.code} ${bundle.bundle.name}`,
              classes: classes,
              order: orderCodes,
            });
          });
        });
			}, this.errorHandlerService.handleError);
  }

  getOnlineAccountItems(): void {
    this.onlineAccounts = [];
		this.crudService
      .getAll<any>(getStudentOrderedOnlineAccountEndpoint
        .concat(`?studentDistrictId=${this.studentInfo.studentDetails.districtId}`)
        .concat(`&syCode=${this.schoolYear}`)
        .concat(`&page=${this.onlineAccountsPage}&size=${this.onlineAccountsSize}`)
        .concat(`&keyword=''`)
      )
			.pipe(takeUntil(this.unsubscribe))
			.subscribe(response => {
				let tempClasses = response.content;
        this.onlineAccountsCount = response.totalElements;
        console.log(tempClasses);
        tempClasses.forEach(onlineAccount => {
          this.onlineAccounts.push({
              ...onlineAccount,
              codeName: `${onlineAccount.referenceCode} ${onlineAccount.referenceName}`,
              curriculumName: onlineAccount.parentItem ? 
                `${onlineAccount.parentItem.referenceCode} ${onlineAccount.parentItem.referenceName}` :  null,
            });
          });
          this.getBookItems();
			}, this.errorHandlerService.handleError);
  }
  
  getClassItems(): void {
    this.classes = [];
		this.crudService
      .getAll<any>(getStudentOrderedClasses
        .concat(`?studentDistrictId=${this.studentInfo.studentDetails.districtId}`)
        .concat(`&withdrawn=false`)
        .concat(`&syCode=${this.schoolYear}`)
        .concat(`&page=${this.classesPage}&size=${this.classesSize}`)
        .concat(`&keyword=''`)
      )
			.pipe(takeUntil(this.unsubscribe))
			.subscribe(response => {
        let tempClasses = response.content;
        this.classesCount = response.totalElements;
        tempClasses.forEach(clazz => {
          this.classes.push({
            ...clazz,
            codeName: `${clazz.referenceCode} ${clazz.referenceName}`,
            curriculumName: clazz.curriculum ? `${clazz.curriculum.code} ${clazz.curriculum.name}` :  null,
            orderId: clazz.tempOrderId ? clazz.tempOrderId : null,
            requestToCancel: clazz.forCancel,
            orderCode: clazz.tempOrderCode,
          });
        });
        this.getWithdrawnClassItems();
      }, this.errorHandlerService.handleError, this.handleCompletion);
  }

  getWithdrawnClassItems(): void {
    this.withdrawnClasses = [];
		this.crudService
      .getAll<any>(getStudentOrderedClasses
        .concat(`?studentDistrictId=${this.studentInfo.studentDetails.districtId}`)
        .concat(`&withdrawn=true`)
        .concat(`&syCode=${this.schoolYear}`)
        .concat(`&page=${this.withdrawnClassesPage}&size=${this.withdrawnClassesSize}`)
        .concat(`&keyword=''`)
      )
			.pipe(takeUntil(this.unsubscribe))
			.subscribe(response => {
        let tempClasses = response.content;
        this.withdrawnClassesCount = response.totalElements;
        tempClasses.forEach(clazz => {
          this.withdrawnClasses.push({
            ...clazz,
            codeName: `${clazz.referenceCode} ${clazz.referenceName}`,
            curriculumName: clazz.curriculum ? `${clazz.curriculum.code} ${clazz.curriculum.name}` :  null,
            orderId: clazz.order ? clazz.order.id : null,
            requestToCancel: clazz.forCancel,
            orderCode: clazz.tempOrderCode,
          });
        });
        this.getOnlineAccountItems();
      }, this.errorHandlerService.handleError, this.handleCompletion);
  }

  getBookItems(): void {
    this.books = [];
		this.crudService
      .getAll<any>(getStudentOrderedWorkbooks
        .concat(`?studentDistrictId=${this.studentInfo.studentDetails.districtId}`)
        .concat(`&withdrawn=false`)
        .concat(`&syCode=${this.schoolYear}`)
        .concat(`&sort=assetDetails.modifiedDate,desc`)
        .concat(`&page=${this.booksPage}&size=${this.booksSize}`)
        .concat(`&keyword=${encodeURIComponent(this.keyword)}`)
      )
			.pipe(takeUntil(this.unsubscribe))
			.subscribe(response => {
        let tempClasses = response.content;
        this.booksCount = response.totalElements;
        // for each tempclasses: get consumable via assetcode and then push
        tempClasses.forEach(book => {
          this.books.push({
            ...book,
            codeName: `${book.referenceCode} ${book.referenceName}`,
            orderId: book.order ? book.order.id : null,
            orderCode: book.order ? book.order.code : null,
            status: book.assetDetails ? book.assetDetails.status : "",
            consumable: book.assetDetails.itemModel.consumable ? "Yes" : "No",
            modifiedBy: book.assetDetails.modifiedBy,
            modifiedDate: book.assetDetails.modifiedDate,
          });
        });
        this.getInvBookItems();
      }, this.errorHandlerService.handleError);
      
      this.handleCompletion();
  }

  // getOtherBookItems(): void {
  //   this.otherBooks = [];
	// 	this.crudService
  //     .getAll<any>(getStudentOrderedOtherBooks
  //       .concat(`?studentDistrictId=${this.studentInfo.studentDetails.districtId}`)
  //       .concat(`&withdrawn=false`)
  //       .concat(`&syCode=${this.schoolYear}`)
  //       .concat(`&sort=assetDetails.modifiedDate,desc`)
  //       .concat(`&page=${this.otherBooksPage}&size=${this.otherBooksSize}`)
  //       .concat(`&keyword=${encodeURIComponent(this.otherBookKeyword)}`)
  //     )
	// 		.pipe(takeUntil(this.unsubscribe))
	// 		.subscribe(response => {
  //       let tempClasses = response.content;
  //       this.otherBooksCount = response.totalElements;
  //       tempClasses.forEach(book => {
  //         this.otherBooks.push({
  //           ...book,
  //           codeName: `${book.referenceCode} ${book.referenceName}`,
  //           orderId: book.order ? book.order.id : null,
  //           orderCode: book.order ? book.order.code : null,
  //           status: book.assetDetails ? book.assetDetails.status : "",
  //           consumable: book.assetDetails.itemModel.consumable ? "Yes" : "No",
  //           modifiedBy: book.assetDetails.modifiedBy,
  //           modifiedDate: book.assetDetails.modifiedDate,
  //         });
  //       });
  //       this.getInvBookItems();
  //     }, this.errorHandlerService.handleError);
      
  //     this.handleCompletion();
  // }

  getInvBookItems(): void {
    this.invBooks = [];
		this.crudService
      .getAll<any>(getStudentOrderedInventoryBooks
        .concat(`?studentDistrictId=${this.studentInfo.studentDetails.districtId}`)
        .concat(`&syCode=${this.schoolYear}`)
        .concat(`&sort=modifiedDate,desc`)
        .concat(`&page=${this.invBooksPage}&size=${this.invBooksSize}`)
        .concat(`&keyword=${encodeURIComponent(this.invBookKeyword)}`)
      )
			.pipe(takeUntil(this.unsubscribe))
			.subscribe(response => {
        let tempClasses = response.content;
        this.invBooksCount = response.totalElements;
        tempClasses.forEach(book => {
          this.invBooks.push({
            ...book,
            codeName: `${book.itemModel.code} ${book.itemModel.name}`,
            assetCode: book.code,
            orderId: null,
            orderCode: book.cosOrderId,
            consumable: book.itemModel.consumable ? "Yes" : "No"
          });
        });
        this.getBundleItems();
      }, this.errorHandlerService.handleError);
      
      this.handleCompletion();
  }

	handleCompletion = (): void => {
		this.loaderMessagingService.showPageLoader(false);
  };
  
  onPageChange($event: any): void {
		this.loaderMessagingService.showPageLoader(true);
    switch ($event.title) {
      case this.classesTitle: {
        this.classesPage = $event.pageIndex;
        this.classesSize = $event.pageSize;
        break;
      }
      case this.withdrawnClassesTitle: {
        this.withdrawnClassesPage = $event.pageIndex;
        this.withdrawnClassesSize = $event.pageSize;
        break;
      }
      case this.bundlesTitle: {
        this.bundlesPage = $event.pageIndex;
        this.bundlesSize = $event.pageSize;
        break;
      }
      case this.booksTitle: {
        if(this.schoolYear == '2019-2020'){
          this.invBooksPage = $event.pageIndex;
          this.invBooksSize = $event.pageSize;
        } else {
          this.booksPage = $event.pageIndex;
          this.booksSize = $event.pageSize;
        }
        break;
      }
      case this.otherBooksTitle: {
        this.otherBooksPage = $event.pageIndex;
        this.otherBooksSize = $event.pageSize;
        break;
      }
      case this.invBooksTitle: {
        this.invBooksPage = $event.pageIndex;
        this.invBooksSize = $event.pageSize;
        break;
      }
      case this.onlineAccountsTitle: {
        this.onlineAccountsPage = $event.pageIndex;
        this.onlineAccountsSize = $event.pageSize;
        break;
      }
      case this.refundedListTitle: {
        this.refundedListPage = $event.pageIndex;
        this.refundedListSize = $event.pageSize;
        this.getStudentById(this.route.snapshot.paramMap.get('id'));
        break;
      }
    }
    if(this.schoolYear === '2019-2020') {
      this.getOldClassItems();
    } else { 
      this.getClassItems();
    }
    this.handleCompletion();
  }

  onSearch(assetTag: string) {
    this.keyword = assetTag;
    this.loaderMessagingService.showPageLoader(true);
    this.getBookItems();
  }

  // onOtherBookSearch(assetTag: string) {
  //   this.otherBookKeyword = assetTag;
  //   this.loaderMessagingService.showPageLoader(true);
  //   this.getOtherBookItems();
  // }

  onInvBookSearch(assetTag: string) {
    this.invBookKeyword = assetTag;
    this.loaderMessagingService.showPageLoader(true);
    this.getInvBookItems();
  }

  onClearFilters(
    isClear: boolean
  ) {
      this.keyword = '';
      this.booksPage = 0;
      this.booksSize = 10;
      this.loaderMessagingService.showPageLoader(true);
      this.getBookItems();
  }

  // onClearOtherBookFilters(
  //   isClear: boolean
  // ) {
  //     this.otherBookKeyword = '';
  //     this.otherBooksPage = 0;
  //     this.otherBooksSize = 10;
  //     this.loaderMessagingService.showPageLoader(true);
  //     this.getOtherBookItems();
  // }

  onClearInvBookFilters(
    isClear: boolean
  ) {
      this.invBookKeyword = '';
      this.invBooksPage = 0;
      this.invBooksSize = 10;
      this.loaderMessagingService.showPageLoader(true);
      this.getInvBookItems();
  }


  // SY: 2019-2020 --START--
  getAllInvBookItems(): void {
    this.invBooks = [];
		this.crudService
      .getAll<any>(getAllStudentOrderedWorkbooks
        .concat(`?studentDistrictId=${this.studentInfo.studentDetails.districtId}`)
        .concat(`&sort=modifiedDate,desc`)
        .concat(`&page=${this.invBooksPage}&size=${this.invBooksSize}`)
        .concat(`&keyword=${encodeURIComponent(this.invBookKeyword)}`)
      )
			.pipe(takeUntil(this.unsubscribe))
			.subscribe(response => {
        let tempClasses = response.content;
        this.invBooksCount = response.totalElements;
        tempClasses.forEach(book => {
          this.invBooks.push({
            ...book,
            codeName: `${book.itemModel.code} ${book.itemModel.name}`,
            assetCode: book.code,
            orderId: null,
            orderCode: book.cosOrderId,
            consumable: book.itemModel.consumable ? "Yes" : "No"
          });
        });
        this.getOldBundleItems();
      }, this.errorHandlerService.handleError);
      
      this.handleCompletion();
  }


  getOldClassItems(): void {
    this.classes = [];
		this.crudService
      .getAll<any>(getStudentOldOrderedItems
        .concat(`?studentDistrictId=${this.studentInfo.studentDetails.districtId}`)
        .concat(`&type=${ORDER_ITEM_TYPE_2}&resourceType=`)
        .concat(`&page=${this.classesPage}&size=${this.classesSize}`)
        .concat(`&keyword=''`)
      )
			.pipe(takeUntil(this.unsubscribe))
			.subscribe(response => {
        let tempClasses = response.content;
        this.classesCount = response.totalElements;
        tempClasses.forEach(clazz => {
          this.classes.push({
            ...clazz,
            codeName: `${clazz.referenceCode} ${clazz.referenceName}`,
            curriculumName: clazz.parentItem ? `${clazz.parentItem.referenceCode} ${clazz.parentItem.referenceName}` :  null,
            orderId: clazz.parentItem ? clazz.parentItem.order ? clazz.parentItem.order.id :
              clazz.parentItem.parentItem.order ? clazz.parentItem.parentItem.order.id : null : null,
            requestToCancel: clazz.forCancel,
            orderCode: clazz.parentItem ? clazz.parentItem.order ? clazz.parentItem.order.code :
              clazz.parentItem.parentItem.order ? clazz.parentItem.parentItem.order.code : null : null,
          });
        });
        this.getAllInvBookItems();
      }, this.errorHandlerService.handleError, this.handleCompletion);
  }

  getOldBundleItems(): void {
    this.bundles = [];
		this.crudService
      .getAll<any>(getStudentOldOrderedItems
        .concat(`?studentDistrictId=${this.studentInfo.studentDetails.districtId}`)
        .concat(`&type=${ORDER_ITEM_TYPE_4}&resourceType=`)
        .concat(`&page=${this.bundlesPage}&size=${this.bundlesSize}`)
        .concat(`&keyword=''`)
      )
			.pipe(takeUntil(this.unsubscribe))
			.subscribe(response => {
        let tempClasses = response.content;
        this.bundlesCount = response.totalElements;
        tempClasses.forEach(bundle => {
          let classes = [];
          let orderCodes = [];
          bundle.subItems.forEach(bundleCurr => {
            classes.push(...bundleCurr.subItems);
            bundleCurr.subItems.forEach(bundleClass => {
              if (bundleClass.referenceType === ORDER_ITEM_TYPE_2) {
                orderCodes.push({ code: bundle.order.code });
              } else {
                orderCodes.push({ code: bundleClass.referenceCode });
              }
            });
          });
          this.bundles.push({
            ...bundle,
            codeName: `${bundle.referenceCode} ${bundle.referenceName}`,
            classes: classes,
            orderCode: orderCodes,
          });
        });
        this.handleCompletion();
			}, this.errorHandlerService.handleError);
  }
  // SY: 2019-2020 --END--
}
