export const allBundlesEndpoint: string = "/bundles";
export const allCurriculumsEndpoint: string = "/curriculums";
export const allClassesEndpoint: string = "/classes";
export const allResourcesEndpoint: string = "/resources";
export const allHqtEndpoint: string = "/hqt";
export const allOrdersEndpoint: string = "/orders";
export const addpendingorders: string = "/orders/add-pending-orders";
export const allTasksEndpoint: string = "/tasks";
export const allStudentsEndpoint: string = "/students";
export const allModelsEndpoint: string = "/inventory/models";
export const allAssetsEndpoint: string ="/inventory/models/assets";
export const allTxnHistoryEndpoint: string = "/txn-history";
export const oldEndpoint: string = "/old";
export const oldOrdersEndpoint: string = "/old" + allOrdersEndpoint;
export const oldTasksEndpoint: string = "/old" + allTasksEndpoint;
export const oldModelsEndpoint: string = "/old" + allModelsEndpoint;

export const downloadCurriculumsEndpoint: string = "/curriculums/download";
export const downloadClassesEndpoint: string = "/classes/download";
export const downloadBundlesEndpoint: string = "/bundles/download";
export const downloadResourcesEndpoint: string = "/resources/download";
export const downloadModelsEndpoint: string = "/inventory/models/download";
export const downloadAssetsEndpoint: string = "/inventory/models/assets/download";
export const downloadShippingDtls: string = "/orders/print-shipping-dtls"

export const downloadTemplateEndpoints: string[] = [
  "/curriculums/download/template",
  "/classes/download/template",
  "/bundles/download/template",
  "/inventory/models/download/template",
  "/inventory/models/assets/download/template",
  "/resources/download/template"
];

export const downloadModelErrorEndpoint: string =
  "/inventory/models/download/error";
export const downloadAssetErrorEndpoint: string =
"/inventory/models/assets/download/error";

export const accessTokenEndpoint: string = "/oauth/token";
export const revokeTokenEndpoint: string = "/oauth/revoke";
export const currentUserEndpoint: string = "/oauth/user";

export const uploadEndpoint: string[] = [
  "/curriculums/upload",
  "/classes/upload",
  "/bundles/upload",
  "/inventory/models/upload",
  "/inventory/models/assets/upload",
  "/resources/upload"
];
export const uploadStatusEndpoint: string = "/uploads/status";

export const createCurriculum: string = "/curriculums";
export const getCountByFilter: string = "/count";
export const getSchoolYearsWithHistory: string = "/history";

export const getActivePeriodStatusEndpoint: string = "/active-period-status";
export const getStatusEndpoint: string = "/status";
export const getProcessingStatusEndpoint: string = "/order-status";
export const getSchoolYearsEndpoint: string = "/ref-school-year/active";
export const getAllSchoolYearsEndpoint: string = "/ref-school-year";
export const getActiveSchoolYearEndpoint: string = "/ref-school-year/active-sy";
export const getCountByStatus: string ="/count-by-status";
export const getOrderDepositItemsEndpoint: string = "/old/orders/deposit";

export const existsByName = "/exists-by-name?name=";
export const existsByCode = "/exists-by-code?code=";
export const existsByEmail = "/exists-by-email?email=";
export const modelErrorExist = "/inventory/models/download/error-exist";
export const assetErrorExist = "/inventory/models/assets/download/error-exist";
export const searchByCodeModelEndpoint: string = "/inventory/models/search";

export const getClassSubjectsEndpoint: string = allClassesEndpoint.concat(
  "/subjects"
);

export const getClassOrderedCount: string = allClassesEndpoint.concat(
  "/count-ordered-class"
);

export const getProcessedOrderedCount: string = getClassOrderedCount.concat(
  "/processed"
)

export const allScopes: string = allCurriculumsEndpoint.concat(
  "/scope"
);

export const allGradeLevelsEndpoint: string = allClassesEndpoint.concat(
  "/grade-levels"
);

export const getBundleTypesEndpoint: string = allBundlesEndpoint.concat(
  "/types"
);
export const getResourceTypesEndpoint: string = allResourcesEndpoint.concat(
  "/types"
);
export const getResourceGroupsEndpoint: string = allResourcesEndpoint.concat(
  "/groups"
);
export const getResourceOnlineAccountsEndpoint: string = allResourcesEndpoint.concat(
  "/online-accounts"
);

export const getByCodeClassEndpoint: string = allClassesEndpoint.concat(
  "/get-by-code"
);
export const getByCodeCurriculumEndpoint: string = allCurriculumsEndpoint.concat(
  "/get-by-code"
);
export const getByCodeResourcesEndpoint: string = allResourcesEndpoint.concat(
  "/get-by-code"
);
export const getByCodeBundlesEndpoint: string = allBundlesEndpoint.concat(
  "/get-by-code"
);
export const getByCurriculumIdClassEndpoint: string = allClassesEndpoint.concat(
  "/get-by-curriculum-id"
);
export const getShippingStatesEndpoint: string = allOrdersEndpoint.concat(
  "/shipping-states"
);
export const getShippingDetailsEndpoint: string = allStudentsEndpoint.concat(
  "/shipping-details"
);
export const getOrderItemTypeEndpoint: string = allOrdersEndpoint.concat(
  "/order-item-type"
);
export const getOldOrderItemTypeEndpoint: string = oldOrdersEndpoint.concat(
  "/order-item-type"
);
export const getOrderTransformEndpoint: string = allOrdersEndpoint.concat(
  "/transform"
);
export const getByCodeOrderEndpoint: string = allOrdersEndpoint.concat(
  "/get-by-code"
);
export const getByOldCodeOrderEndpoint: string = oldOrdersEndpoint.concat(
  "/get-by-code"
);
export const getOrderTotalEndpoint: string = allOrdersEndpoint.concat(
  "/get-order-total"
);
export const getTaskGroupTypeEndpoint: string = allTasksEndpoint.concat(
  "/task-group-type"
);
export const requestToCancelOrderItemEndpoint: string = allOrdersEndpoint.concat(
  "/request-to-cancel"
);
export const orderAddBookItemEndpoint: string = allOrdersEndpoint.concat(
  "/add-book-item"
);
export const orderUpdateShippingAddressEndpoint: string = allOrdersEndpoint.concat(
  "/update-shipping-address"
);
export const orderUpdateMultipleBookTrackingIdsEndpoint: string = allOrdersEndpoint.concat(
  "/update-multiple-book-tracking-id"
);
export const getStudentOrderedClasses: string = allOrdersEndpoint.concat(
  "/get-student-ordered-classes"
);
export const getStudentItemsByItemTypeEndpoint: string = allOrdersEndpoint.concat(
  "/get-student-items-by-item-type"
);

export const getStudentOrderedOnlineAccountEndpoint: string = allOrdersEndpoint.concat(
  "/get-student-ordered-online-accounts"
);

export const getStudentOldItemsByItemTypeEndpoint: string = oldOrdersEndpoint.concat(
  "/get-student-items-by-item-type"
);
export const getStudentOldOrderedItems: string = oldEndpoint.concat(
  getStudentItemsByItemTypeEndpoint
);
export const getStudentOrderedWorkbooks: string = allOrdersEndpoint.concat(
  "/get-student-ordered-workbooks"
);
export const getAllStudentOrderedWorkbooks: string = allOrdersEndpoint.concat(
  "/get-all-student-workbooks"
);
export const getStudentOrderedOtherBooks: string = allOrdersEndpoint.concat(
  "/get-student-ordered-other-books"
);
export const getStudentOrderedInventoryBooks: string = allOrdersEndpoint.concat(
  "/get-student-ordered-inventory-books"
);
export const getStudentOrderedBundle: string = allOrdersEndpoint.concat(
  "/get-student-ordered-bundles"
);
export const getOrderBundleByItem: string = allOrdersEndpoint.concat(
  "/get-order-bundle-by-item"
);
export const reformEosOrderEndpoint: string = allOrdersEndpoint.concat(
  "/reform-eos-order"
);
export const getOldItemLatestStatusHistoryEndpoint: string = oldEndpoint.concat(allOrdersEndpoint.concat(
  "/get-item-latest-status-history"
));
export const getItemLatestStatusHistoryEndpoint: string = allTxnHistoryEndpoint.concat(
  "/get-item-latest-status-history"
);
export const getItemLatestHistoryByStatusEndpoint: string = allTxnHistoryEndpoint.concat(
  "/get-item-latest-history-by-status"
);
export const getItemCreatedStatusHistoryEndpoint: string = allTxnHistoryEndpoint.concat(
  "/get-item-created-status-history"
);
export const refundEndpoint: string = oldOrdersEndpoint.concat(
  "/refund"
);
export const getItemRefundHistoryEndpoint: string = allOrdersEndpoint.concat(
  "/get-item-refund-history"
);
export const adjustItemAmountEndpoint: string = allOrdersEndpoint.concat(
  "/adjust-item-amount"
);
export const getItemAdjustmentHistoryEndpoint: string = allOrdersEndpoint.concat(
  "/get-item-adjustment-history"
);
export const updateTaskStatusEndpoint: string = allTasksEndpoint.concat(
  "/update-status"
);
export const updateWTBTaskStatusEndpoint: string = allTasksEndpoint.concat(
  "/update-wtb-status"
);
export const createWorkbookTextbook: string = allTasksEndpoint.concat(
  "/create-workbook-textbook"
);
export const withdrawItemEndpoint: string = allTasksEndpoint.concat(
  "/withdraw"
);
export const updateAssetRelatedTaskEndpoint: string = allAssetsEndpoint.concat(
  "/updateAssetRelatedTask"
);
export const checkInStatusEndpoint: string = allAssetsEndpoint.concat(
  "/checkInStatus"
)
export const getUserAssignedCurriculumEndpoint: string = allTasksEndpoint.concat(
  "/user-assigned-curriculum"
);
export const getByOrderCodeTaskEndpoint: string = allTasksEndpoint.concat(
  "/get-by-order-code"
);
export const getRefundListPerStudentEndpoint: string = allOrdersEndpoint.concat(
  "/get-refund-list-student"
);
export const updateOrderModifiedOnlyEndpoint: string = allOrdersEndpoint.concat(
  "/update-order-modified-only"
);
export const updateTaskModifiedOnlyEndpoint: string = allTasksEndpoint.concat(
  "/update-task-modified-only"
);
export const getByResourceIdEndpoint: string = allResourcesEndpoint.concat(
  "/get-by-resource-id"
);
export const updatePendingorderstatus:string=allOrdersEndpoint.concat(
  "/approve-pending-order"
);
