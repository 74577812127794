import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { CrudService } from 'src/app/core/services/data/crud.service';
import { LoaderMessagingService } from 'src/app/core/services/messaging/loader-messaging.service';
import { ErrorHandlerService } from 'src/app/core/services/util/error-handler.service';
import { HeaderMessagingService } from 'src/app/core/services/messaging/header-messaging.service';
import { DataCustomizerService } from 'src/app/core/services/util/data-customizer.service';
import { FabButtonMessagingService } from 'src/app/core/services/messaging/fab-button-messaging.service';
import { UserService } from 'src/app/core/services/util/user.service';
import { VIEW_MODULE_NAME } from 'src/app/core/constants/configuration/common.constant';
import { CLASSES_PATH, MODEL_UPDATE_PATH, MODEL_PATH } from 'src/app/core/constants/routes.constant';
import { allModelsEndpoint } from 'src/app/core/constants/endpoints.constant';
import { takeUntil } from 'rxjs/operators';
import { modelModuleName } from 'src/app/core/constants/configuration/model-constants.config';
import { Model } from 'src/app/core/models/model.model';
import { settingsRoutingPermissions } from "src/app/core/constants/configuration/role-constants.config";

@Component({
  selector: 'app-model-view',
  templateUrl: './model-view.component.html',
  styleUrls: ['./model-view.component.scss']
})
export class ModelViewComponent implements OnInit, OnDestroy {

	unsubscribe: Subject<any> = new Subject();
	data: any = {};
	hasInitialized: boolean = false;

	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private crudService: CrudService,
		private loaderMessagingService: LoaderMessagingService,
		private errorHandlerService: ErrorHandlerService,
		private headerMessagingService: HeaderMessagingService,
		private dataCustomizerService: DataCustomizerService,
		private fabButtonMessagingService: FabButtonMessagingService,
		private userService: UserService,
	) {
		this.userService.checkRolePermission(MODEL_PATH, settingsRoutingPermissions);
		this.headerMessagingService.setHeader(modelModuleName, VIEW_MODULE_NAME, false, null);
	}

	ngOnInit() {
		this.loaderMessagingService.showPageLoader(true);
		this.findModel(this.route.snapshot.paramMap.get('id'));
	}

	ngOnDestroy(): void {
		this.fabButtonMessagingService.sendMessage({ canEdit: false, path: null, id: null });
		this.unsubscribe.next();
		this.unsubscribe.complete();
	}

	handleCompletion = (): void => {
		this.loaderMessagingService.showPageLoader(false);
	};

	get canView(): boolean {
		return this.data.model && this.hasInitialized;
	}

	onLinkClick(id: number): void {
		this.router.navigate([CLASSES_PATH, id]);
	}

	// PUT ALL API CALL BELOW
	private findModel(id: string): void {

		this.crudService
			.getById<Model>(allModelsEndpoint.concat(`/${id}`))
			.pipe(takeUntil(this.unsubscribe))
			.subscribe(response => {
				if (response) {
          this.data.model = response;
          this.initFabMessage();
				}
			}, this.errorHandlerService.handleError, this.handleCompletion);

	}

	private initFabMessage(): void {
		let roles = settingsRoutingPermissions.find(route => route.path === MODEL_PATH);
		const message: any = { canEdit: this.userService.hasRole(roles ? roles.roles : []), path: MODEL_UPDATE_PATH, id: this.data.model.id };
		this.fabButtonMessagingService.sendMessage(message);
		this.hasInitialized = true;
  }
}
