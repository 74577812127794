import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";

@Injectable({ providedIn: 'root' })
export class LoginMessagingService {

  private loginMessageSource: Subject<string> = new Subject<string>();

  loginMessage$: Observable<string> = this.loginMessageSource.asObservable();

  sendMessage(message: string): void {
    this.loginMessageSource.next(message);
  }

}
