import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';
import { faThumbsUp, IconDefinition, faBell, faCogs, faCheckCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'task-statistics',
  templateUrl: './task-statistics.component.html',
  styleUrls: ['./task-statistics.component.scss']
})
export class TaskStatisticsComponent implements OnInit {

	@Output() filterChange = new EventEmitter();
	@Input() statusListCount: any;
	filterStatus = new Map<string, string>();
	approvedIcon: IconDefinition = faThumbsUp;
	processingIcon: IconDefinition = faCogs;
	preCompletedIcon: IconDefinition = faBell;
	completedIcon: IconDefinition = faCheckCircle;
	canceledIcon: IconDefinition = faTimesCircle;

	constructor() { }

	ngOnInit() {
	}

	filter(value: string){
		this.filterStatus.set('status', value);
		this.filterChange.emit(this.filterStatus);
	  }

}
