import { accessToken, clientIdentity } from "../../constants/configuration/config.constant";

export class HeaderService {

  static getHeaders(url: string, method: string) {

    let headers;

    if (method === "POST" && url.includes("/oauth/token")) {

      headers = {
        'Accept': 'application/json',
        'Authorization': 'Basic ' + btoa(clientIdentity),
        'Content-Type': 'application/x-www-form-urlencoded',
      };

    } else if (method === "POST" && url.includes("/upload")) {

      headers = {
        'Accept': 'application/json',
        'Authorization': localStorage.getItem(accessToken),
      };

    } else if (method !== "GET") {

      headers = {
        'Accept': 'application/json',
        'Authorization': localStorage.getItem(accessToken),
        'Content-Type': 'application/json',
      };

    } else {

      headers = {
        'Accept': 'application/json',
        'Authorization': localStorage.getItem(accessToken),
      };

    }

    return headers;

  }

}
