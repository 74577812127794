import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { faFilter } from '@fortawesome/free-solid-svg-icons';
import { STUDENTS_PATH } from 'src/app/core/constants/routes.constant';
import { allStudentsEndpoint, allGradeLevelsEndpoint } from 'src/app/core/constants/endpoints.constant';
import { homeModuleName, pageSizeOptions, ASC, OPEN, CLOSE } from 'src/app/core/constants/configuration/common.constant';
import { studentListDataProperties } from 'src/app/core/constants/configuration/student-constants.config';
import { homeRoutingPermissions, TEACHER } from 'src/app/core/constants/configuration/role-constants.config';
import { DataProperties } from 'src/app/core/models/data-properties.model';
import { Student } from 'src/app/core/models/eos/student.model';
import { CrudService } from 'src/app/core/services/data/crud.service';
import { DataCustomizerService } from 'src/app/core/services/util/data-customizer.service';
import { ErrorHandlerService } from 'src/app/core/services/util/error-handler.service';
import { LoaderMessagingService } from 'src/app/core/services/messaging/loader-messaging.service';
import { HeaderMessagingService } from 'src/app/core/services/messaging/header-messaging.service';
import { QueryService } from 'src/app/core/services/util/query.service';
import { UserService } from "src/app/core/services/util/user.service";
import { FilterService } from "src/app/core/services/util/filter.service";
import { FilterComponent } from 'src/app/shared/components/filter/filter.component';

@Component({
	selector: 'app-students',
	templateUrl: './students.component.html',
	styleUrls: ['./students.component.scss']
})
export class StudentsComponent implements OnInit, OnDestroy {

	@ViewChild(FilterComponent) filterComponent: FilterComponent;
	students: any[] = [];
	items: any[] = [];
	itemsCount: number = 0;
	pageIndex: number = 0;
	pageSize: number = pageSizeOptions[0];
	keyword: string = "";
	filters: DataProperties[];
	unsubscribe: Subject<any> = new Subject();
	reference: any = {};
	selectedSyId: number;
	schoolYear: string;
	filterLabels: string[] = [];
	studentListDataProperties: DataProperties[] = studentListDataProperties;
	clearFilters: boolean = true;
	includeInactive: boolean = false;
	sortedBy: string;
	sortOrder: string;
	isType1: boolean = false;
	trigger: string = CLOSE;
  selectedFilter: string;

	filtersKey: string = "studentFilters";
	selectedFilterKey: string = "selectedFilter";
	filterIcon: IconDefinition = faFilter;

	constructor(
		private crudService: CrudService,
		private dataCustomizerService: DataCustomizerService,
		private errorHandlerService: ErrorHandlerService,
		private headerMessagingService: HeaderMessagingService,
		private loaderMessagingService: LoaderMessagingService,
		private queryService: QueryService,
    private userService: UserService,
    private filterService: FilterService,
	) {
		this.userService.checkRolePermission(STUDENTS_PATH, homeRoutingPermissions);
		this.headerMessagingService.setHeader(homeModuleName, "", true, homeRoutingPermissions);
    this.includeInactive = !this.userService.hasRole([TEACHER]);
		let studentFilters = this.filterService.getFilter(this.filtersKey);
		if (studentFilters) {
			this.keyword = studentFilters.keyword || "";
			let localStorageFilter = studentFilters.filters ?
				this.filterService.objectToMap(studentFilters.filters) : new Map<string, string>();
			if (localStorageFilter && localStorageFilter.has(this.selectedFilterKey)) {
				this.selectedFilter = localStorageFilter.get(this.selectedFilterKey);
			}
		}
	}

	ngOnInit() {
		this.loaderMessagingService.showPageLoader(true);
		this.initParams();
	}

	ngOnDestroy(): void {
		this.unsubscribe.next();
		this.unsubscribe.complete();
	}
  
	initParams(): void {
		this.sortedBy = this.studentListDataProperties[0].property;
		this.sortOrder = ASC;
		this.filters = this.filters && this.filters.length > 0 ?
			this.filters : Object.assign([], this.studentListDataProperties.filter(prop => prop.filterable));
		this.selectedFilter = this.selectedFilter || this.filters[1].property;
		this.setReferenceGradeLevels();
	}

	search(): void {
		this.loaderMessagingService.showListLoader(true);
		this.items = [];
		let localStorageFilter = new Map<string, string>();
		localStorageFilter.set(this.selectedFilterKey, this.selectedFilter);
		this.filterService.setFilter(this.filtersKey, this.keyword, localStorageFilter);

		this.crudService
			.getAll<Student>(allStudentsEndpoint.concat(this.queryService.buildStudentSearchQuery(
        this.pageIndex, this.pageSize, this.includeInactive, this.keyword, this.selectedFilter)))
			.pipe(takeUntil(this.unsubscribe))
			.subscribe(response => {
				if (response) {
					this.itemsCount = response.totalElements;
					this.students = response.content;
					response.content.forEach(student =>
						this.items.push(this.dataCustomizerService.formatStudentListDetailDisplay(student, this.reference)));
					}
			},
			this.errorHandlerService.handleError,
			this.handleCompletion
		);
	}

  private setReferenceGradeLevels(): void {
		this.crudService
			.getById<any>(allGradeLevelsEndpoint)
			.pipe(takeUntil(this.unsubscribe))
			.subscribe(response => {
				if (response) {
					this.reference.gradeLevels = response;
					this.reference.gradeLevels.sort((a, b) => a.code.localeCompare(b.code));
					this.search();
				}
			}, this.errorHandlerService.handleError);
  }

	handleCompletion = (): void => {
		this.loaderMessagingService.showListLoader(false);
		this.loaderMessagingService.showPageLoader(false);
	};
	
	onSearch(keyword: string): void {
		this.keyword = keyword;
		this.pageIndex = 0;
		this.search();
	}
  
	filterOnChange(filters) {
		this.selectedFilter = filters;
		this.pageIndex = 0;
	}
  
  onPageChange($event: any): void {
		this.pageIndex = $event.pageIndex;
		this.pageSize = $event.pageSize;
		this.search();
	}

	onFilterChange(event: any) {
		this.trigger = OPEN;
		setTimeout(() => { this.filterComponent.filterByStatus(); }, 200);
	}

}
