import { Component, OnInit, OnDestroy } from '@angular/core';
import { allResourcesEndpoint, getResourceTypesEndpoint } from 'src/app/core/constants/endpoints.constant';
import { Subject } from 'rxjs';
import { Resource } from 'src/app/core/models/resource.model';
import { ResourceGroupForm } from 'src/app/core/models/form/resource-group-form-model';
import { ActivatedRoute, Router } from '@angular/router';
import { CrudService } from 'src/app/core/services/data/crud.service';
import { LoaderMessagingService } from 'src/app/core/services/messaging/loader-messaging.service';
import { ErrorHandlerService } from 'src/app/core/services/util/error-handler.service';
import { HeaderMessagingService } from 'src/app/core/services/messaging/header-messaging.service';
import { RESOURCE } from 'src/app/core/constants/configuration/resource-constants.config';
import { VIEW_MODULE_NAME } from 'src/app/core/constants/configuration/common.constant';
import { ResourceHelper } from "src/app/core/services/util/resource.helper";
import { takeUntil } from 'rxjs/operators';
import { RESOURCE_UPDATE_PATH, RESOURCES_PATH } from 'src/app/core/constants/routes.constant';
import { FabButtonMessagingService } from 'src/app/core/services/messaging/fab-button-messaging.service';
import { UserService } from 'src/app/core/services/util/user.service';
import { homeRoutingPermissions } from "src/app/core/constants/configuration/role-constants.config";

@Component({
	selector: 'app-resource-view',
	templateUrl: './resource-view.component.html',
	styleUrls: ['./resource-view.component.scss']
})
export class ResourceViewComponent implements OnInit, OnDestroy {

	unsubscribe: Subject<any> = new Subject();
	resource: Resource;
	hasInitialized: boolean = false;
	groups: ResourceGroupForm[];
	resourceTypes: any[];

	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private crudService: CrudService,
		private loaderMessagingService: LoaderMessagingService,
		private errorHandlerService: ErrorHandlerService,
		private headerMessagingService: HeaderMessagingService,
		private resourceHelper: ResourceHelper,
		private fabButtonMessagingService: FabButtonMessagingService,
    private userService: UserService,
	) {
		this.userService.checkRolePermission(RESOURCES_PATH, homeRoutingPermissions);
		this.headerMessagingService.setHeader(RESOURCE, VIEW_MODULE_NAME, false, null);
	}

	ngOnInit() {
		this.loaderMessagingService.showPageLoader(true);
		this.findResource(this.route.snapshot.paramMap.get('id'));
	}

	ngOnDestroy(): void {
		this.fabButtonMessagingService.sendMessage({ canEdit: false, path: null, id: null });
		this.unsubscribe.next();
		this.unsubscribe.complete();
	}

	handleCompletion = (): void => {
		this.loaderMessagingService.showPageLoader(false);
	};

	get canView(): boolean {
		return this.resource && this.hasInitialized;
	}

	get getResourceType() {
		return this.resourceTypes ? this.resourceTypes.find(resourceType => resourceType.code === this.resource.type).name : null;
	}

	onLinkClick(data: any): void {
		this.router.navigate([data.path, data.id]);
	}

	// PUT ALL API CALL BELOW
	private findResource(id: string): void {
		this.crudService
			.getById<Resource>(allResourcesEndpoint.concat(`/${id}`))
			.pipe(takeUntil(this.unsubscribe))
			.subscribe(response => {
				if (response) {
					this.resource = response;
					this.groups = this.resourceHelper.setResourceGroupFormValues(response.resourceAssoc);
					this.getResourceTypes();
				}
			}, this.errorHandlerService.handleError, this.handleCompletion);
	}

  getResourceTypes(): void {
    this.crudService
      .getById<any>(getResourceTypesEndpoint)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(response => {
				this.resourceTypes = response;
				this.resourceTypes.sort((a, b) => a.code.localeCompare(b.code));
				this.initFabMessage();
      }, this.errorHandlerService.handleError);
	}
	
	private initFabMessage(): void {
		let roles = homeRoutingPermissions.find(route => route.path === RESOURCES_PATH);
		const message: any = { canEdit: this.userService.hasRole(roles ? roles.roles : []), path: RESOURCE_UPDATE_PATH, id: this.resource.id };
		this.fabButtonMessagingService.sendMessage(message);
		this.hasInitialized = true;
	}

}
