import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Bundle } from 'src/app/core/models/bundle.model';

@Component({
  selector: 'app-bundle-details-dialog',
  templateUrl: './bundle-details-dialog.component.html',
  styleUrls: ['./bundle-details-dialog.component.scss']
})
export class BundleDetailsDialogComponent implements OnInit {

  bundle: any = {};

  constructor(public dialogRef: MatDialogRef<BundleDetailsDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: Bundle) { }

  ngOnInit() {
    this.bundle = this.data;
  }

}
