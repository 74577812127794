import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { faMinus } from "@fortawesome/free-solid-svg-icons";
import { MatInput } from "@angular/material/input";
import { MatDatepickerInputEvent } from "@angular/material/datepicker";
import * as moment from "moment";
import { dateSearchFormat } from "src/app/core/constants/configuration/common.constant";
import { DataProperties } from "src/app/core/models/data-properties.model";
import { getClassSubjectsEndpoint } from "src/app/core/constants/endpoints.constant";
import { takeUntil } from "rxjs/operators";
import { CrudService } from "src/app/core/services/data/crud.service";
import { Subject } from "rxjs";
import { ErrorHandlerService } from "src/app/core/services/util/error-handler.service";

@Component({
  selector: "app-auto-complete-filter",
  templateUrl: "./auto-complete-filter.component.html",
  styleUrls: ["./auto-complete-filter.component.scss"],
})
export class AutoCompleteFilterComponent implements OnInit {

  faPlus = faPlus;
  faMinus = faMinus;
  @ViewChild('autoCompleteInput') autoCompleteInput: MatInput;

  @Output() filterOnChange = new EventEmitter();
  @Input() dataProperties: DataProperties[];
  @Input() set clearFilters(clear: boolean) {
    if (clear) this.ngOnInit();
  };

  filters: any[] = [];
  selectedFilters: Map<string, string> = new Map<string, string>();
  availableFilters: DataProperties[];
  filterLabels: Map<string, string> = new Map<string, string>();
  disable: boolean = false;
  currentFilter: any;
  modelList: any;
  delayTimer: NodeJS.Timer;
  timeout: number = 300;
  isActive: boolean = false;
  unsubscribe: Subject<any> = new Subject();
  currentModel: any;
  reference: any;
  autoCompleteKeyword: string;

  constructor(
    private crudService: CrudService,
    private errorHandlerService: ErrorHandlerService,
  ) {}

  ngOnInit(): void {
    this.availableFilters = Object.assign([], this.dataProperties.filter(prop => prop.filterable));
    this.filters = [];
    this.selectedFilters.clear();
    this.filterLabels.clear();
    this.currentFilter = this.availableFilters[0];
    this.currentModel = "";
    this.autoCompleteKeyword = "";
    this.disable = false;
    this.getReferenceSubjects();
  }

  onAddFilter(): void {
    if (this.availableFilters.length > 0) {
      this.filters.push(this.availableFilters[0]);
    }
    this.disable = true;
  }

  onRemoveFilter(key: any) {
    this.selectedFilters.delete(key.property);
    this.filters.splice(this.filters.findIndex(item => item.property === key.property), 1);
    this.filters.length > 0 ? this.availableFilters.push(this.dataProperties.find(prop => prop.property === key.property)) : this.ngOnInit();
    this.filterOnChange.emit(this.selectedFilters);
  }

  onChange(key: any) {
   this.filters[this.filters.length - 1] = key;
   this.currentFilter = key;
  }

  filterParamOnChange(key: any, filterValue: string) {
    if (filterValue && filterValue.trim().length !== 0) {
      this.setFilterValue(key, filterValue);
      this.autoCompleteKeyword = "";
    }
  }

  onDateSelect(event: MatDatepickerInputEvent<Date>, key: string) {
    this.setFilterValue(key, moment(event.value).format(dateSearchFormat));
  }

  checkFilterExist(filter: string) {
    return this.selectedFilters.get(filter) !== "";
  }

  checkValue(index: number) {
    return this.filters[index] !== "";
  }

  private setFilterValue(key: any, filterValue: string): void {
    this.selectedFilters.set(key.property, filterValue);
    this.filterLabels.set(key.property, this.dataProperties.find(property => property.property === key.property).label);
    this.removeFromAvailableFilters(key.property);
    this.currentFilter = this.availableFilters[0];
    this.disable = false;
    this.filterOnChange.emit(this.selectedFilters);
  }

  removeFromAvailableFilters(key: string): void {
    this.availableFilters = Object.assign([], this.availableFilters.filter(filter => filter.property !== key));
  }

  onModelChange(keyword : string, currentFilter: any) : void {
    this.modelList = [];

    if (!keyword || keyword.trim().length === 0) {
			return;
		};

    clearTimeout(this.delayTimer);
    this.delayTimer = setTimeout(() => {
		  this.isActive = true;
      setTimeout(() => this.getModelList(keyword.trim(), currentFilter), this.timeout);
    }, this.timeout);
  }
  
  getModelList(keyword: string, currentFilter) {
    this.crudService
      .getAllBy<any>(`${currentFilter.endpoint}?keyword=${encodeURIComponent(keyword)}`)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(response => {
        this.autoCompleteKeyword = keyword.trim();
		    this.isActive = false;
        if (response) {
          if (response.length > 0) {
            response.map(model => this.modelList.push(model));
          } 
        }
        setTimeout(_ => this.autoCompleteInput.focus());
      },
        this.errorHandlerService.handleError,
    );
  }

  onModelSelect(selectedModelName: string, currentFilter: any) : void {
    const selectedModel = this.modelList.find(model => model.name === selectedModelName);
    this.filterParamOnChange(currentFilter, '###'.concat(selectedModel.name));
  }

  private getReferenceSubjects(): void {
		this.crudService
			.getById<any>(getClassSubjectsEndpoint)
			.pipe(takeUntil(this.unsubscribe))
			.subscribe(response => {
				if (response) {
					this.reference = {};
					this.reference.subjects = response;
					this.reference.subjects.sort((a, b) => a.code.localeCompare(b.code));
				}
			}, this.errorHandlerService.handleError);
  }
  
  onSelectionChange(event: any, currentFilter: any) {
    this.filterParamOnChange(currentFilter, '###'.concat(event.source.value));
  }

}
