import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Class } from 'src/app/core/models/class.model';
import { faQuestionCircle, IconDefinition} from '@fortawesome/free-solid-svg-icons';


@Component({
  selector: 'class-details',
  templateUrl: './class-details.component.html',
  styleUrls: ['./class-details.component.scss']
})
export class ClassDetailsComponent implements OnInit {
  
	@Output() linkClink: EventEmitter<number> = new EventEmitter();
	@Input() clazz: Class;
	@Input() referenceValues: any;
	@Input() classOrderedCount : number;
	@Input() classProcessedOrderedCount : number;

	mainDetails: any;

	faQuestionCircle: IconDefinition = faQuestionCircle;

	constructor() { }

	ngOnInit() {
		const { code, name , createdBy, createdDate, modifiedBy, modifiedDate, status, maxSlot } = this.clazz
		const classOrderedCountArray = [];
		classOrderedCountArray['classOrderedCount'] = this.classOrderedCount;
		const classOrderedCount = classOrderedCountArray['classOrderedCount'];
		this.mainDetails = { code, name, createdBy, createdDate, modifiedBy, modifiedDate, status, maxSlot, classOrderedCount};	
	}

	get hasHqt(): boolean {
		return this.referenceValues.hqt && String(this.referenceValues.hqt.email).trim().length > 0 && String(this.referenceValues.hqt.name).trim().length > 0;
	}

	onViewCurriculum(id: number): void {
		this.linkClink.emit(id);
	}

}
