import { Component, OnInit, OnDestroy, EventEmitter, Output, Input } from '@angular/core';
import { Subject } from 'rxjs';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { Bundle } from 'src/app/core/models/bundle.model';
import { Page } from 'src/app/core/models/page.model';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit, OnDestroy {

  @Input() keywordValue: string = null;
  @Input() cardTypeValue: string = null;
  @Output()
  search: EventEmitter<string> = new EventEmitter();

  bundles: Page<Bundle>;
  delayTimer: NodeJS.Timer;
  timeout: number = 2000;
  unsubscribe: Subject<any> = new Subject();
  keyword: string = "";
  cardtype: string;

  searchIcon: IconDefinition = faSearch;

  constructor() { }

  ngOnInit(): void {
    if (this.cardTypeValue) this.cardtype = this.cardTypeValue;
    if (this.keywordValue) this.keyword = this.keywordValue;
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  onKeyup($event: any): void {

    this.keyword = $event.target.value;

    // uncomment below to trigger auto searching
    // clearTimeout(this.delayTimer);
    // this.delayTimer = setTimeout(() => this.search.emit(this.keyword), this.timeout);

  }

  onSearch(): void {
    this.search.emit(this.keyword);
  }

}
