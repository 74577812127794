import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";

@Injectable({ providedIn: "root" })
export class HeaderMessagingService {

	private headerMessageSource: Subject<any> = new Subject<any>();
	headerMessage$: Observable<any> = this.headerMessageSource.asObservable();

	setHeader(title: string, moduleName: string, isMain: boolean, routes: any[]): void {
		this.headerMessageSource.next({ title, moduleName, isMain, routes });
	}

}
