import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";

@Injectable({ providedIn: 'root' })
export class DialogMessagingService {

  private dialogMessageSource: Subject<any> = new Subject<any>();

  dialogMessage$: Observable<any> = this.dialogMessageSource.asObservable();

  sendMessage(message: any): void {
    this.dialogMessageSource.next(message);
  }

}
