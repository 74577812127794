import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatCheckboxChange, MatRadioChange } from '@angular/material';
import { DownloadFiltersDialogData } from './models/download-filters-dialog-data';
import { DownloadFiltersTypeEnum } from './enums/download-filters-type-enum';
import { ReferenceSchoolYearDTO } from 'src/app/core/models/dto/referennce-school-year-dto';
import { DownloadFilters } from './models/download-filters';
import { downloadConfirmationCurriculum, downloadConfirmationClass, downloadConfirmationBundle, downloadConfirmationModel, downloadConfirmationAsset } from 'src/app/core/constants/message.constant';

@Component({
  selector: 'app-download-filters-dialog',
  templateUrl: './download-filters-dialog.component.html',
  styleUrls: ['./download-filters-dialog.component.scss']
})
export class DownloadFiltersDialogComponent implements OnInit {
  schoolYears: ReferenceSchoolYearDTO[] = [];
  statusList: any[] = [];
  selectedSchoolYear: string = '';
  selectedStatus: string = '';
  filters: DownloadFilters;
  downloadFiltersType: DownloadFiltersTypeEnum;
  selectedStatusTypes: string[] = [];
  selectedStatusType: string = '';
  selectedStatusTypeName: string = '';
  rbSelected: any = {};

  constructor(
    public dialogRef: MatDialogRef<DownloadFiltersDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DownloadFiltersDialogData
  ) {
    // set default values here:
    this.filters = new DownloadFilters();
    this.schoolYears = data.schoolYears;
    this.statusList = data.statuses;
    this.selectedSchoolYear = data.selectedSchoolYear;
    this.downloadFiltersType = data.type;
    if (this.downloadFiltersType == DownloadFiltersTypeEnum.MODEL) {
      this.rbSelected = data.statuses[0];
    }
  }

  ngOnInit() {
  }

  cancelOnClick() {
    this.dialogRef.close();
  }

  okOnClick() {
    switch(this.downloadFiltersType) {
      case DownloadFiltersTypeEnum.MODEL:
        this.selectedStatusTypes.push(this.rbSelected.code);
        this.selectedStatusTypeName = this.rbSelected.name;
        this.filters.status = this.selectedStatusTypes;
        this.filters.statusName = this.generateStatusName(this.selectedStatusTypes);
      break;
      case DownloadFiltersTypeEnum.ASSET:
        this.filters.status = this.selectedStatusTypes;
        this.filters.statusName = this.isAllStatusSelected()
          ? 'all'
          : this.generateStatusName(this.selectedStatusTypes);
      break;
      default:
        this.selectedStatusTypes.push(this.selectedSchoolYear);
        this.filters.status = this.selectedStatusTypes;
        this.filters.statusName = this.isAllStatusSelected()
          ? 'all'
          : this.selectedSchoolYear;
      break;
    }
    this.dialogRef.close(this.filters);
  }

  onCheckEvent(
    checkboxChangeEvent: MatCheckboxChange,
    type: any
  ) {
    if (checkboxChangeEvent.checked) {
      this.selectedStatusTypes.push(type.code);
    } else {
      this.selectedStatusTypes.splice(
        this.selectedStatusTypes.findIndex(x => x == type.code),
        1
      );
    }
    this.selectedStatusTypeName = this.isAllStatusSelected()
      ? 'all'
      : this.generateStatusName(this.selectedStatusTypes);
  }

  isAllStatusSelected(): boolean {
    return this.selectedStatusTypes.length == this.statusList.length;
  }

  getHeaderMessage() {
    let message: string;
    switch(this.downloadFiltersType) {
      case DownloadFiltersTypeEnum.CURRICULUM:
        message = downloadConfirmationCurriculum;
      break;
      case DownloadFiltersTypeEnum.CLASS:
        message = downloadConfirmationClass;
      break;
      case DownloadFiltersTypeEnum.BUNDLE:
        message = downloadConfirmationBundle;
      break;
      case DownloadFiltersTypeEnum.MODEL:
        message = downloadConfirmationModel;
      break;
      case DownloadFiltersTypeEnum.ASSET:
        message = downloadConfirmationAsset;
      break;
      default:
        throw new Error('Invalid Download Filters Type');
      break;
    }
    return message;
  }

  generateStatusName(
    selectedStatusTypes: string[]
  ): string {
    let name: string = '';
    for (var i=0; i < selectedStatusTypes.length; i ++) {
      if (i == selectedStatusTypes.length-1) {
        name += this.refineStatusName(
          this.statusList
            .find(x => x.code == selectedStatusTypes[i])
            .name
        );
      } else {
        name += `${this.refineStatusName(
          this.statusList
          .find(x => x.code == selectedStatusTypes[i])
          .name
        )}_`;
      }
    }
    return name;
  }

  refineStatusName(name: string): string {
    return name.replace(' ', '_').toLowerCase();
  }
}
