import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { CrudService } from 'src/app/core/services/data/crud.service';
import { LoaderMessagingService } from 'src/app/core/services/messaging/loader-messaging.service';
import { ErrorHandlerService } from 'src/app/core/services/util/error-handler.service';
import { HeaderMessagingService } from 'src/app/core/services/messaging/header-messaging.service';
import { DataCustomizerService } from 'src/app/core/services/util/data-customizer.service';
import { FabButtonMessagingService } from 'src/app/core/services/messaging/fab-button-messaging.service';
import { UserService } from 'src/app/core/services/util/user.service';
import { VIEW_MODULE_NAME } from 'src/app/core/constants/configuration/common.constant';
import { CLASSES_PATH, ASSET_PATH, ASSET_UPDATE_PATH } from 'src/app/core/constants/routes.constant';
import { allAssetsEndpoint, allStudentsEndpoint } from 'src/app/core/constants/endpoints.constant';
import { takeUntil } from 'rxjs/operators';
import { settingsRoutingPermissions, TEACHER } from 'src/app/core/constants/configuration/role-constants.config';
import { Asset } from 'src/app/core/models/asset.model';
import { Student } from 'src/app/core/models/eos/student.model';
import { QueryService } from 'src/app/core/services/util/query.service';
import { assetModuleName } from 'src/app/core/constants/configuration/asset-constants.config';

@Component({
	selector: 'app-asset-view',
	templateUrl: './asset-view.component.html',
	styleUrls: ['./asset-view.component.scss']
})
export class AssetViewComponent implements OnInit, OnDestroy {

	unsubscribe: Subject<any> = new Subject();
	data: any = {};
	hasInitialized: boolean = false;
	statusList: any;
	includeInactive: boolean = false;

	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private crudService: CrudService,
		private loaderMessagingService: LoaderMessagingService,
		private errorHandlerService: ErrorHandlerService,
		private headerMessagingService: HeaderMessagingService,
		private fabButtonMessagingService: FabButtonMessagingService,
		private userService: UserService,
		private queryService: QueryService
	) {
		this.userService.checkRolePermission(ASSET_PATH, settingsRoutingPermissions);
		this.headerMessagingService.setHeader(assetModuleName, VIEW_MODULE_NAME, false, null);
		this.includeInactive = !this.userService.hasRole([TEACHER]);
	}

	ngOnInit() {
		this.loaderMessagingService.showPageLoader(true);
		this.findAsset(this.route.snapshot.paramMap.get('id'));
	}

	ngOnDestroy(): void {
		this.fabButtonMessagingService.sendMessage({ canEdit: false, path: null, id: null });
		this.unsubscribe.next();
		this.unsubscribe.complete();
	}

	handleCompletion = (): void => {
		this.loaderMessagingService.showPageLoader(false);
	};

	get canView(): boolean {
		return this.data.asset && this.hasInitialized;
	}

	onLinkClick(id: number): void {
		this.router.navigate([CLASSES_PATH, id]);
	}

	// PUT ALL API CALL BELOW
	private findAsset(id: string): void {

		this.crudService
			.getById<Asset>(allAssetsEndpoint.concat(`/${id}`))
			.pipe(takeUntil(this.unsubscribe))
			.subscribe(response => {
				if (response) {
					this.transformStatus(response);
				}
			}, this.errorHandlerService.handleError, this.handleCompletion);

	}

	transformStatus(asset) {
		this.crudService
			.getAll<any>(
				allAssetsEndpoint.concat("/status"))
			.pipe(takeUntil(this.unsubscribe))
			.subscribe((response: any) => {
				if (response) {
					response.forEach(status => {
						if (status.code === asset.status) {
							asset.status = status.name;
						}
					});
					this.setCurrentStudent(asset);
				}
			},
				this.errorHandlerService.handleError,
			);
	}

	private initFabMessage(): void {
		let roles = settingsRoutingPermissions.find(route => route.path === ASSET_PATH);
		const message: any = { canEdit: this.userService.hasRole(roles ? roles.roles : []), path: ASSET_UPDATE_PATH, id: this.data.asset.id };
		this.fabButtonMessagingService.sendMessage(message);
		this.hasInitialized = true;
	}

	private setCurrentStudent(asset) {
		if (asset.loanedTo) {
			this.search(asset);
		} else {
			this.data.asset = asset;
			this.initFabMessage();
		}
	}

	search(asset) {

		const filter = "districtId";

		this.crudService
			.getAll<Student>(allStudentsEndpoint.concat(this.queryService.buildStudentSearchQuery(
				0, 10, this.includeInactive, asset.loanedTo, filter)))
			.pipe(takeUntil
				(this.unsubscribe))
			.subscribe(response => {
				if (response) {
					let student: any;
					student = response.content[0];
					asset.loanedTo = student;
					asset.loanedTo.name = student.firstName
						+ " " + student.lastName
						+ " " + "(" + student.districtId + ")";
				}
				this.data.asset = asset;
				this.initFabMessage();
			},
				this.errorHandlerService.handleError
			);

	}
}
