import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormUtil } from 'src/app/core/services/util/form.util';
import { BundleMainForm } from 'src/app/core/models/form/bundle-main-form.model';
import { NEXT, PER_YEAR, FIRST_SEM, ACTIVE, INACTIVE } from 'src/app/core/constants/configuration/common.constant';
import { CrudService } from 'src/app/core/services/data/crud.service';
import { LoaderMessagingService } from 'src/app/core/services/messaging/loader-messaging.service';
import { ErrorHandlerService } from 'src/app/core/services/util/error-handler.service';
import { Subject } from 'rxjs';
import { allBundlesEndpoint, existsByCode, existsByName } from 'src/app/core/constants/endpoints.constant';
import { takeUntil } from 'rxjs/operators';
import * as moment from 'moment';
import { NumberHelper } from 'src/app/core/services/util/number-helper';
@Component({
  selector: 'app-bundle-main-form',
  templateUrl: './bundle-main-form.component.html',
  styleUrls: ['./bundle-main-form.component.scss']
})
export class BundleMainFormComponent implements OnInit {

  @Output() getFormValue = new EventEmitter<any>();
  @Output() changePage = new EventEmitter<any>();
  @Input() bundleFormValues: BundleMainForm;
  @Input() forUpdate: boolean;
  @Input() isFirst: boolean;
  @Input() isLast: boolean;
  @Input() schoolYears: any[];
  @Input() status: any[];
  @Input() activePeriods: any[];
  unsubscribe: Subject<any> = new Subject();
  existsByNameEndpoint: string = allBundlesEndpoint.concat(existsByName);
  existsByCodeEndpoint: string = allBundlesEndpoint.concat(existsByCode);
	exists: boolean = true;
	timeout: number = 300;

  bundleForm: FormGroup;

  minDate: string;

  constructor(
    private formUtil: FormUtil,
    private crudService: CrudService,
    private loaderMessagingService: LoaderMessagingService,
    private errorHandlerService: ErrorHandlerService,
    private numberHelper: NumberHelper
  ) {
    this.bundleForm = formUtil.createBundleMainForm();
  }

  ngOnInit(): void {
    if (this.forUpdate || this.bundleFormValues) this.initFormValues();
  }

  onClick = (label: string): void => {
		if(label === NEXT) {
			this.validateUntouchedForms();
			this.validateName().then(() => {
        setTimeout(() => {
          if (!this.bundleForm.valid) {
            return;
          } else {
            this.changePage.emit(label);
          this.getFormValue.emit(this.bundleForm.value);
          this.bundleForm.reset();
          }
        }, this.timeout);
      });
		}
	}

  setSchoolYear(id: number): void {
    this.bundleForm.patchValue({ syCode: id });
  }

  private initFormValues = (): void => {
    this.minDate = new Date().toJSON().split('T')[0];
    this.bundleForm.setValue(this.formUtil.initBundleMainForm(this.bundleFormValues));
    this.changeStatusValue();
  }

  onPeriodCheckboxChange($event: any): void {

    const checkBoxStatus: boolean = $event.checked;
    if (checkBoxStatus) {
      this.bundleForm.controls['activePeriodType'].patchValue(PER_YEAR);
    } else {
      this.bundleForm.controls['activePeriodType'].patchValue(FIRST_SEM);
    }
  }

  validateUntouchedForms(): void {
    Object.keys(this.bundleForm.controls).forEach(control => {
      this.bundleForm.get(control).markAsTouched();
      this.bundleForm.get(control).markAsDirty();
    });
  }

  async validateName() {
		this.exists = true;
		this.bundleForm.controls['name'].updateValueAndValidity();
		
		const name = this.bundleForm.get("name").value;
		const syCode = this.bundleForm.get("syCode").value;
		const code = this.bundleForm.get("code").value;
		if (name && syCode) {
			this.crudService
			.getById<JSON>(this.existsByNameEndpoint.concat(`
				${encodeURIComponent(this.bundleForm.get("name").value)}
				&syCode=
				${encodeURIComponent(this.bundleForm.get("syCode").value)}
				&code=
				${encodeURIComponent(code ? code : "")}
			`))
			.pipe(takeUntil(this.unsubscribe))
			.subscribe((response: any) => {
				response.value = this.bundleForm.get("name").value;
				this.checkExistResult(response);
				this.exists = response.exists;
			}, this.errorHandlerService.handleError);
		}
  }
  
  checkExistResult(result: any): void {
		if (result.exists) {
			this.bundleForm.controls['name'].setErrors({'nameExists': true});
		} else {
			this.bundleForm.controls['name'].updateValueAndValidity();
		}
	}

  onNameChange($event: any) {
    this.loaderMessagingService.showPageLoader(true);
    const keyword = $event.target.value;
    this.crudService
    .getById<JSON>(allBundlesEndpoint.concat(`/exists-by-name?name=${keyword}`))
    .pipe(takeUntil(this.unsubscribe))
    .subscribe(
      (data: any) => {
        if (data.exists) {
          if ((this.bundleFormValues && keyword !== this.bundleFormValues.name && this.forUpdate) || !this.forUpdate) {
            this.bundleForm.controls['name'].setErrors({'nameExists': true});
          } else {
            this.bundleForm.controls['name'].updateValueAndValidity();
          }
        }
      },
      this.errorHandlerService.handleError,
      this.handleCompletion
    );
  }

  handleCompletion = (): void => {
    this.loaderMessagingService.showPageLoader(false);
  }

  onEndDateChange($event: any): void {
    this.changeStatusValue();
  }

  onStartDateChange($event: any): void {
    this.changeStatusValue();
  }

  changeStatusValue(): void {
    const startDate = moment(this.bundleForm.controls['startDate'].value).hours(0).minutes(0).seconds(0);
    const endDate = moment(this.bundleForm.controls['endDate'].value).hours(23).minutes(59).seconds(59);
    if (moment().isBetween(startDate, endDate)) {
      this.bundleForm.controls['availability'].setValue(ACTIVE);
    } else {
      this.bundleForm.controls['availability'].setValue(INACTIVE);
    }
  }

  onGetCodeResult(result: any): void {

		if (result.exists) {

			if ((this.bundleFormValues && result.value !== this.bundleFormValues.code && this.forUpdate) || !this.forUpdate) {
				this.bundleForm.controls['code'].setErrors({'codeExists': true});
			} else {
				this.bundleForm.controls['code'].updateValueAndValidity();
			}

		}

  }
  
  onGetNameResult(result: any): void {

		if (result.exists) {

			if ((this.bundleFormValues && result.value !== this.bundleFormValues.name && this.forUpdate) || !this.forUpdate) {
				this.bundleForm.controls['name'].setErrors({'nameExists': true});
			} else {
				this.bundleForm.controls['name'].updateValueAndValidity();
			}

		}

  }
  
  onChangeNumber(type: string): void {
		const value: number = this.bundleForm.controls[type].value;
    const max = 999999999;
		if (value === null){
			this.bundleForm.controls[type].setValue("");
		} else if (value === -0) {
			this.bundleForm.controls[type].setValue(0);
    } else if (value > max) {
      this.bundleForm.controls[type].setValue(String(value).substring(0,9));
    } else if (
      this.numberHelper.isNegativeNumber(value)
      && this.numberHelper.getCentsValue(value).toString().length > 2
    ) {
      this.bundleForm.controls[type].setValue(value.toFixed(2));
    }
	}

}
