import { DataProperties } from "../../models/data-properties.model";

export const assetModuleName: string = "ASSET";
export const assetTab: string = "ASSET";
export const orderTxnHistoryTab: string = "ORDER_TXN_HISTORY";
export const orderItemTxnHistoryTab: string = "ORDER_ITEM_TXN_HISTORY";
export const sideNavLabelModel: string[] = ["Main Form", "Summary"];

export const ASSET_STATUS_1: string = "ASSET_STATUS_1";
export const ASSET_STATUS_2: string = "ASSET_STATUS_2";
export const ASSET_STATUS_3: string = "ASSET_STATUS_3";
export const ASSET_STATUS_4: string = "ASSET_STATUS_4";

export const assetDataProperties: DataProperties[] = [
    {
        label: "Asset Tag",
        property: "code",
        sortable: true,
        clickable: true,
        filterable: true,
        autocomplete: false,
        endpoint: ""
    },
    {
        label: "Model Name",
        property: "itemModelName",
        sortable: true,
        clickable: false,
        filterable: true,
        autocomplete: false,
        endpoint: ""
    },
    {
        label: "Model ID or ISBN",
        property: "itemModelId",
        sortable: true,
        clickable: false,
        filterable: true,
        autocomplete: false,
        endpoint: ""
    },
    {
        label: "Status",
        property: "status",
        sortable: true,
        clickable: false,
        filterable: true,
        autocomplete: false,
        endpoint: ""
    },
    {
        label: "Student Name",
        property: "studentName",
        sortable: false,
        clickable: false,
        filterable: false,
        autocomplete: false,
        endpoint: ""
    },
    {
        label: "District ID",
        property: "loanedTo",
        sortable: false,
        clickable: false,
        filterable: true,
        autocomplete: false,
        endpoint: ""
    },
    {
        label: "Order ID",
        property: "cosOrderId",
        sortable: false,
        clickable: false,
        filterable: false,
        autocomplete: false,
        endpoint: ""
    },
    {
        label: "Consumable",
        property: "consumable",
        sortable: false,
        clickable: false,
        filterable: false,
        autocomplete: false,
        endpoint: ""
    }
];
