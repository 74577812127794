import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { studentListDataProperties } from 'src/app/core/constants/configuration/student-constants.config';
import { DataProperties } from 'src/app/core/models/data-properties.model';
import { LoaderMessagingService } from 'src/app/core/services/messaging/loader-messaging.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DataCustomizerService } from 'src/app/core/services/util/data-customizer.service';
import { STUDENTS_PATH } from 'src/app/core/constants/routes.constant';
import { Router } from '@angular/router';
import { UserService } from '../../../../../core/services/util/user.service';

@Component({
	selector: 'students-list',
	templateUrl: './students-list.component.html',
	styleUrls: ['./students-list.component.scss']
})
export class StudentsListComponent implements OnInit, OnDestroy {

    @Input() items: any[];

    unsubscribe: Subject<any> = new Subject();
    isLoading: boolean = false;
    dataProperties: DataProperties[] = studentListDataProperties;

    constructor(
        private loaderMessagingService: LoaderMessagingService,
        private router: Router,
    ) {
        this.subscribeToLoaderMessagingService();
    }

    ngOnInit(): void {
    }

    ngOnDestroy(): void {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    formatAmount(value: number): string {
      return value < 0 ? `$ (${(value * -1).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')})`
        : `$ ${value.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}`
    }

    hasTooltipAndEllipses(property: string): boolean {
        return ['name', 'scope', 'learningCenter'].includes(property);
    }

    onLinkClick($event: any, id: string): void {
        this.router.navigate([STUDENTS_PATH, id]);
    }

    private subscribeToLoaderMessagingService(): void {
        this.loaderMessagingService.listLoader$
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(show => setTimeout(() => (this.isLoading = show)));
    }

}
