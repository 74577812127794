import { Component, OnInit } from '@angular/core';
import { HeaderMessagingService } from 'src/app/core/services/messaging/header-messaging.service';
import { settingsModuleName } from 'src/app/core/constants/configuration/common.constant';
import { settingsRoutingPermissions } from 'src/app/core/constants/configuration/role-constants.config';

@Component({
	selector: 'settings',
	templateUrl: './settings.component.html',
	styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {

	constructor(private headerMessagingService: HeaderMessagingService) {
		this.headerMessagingService.setHeader(settingsModuleName, "", true, settingsRoutingPermissions);
	}

	ngOnInit() {
	}

}
