import { Injectable } from "@angular/core";
import { HttpErrorResponse } from "@angular/common/http";
import { Subject, Observable } from "rxjs";
import { ErrorMessage } from "../../models/error-message.model";
import { unauthorizeMessage } from "../../constants/message.constant";

@Injectable({ providedIn: 'root' })
export class ErrorHandlerService {

	private errorSource: Subject<ErrorMessage> = new Subject<ErrorMessage>();
	errors$: Observable<ErrorMessage> = this.errorSource.asObservable();

	constructor() {}

	handleError = (error: HttpErrorResponse | any): void => {

		let errorMessage: ErrorMessage;

		if (error instanceof HttpErrorResponse) {

			let message: string =  `Error encountered while accessing ${error.url}: Reason ${error.error.error_description}`

			if(error.status === 400) {
				message = error.error;
			}

			if (error.status === 401) {
				localStorage.clear();
				message = unauthorizeMessage;
			}
			if (error.status === 406) message = error.error[0].toUpperCase() + error.error.substr(1).toLowerCase();
			if (error.status === 500) message = error.error;
			if (error.status === 0 || error.status === 503) message = 'Connection timeout. Service is currently unavailable.';

			errorMessage = {
				status: error.status,
				type: error.name,
				message,
			};

		} else {

			errorMessage = {
				status: null,
				type: 'Client Side Error',
				message: error,
			}

		}

		this.sendMessage(errorMessage);

	}

	sendMessage(error: ErrorMessage): void {
		this.errorSource.next(error);
	}

}
