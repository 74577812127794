import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subject } from "rxjs";
import { takeUntil } from 'rxjs/operators';
import { MatDialog } from "@angular/material";
import { CrudService } from "src/app/core/services/data/crud.service";
import { LoaderMessagingService } from 'src/app/core/services/messaging/loader-messaging.service';
import { ErrorHandlerService } from 'src/app/core/services/util/error-handler.service';
import { HeaderMessagingService } from 'src/app/core/services/messaging/header-messaging.service';
import { ORDER, PROCESSING_STATUS_1 } from 'src/app/core/constants/configuration/order-constants.config';
import { VIEW_MODULE_NAME } from 'src/app/core/constants/configuration/common.constant';
import { Order } from "src/app/core/models/order/old/old-order.model";
import { Task } from "src/app/core/models/order/old/old-task.model";
import { StudentInfo } from "src/app/core/models/eos/student-info.model";
import {
  oldOrdersEndpoint,
  allOrdersEndpoint,
  allStudentsEndpoint,
  getByOrderCodeTaskEndpoint,
  getProcessingStatusEndpoint,
  getShippingDetailsEndpoint,
  oldEndpoint,
} from 'src/app/core/constants/endpoints.constant';
import { ORDERS_PATH } from 'src/app/core/constants/routes.constant';
import { homeRoutingPermissions, TEACHER } from 'src/app/core/constants/configuration/role-constants.config';
import { UserService } from "src/app/core/services/util/user.service";
import { GuardianShippingDetails } from '../../../../../core/models/eos/guardian-shipping-details.model';
import { DataCustomizerService } from '../../../../../core/services/util/data-customizer.service';

@Component({
  selector: 'app-old-order-view',
  templateUrl: './order-view.component.html',
  styleUrls: ['./order-view.component.scss']
})
export class OldOrderViewComponent implements OnInit {

	unsubscribe: Subject<any> = new Subject();
  orderDetails: Order;
  taskDetails: Task[] = [];
  studentInfo: StudentInfo;
  shippingDetails: any[] = [];
  processingStatus: any[] = [];

  constructor(
    private crudService: CrudService,
    private dataCustomizerService: DataCustomizerService,
    private dialog: MatDialog,
		private errorHandlerService: ErrorHandlerService,
		private headerMessagingService: HeaderMessagingService,
		private loaderMessagingService: LoaderMessagingService,
		private route: ActivatedRoute,
    private userService: UserService,
	) {
		this.userService.checkRolePermission(ORDERS_PATH, homeRoutingPermissions);
    this.headerMessagingService.setHeader(ORDER, VIEW_MODULE_NAME, false, null);
  }

  ngOnInit() {
		this.loaderMessagingService.showPageLoader(true);
		this.getOrderDetails(this.route.snapshot.paramMap.get('id'));
  }

  getOrderDetails(id: string) {
    this.crudService.getById<Order>(oldOrdersEndpoint.concat(`/${id}`))
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(response => {
        this.orderDetails = response;
        this.getStudentById(this.orderDetails.studentId);
      }, this.errorHandlerService.handleError);
  }

  getStudentById(id: string): void {
    this.crudService.getById<StudentInfo>(allStudentsEndpoint.concat(`/${id}`))
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(response => {
        this.studentInfo = response;
        this.getProcessingStatus();
      }, this.errorHandlerService.handleError);
  }
  
  getProcessingStatus(): void {
		this.crudService
			.getById<any>(getProcessingStatusEndpoint)
			.pipe(takeUntil(this.unsubscribe))
			.subscribe(response => {
				this.processingStatus = response;
				this.processingStatus.sort((a, b) => b.code.localeCompare(a.code));
        this.getTaskDetails();
			}, this.errorHandlerService.handleError);
  }

  getTaskDetails() {
    this.crudService.getAllBy<Task>(oldEndpoint.concat(getByOrderCodeTaskEndpoint.concat(`?code=${this.orderDetails.code}`)))
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(response => {
        this.taskDetails = response;
				this.getGuardianShippingDetails();
      }, this.errorHandlerService.handleError);
  }

  getGuardianShippingDetails(): void {
    this.shippingDetails = [];
    this.crudService
      .getAllBy<GuardianShippingDetails>(getShippingDetailsEndpoint.concat(`/${this.studentInfo.studentDetails.id}`))
      .pipe(takeUntil(this.unsubscribe))
			.subscribe(response => {
				if (response) {
          response.forEach(shippingDetail => {
            this.shippingDetails.push(
              this.dataCustomizerService.formatShippingDetailDisplay(shippingDetail))
          });
          const shippingDetail = {
            ...this.orderDetails.shippingDetails,
            check: true,
            addressLine: this.orderDetails.shippingDetails ? this.orderDetails.shippingDetails.line : "",
            addressName: this.orderDetails.shippingDetails ? this.orderDetails.shippingDetails.name : "",
          };
          let match = this.shippingDetails.find(detail => this.doesShippingAddressExists(detail, shippingDetail));
          if(match) this.shippingDetails.forEach(detail => { if(match.id === detail.id) detail.check = true; });
          else this.shippingDetails.push(shippingDetail);
				}
    	}, this.errorHandlerService.handleError, this.handleCompletion);
  }

  doesShippingAddressExists(shippingDetail: any, currentShippingDetail: any) {
    return shippingDetail.addressLine === currentShippingDetail.addressLine &&
      shippingDetail.addressName === currentShippingDetail.addressName &&
      shippingDetail.city === currentShippingDetail.city &&
      shippingDetail.email === currentShippingDetail.email &&
      shippingDetail.phone === currentShippingDetail.phone &&
      shippingDetail.state === currentShippingDetail.state &&
      shippingDetail.zipCode === currentShippingDetail.zipCode
  }

  get isUserTeacher(): boolean {
    return this.userService.hasRole([TEACHER]);
  }

  get isOrderHstApproved(): boolean {
    return this.orderDetails.status === PROCESSING_STATUS_1;
  }

	handleCompletion = (): void => {
		this.loaderMessagingService.showPageLoader(false);
  };

}
