import { Component, OnInit } from '@angular/core';
import { HeaderMessagingService } from 'src/app/core/services/messaging/header-messaging.service';
import { CrudService } from 'src/app/core/services/data/crud.service';
import { ErrorHandlerService } from 'src/app/core/services/util/error-handler.service';
import { MatDialog } from '@angular/material';
import { Router } from '@angular/router';
import { Resource } from 'src/app/core/models/resource.model';
import { baseServerUri } from 'src/app/core/constants/configuration/config.constant';
import { allResourcesEndpoint } from 'src/app/core/constants/endpoints.constant';
import { takeUntil } from 'rxjs/operators';
import { RESOURCES_PATH } from 'src/app/core/constants/routes.constant';
import { DialogBoxComponent } from 'src/app/shared/components/dialog-box/dialog-box.component';
import { dialogBoxSuccessTitle, createSuccessResource } from 'src/app/core/constants/message.constant';
import { Subject } from 'rxjs';
import { sideNavLabelResource, RESOURCE } from 'src/app/core/constants/configuration/resource-constants.config';
import { CREATE_MODULE_NAME } from 'src/app/core/constants/configuration/common.constant';
import { UserService } from 'src/app/core/services/util/user.service';
import { homeRoutingPermissions } from "src/app/core/constants/configuration/role-constants.config";

@Component({
  selector: 'app-resource-create',
  templateUrl: './resource-create.component.html',
  styleUrls: ['./resource-create.component.scss']
})
export class ResourceCreateComponent implements OnInit {
  
  forUpdate: boolean;
  activeIndex: number = 0;
  isProcessing: boolean = false;
  sideNavLabels: string[] = [];
  unsubscribe: Subject<any> = new Subject();

  constructor(
    private headerMessagingService: HeaderMessagingService,
    private crudService: CrudService,
    private errorHandlerService: ErrorHandlerService,
    public dialog: MatDialog,
    public router: Router,
    private userService: UserService,
	) {
		this.userService.checkRolePermission(RESOURCES_PATH, homeRoutingPermissions);
    this.headerMessagingService.setHeader(RESOURCE, CREATE_MODULE_NAME, false, null);
    this.sideNavLabels = sideNavLabelResource;
  }

  ngOnInit() {
  }

  onResourceSave($event : Resource) {
    this.isProcessing = true;
    this.crudService
      .add<Resource>(allResourcesEndpoint, $event)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(response => {
          this.router.navigate([RESOURCES_PATH, response.id]);
          const dialogRef = this.dialog.open(DialogBoxComponent);
          dialogRef.componentInstance.dialogTitle = dialogBoxSuccessTitle;
          dialogRef.componentInstance.contentMessage = createSuccessResource;
      },  this.errorHandlerService.handleError);
  }

}
