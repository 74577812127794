import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { changeSize } from "src/app/core/constants/animations.constant";
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Resource } from 'src/app/core/models/resource.model';
import { CrudService } from 'src/app/core/services/data/crud.service';
import { ErrorHandlerService } from 'src/app/core/services/util/error-handler.service';
import { LoaderMessagingService } from 'src/app/core/services/messaging/loader-messaging.service';
import { getResourceTypesEndpoint } from 'src/app/core/constants/endpoints.constant';
import { RESOURCE_TYPE_CODE_2 } from 'src/app/core/constants/configuration/resource-constants.config';

@Component({
  selector: 'app-order-resource-modal-form',
  templateUrl: './order-resource-modal-form.component.html',
  styleUrls: ['./order-resource-modal-form.component.scss'],
  animations: [changeSize]
})
export class OrderResourceModalFormComponent implements OnInit {

  @Output() getResource = new EventEmitter<any>();
  @Output() closeResourceFormModal = new EventEmitter<any>();
  unsubscribe: Subject<any> = new Subject();
  formValue: Resource;
  resourceTypes: any[];

  constructor(
    private crudService: CrudService,
    private errorHandlerService: ErrorHandlerService,
    private loaderMessagingService: LoaderMessagingService,
  ) {
    this.loaderMessagingService.showPageLoader(true);
  }

  ngOnInit() {
    this.getResourceTypes();
  }

	onSaveResource($event: Resource) {
    this.getResource.emit($event);
  }

	onClose() {
    this.closeResourceFormModal.emit(true);
  }

  getResourceTypes(): void {
    this.crudService
      .getById<any>(getResourceTypesEndpoint)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(response => {
        this.resourceTypes = response.filter(type => type.code !== RESOURCE_TYPE_CODE_2);
        this.resourceTypes
          .sort((a, b) => a.code.localeCompare(b.code));
      }, this.errorHandlerService.handleError, this.handleCompletion);
  }
  
  handleCompletion = (): void => {
    this.loaderMessagingService.showPageLoader(false);
  };

}
