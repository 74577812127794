import { DataProperties } from "../../models/data-properties.model";

export const TASK: string = "TASK";
export const TASK_GROUP_1: string = "TASK_GROUP_1";
export const TASK_GROUP_2: string = "TASK_GROUP_2";
export const TASK_GROUP_3: string = "TASK_GROUP_3";
export const TASK_GROUP_4: string = "TASK_GROUP_4";
export const TASK_GROUP_5: string = "TASK_GROUP_5";

export const UNASSIGN: string = "UNASSIGN";
export const ASSIGN_OTHERS: string = "ASSIGN_OTHERS";
export const ASSIGN_SELF: string = "ASSIGN_SELF";
export const PRE_COMPLETE: string = "PRE_COMPLETE";
export const COMPLETE: string = "COMPLETE";
export const CREATE_WORKBOOK_TEXTBOOK: string = "CREATE_WORKBOOK_TEXTBOOK";
export const CANCEL: string = "CANCEL";
export const WITHDRAW: string = "WITHDRAW";
export const WORKBOOKTEXTBOOK: string = "Workbook/Text Book";

export const taskDataProperties: DataProperties[] = [
    {
        label: "Task ID",
        property: "code",
        sortable: true,
        clickable: true,
        filterable: true,
        autocomplete: false,
        endpoint: ""
    },
    {
        label: "Curriculum",
        property: "curriculumName",
        sortable: true,
        clickable: false,
        filterable: true,
        autocomplete: false,
        endpoint: ""
    },
    {
        label: "Task Type",
        property: "type",
        sortable: true,
        clickable: false,
        filterable: true,
        autocomplete: false,
        endpoint: ""
    },
    {
        label: "COS Order ID",
        property: "cosOrderCode",
        sortable: true,
        clickable: false,
        filterable: true,
        autocomplete: false,
        endpoint: ""
    },
    {
        label: "EOS Order ID",
        property: "eosOrderCode",
        sortable: true,
        clickable: false,
        filterable: false,
        autocomplete: false,
        endpoint: ""
    },
    {
        label: "Student Name",
        property: "name",
        sortable: true,
        clickable: false,
        filterable: true,
        autocomplete: false,
        endpoint: ""
    },
    {
        label: "District ID",
        property: "districtId",
        sortable: true,
        clickable: false,
        filterable: true,
        autocomplete: false,
        endpoint: ""
    },
    {
        label: "Task Status",
        property: "status",
        sortable: true,
        clickable: false,
        filterable: true,
        autocomplete: false,
        endpoint: ""
    },
    {
        label: "Requested By",
        property: "requestedBy",
        sortable: true,
        clickable: false,
        filterable: true,
        autocomplete: false,
        endpoint: ""
    },
    {
        label: "Requested Date",
        property: "createdDate",
        sortable: true,
        clickable: false,
        filterable: true,
        autocomplete: false,
        endpoint: ""
      },
      {
        label: "Assigned To",
        property: "assignedTo",
        sortable: true,
        clickable: false,
        filterable: true,
        autocomplete: false,
        endpoint: ""
      }  
];
