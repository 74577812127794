import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CrudService } from 'src/app/core/services/data/crud.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { HeaderMessagingService } from 'src/app/core/services/messaging/header-messaging.service';
import { MatDialog } from '@angular/material';
import { allModelsEndpoint } from 'src/app/core/constants/endpoints.constant';
import {
  dialogBoxSuccessTitle,
  updateSuccessModel, 
} from 'src/app/core/constants/message.constant';
import { DialogBoxComponent } from 'src/app/shared/components/dialog-box/dialog-box.component';
import { MODEL_PATH } from 'src/app/core/constants/routes.constant';
import { ErrorHandlerService } from 'src/app/core/services/util/error-handler.service';
import { LoaderMessagingService } from 'src/app/core/services/messaging/loader-messaging.service';
import { sideNavLabelModel, modelModuleName } from 'src/app/core/constants/configuration/model-constants.config';
import { UPDATE_MODULE_NAME } from 'src/app/core/constants/configuration/common.constant';
import { Model } from 'src/app/core/models/model.model';
import { settingsRoutingPermissions } from "src/app/core/constants/configuration/role-constants.config";
import { UserService } from 'src/app/core/services/util/user.service';

@Component({
  selector: 'app-model-update',
  templateUrl: './model-update.component.html',
  styleUrls: ['./model-update.component.scss']
})
export class ModelUpdateComponent implements OnInit {

	unsubscribe: Subject<any> = new Subject();
	activeIndex: number = 0;
	sideNavLabels: string[] = [];
	model: Model;

	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private crudService: CrudService,
		private headerMessagingService: HeaderMessagingService,
		private errorHandlerService: ErrorHandlerService,
		private loaderMessagingService: LoaderMessagingService,
		private dialog: MatDialog,
		private userService: UserService,
	) {
		this.userService.checkRolePermission(MODEL_PATH, settingsRoutingPermissions);
		this.headerMessagingService.setHeader(modelModuleName, UPDATE_MODULE_NAME, false, null);
		this.sideNavLabels = sideNavLabelModel;
	}
	
	ngOnInit() {
		this.loaderMessagingService.showPageLoader(true);
		this.route.params.pipe(takeUntil(this.unsubscribe)).subscribe(params => this.findModel(params["id"]));
	}

	ngOnDestroy(): void {
		this.unsubscribe.next();
		this.unsubscribe.complete();
	}

	private findModel(id: number): void {

		this.crudService
			.getById<Model>(allModelsEndpoint.concat(`/${id}`))
			.pipe(takeUntil(this.unsubscribe))
			.subscribe(response => {
				if (response) this.model = response;
			}, this.errorHandlerService.handleError, this.handleCompletion);

	}

	onSaveModel($event: Model) {
		this.loaderMessagingService.showPageLoader(true);
		this.crudService
			.edit<Model>(allModelsEndpoint.concat(`/${$event.id}`), $event)
			.pipe(takeUntil(this.unsubscribe))
			.subscribe(() => {
				this.router.navigate([MODEL_PATH, $event.id]);
				const dialogRef = this.dialog.open(DialogBoxComponent);
				dialogRef.componentInstance.dialogTitle = dialogBoxSuccessTitle;
				dialogRef.componentInstance.contentMessage = updateSuccessModel;
			}, this.errorHandlerService.handleError, this.handleCompletion);
		
	}

	handleCompletion = (): void => {
		this.loaderMessagingService.showPageLoader(false);
	}

}
