import { Routes } from "@angular/router";

import { AuthGuard } from "src/app/core/guards/auth.guard";
import {
	ORDERS_PATH,
	ORDER_CREATE_PATH,
	TASKS_PATH,
	OLD_ORDERS_PATH,
	STUDENTS_PATH,
	ORDER_DEPOSIT_PATH,
	ORDER_CURRICULUM_FEE_PATH,
	DOWNLOAD_ONLINE_ACCOUNTS_PATH,
	DOWNLOAD_CURRICULUM_REPORTS_PATH,
	DOWNLOAD_REPORTS_PATH,
} from "src/app/core/constants/routes.constant";
import { OrderCreateComponent } from "../order-create/order-create.component";
import { OrdersComponent } from "../orders/orders.component";
import { OrderViewComponent } from "../orders/order-view/order-view.component";
import { OldOrderViewComponent } from "../orders/old/order-view/order-view.component";
import { TasksComponent } from "../task/tasks/tasks.component";
import { TaskViewComponent } from "../task/task-view/task-view.component";
import { StudentsComponent } from "../student/students/students.component";
import { StudentViewComponent } from "../student/student-view/student-view.component";
import { OrderDepositViewComponent } from "../orders/old/order-deposit-view/order-deposit-view.component";
import { DownloadOnlineAccountsComponent } from "../task/download-online-accounts/download-online-accounts.component";
import { DownloadCurriculumReportsComponent } from "../task/download-curriculum-reports/download-curriculum-reports.component";
import { DownloadReportsComponent } from "../task/download-reports/download-reports.component";


export const orderRoutes: Routes = [

	{
		path: ORDERS_PATH,
		component: OrdersComponent,
		canActivate: [AuthGuard],
	},
	{
		path: ORDER_CREATE_PATH,
		component: OrderCreateComponent,
		canActivate: [AuthGuard],
	},
	{
		path: ORDERS_PATH.concat("/:id"),
		component: OrderViewComponent,
		canActivate: [AuthGuard],
	},
	{
		path: OLD_ORDERS_PATH.concat("/:id"),
		component: OldOrderViewComponent,
		canActivate: [AuthGuard],
	},

	{
		path: `${ORDER_DEPOSIT_PATH}/:id`,
		component: OrderDepositViewComponent,
		canActivate: [AuthGuard],
	},
	{
		path: `${ORDER_CURRICULUM_FEE_PATH}/:id`,
		component: OrderDepositViewComponent,
		canActivate: [AuthGuard],
	},

	// TASKS
	{
		path: TASKS_PATH,
		component: TasksComponent,
		canActivate: [AuthGuard],
	},
	{
		path: TASKS_PATH.concat("/:syCode").concat("/:id"),
		component: TaskViewComponent,
		canActivate: [AuthGuard],
	},
	{
		path: DOWNLOAD_ONLINE_ACCOUNTS_PATH,
		component: DownloadOnlineAccountsComponent,
		canActivate: [AuthGuard],
	},
	{
		path: DOWNLOAD_CURRICULUM_REPORTS_PATH,
		component: DownloadCurriculumReportsComponent,
		canActivate: [AuthGuard],
	},
	{
		path: DOWNLOAD_REPORTS_PATH,
		component: DownloadReportsComponent,
		canActivate: [AuthGuard],
	},

	// STUDENTS
	{
		path: STUDENTS_PATH,
		component: StudentsComponent,
		canActivate: [AuthGuard],
	},
	{
		path: `${STUDENTS_PATH}/:id`,
		component: StudentViewComponent,
		canActivate: [AuthGuard],
	}


];
