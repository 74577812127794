import { Injectable } from '@angular/core';
import { CurriculumClassForm } from '../../models/form/curriculum-class-form.model';
import { Class } from '../../models/class.model';
import { Curriculum } from '../../models/curriculum.model';

@Injectable({ providedIn: 'root' })
export class ClassHelper {

    constructor() { }

    setClassModel(classFormValues: CurriculumClassForm, forUpdate: boolean, original?: Class): Class {

      return {

        id: forUpdate ? original.id : null,
        code: forUpdate ? original.code : null,
        name: classFormValues.name,
        description: classFormValues.description,
        status: classFormValues.status,
        createdBy: forUpdate ? original.createdBy : "",
        modifiedBy:   forUpdate ? original.modifiedBy : "",
        createdDate:  forUpdate ? original.createdDate : "",
        modifiedDate: forUpdate ? original.createdDate : "",
        //activeStatus: original.activeStatus,
        courseCode: classFormValues.courseCode,
        subject: classFormValues.subject,
        fromGradeLevel: classFormValues.fromGradeLevel,
        toGradeLevel: classFormValues.toGradeLevel,
        activePeriodType: classFormValues.activePeriodType,
        hqtUsername: classFormValues.hqtUsername,
        deposit: classFormValues.deposit || 0,
        fee: classFormValues.fee || 0,
        curriculum: this.setCurriculum(classFormValues.curriculumCode, classFormValues.hqtUsername),
        classCurriculum: null,
        maxSlot: classFormValues.maxSlot,
        orderedCount: null,
        processedCount: null,
        closed: null,
      };

    }

    setCurriculum(code: any, hqtUsername: string): Curriculum {

		return {
			id: null,
			code: code,
			name: null,
			description: null,
			status: null,
			createdBy: null,
			modifiedBy: null,
			createdDate: null,
      modifiedDate: null,
      //activeStatus: null,
			startDate: null,
			endDate: null,
      syCode: null,
      deposit: null,
			hasDeposit: null,
			fee: null,
			perClassCost: null,
			hasHqt: hqtUsername && hqtUsername.trim().length > 0,
			classes: null,
      bookRequired: null,
      scopes: null
		};

	}

    setClassMainFormValues(clazz: Class): CurriculumClassForm {

    return {
          curriculumCode: clazz.classCurriculum.code,
          name: clazz.name,
          courseCode: clazz.courseCode,
          description: clazz.description,
          subject: clazz.subject,
          status: clazz.status,
          fromGradeLevel: clazz.fromGradeLevel,
          toGradeLevel: clazz.toGradeLevel,
          activePeriodType: clazz.activePeriodType,
          hqtName: "",
          hqtUsername: clazz.hqtUsername,
          deposit: clazz.deposit,
          fee: clazz.fee,
          maxSlot: clazz.maxSlot,
      };

    }

}
