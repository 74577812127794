import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Curriculum } from 'src/app/core/models/curriculum.model';
import { MatDialog } from '@angular/material';
import { CrudService } from 'src/app/core/services/data/crud.service';
import { ErrorHandlerService } from 'src/app/core/services/util/error-handler.service';
import { Subject } from 'rxjs';
import { DataCustomizerService } from 'src/app/core/services/util/data-customizer.service';
import { ClassDetailsDialogComponent } from 'src/app/domains/home/class/class-details-dialog/class-details-dialog.component';
import { Scope } from 'src/app/core/models/scope.model';

@Component({
  selector: 'curriculum-view-dialog',
  templateUrl: './curriculum-view-dialog.component.html',
  styleUrls: ['./curriculum-view-dialog.component.scss']
})
export class CurriculumViewDialogComponent implements OnInit {

  @Input() curriculum: Curriculum;
  @Input() curriculumDisplay: any;
  @Output() linkClick: EventEmitter<any> = new EventEmitter();

  classDisplay: any = {};

  unsubscribe: Subject<any> = new Subject();

  constructor(
    private crudService: CrudService,
    private errorHandlerService: ErrorHandlerService,
    private dialog: MatDialog,
    private dataCustomizerService: DataCustomizerService,
  ) { }

  ngOnInit() {
  }

  onLinkClick($event: any, id: string, index: number): void {
    this.dialog.closeAll();
    this.classDisplay = this.dataCustomizerService.formatClassDetailsDisplay(this.curriculumDisplay.classes[index]);
    this.classDisplay.classCurriculum = this.curriculum;
    const dialogRef = this.dialog.open(ClassDetailsDialogComponent, {
      width: "80%",
      data: this.classDisplay,
    });
  }

}
