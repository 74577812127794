import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-side-nav-label',
  templateUrl: './side-nav-label.component.html',
  styleUrls: ['./side-nav-label.component.scss']
})
export class SideNavLabelComponent implements OnInit {

  @Input()
  sideNavLabels: string[] = [];

  @Input()
  activeIndex: number = 0;

  constructor() { }

  ngOnInit() {
  }

}
