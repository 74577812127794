import { Component, EventEmitter, OnInit, Output, Input } from '@angular/core';
import { Subject } from 'rxjs';
import { MatDialog } from '@angular/material';
import { FormControl, Validators, FormGroup } from '@angular/forms';
import { ConfirmDialogComponent } from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';
import { OrderItem } from 'src/app/core/models/order/order-item.model';
import {
  ORDER_ITEM_TYPE_1,
  ORDER_ITEM_TYPE_3,
  ADJUSTMENT_TYPE_1,
  ADJUSTMENT_TYPE_3,
  ADJUSTMENT_TYPE_2,
  ADJUSTMENT_TYPE_4,
  ORDER_ITEM_TYPE_5,
  ORDER_ITEM_TYPE_4,
} from '../../../../core/constants/configuration/order-constants.config';
import { OrderBundle } from 'src/app/core/models/order/order-bundle.model';


const CLASS_REFUND_TYPE_FEE = 'Class Fee';
const CLASS_REFUND_TYPE_DEPOSIT = 'Class Deposit';
@Component({
  selector: 'app-order-item-adjustment-dialog',
  templateUrl: './order-item-adjustment-dialog.component.html',
  styleUrls: ['./order-item-adjustment-dialog.component.scss']
})
export class OrderItemAdjustmentDialogComponent implements OnInit {

  @Input() item: OrderItem;
  @Input() orderBundle: OrderBundle;
  @Input() forRefund: boolean;
  @Output() close = new EventEmitter<any>();
  @Output() refund = new EventEmitter<any>();

  amount: number = 0;
  amountLimit: number = 0;
  reasonLimit: number = 255;
  canRefund: boolean = false;
  fixedRefundAmount: boolean = false;
  refundForm: FormGroup;
  unsubscribe: Subject<any> = new Subject();
  selectedType: any;
  types: any[] = [];
  classTypes: any[] = [
    { label: "Class Cost", value: ADJUSTMENT_TYPE_3 },
    { label: "Class Fee", value: ADJUSTMENT_TYPE_1 },
    { label: "Class Deposit", value: ADJUSTMENT_TYPE_2 },
  ];
  bundleClassTypes: any[] = [{ label: "Bundle Price", value: ADJUSTMENT_TYPE_4 }];
  bookTypes: any[] = [{ label: "Resource Price", value: ADJUSTMENT_TYPE_1 }];
  depositTypes: any[] = [{ label: "Deposit", value: ADJUSTMENT_TYPE_2 }];
  feeTypes: any[] = [{ label: "Fee", value: ADJUSTMENT_TYPE_1 }];
  classWithBundleTypes: any[] = [
    { label: "Class Fee", value: ADJUSTMENT_TYPE_1 },
    { label: "Class Deposit", value: ADJUSTMENT_TYPE_2 },
    { label: "Bundle Price", value: ADJUSTMENT_TYPE_4 },
  ];

  data: any[] = [
    {duration: '0 - 14 days', amount: '100%'},
    {duration: '15+ days', amount: 'No Refund'},
  ];

  constructor(
    private dialog: MatDialog,
  ) { }

  ngOnInit() {
    // this.types = this.item.referenceType === ORDER_ITEM_TYPE_2 ?
    //   this.item.inBundle && this.forRefund ? this.bundleClassTypes : 
    //   this.item.inBundle && !this.forRefund ? this.classWithBundleTypes : this.classTypes
    //   : this.item.referenceType === ORDER_ITEM_TYPE_3 ? this.bookTypes
    this.types = this.item.referenceType === ORDER_ITEM_TYPE_1 ?
      this.item.inBundle 
      ? this.generateBundleClassRefundTypes(this.item, this.classWithBundleTypes)
      : this.generateClassRefundTypes(this.item, this.classTypes)
      : this.item.referenceType === ORDER_ITEM_TYPE_4 ? this.depositTypes
      : this.item.referenceType === ORDER_ITEM_TYPE_5 ? this.feeTypes
      : this.item.referenceType === ORDER_ITEM_TYPE_3 ? this.bookTypes : [];
    this.refundForm = new FormGroup({
      refundAmount: new FormControl(0, [Validators.max(this.amountLimit), Validators.min(0)]),
      adjustAmount: new FormControl(),
      type: new FormControl(""),
      remarks: new FormControl(""),
    });
  }

  generateClassRefundTypes(
    item: OrderItem,
    classRefundTypes: any[]
  ): any[] {
    // Validate Deposit Fee if Zero: Remove Class Deposit
    if (item.deposit <= 0) {
      classRefundTypes.splice(
        this.getTypeIndexFromArray(
          classRefundTypes,
          CLASS_REFUND_TYPE_DEPOSIT
        ),
        1
      );
    }
    // If Fee is Zero: Remove Class Fee:
    if(item.price <= 0) {
      classRefundTypes.splice(
        this.getTypeIndexFromArray(
          classRefundTypes,
          CLASS_REFUND_TYPE_FEE
        ),
        1
      );
    }
    return classRefundTypes;
  }

  // Helper Methods:
  getTypeIndexFromArray(
    array: any[],
    key: string
  ) {
    return array.findIndex(function(type) {
      return type.label == key
    });
  }

  generateBundleClassRefundTypes(
    item: OrderItem,
    classWithBundleTypes: any[]
  ): any[] {
    // Validate Deposit Fee if Zero: Remove Class Deposit
    if (item.deposit <= 0) {
      classWithBundleTypes.splice(
        this.getTypeIndexFromArray(
          classWithBundleTypes,
          CLASS_REFUND_TYPE_DEPOSIT
        ),
        1
      );
    }
    // If Fee is Zero: Remove Class Fee:
    if(item.price <= 0) {
      classWithBundleTypes.splice(
        this.getTypeIndexFromArray(
          classWithBundleTypes,
          CLASS_REFUND_TYPE_FEE
        ),
        1
      );
    }
    return classWithBundleTypes;
  }

  get refundExpense() {
    if (this.forRefund) {
      let expense = this.amountLimit - this.refundForm.get('refundAmount').value;
      if (expense > 0 && expense <= this.amountLimit) return expense.toFixed(2);
      else return 0;
    } else {
      let expense = this.amountLimit + this.refundForm.get('adjustAmount').value;
      return expense.toFixed(2);
    }
  }

  onChange(fieldName: string) {
    let enteredAmount = this.refundForm.get(fieldName).value;
    if (this.forRefund && enteredAmount > 0 && enteredAmount <= this.amountLimit) this.amount = enteredAmount;
    else this.amount = enteredAmount;
    this.canRefund = this.refundForm.valid;
  }

  onChangeReason() {
    let reason = this.refundForm.get('remarks').value;
    if (reason.length > this.reasonLimit)
      this.refundForm.get('remarks').setValue(this.refundForm.get('remarks').value.substring(0, 255))
    this.canRefund = this.refundForm.valid;
  }
	
	onTypeChange(value) {
    this.selectedType = value;
    if (this.forRefund) {
      if (this.selectedType.value === ADJUSTMENT_TYPE_1)
        this.amountLimit = this.item.newPrice !== null ? this.item.newPrice : this.item.price;
      else if (this.selectedType.value === ADJUSTMENT_TYPE_2)
        this.amountLimit = this.item.newDeposit !== null ? this.item.newDeposit : this.item.deposit;
      else if (this.selectedType.value === ADJUSTMENT_TYPE_3)
        this.amountLimit = this.item.newPerClassCost !== null ? this.item.newPerClassCost : this.item.perClassCost;
      else if (this.selectedType.value === ADJUSTMENT_TYPE_4) 
        this.amountLimit = this.orderBundle != null ? this.orderBundle.originalPrice : 0;
      if ((this.selectedType.value === ADJUSTMENT_TYPE_1 && this.item.newPrice === null) ||
          (this.selectedType.value === ADJUSTMENT_TYPE_2 && this.item.newDeposit === null) ||
          (this.selectedType.value === ADJUSTMENT_TYPE_3 && this.item.newPerClassCost === null) ||
          (this.selectedType.value === ADJUSTMENT_TYPE_4 && this.item.newPerClassCost === null)) {
        this.refundForm.removeControl('refundAmount');
        // let defaultRefundAmount = this.item.referenceType === ORDER_ITEM_TYPE_7 ? this.item.deposit :
        //   this.item.referenceType === ORDER_ITEM_TYPE_8 ? this.item.price : 0;
        let defaultRefundAmount = 0;
        if (this.fixedRefundAmount) this.amount = defaultRefundAmount;
        this.refundForm.addControl('refundAmount', 
          new FormControl(defaultRefundAmount, [Validators.max(this.amountLimit), Validators.min(0)]));
        this.refundForm.controls['type'].updateValueAndValidity();
      } else {
        this.refundForm.controls['type'].setErrors({ 'invalid': true });
      }
    } else {
      if (this.selectedType.value === ADJUSTMENT_TYPE_1)
        this.amountLimit = this.item.adjustedPrice !== null ? this.item.adjustedPrice
          : this.item.newPrice !== null ? this.item.newPrice : this.item.price;
      else if (this.selectedType.value === ADJUSTMENT_TYPE_2)
        this.amountLimit = this.item.adjustedDeposit !== null ? this.item.adjustedDeposit
          : this.item.newDeposit !== null ? this.item.newDeposit : this.item.deposit;
      else if (this.selectedType.value === ADJUSTMENT_TYPE_3)
        this.amountLimit = this.item.adjustedPerClassCost !== null ? this.item.adjustedPerClassCost
          : this.item.newPerClassCost !== null ? this.item.newPerClassCost : this.item.perClassCost;
      else if (this.selectedType.value === ADJUSTMENT_TYPE_4) {
        let itemBundle = this.item.orderBundle;
        this.amountLimit = itemBundle.adjustedPrice !== null ? itemBundle.adjustedPrice : itemBundle.price;
      }
    }
    this.canRefund = this.refundForm.valid;
	}

  onClose() {
    this.close.emit(null);
  }

  onRefund() {
    const dialogRef = this.dialog.open(ConfirmDialogComponent);
    dialogRef.componentInstance.confirmMessage = `Are you sure you want to refund 
    ${this.toAmount(this.amount)} from ${this.item.referenceName}?`;

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.refund.emit({ amount: this.amount, type: this.selectedType.value, remarks: this.refundForm.get('remarks').value });
      }
    });
  }

  onAdjust() {
    const dialogRef = this.dialog.open(ConfirmDialogComponent);
    dialogRef.componentInstance.confirmMessage = `Are you sure you want to ${ this.amount > 0 ? 'add ' : 'deduct'  } 
      ${this.toAmount(Math.abs(this.amount))} to ${this.item.referenceCode} ${this.item.referenceName}?`;
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.refund.emit({ amount: this.amount, type: this.selectedType.value, remarks: this.refundForm.get('remarks').value });
      }
    });
  }

  toAmount(amount: number) {
    return '$' + amount.toFixed(2);
  }

  get showSuggestedAmount() {
    return true
    // return this.item.referenceType != ORDER_ITEM_TYPE_7 && this.item.referenceType != ORDER_ITEM_TYPE_8;
  }

  get isDepositItem() {
    return false
    // return this.item.referenceType === ORDER_ITEM_TYPE_7;
  }

  //Temp Method for Disable
  get isBundlePrice(){
    let isDisable = false;
    if(this.selectedType && this.selectedType.value == ADJUSTMENT_TYPE_4){
      isDisable = true;
    } else if(this.canRefund){
      isDisable = false;
    }
    return isDisable;
  }

}
