import { Component, EventEmitter, OnInit, Output, Input } from '@angular/core';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { faPlus, faPencilAlt, faCommentDots, faTimes } from '@fortawesome/free-solid-svg-icons';
import { ASSET_STATUS_1, ASSET_STATUS_2, ASSET_STATUS_3, ASSET_STATUS_4 } from '../../../../core/constants/configuration/asset-constants.config';
import { allAssetsEndpoint, updateAssetRelatedTaskEndpoint, checkInStatusEndpoint, orderAddBookItemEndpoint, orderUpdateMultipleBookTrackingIdsEndpoint, updateOrderModifiedOnlyEndpoint, updateTaskModifiedOnlyEndpoint } from '../../../../core/constants/endpoints.constant';
import { takeUntil } from 'rxjs/operators';
import { Subject, BehaviorSubject } from 'rxjs';
import { CrudService } from '../../../../core/services/data/crud.service';
import { ErrorHandlerService } from '../../../../core/services/util/error-handler.service';
import { LoaderMessagingService } from '../../../../core/services/messaging/loader-messaging.service';
import { MatDialog } from '@angular/material';
import { DialogBoxComponent } from '../../../../shared/components/dialog-box/dialog-box.component';
import { ResourceTaskModalFormComponent } from '../../../../shared/components/form/resource-task-modal-form/resource-task-modal-form.component';
import { Asset } from '../../../../core/models/asset.model';
import { Order } from '../../../../core/models/order/order.model';
import { Task } from '../../../../core/models/order/task.model';
import { ORDER_ITEM_TYPE_2, PROCESSING_STATUS_5, PROCESSING_STATUS_1, PROCESSING_STATUS_4 } from '../../../../core/constants/configuration/order-constants.config';
import { ConfirmDialogComponent } from '../../../../shared/components/confirm-dialog/confirm-dialog.component';
import { OrderItem } from 'src/app/core/models/order/order-item.model';
import { WorkTextBookSelectedService } from 'src/app/core/services/messaging/worktextbook-selected';
import { ORDER_ITEM_TYPE_3 } from 'src/app/core/constants/configuration/old-order-constants.config';

const headers: any[] = [
  {code: 'checkbox', value: ''},
  {code: 'assetTag', value: 'Asset Tag'},
  {code: 'code', value: 'Model ID or ISBN'},
  {code: 'name', value: 'Model Name'},
  {code: 'consumable', value: 'Consumable'},
  {code: 'status', value: 'Status'},
  {code: 'trackingId', value: 'Tracking ID'}
];

const testData: any[] = [
  {assetTag: 'A11111X', code: '1111111111', name: 'Test Workbook/Text Book 1', status: ASSET_STATUS_2, trackingId: ''},
  {assetTag: 'A22222X', code: '2222222222', name: 'Test Workbook/Text Book 2', status: ASSET_STATUS_2, trackingId: ''},
  {assetTag: 'A33333X', code: '3333333333', name: 'Test Workbook/Text Book 3', status: ASSET_STATUS_1, trackingId: ''},
  {assetTag: 'A44444X', code: '4444444444', name: 'Test Workbook/Text Book 4', status: ASSET_STATUS_2, trackingId: ''},
  {assetTag: 'A55555X', code: '5555555555', name: 'Test Workbook/Text Book 5', status: ASSET_STATUS_1, trackingId: ''},
];

@Component({
  selector: 'app-workbooks-list',
  templateUrl: './workbooks-list.component.html',
  styleUrls: ['./workbooks-list.component.scss']
})
export class WorkbooksListComponent implements OnInit {

  @Output() reloadPage = new EventEmitter<any>();
  @Output() onCheckChangeEvent = new EventEmitter<any[]>();

  unsubscribe: Subject<any> = new Subject();
  orderDetails: Order;
  taskDetails: Task;
  headers: any[] = headers;
  workbooksTextBooks: any[] = [];
  statusList: any[] = [];
  checkedItems: any[] = [];
  selectedAsset: any;
  selectedTrackingId: string;
  newTrackingId: string = "";
  createdResource: any;
  hasCheckedInAsset: boolean;

  addIcon: IconDefinition = faPlus;
  editIcon: IconDefinition = faPencilAlt;
  notesIcon: IconDefinition = faCommentDots;
  clearIcon: IconDefinition = faTimes;
  isDoneSubject = new BehaviorSubject<boolean>(false);
  isNewAsset: boolean = true;

  constructor(
    private crudService: CrudService,
    private dialog: MatDialog,
    private errorHandlerService: ErrorHandlerService,
    private loaderMessagingService: LoaderMessagingService,
    private workTextBookSelectedService: WorkTextBookSelectedService
  ) { }

  ngOnInit() {
    this.initParams();
  }

  initParams(): void {
    this.getAllStatus();
    this.checkedItems = [];
    // default workbooks to selected:
    this.workbooksTextBooks.forEach( wb => {
      wb.checked = false
      this.workTextBookSelectedService.setSelected(wb.assetTag);
    });
    this.workTextBookSelectedService.setIsCheckedAll(false);
  }

  getStatusName(code: any): string {
    let status = this.statusList.find(type => type.code === code);
    return status ? code === ASSET_STATUS_1 ? "Checked In" : status.name : ""
  }

  @Input() 
  set getOrderDetails(value) {
    this.orderDetails = value;
    this.workbooksTextBooks = [];
    if(value.syCode != '2019-2020'){
      this.getWorkbooksTextBooks();
    } else {
      this.getOldWorkbooksTextBooks();
    }
  }

  @Input() 
  set getTaskDetails(value) {
    this.taskDetails = value;
    this.workbooksTextBooks = [];
    if(value.syCode != '2019-2020'){
      this.getWorkbooksTextBooks();
    } else {
      this.getOldWorkbooksTextBooks();
    }
  }

  getOldWorkbooksTextBooks() {
    if (this.orderDetails && this.taskDetails) {
      this.orderDetails.orderItems.forEach(orderItem => {
        if (orderItem.referenceType === ORDER_ITEM_TYPE_3) {
          this.taskDetails.orderItemAssoc.forEach(taskItem => {
            if (orderItem.id === taskItem.txnOrderItemId) {
              this.workbooksTextBooks.push({
                ...orderItem,
                checked: false,
                code: orderItem.referenceCode,
                name: orderItem.referenceName,
                assetTag: orderItem.assetCode,
                status: orderItem.checkIn ? ASSET_STATUS_1 : ASSET_STATUS_2,
              });
            }
          });
        }
      });
    }
  }

  getWorkbooksTextBooks() {
    if (this.orderDetails && this.taskDetails) {
      this.orderDetails.orderItems.forEach(orderItem => {
        if (orderItem.referenceType === ORDER_ITEM_TYPE_2) {
          this.taskDetails.orderItemAssoc.forEach(taskItem => {
            if (orderItem.id === taskItem.txnOrderItemId) {
              this.workbooksTextBooks.push({
                ...orderItem,
                checked: false,
                code: orderItem.referenceCode,
                name: orderItem.referenceName,
                assetTag: orderItem.assetCode,
                taskId: taskItem.id
              });
            }
          });
        }
      });
      this.workbooksTextBooks.sort((a,b)=>a.taskId - b.taskId);
    }
  }

  setTrackingId(orderItem, taskItem){
    let trackingId = "";
    if(taskItem.status == PROCESSING_STATUS_4){
      trackingId = orderItem.trackingId;
    } else {
      trackingId = "";
    }
    return trackingId;
  }

  checkStatus(orderItem, taskItem){
    let status = "";
    if(taskItem.status == PROCESSING_STATUS_4){
      if(orderItem.status == ASSET_STATUS_1){
        status = ASSET_STATUS_1;
      } else {
        status = ASSET_STATUS_2;
      }
    } else {
      status = ASSET_STATUS_1;
    }
    return status;
  }

  getAllStatus() {
    this.crudService
      .getAll<any>(allAssetsEndpoint.concat("/status"))
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((response : any) => {
        if (response) {
          this.statusList = response;
        }
      },
      this.errorHandlerService.handleError,
      this.handleCompletion
    );
  }

  handleCompletion = (): void => {
    this.loaderMessagingService.showListLoader(false);
    this.loaderMessagingService.showPageLoader(false);
  };

  getStatusClass(status: string): string {
    switch (status) {
      case ASSET_STATUS_1: return "checked-in status"
      case ASSET_STATUS_2: return "checked-out status"
      case ASSET_STATUS_3: return "for-disposal status"
      case ASSET_STATUS_4: return "lost status"
      default: return "status"
    }
  }

  onChangeTrackingId(value: string) {
    this.newTrackingId = value;
  }

  onCheck(book: any): void {
    // toggle checked:
    let workTextBook = this.workbooksTextBooks.find( wb => wb.id == book.id);
    workTextBook.checked = !this.workbooksTextBooks.find( wb => wb.id == book.id).checked;
    let index = this.workbooksTextBooks.find( wb => wb.id == book.id);
    this.workbooksTextBooks[index] = workTextBook;
    // then check if book.id is checked ?
    // add to selectedservice
    if (workTextBook.checked) {
      this.workTextBookSelectedService.setSelected(
        workTextBook.assetTag
      );
      this.checkedItems.push(workTextBook);
    // remove from selectedService
    } else {
      this.workTextBookSelectedService.removeWorkTextBook(
        workTextBook.assetTag
      );
      this.checkedItems = this.checkedItems.filter(book => workTextBook.assetTag != book.assetTag);
    }

    //
    this.onCheckChangeEvent.emit(this.checkedItems);
  }

  onCheckAll(isChecked: boolean) {
    this.workTextBookSelectedService.setIsCheckedAll(isChecked);
    this.checkedItems = [];
    if ( isChecked ) {
      this.workbooksTextBooks.forEach( wb => {
        wb.checked = true;
        this.workTextBookSelectedService.setSelected(wb.assetTag);
        this.checkedItems.push(wb);
      });
    } else {
      this.workbooksTextBooks.forEach( wb => {
        wb.checked = false;
        this.workTextBookSelectedService.removeWorkTextBook(wb.assetTag);
        this.checkedItems = [];
      });
    }
    
    //
    this.onCheckChangeEvent.emit(this.checkedItems);
  }

  get isAllChecked(): boolean {
    let isAllChecked = true;
    this.workbooksTextBooks.forEach ( wb => {
      if ( wb.checked == false) isAllChecked = false;
    });
    return isAllChecked;
  }

  get isTaskHstApprovedOrCancelled(): boolean {
    return (this.taskDetails && [PROCESSING_STATUS_5, PROCESSING_STATUS_1].includes(this.taskDetails.status));
  }

  canEdit(book: any): boolean {
    return (this.taskDetails && [PROCESSING_STATUS_5, PROCESSING_STATUS_1].includes(this.taskDetails.status)) || (book.checkIn || book.status == ASSET_STATUS_1);
  }

  openResourceFormModal() {
    this.showResourceFormModal({ referenceCode: '', assetTag: '', trackingId: '' }, null, "checkout");
  }

  addTrackingId() {
    const dialogRef = this.dialog.open(ConfirmDialogComponent);
    dialogRef.componentInstance.confirmMessage = `Are you sure you want to set ${this.newTrackingId} as tracking ID of the selected books?`;
    dialogRef.afterClosed().subscribe((result) => {
      if (result) this.setMultipleTrackingIds();
    });
  }

  setMultipleTrackingIds() {
    this.loaderMessagingService.showPageLoader(true);
    let selectedBooks = this.checkedItems.map(book => {
      book.trackingId = this.newTrackingId;
      book.referenceName = this.orderDetails.code;
      return book;
    });
    let booksOrder = this.orderDetails;
    booksOrder.orderItems = selectedBooks;
		this.crudService
			.add<any>(orderUpdateMultipleBookTrackingIdsEndpoint, booksOrder)
			.pipe(takeUntil(this.unsubscribe))
			.subscribe((response) => {
        if (response) {
          this.openSuccessDialog(null, `Successfully set ${this.newTrackingId} as tracking ID to the selected workbook/text book.`);
          this.checkedItems = [];
        }
			}, this.errorHandlerService.handleError, this.handleCompletion);
  }

  onEditAssetDetails(item: any) {
    this.showResourceFormModal({...item, assetCode: item.assetTag.trim()}, item.status, "update");
  }

  isBookExistInOrder(result: any): boolean {
    let isBookEsistInOrder = false;
    this.workbooksTextBooks.forEach(workbook =>{
      if(workbook.assetCode && result.asset.assetCode){
        if(workbook.assetCode.trim() === result.asset.assetCode.trim()){
          isBookEsistInOrder = true;
        }
      }
    });
    return isBookEsistInOrder;
  }

  showResourceFormModal(item: any, status: string, action: string) {
    let bookFormDialogRef = this.dialog.open(ResourceTaskModalFormComponent, { width: "50%" });
    bookFormDialogRef.componentInstance.book = item;
    bookFormDialogRef.componentInstance.action = action;
    bookFormDialogRef.componentInstance.requiredTrackingId = false;
    bookFormDialogRef.componentInstance.closeResourceTaskFormModal.subscribe(() => {
      bookFormDialogRef.close();
    });
    bookFormDialogRef.componentInstance.processBooks.subscribe((result: any) => {
      if (result) {
        let isAddWorkBook = false;
        bookFormDialogRef.close();
        this.selectedAsset = result.asset;
        this.selectedTrackingId = result.trackingId;
        this.createdResource = {...result.book, status: this.orderDetails.code, syCode: this.orderDetails.syCode };
        if (action === "update") {
          if(item.assetCode.trim() === result.asset.code.trim()) {
            this.saveTrackingId(item, result, status, false, this.orderDetails);
          } else if(item.assetCode.trim() != result.asset.code.trim() 
          && item.trackingId != result.book.trackingId) {
            isAddWorkBook = true;
            this.saveTrackingId(item, result, status, isAddWorkBook, this.orderDetails);
          } else {
            isAddWorkBook = true;
            this.revertPreviousAsset(item, status, isAddWorkBook, result);
          }
        } else {
          let updatedAsset = {...this.selectedAsset, status: ASSET_STATUS_2, loanedTo: this.orderDetails.studentDistrictId, cosOrderId: this.orderDetails.code, syCode: this.orderDetails.syCode}
          this.addWorkbookTextBook(updatedAsset, status, action, this.selectedAsset.code, this.selectedTrackingId);
        }
      }
    });
  }

  saveTrackingId(item: any, result: any, status: any, isRevertAsset: boolean, orderDetails: any){
    let booksOrder = this.orderDetails;
    let orderItems: OrderItem[] = [];
    let oldTrackingId = item.trackingId;
    item.trackingId = result.book.trackingId;
    item.referenceName = this.orderDetails.code;
    orderItems.push(item);
    booksOrder.orderItems = orderItems;
    if(oldTrackingId != result.book.trackingId && isRevertAsset) {
      this.revertPreviousAsset(item, status, isRevertAsset, result);
      // let message = 'Successfully updated the asset details of the selected item.';
      // this.openSuccessDialog(null, message);
      // this.handleCompletion;
    } else {
      this.crudService
      .add<any>(orderUpdateMultipleBookTrackingIdsEndpoint, booksOrder)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((response) => {
        if(isRevertAsset){
          this.revertPreviousAsset(item, status, isRevertAsset, result);
        } else {
          let message = 'Successfully updated the asset details of the selected item.';
          this.openSuccessDialog(null, message);
        }
      }, this.errorHandlerService.handleError, this.handleCompletion);
    }

    if(isRevertAsset){
      this.updateOrderModifiedDetails();
    }

    this.updateTaskModifiedDetails();

  }

  updateOrderModifiedDetails(){
    let orderId = this.orderDetails.code;
    this.crudService.add<any>(updateOrderModifiedOnlyEndpoint, orderId)
    .pipe(takeUntil(this.unsubscribe))
    .subscribe((response) => {
    }, this.errorHandlerService.handleError);
  }
  
  updateTaskModifiedDetails(){
    let taskId = this.taskDetails.code;
    this.crudService.add<any>(updateTaskModifiedOnlyEndpoint, taskId)
    .pipe(takeUntil(this.unsubscribe))
    .subscribe((response) => {
    }, this.errorHandlerService.handleError);
  }

	addWorkbookTextBook(updatedAsset: any, status: string, action: string, assetCode: string, trackingId: string) {
    this.loaderMessagingService.showPageLoader(true);
		this.crudService
			.add<any>(orderAddBookItemEndpoint, this.createdResource)
			.pipe(takeUntil(this.unsubscribe))
			.subscribe((response) => {
        if (response) {
          this.updateAsset(updatedAsset, this.createdResource, status, action, assetCode, trackingId);
        }
			}, this.errorHandlerService.handleError, this.handleCompletion);
  }

  updateAssentOnly(asset: any, selectedItem: any, status: any, action: string, assetCode: string, trackingId: string) {
    asset.syCode = this.orderDetails.syCode;
    this.loaderMessagingService.showPageLoader(true);
    this.crudService
    .editWithResponse<Asset>(allAssetsEndpoint.concat(`/${asset.id}`), asset)
    .pipe(takeUntil(this.unsubscribe))
    .subscribe((response) => {
      if (response) {
        if (action === "update") {
          this.updateAssetRelatedTask(selectedItem, status, assetCode, trackingId, true);
        }
      }
      if (action === "new-asset") {
        if(this.isNewAsset){
          this.isDoneSubject.next(true);
          this.isNewAsset = false;
        }
      }
    });
  }

	updateAsset(asset: any, selectedItem: any, status: any, action: string, assetCode: string, trackingId: string) {
    asset.syCode = this.orderDetails.syCode;
    this.loaderMessagingService.showPageLoader(true);
		this.crudService
			.editWithResponse<Asset>(allAssetsEndpoint.concat(`/${asset.id}`), asset)
			.pipe(takeUntil(this.unsubscribe))
			.subscribe((response) => {
        if (response) {
            if (action === "update") {
              this.updateAssetRelatedTask(selectedItem, status, assetCode, trackingId, false);
            } else {
              let message = action === "new-asset" ? 'Successfully updated the asset details of the selected item.'
                : `Successfully checked-out the workbook/text book "${selectedItem.referenceCode} ${selectedItem.referenceName}" using the asset tag ${assetCode}.`;
              this.openSuccessDialog(null, message);
            }
        }
        if(action == "checkout") {
          this.updateOrderModifiedDetails();
          this.updateTaskModifiedDetails();
        }
			}, this.errorHandlerService.handleError, this.handleCompletion);
  }
  
  updateAssetRelatedTask(selectedItem: any, newStatus: string, assetCode: string, trackingId: string, isUpdateOnly: boolean) {
    this.loaderMessagingService.showPageLoader(true);
    const taskToUpdate = this.setTaskToUpdate([selectedItem], newStatus, assetCode, trackingId);
    this.crudService
      .edit<any>(updateAssetRelatedTaskEndpoint, { tasks: [taskToUpdate] })
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(response => {
        if (response) {
          let updatedAsset = {...this.selectedAsset, selectedItem, status: ASSET_STATUS_2, loanedTo: this.orderDetails.studentDistrictId, cosOrderId: this.orderDetails.code}
          if(isUpdateOnly) {
            this.updateAssentOnly(updatedAsset, selectedItem, status, "new-asset", assetCode, trackingId);
          } else {
            this.updateAsset(updatedAsset, selectedItem, status, "new-asset", assetCode, trackingId);
          }
        }
      }, this.errorHandlerService.handleError, this.handleCompletion);
  }

  setTaskToUpdate(selectedItems: any, newStatus: string, assetCode: string, trackingId: string) {
    let taskToUpdate = this.taskDetails;
    let selectedTaskItems = [];
    selectedItems.forEach(item => {
      this.taskDetails.orderItemAssoc.slice().forEach(itemAssoc => {
        if (item.id === itemAssoc.txnOrderItemId) {
          selectedTaskItems.push({...itemAssoc, status: newStatus});
        }
      });
    });
    taskToUpdate.orderItemAssoc = selectedTaskItems;
    if (assetCode || trackingId) {
      // used task's curriculumCode and curriculumName to send the values of assetCode and trackingId to updateTaskStatus API
      taskToUpdate = {...taskToUpdate, curriculumCode: assetCode, curriculumName: trackingId};
    }
    return taskToUpdate;
  }

  revertPreviousAsset(item: any, status: string, isAddWorkBook: boolean, result: any) : void {
    this.loaderMessagingService.showPageLoader(true);
		this.crudService
    .getAllBy<any>(`${allAssetsEndpoint}/get-by-code?code=${item.assetCode}&itemModelId=${item.referenceCode ? item.referenceCode : ''}`)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((response: any) => {
        if (response) {
          let updatedAsset = {...response, status: ASSET_STATUS_1, loanedTo: null, cosOrderId: null}
          if(isAddWorkBook){
            this.updateAssentOnly(updatedAsset, item, status, "update", this.selectedAsset.code, this.selectedTrackingId);
          } else {
            this.updateAsset(updatedAsset, item, status, "update", this.selectedAsset.code, this.selectedTrackingId);
          }
          
          this.isDoneSubject.subscribe(done=>{
            if(isAddWorkBook && done) {
              this.loaderMessagingService.showPageLoader(true);
              this.loaderMessagingService.showListLoader(true);
              let updatedAsset = {...result.asset, status: ASSET_STATUS_2, loanedTo: this.orderDetails.studentDistrictId, cosOrderId: this.orderDetails.code}
              this.addWorkbookTextBook(updatedAsset, status, "update-add", this.selectedAsset.code, this.selectedTrackingId); 
            }
          });
        }
      },
      this.errorHandlerService.handleError,
    );
  }

  openSuccessDialog(title: string, message: string): void {
    const dialogRef = this.dialog.open(DialogBoxComponent);
    dialogRef.componentInstance.dialogTitle = title ? title : 'Success';
    dialogRef.componentInstance.contentMessage = message;
    dialogRef.afterClosed().subscribe(() => {
      this.reloadPage.emit();
    });
  }

  get hasCheckedIn(): boolean {
    let hasCheckedIn = false;
    if(this.checkedItems.length > 0){
      this.checkedItems.forEach(book =>{
        if(book.status == "ASSET_STATUS_1" || (book.trackingId != "" && book.trackingId != null)){
          hasCheckedIn = true;
        }
      });
    }
    if((this.isTaskHstApprovedOrCancelled || this.newTrackingId.length === 0 || this.checkedItems.length === 0)){
      hasCheckedIn = true;
    }

    return hasCheckedIn;
    
  }
  

}
