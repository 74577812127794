import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DataProperties } from 'src/app/core/models/data-properties.model';
import { Subject } from 'rxjs';
import { MAT_CHECKBOX_CLICK_ACTION } from '@angular/material';
import { LoaderMessagingService } from 'src/app/core/services/messaging/loader-messaging.service';
import { orderClassDataProperties } from 'src/app/core/constants/configuration/order-constants.config';
import { takeUntil } from 'rxjs/operators';
import * as moment from 'moment';
import { tableDateFormat } from 'src/app/core/constants/configuration/common.constant';
import { UserService } from 'src/app/core/services/util/user.service';
import { TEACHER } from 'src/app/core/constants/configuration/role-constants.config';

@Component({
  selector: 'app-order-class-resource-list',
  templateUrl: './order-class-resource-list.component.html',
  styleUrls: ['./order-class-resource-list.component.scss'],
  providers: [{provide: MAT_CHECKBOX_CLICK_ACTION, useValue: 'noop'}]
})
export class OrderClassResourceListComponent implements OnInit {

  @Input() items: any[] = [];
  @Output() getSelectedClass = new EventEmitter<any>();

  dataProperties: DataProperties[];
  headersLength: number;
  isLoading: boolean = false;
  unsubscribe: Subject<any> = new Subject();
  isTeacherRole

  constructor(
    private loaderMessagingService: LoaderMessagingService,
    private userService: UserService
  ) {
    this.subscribeToLoaderMessagingService();
    this.isTeacherRole = this.userService.hasRole([TEACHER]);
  }
  
  ngOnInit() {
    this.dataProperties = orderClassDataProperties;
    this.headersLength = this.dataProperties.length;
  }

  formatDate(date: string): string {
    return moment(date).format(tableDateFormat)
  }

  private subscribeToLoaderMessagingService(): void {
    this.loaderMessagingService.listLoader$
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(show => setTimeout(() => (this.isLoading = show)));
  }

   isCheckboxDisabled(item){
     let isCheckboxDisabled = false;
     if(this.isTeacherRole && item.closed){
      isCheckboxDisabled = true;
     }
     return isCheckboxDisabled;
   }
  

  onCheckboxCheck($event: any, selectedItem: any) {
    this.items.forEach(item => {
      if(item.id !== selectedItem.id) {
        item.check = false;
      }
      });
      this.getSelectedClass.emit(selectedItem);
    }
}
