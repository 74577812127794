import { Injectable } from "@angular/core";
import { Router } from '@angular/router';
import { User } from "../../models/user.model";
import { loginUser } from "../../constants/configuration/config.constant";
import { Authority } from "../../models/authority.model";
import { ADMIN } from "../../constants/configuration/role-constants.config";
import { CURRICULUMS_PATH, ORDERS_PATH } from "../../constants/routes.constant";

@Injectable({ providedIn: 'root' })
export class UserService {

	constructor(
		private router: Router,
	) {
	}

    getLoginUser(): User {
        return JSON.parse(localStorage.getItem(loginUser)) as User;
    }

    getFirstRole(): Authority {
        const user = JSON.parse(localStorage.getItem(loginUser)) as User;
        return user.authorities[0] as Authority;
    }

    getUserRoles(): String[] {
        const user = JSON.parse(localStorage.getItem(loginUser)) as User;
        return user.authorities.map(authority => authority.authority);
    }

    hasRole(roles: String[]): boolean {
        return this.getUserRoles().some(role => roles.includes(role));
    }

    checkRolePermission(path: String, routingPermissions: any[]) {
        let routingPermission = routingPermissions.find(route => route.path === path);
        if (!this.hasRole(routingPermission ? routingPermission.roles : []))
            if (this.hasRole([ADMIN])) this.router.navigateByUrl(CURRICULUMS_PATH)
            else this.router.navigateByUrl(ORDERS_PATH)
    }

}
