import { Component, OnInit, ViewChild } from '@angular/core';
import { Subject } from "rxjs";
import { takeUntil } from 'rxjs/operators';
import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { MatDialog, MatStepper } from "@angular/material";
import { CrudService } from "src/app/core/services/data/crud.service";
import { Resource } from 'src/app/core/models/resource.model';
import { StudentInfo } from "src/app/core/models/eos/student-info.model";
import { ORDER } from 'src/app/core/constants/configuration/order-constants.config';
import { CREATE_MODULE_NAME } from 'src/app/core/constants/configuration/common.constant';
import { ErrorHandlerService } from "src/app/core/services/util/error-handler.service";
import { HeaderMessagingService } from 'src/app/core/services/messaging/header-messaging.service';
import { LoaderMessagingService } from "src/app/core/services/messaging/loader-messaging.service";
import { OrderResourceSelectionComponent } from './order-resource-selection/order-resource-selection.component';
import { OrderResourceModalFormComponent } from "./order-resource-modal-form/order-resource-modal-form.component";
import { ConfirmDialogComponent } from "src/app/shared/components/confirm-dialog/confirm-dialog.component";
import { GuardianShippingDetails } from 'src/app/core/models/eos/guardian-shipping-details.model';
import { Order } from 'src/app/core/models/order/order.model';
import {
  allOrdersEndpoint,
  getShippingDetailsEndpoint,
  getOrderTransformEndpoint,
  getActiveSchoolYearEndpoint,
  getProcessingStatusEndpoint,
  addpendingorders
} from 'src/app/core/constants/endpoints.constant';
import { DataCustomizerService } from 'src/app/core/services/util/data-customizer.service';
import { OrderHelper } from 'src/app/core/services/util/order.helper';
import { Router } from '@angular/router';
import { ORDERS_PATH } from 'src/app/core/constants/routes.constant';
import { DialogBoxComponent } from "src/app/shared/components/dialog-box/dialog-box.component";

@Component({
  selector: 'app-order-create',
  templateUrl: './order-create.component.html',
  styleUrls: ['./order-create.component.scss']
})
export class OrderCreateComponent implements OnInit {

  navLabels: string[] = ['Student Selection', 'Shipping Details', 'Class and Resource', 'Summary'];
  activeIndex: number = 0;
  isFirst: boolean = true;
  isLast: boolean = false;
  studentId: number;
  isStudentCompleted: boolean;
  isShippingCompleted: boolean;
  isClassCompleted: boolean;
	unsubscribe: Subject<any> = new Subject();
  studentInfo: StudentInfo;
  resourceListDialogRef: any;
  resourceFormDialogRef: any;
  selectedClasses: any[] = [];
  selectedResources: Resource[] = [];
  newResources: Resource[] = [];
  shippingDetails: any[] = [];
  processingStatus: any[] = [];
  selectedShippingDetail: any;
  orderDetails: any;
  activeSchoolYear: any;
  totalAmount: number = 0;

  leftIcon: IconDefinition = faAngleLeft;
  rightIcon: IconDefinition = faAngleRight;
  @ViewChild('stepper') stepper: MatStepper;

  constructor(
    private crudService: CrudService,
    private dialog: MatDialog,
    private errorHandlerService: ErrorHandlerService,
    private headerMessagingService: HeaderMessagingService,
    private loaderMessagingService: LoaderMessagingService,
    private dataCustomizerService: DataCustomizerService,
    private orderHelper: OrderHelper,
    private router: Router,
  ) {
    this.headerMessagingService.setHeader(ORDER, CREATE_MODULE_NAME, false, null);
  }

  ngOnInit() {
    this.isStudentCompleted = false;
    this.isShippingCompleted = false;
    this.isClassCompleted = false;
    this.getActiveSchoolYear();
  }

  onChangePage(prop: any, label: string): void {

    if (label === 'STUDENT_SELECTION') {
      if (this.isStudentCompleted) {
        this.getStudentById(this.studentId);
      } else {
        this.openOrderCreationSuccessDialog(null, '', "Please select a student.");
      }
    }

    if (label === 'SHIPPING') {
      if (this.isShippingCompleted) {
        this.getStudentById(this.studentId);
      } else if(prop === 'NEXT') {
        this.openOrderCreationSuccessDialog(null, '', "Please select a shipping address.");
      } else if(prop === 'PREV') {
        this.isShippingCompleted = false;
      }
    }

    if (label === 'CLASS_RESOURCE') {
      if(this.isClassCompleted) {
        this.createOrderObject();
      }
    }

    if (label === 'SAVE_ORDER') {
      this.openSaveOrderConfirm();
    }
  }

  getNewShippingDetails($event) {
    this.selectedShippingDetail = $event;
  }

  onStudentSelect(studentId: number): void {
    this.studentId = studentId;

    if (studentId) {
      this.isStudentCompleted = true;
    } else {
      this.isStudentCompleted = false;
    }
  }

  onShippingSelect($event: any): void {
    this.shippingDetails = $event.items;
    this.selectedShippingDetail = $event.selectedItem;

    if ($event.selectedItem) {
      this.isShippingCompleted = true;
    } else {
      this.isShippingCompleted = false;
    }
  }

  onClick($event: any): void {
  }

  showResourceList() {
    this.resourceListDialogRef = this.dialog.open(OrderResourceSelectionComponent, { width: "80%", height: "82%" });
    this.resourceListDialogRef.componentInstance.openResourceFormModal.subscribe(() => {
      this.resourceListDialogRef.close();
      this.showResourceFormModal();
    });
    this.resourceListDialogRef.componentInstance.closeResourceListModal.subscribe(() => {
      this.resourceListDialogRef.close();
    });
    this.resourceListDialogRef.componentInstance.getSelectedResource.subscribe((resource: Resource) => {
      let message = null;
      let exists = this.selectedResources.find(value => value.code === resource.code);
      if (!exists)
        this.selectedResources.push(resource);
      else
        message = 'Resource is already added to cart';
      this.resourceListDialogRef.close();
      this.isClassCompleted = true;
      this.openSuccessDialog(message);
    });
  }

  showResourceFormModal() {
    this.resourceFormDialogRef = this.dialog.open(OrderResourceModalFormComponent, { width: "50%" });
    this.resourceFormDialogRef.componentInstance.closeResourceFormModal.subscribe(() => {
      this.resourceFormDialogRef.close();
    });
    this.resourceFormDialogRef.componentInstance.getResource.subscribe((resource: Resource) => {
      let message = null;
      let exists = this.newResources.find(value => value.code === resource.code && value.name === resource.name);
      if (!exists)
        this.newResources.push(resource);
      else
        message = 'Resource is already added to cart';
      this.resourceFormDialogRef.close();
      this.isClassCompleted = true;
      this.openSuccessDialog(message);
    });
  }

  getSelectedClassAndResources($event: any) {
    let exists = this.selectedClasses.find(item => item.selectedClass.code === $event.selectedClass.code);
    if (exists)
      this.selectedClasses.forEach(item => {
        if (item.selectedClass.code === $event.selectedClass.code) {
          item.selectedClassResources = $event.selectedClassResources;
          item.selectedCurriculumResources = $event.selectedCurriculumResources;
        }
      });
    else
      this.selectedClasses.push($event);

    this.updateCurriculumResourcesPerSelectedClass();
    this.isClassCompleted = true;
    this.openSuccessDialog(null);
  }

  updateCurriculumResourcesPerSelectedClass() {
    let curriculums = [];
    this.selectedClasses.forEach(item => {
      let curriculumExists = curriculums.find(curr => curr.code === item.selectedClass.curriculum.code);
      if (curriculumExists) {
        let curriculumResources = curriculumExists.resources ? curriculumExists.resources : [];
        item.selectedCurriculumResources.forEach(currResource => {
          let resourceExists = curriculumResources.find(resource => resource.code === currResource.code);
          if (!resourceExists) curriculumResources.push(currResource);
        });
      } else
        curriculums.push({
          code: item.selectedClass.curriculum.code,
          resources: item.selectedCurriculumResources,
        });
    });
    curriculums.forEach(curr => {
      this.selectedClasses.forEach(item => {
        if (item.selectedClass.curriculum.code === curr.code)
          item.selectedCurriculumResources = curr.resources;
      });
    });
  }

  openSuccessDialog(message: string) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, { autoFocus: false });
    dialogRef.componentInstance.confirmTitle = 'Success';
    dialogRef.componentInstance.confirmMessage = message ? message : "Item/s successfully added to cart.";
    dialogRef.componentInstance.cancelLabel = "Continue Browsing";
    dialogRef.componentInstance.confirmLabel = "Go to Cart";
    dialogRef.afterClosed().subscribe(result => {
      if(result) this.goToCart();
    });
  }

  openSaveOrderConfirm() {
    if (this.orderDetails && this.orderDetails.orderItems && this.orderDetails.orderItems.length > 0) {
      const dialogRef = this.dialog.open(ConfirmDialogComponent);
      dialogRef.componentInstance.confirmTitle = 'Save Order';
      dialogRef.componentInstance.confirmMessage = "Are you sure you want to save this order?";
      dialogRef.afterClosed().subscribe(result => {
        if(result) this.saveOrder();
      });
    }
  }

  goToCart() {
    this.createOrderObject();
    this.stepper.selectedIndex = 3;
  }

  onGetTotalAmount(totalAmount) {
    this.totalAmount = totalAmount;
    this.orderDetails.total = totalAmount;
  }

  getNewOrderDetails($event) {
    let selectedItem = $event;
    this.selectedClasses = this.selectedClasses.map(item => {
      if (item.selectedClass.code === selectedItem.referenceCode)
        return null
      else {
        let classResources = item.selectedClassResources.filter(resource =>
          selectedItem.referenceCode !== resource.code && selectedItem.referenceName !== resource.name
        );
        let curriculumResources = item.selectedCurriculumResources.filter(resource =>
          selectedItem.referenceCode !== resource.code && selectedItem.referenceName !== resource.name
        );
        return {
          selectedClass: item.selectedClass,
          selectedClassResources: classResources,
          selectedCurriculumResources: curriculumResources,
        }
      }
    });
    this.selectedResources = this.selectedResources.filter(resource =>
      selectedItem.referenceCode !== resource.code && selectedItem.referenceName !== resource.name
    );
    this.newResources = this.newResources.filter(resource =>
      selectedItem.referenceCode !== resource.code && selectedItem.referenceName !== resource.name
    );
    this.selectedClasses = this.selectedClasses.filter(item => item !== null);
    this.updateCurriculumResourcesPerSelectedClass();
    this.createOrderObject();
  }

  createOrderObject() {
    let syCode = this.selectedClasses.length > 0 ?
      this.selectedClasses[0].selectedClass.curriculum.syCode
      : this.activeSchoolYear.schoolYear;

      this.orderDetails = this.orderHelper.setOrderModel(
        this.studentInfo,
        this.selectedShippingDetail,
        this.selectedClasses,
        this.selectedResources,
        syCode,
      );

    this.transformOrder();
  }

  private getStudentById(id: number): void {
		this.loaderMessagingService.showPageLoader(true);
    this.crudService.getById<StudentInfo>(`/students/${id}`)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(response => {
        this.studentInfo = response;
        this.getGuardianShippingDetails();
      }, this.errorHandlerService.handleError);
  }

  transformOrder(): void {
		this.loaderMessagingService.showPageLoader(true);
    this.crudService
      .add<Map<String, Order[]>>(getOrderTransformEndpoint, this.orderDetails)
      .pipe(takeUntil(this.unsubscribe))
			.subscribe(response => {
				if (response) {
          this.orderDetails = response;
				}
    	}, this.errorHandlerService.handleError, this.handleCompletion);
  }

  saveOrder(): void {
		this.loaderMessagingService.showPageLoader(true);
    // this.crudService
    //   .add<Order>(allOrdersEndpoint, this.orderDetails)
    //   .pipe(takeUntil(this.unsubscribe))
		// 	.subscribe(response => {
    //     this.openOrderCreationSuccessDialog(response.id, 'Success', "Order successfully saved.");
    // 	}, this.errorHandlerService.handleError, this.handleCompletion);
    this.crudService
      .add<Order>(addpendingorders, this.orderDetails)
      .pipe(takeUntil(this.unsubscribe))
			.subscribe(response => {
        this.openOrderCreationSuccessDialog(response.id, 'Success', "Order successfully saved.");
    	}, this.errorHandlerService.handleError, this.handleCompletion);
  }

  openOrderCreationSuccessDialog(id: number, title: string, message: string): void {
    const dialogRef = this.dialog.open(DialogBoxComponent);
    dialogRef.componentInstance.dialogTitle = title;
    dialogRef.componentInstance.contentMessage = message;
    if (id) {
      dialogRef.afterClosed().subscribe(() => {
        this.router.navigate([ORDERS_PATH, id]);
      });
    }
  }

  getGuardianShippingDetails(): void {
    this.shippingDetails = [];
    this.crudService
      .getAllBy<GuardianShippingDetails>(getShippingDetailsEndpoint.concat(`/${this.studentInfo.studentDetails.id}`))
      .pipe(takeUntil(this.unsubscribe))
			.subscribe(response => {
				if (response) {
          response.forEach(shippingDetail => {
            this.shippingDetails.push(
              this.dataCustomizerService.formatShippingDetailDisplay(shippingDetail))
          });
          this.shippingDetails.forEach(item => {
            item.check = this.selectedShippingDetail && item.id === this.selectedShippingDetail.id;
          });
				}
    	}, this.errorHandlerService.handleError, this.handleCompletion);
  }

  getActiveSchoolYear(): void {
    this.crudService
      .getById<any>(getActiveSchoolYearEndpoint)
      .pipe(takeUntil(this.unsubscribe))
			.subscribe(response => {
				if (response) {
          this.activeSchoolYear = response;
        }
        this.getProcessingStatus();
    	}, this.errorHandlerService.handleError);
  }

  getProcessingStatus(): void {
		this.crudService
			.getById<any>(getProcessingStatusEndpoint)
			.pipe(takeUntil(this.unsubscribe))
			.subscribe(response => {
				this.processingStatus = response;
				this.processingStatus.sort((a, b) => b.code.localeCompare(a.code));
			}, this.errorHandlerService.handleError, this.handleCompletion);
  }

	handleCompletion = (): void => {
		this.loaderMessagingService.showPageLoader(false);
  };

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
}
