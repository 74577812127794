import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Order } from "src/app/core/models/order/order.model";
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { faWrench, faCopy } from '@fortawesome/free-solid-svg-icons';
import {
  PROCESSING_STATUS_1,
  PROCESSING_STATUS_2,
  PROCESSING_STATUS_3,
  PROCESSING_STATUS_4,
  PROCESSING_STATUS_5,
  PROCESSING_STATUS_6,
  PROCESSING_STATUS_7
} from 'src/app/core/constants/configuration/order-constants.config';
import { UserService } from 'src/app/core/services/util/user.service';
import { ADMIN, SUPERVISOR } from 'src/app/core/constants/configuration/role-constants.config';
import { Router } from '@angular/router';
import { ORDER_DEPOSIT_PATH, ORDER_CURRICULUM_FEE_PATH, ORDERS_PATH, OLD_ORDERS_PATH } from 'src/app/core/constants/routes.constant';

@Component({
  selector: 'app-order-details',
  templateUrl: './order-details.component.html',
  styleUrls: ['./order-details.component.scss']
})
export class OrderDetailsComponent implements OnInit {

  @Input() orderDetails: Order;
  @Input() processingStatus: any[];
  @Input() showReform: boolean = false;
  @Output() reformEosOrder = new EventEmitter<any>();
  @Input() isTask: boolean = false;

  reformIcon: IconDefinition = faWrench;
  faCopy: IconDefinition = faCopy;

  constructor(
    private userService: UserService,
    private router: Router,
  ) { }

  ngOnInit() {
  }

  get canReformEosOrder(): boolean {
    return this.userService.hasRole([ADMIN, SUPERVISOR])
  }

  onReformEosOrder() {
    this.reformEosOrder.emit();
  }

  getStatusName(statusCode: string) {
    const statusName: any = this.processingStatus ? this.processingStatus.find(status => status.code === statusCode) : "";
    return statusName ? statusName.name : "";
  }

  getStatusClass(statusCode: string): string {
    switch (statusCode) {
      case PROCESSING_STATUS_1: return "approved status"
      case PROCESSING_STATUS_2: return "processing status"
      case PROCESSING_STATUS_3: return "pre-completed status"
      case PROCESSING_STATUS_4: return "completed status"
      case PROCESSING_STATUS_5: return "canceled status"
      case PROCESSING_STATUS_6: return "refunded status"
      case PROCESSING_STATUS_7: return "adjusted status"
      default: return "status"
    }
  }

  copyToClipboard(item): void {
    let listener = (e: ClipboardEvent) => {
        e.clipboardData.setData('text/plain', (item));
        e.preventDefault();
    };

    document.addEventListener('copy', listener);
    document.execCommand('copy');
    document.removeEventListener('copy', listener);
  }

  goToOrder(order: any) {
    if(order.syCode == "2019-2020"){
      if(order.code.includes("DEPOSIT")){
        this.router.navigate([ORDER_DEPOSIT_PATH, order.id]);
      } else if(order.code.includes("FEE")){
        this.router.navigate([ORDER_CURRICULUM_FEE_PATH, order.id]);
      } else {
        this.router.navigate([OLD_ORDERS_PATH, order.id])
      }
    } else {
      this.router.navigate([ORDERS_PATH, order.id]);
    }
  }

}
