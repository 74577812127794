import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { Resource } from 'src/app/core/models/resource.model';
import { ResourceGroupForm } from 'src/app/core/models/form/resource-group-form-model';
import { CrudService } from 'src/app/core/services/data/crud.service';
import { Subject } from 'rxjs';
import { allCurriculumsEndpoint, allClassesEndpoint } from 'src/app/core/constants/endpoints.constant';
import { Curriculum } from 'src/app/core/models/curriculum.model';
import { takeUntil } from 'rxjs/operators';
import { LoaderMessagingService } from 'src/app/core/services/messaging/loader-messaging.service';
import { ErrorHandlerService } from 'src/app/core/services/util/error-handler.service';
import { Class } from 'src/app/core/models/class.model';
import { BUNDLE_GROUP_1 } from 'src/app/core/constants/configuration/bundle-constants.config';
import { CURRICULUMS_PATH, CLASSES_PATH } from 'src/app/core/constants/routes.constant';

@Component({
	selector: 'resource-details',
	templateUrl: './resource-details.component.html',
	styleUrls: ['./resource-details.component.scss']
})
export class ResourceDetailsComponent implements OnInit, OnDestroy {

	@Output() linkClink: EventEmitter<any> = new EventEmitter();
	@Input() resource: Resource;
	@Input() groups: ResourceGroupForm[];
	@Input() onlineAccount: string;
	@Input() resourceType: string;
	@Input() hasLink: boolean;

	unsubscribe: Subject<any> = new Subject();
	mainDetails: any;
	
	constructor(
		private crudService: CrudService,
		private loaderMessagingService: LoaderMessagingService,
		private errorHandlerService: ErrorHandlerService,
	) { }

	ngOnInit() {
		const { code, name , createdBy, createdDate, modifiedBy, modifiedDate, status } = this.resource;
		this.mainDetails = { code, name, createdBy, createdDate, modifiedBy, modifiedDate, status };
	}

	ngOnDestroy(): void {
		this.unsubscribe.next();
		this.unsubscribe.complete();
	}
	
	handleCompletion = (): void => {
		this.loaderMessagingService.showPageLoader(false);
	};

	onLinkClick(code: string): void {
		const type: string = this.groups.find(group => group.code === code).type;
		type === BUNDLE_GROUP_1 ? this.findCurriculum(code) : this.findClass(code);
	}
	
	private findCurriculum(code: string): void {

		this.loaderMessagingService.showPageLoader(true);

		this.crudService.getAll<Curriculum>(`${allCurriculumsEndpoint}/search?code=${code}`)
			.pipe(takeUntil(this.unsubscribe))
			.subscribe(response => {
				if (response) this.linkClink.emit({ path: CURRICULUMS_PATH, id: response.content[0].id });
			}, this.errorHandlerService.handleError, this.handleCompletion);

	}

	private findClass(code: string): void {

		this.loaderMessagingService.showPageLoader(true);

		this.crudService.getAll<Class>(`${allClassesEndpoint}/search?code=${code}`)
			.pipe(takeUntil(this.unsubscribe))
			.subscribe(response => {
				if (response) this.linkClink.emit({ path: CLASSES_PATH, id: response.content[0].id });
			}, this.errorHandlerService.handleError, this.handleCompletion);

	}

}
