import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class FileService {
    constructor(private httpClient: HttpClient) { }

    pushFileToApi(file: File, endpoint: string) {
        const formdata: FormData = new FormData();

        formdata.append('file', file);

        const req = new HttpRequest('POST', endpoint, formdata, {
            reportProgress: true,
            responseType: 'text',
        });

        return this.httpClient.request(req);
    }
    getFile(endpoint: string): Observable<any> {
        return this.httpClient.get<any>(endpoint, { responseType: 'blob' as 'json'});

    }
    getFileWithParams(endpoint: string): Observable<any> {
        return this.httpClient.get<any>(endpoint, { responseType: 'blob' as 'json'} );
    }
}