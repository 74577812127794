import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { HeaderMessagingService } from 'src/app/core/services/messaging/header-messaging.service';
import { Curriculum } from 'src/app/core/models/curriculum.model';
import { CrudService } from 'src/app/core/services/data/crud.service';
import { allCurriculumsEndpoint } from "src/app/core/constants/endpoints.constant";
import { baseServerUri } from "src/app/core/constants/configuration/config.constant";
import { MatDialog } from '@angular/material';
import { Router } from '@angular/router';
import { CURRICULUMS_PATH } from 'src/app/core/constants/routes.constant';
import { createSuccessCurriculum, dialogBoxSuccessTitle } from 'src/app/core/constants/message.constant';
import { DialogBoxComponent } from 'src/app/shared/components/dialog-box/dialog-box.component';
import { ErrorHandlerService } from 'src/app/core/services/util/error-handler.service';
import { LoaderMessagingService } from 'src/app/core/services/messaging/loader-messaging.service';
import { sideNavLabelCurriculum, CURRICULUM } from 'src/app/core/constants/configuration/curriculum-constants.config';
import { CREATE_MODULE_NAME } from 'src/app/core/constants/configuration/common.constant';
import { UserService } from 'src/app/core/services/util/user.service';
import { homeRoutingPermissions } from "src/app/core/constants/configuration/role-constants.config";

@Component({
	selector: 'app-curriculum-create',
	templateUrl: './curriculum-create.component.html',
	styleUrls: ['./curriculum-create.component.scss']
})
export class CurriculumCreateComponent implements OnInit, OnDestroy {

	activeIndex: number = 0;
	sideNavLabels: string[] = [];
	unsubscribe: Subject<any> = new Subject();

	constructor(
		private headerMessagingService: HeaderMessagingService,
		private crudService: CrudService,
		private errorHandlerService: ErrorHandlerService,
		private loaderMessagingService: LoaderMessagingService,
		private dialog: MatDialog,
		private router: Router,
    private userService: UserService,
	) {
		this.userService.checkRolePermission(CURRICULUMS_PATH, homeRoutingPermissions);
		this.headerMessagingService.setHeader(CURRICULUM, CREATE_MODULE_NAME, false, null);
		this.sideNavLabels = sideNavLabelCurriculum;
	}

	ngOnInit() {
	}

	ngOnDestroy(): void {
		this.unsubscribe.next();
		this.unsubscribe.complete();
	}

	onSaveCurriculum($event : Curriculum) {

		this.loaderMessagingService.showPageLoader(true);

		this.crudService
			.add<Curriculum>(allCurriculumsEndpoint, $event)
			.pipe(takeUntil(this.unsubscribe))
			.subscribe(response => {

				if (response) {
					this.router.navigate([CURRICULUMS_PATH, response.id]);
					const dialogRef = this.dialog.open(DialogBoxComponent);
					dialogRef.componentInstance.dialogTitle = dialogBoxSuccessTitle;
					dialogRef.componentInstance.contentMessage = createSuccessCurriculum;
				}

			},  this.errorHandlerService.handleError);
	}

	handleCompletion = (): void => {
		this.loaderMessagingService.showPageLoader(false);
	};

}
