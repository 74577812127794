import { Component, OnInit, Input } from '@angular/core';
import { STATUS_1 } from 'src/app/core/constants/configuration/common.constant';

@Component({
  selector: 'app-main-details',
  templateUrl: './main-details.component.html',
  styleUrls: ['./main-details.component.scss']
})
export class MainDetailsComponent implements OnInit {

  @Input() details: any;
  @Input() label: string;
  STATUS_1 = STATUS_1;

  constructor() { }

  ngOnInit() {
  }

}
