import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';
import { Bundle } from 'src/app/core/models/bundle.model';
import { Subject } from 'rxjs';
import { ErrorHandlerService } from 'src/app/core/services/util/error-handler.service';
import { MatDialog } from '@angular/material';
import { takeUntil } from 'rxjs/operators';
import { ConfirmDialogComponent } from '../../../confirm-dialog/confirm-dialog.component';
import { saveBundleConfirmation } from 'src/app/core/constants/message.constant';

@Component({
  selector: 'app-bundle-summary',
  templateUrl: './bundle-summary.component.html',
  styleUrls: ['./bundle-summary.component.scss']
})
export class BundleSummaryComponent implements OnInit {

  @Output() changePage = new EventEmitter<any>();
  @Input() bundle: Bundle;

  @Input()
  isFirst: boolean;
  @Input()
  isLast: boolean;

  unsubscribe: Subject<any> = new Subject();

  constructor(private errorHandlerService: ErrorHandlerService,
    private dialog: MatDialog,) { }

  ngOnInit() {
  }

  onClick = (label: string): void => {
    if (label === "SAVE") {
      const dialogRef = this.dialog.open(ConfirmDialogComponent);
      dialogRef.componentInstance.confirmMessage = saveBundleConfirmation;
      dialogRef.afterClosed()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(result => {
        if (result) {
          this.changePage.emit(label);
        }
      }, this.errorHandlerService.handleError);
    } else {
      this.changePage.emit(label);
    }
  }

}
