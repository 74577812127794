import { Component, OnInit, Input, OnDestroy } from "@angular/core";
import { Resource } from "src/app/core/models/resource.model";
import { Subject } from "rxjs";
import { ErrorHandlerService } from "src/app/core/services/util/error-handler.service";
import { CrudService } from "src/app/core/services/data/crud.service";
import { ResourceHelper } from "src/app/core/services/util/resource.helper";
import {
  SAVE,
  NEXT,
} from "src/app/core/constants/configuration/common.constant";
import { MatDialog } from "@angular/material";
import { ResourceMainForm } from "src/app/core/models/form/resource-main-form.model";
import { ResourceGroupForm } from "src/app/core/models/form/resource-group-form-model";
import { Router } from "@angular/router";
import { baseServerUri } from "src/app/core/constants/configuration/config.constant";
import {
  allResourcesEndpoint,
  getResourceTypesEndpoint,
  getResourceGroupsEndpoint,
  getResourceOnlineAccountsEndpoint
} from "src/app/core/constants/endpoints.constant";
import { takeUntil } from "rxjs/operators";
import {
  dialogBoxSuccessTitle,
  createSuccessResource,
  updateSuccessResource
} from "src/app/core/constants/message.constant";
import { DialogBoxComponent } from "../../dialog-box/dialog-box.component";
import { LoaderMessagingService } from "src/app/core/services/messaging/loader-messaging.service";
import { FormUtil } from "src/app/core/services/util/form.util";
import { PageForm } from "src/app/core/models/form/page-form.model";
import { ResourceAssociation } from "src/app/core/models/resource-association.model";
import { sideNavLabelResource } from "src/app/core/constants/configuration/resource-constants.config";
import { RESOURCES_PATH } from "src/app/core/constants/routes.constant";

@Component({
  selector: "resource-form",
  templateUrl: "./resource-form.component.html",
  styleUrls: ["./resource-form.component.scss"]
})
export class ResourceFormComponent implements OnInit, OnDestroy {
  @Input() forUpdate: boolean;
  @Input() resourceId: number;
  resource: Resource;
  activeIndex: number = 0;
  modifiedResource: Resource;
  resourceFormValues: ResourceMainForm;
  resourceGroupFormValues: ResourceGroupForm[];
  sideNavLabels: string[] = [];
  isFirst: boolean = true;
  isLast: boolean = false;
  resourceTypes: any[];
  resourceGroups: any[];
  onlineAccounts: any[];
  unsubscribe: Subject<any> = new Subject();
  hasAssociations: boolean;
  onlineAccount: string;
  resourceType: string;

  constructor(
    private resourceHelper: ResourceHelper,
    private errorHandlerService: ErrorHandlerService,
    private crudService: CrudService,
    private dialog: MatDialog,
    private router: Router,
    private formUtil: FormUtil,
    private loaderMessagingService: LoaderMessagingService
  ) {}

  ngOnInit() {
    this.loaderMessagingService.showPageLoader(true);
    this.sideNavLabels = sideNavLabelResource;
    this.getResourceTypes();
    this.isFirst = true;
    this.isLast = false;
    if (this.forUpdate) this.initResourceForms();
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  handleCompletion = (): void => {
    this.loaderMessagingService.showPageLoader(false);
  };

  onChangePage(label: any) {
    if (!this.hasAssociations) {
      this.activeIndex =
        label === NEXT ? this.activeIndex + 1 : this.activeIndex - 1;
      this.resourceGroupFormValues = [];
    }

    let pageForm: PageForm = this.formUtil.setPageForm(
      label,
      this.sideNavLabels.length,
      this.activeIndex
    );

    this.activeIndex = pageForm.activeIndex;
    this.isFirst = pageForm.isFirst;
    this.isLast = pageForm.isLast;

    if (label === SAVE) {
      this.forUpdate ? this.update() : this.save();
    }
  }

  onGetAssociation(hasAssociations: boolean): void {
    this.hasAssociations = hasAssociations;
  }

  onFormSubmit($event: any, form: string): void {
    switch (form) {
      case "MAIN": {
        this.resourceFormValues = $event;
        this.resourceType = this.resourceTypes ?
          this.resourceTypes.find(resourceType => resourceType.code === this.resourceFormValues.type).name
          : this.resourceFormValues.type;

        if (this.resourceFormValues.hasOwnProperty("onlineAccountType")) {
          this.onlineAccount = this.onlineAccounts.find(
            account =>
              account.code === this.resourceFormValues.onlineAccountType
          ).name;
        }
        if (!this.hasAssociations) this.setResource();
        break;
      }
      case "GROUP": {
        this.resourceGroupFormValues = $event;
        this.setResource();
        break;
      }
      default:
        break;
    }
  }

  setResource(): void {
    let associations: ResourceAssociation[] = [];

    if (this.hasAssociations) {
      associations = this.resourceGroupFormValues.map(group => {
        let association: ResourceAssociation = {
          id: null,
          referenceCode: group.code,
          referenceName: group.name,
          referenceType: group.type,
          isRequired: group.isRequired
        };
        return association;
      });
    }

    this.modifiedResource = this.resourceHelper.setResourceModel(
      this.resourceFormValues,
      associations,
      this.forUpdate,
      this.resource
    );
  }

  get canViewMainForm(): boolean {
    return ((this.resourceFormValues && this.forUpdate) || !this.forUpdate) && this.resourceTypes && this.onlineAccounts && this.activeIndex === 0
  }

  get canViewGroupForm(): boolean {
    return ((this.resourceGroupFormValues && this.forUpdate) || !this.forUpdate) && this.resourceGroups && this.activeIndex === 1
  }

  private save(): void {
    this.loaderMessagingService.showPageLoader(true);
    this.crudService
      .add<Resource>(
        allResourcesEndpoint,
        this.modifiedResource
      )
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(response => {
          this.router.navigate([RESOURCES_PATH, response.id]);
          const dialogRef = this.dialog.open(DialogBoxComponent);
          dialogRef.componentInstance.dialogTitle = dialogBoxSuccessTitle;
          dialogRef.componentInstance.contentMessage = createSuccessResource;
        },
        this.errorHandlerService.handleError,
        this.handleCompletion
      );
  }

  private update(): void {
    this.loaderMessagingService.showPageLoader(true);
    this.crudService
      .edit<Resource>(
        allResourcesEndpoint.concat(`/${this.modifiedResource.id}`),
        this.modifiedResource
      )
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(
        response => {
          this.router.navigate([RESOURCES_PATH, this.modifiedResource.id]);
          const dialogRef = this.dialog.open(DialogBoxComponent);
          dialogRef.componentInstance.dialogTitle = dialogBoxSuccessTitle;
          dialogRef.componentInstance.contentMessage = updateSuccessResource;
        },
        this.errorHandlerService.handleError,
        this.handleCompletion
      );
  }

  // invoke only if for update
  initResourceForms(): void {
    this.crudService
      .getById<Resource>(allResourcesEndpoint.concat(`/${this.resourceId}`))
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(data => {
        if (data) {
          this.resource = data;
          this.resourceFormValues = this.resourceHelper.setResourceMainFormValues(
            data
          );
          this.resourceGroupFormValues = this.resourceHelper.setResourceGroupFormValues(
            data.resourceAssoc
          );
          this.hasAssociations = this.resourceGroupFormValues.length > 0;
        }
      }, this.errorHandlerService.handleError);
  }

  getResourceTypes(): void {
    this.crudService
      .getById<any>(getResourceTypesEndpoint)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(response => {
        this.resourceTypes = response;
        this.resourceTypes.sort((a, b) => a.code.localeCompare(b.code));
        this.getResourceGroups();
      }, this.errorHandlerService.handleError);
  }

  getResourceGroups(): void {
    this.crudService
      .getById<any>(getResourceGroupsEndpoint)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(response => {
        this.resourceGroups = response;
        this.resourceGroups.sort((a, b) => a.code.localeCompare(b.code));
        this.getOnlineAccounts();
      }, this.errorHandlerService.handleError);
  }

  getOnlineAccounts(): void {
    this.crudService
      .getById<any>(getResourceOnlineAccountsEndpoint)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(
        response => {
          this.onlineAccounts = response;
          this.onlineAccounts.sort((a, b) => a.code.localeCompare(b.code));
        },
        this.errorHandlerService.handleError,
        this.handleCompletion
      );
  }
}
