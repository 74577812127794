import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CrudService } from 'src/app/core/services/data/crud.service';
import { Curriculum } from 'src/app/core/models/curriculum.model';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { HeaderMessagingService } from 'src/app/core/services/messaging/header-messaging.service';
import { MatDialog } from '@angular/material';
import { allCurriculumsEndpoint } from 'src/app/core/constants/endpoints.constant';
import { baseServerUri } from 'src/app/core/constants/configuration/config.constant';
import {
  dialogBoxSuccessTitle,
  updateSuccessCurriculum, 
} from 'src/app/core/constants/message.constant';
import { DialogBoxComponent } from 'src/app/shared/components/dialog-box/dialog-box.component';
import { CURRICULUMS_PATH } from 'src/app/core/constants/routes.constant';
import { ErrorHandlerService } from 'src/app/core/services/util/error-handler.service';
import { LoaderMessagingService } from 'src/app/core/services/messaging/loader-messaging.service';
import { sideNavLabelCurriculum, CURRICULUM } from 'src/app/core/constants/configuration/curriculum-constants.config';
import { UPDATE_MODULE_NAME } from 'src/app/core/constants/configuration/common.constant';
import { UserService } from 'src/app/core/services/util/user.service';
import { homeRoutingPermissions } from "src/app/core/constants/configuration/role-constants.config";

@Component({
	selector: 'app-curriculum-update',
	templateUrl: './curriculum-update.component.html',
	styleUrls: ['./curriculum-update.component.scss']
})
export class CurriculumUpdateComponent implements OnInit, OnDestroy {

	unsubscribe: Subject<any> = new Subject();
	activeIndex: number = 0;
	sideNavLabels: string[] = [];
	curriculum: Curriculum;

	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private crudService: CrudService,
		private headerMessagingService: HeaderMessagingService,
		private errorHandlerService: ErrorHandlerService,
		private loaderMessagingService: LoaderMessagingService,
		private dialog: MatDialog,
    private userService: UserService,
	) {
		this.userService.checkRolePermission(CURRICULUMS_PATH, homeRoutingPermissions);
		this.headerMessagingService.setHeader(CURRICULUM, UPDATE_MODULE_NAME, false, null);
		this.sideNavLabels = sideNavLabelCurriculum;
	}
	
	ngOnInit() {
		this.loaderMessagingService.showPageLoader(true);
		this.route.params.pipe(takeUntil(this.unsubscribe)).subscribe(params => this.findCurriculum(params["id"]));
	}

	ngOnDestroy(): void {
		this.unsubscribe.next();
		this.unsubscribe.complete();
	}

	private findCurriculum(id: number): void {

		this.crudService
			.getById<Curriculum>(allCurriculumsEndpoint.concat(`/${id}`))
			.pipe(takeUntil(this.unsubscribe))
			.subscribe(response => {
				if (response) this.curriculum = response;
			}, this.errorHandlerService.handleError, this.handleCompletion);

	}

	onSaveCurriculum($event: Curriculum) {

		this.loaderMessagingService.showPageLoader(true);
		this.crudService
			.edit<Curriculum>(allCurriculumsEndpoint.concat(`/${$event.id}`), $event)
			.pipe(takeUntil(this.unsubscribe))
			.subscribe(() => {
				this.router.navigate([CURRICULUMS_PATH, $event.id]);
				const dialogRef = this.dialog.open(DialogBoxComponent);
				dialogRef.componentInstance.dialogTitle = dialogBoxSuccessTitle;
				dialogRef.componentInstance.contentMessage = updateSuccessCurriculum;
			}, this.errorHandlerService.handleError, this.handleCompletion);
		
	}

	handleCompletion = (): void => {
		this.loaderMessagingService.showPageLoader(false);
	}

}
