import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { DataProperties } from 'src/app/core/models/data-properties.model';
import { Subject } from 'rxjs';
import { LoaderMessagingService } from 'src/app/core/services/messaging/loader-messaging.service';
import { takeUntil } from 'rxjs/operators';
import { shippingDetailDataProperties } from 'src/app/core/constants/configuration/order-constants.config';
import { FormGroup, Validators } from '@angular/forms';
import { FormUtil } from 'src/app/core/services/util/form.util';
import { regexAlphanumericFormat, regexNumericFormat, regexFloatingNumericFormat } from 'src/app/core/constants/configuration/common.constant';
import { changeSize } from 'src/app/core/constants/animations.constant';

@Component({
  selector: 'app-order-shipping-details-list',
  templateUrl: './order-shipping-details-list.component.html',
  styleUrls: ['./order-shipping-details-list.component.scss'],
  animations: [changeSize]
})
export class OrderShippingDetailsListComponent implements OnInit {

  @Input() items: any[] = [];
  @Input() reference: any = {};
  @Output() getSelectedShipping = new EventEmitter<any>();
  hasAddedShippingDetails: boolean = false;
  dataProperties: DataProperties[];
  headersLength: number;
  isLoading: boolean = false;
  unsubscribe: Subject<any> = new Subject();
  shippingForm: FormGroup;
  addFormToggle: boolean = false;
  addedShippingDetails: any;

  constructor(
    private loaderMessagingService: LoaderMessagingService,
    private formUtil: FormUtil,
  ) {
    this.subscribeToLoaderMessagingService();
    this.shippingForm = this.formUtil.createShippingDetailForm();
  }

  ngOnInit() {
    this.dataProperties = shippingDetailDataProperties;
    this.headersLength = this.dataProperties.length;
    this.setValidatorsToDefault();
    this.items.forEach(item => {
      if (!item.guardianId) item.id = null;
    });
  }

  private subscribeToLoaderMessagingService(): void {
    this.loaderMessagingService.listLoader$
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(show => setTimeout(() => (this.isLoading = show)));
  }

  onCheckboxCheck($event: any, selectedItem: any) {
    if ($event.checked) {
      this.items.forEach(item => {
        if(item.id !== selectedItem.id) {
          item.check = false;
        }
      });
      this.getSelectedShipping.emit({ selectedItem: selectedItem, items: this.items });
    }
  }

  onClickAddForm() {
    this.hasAddedShippingDetails = this.items.filter(item => !item.id).length > 0;
    this.addFormToggle = !this.addFormToggle;

    if (this.addFormToggle) {
      this.addRequiredToFormValidator();
      if (this.hasAddedShippingDetails) {
        this.addedShippingDetails = this.items.find(item => !item.id);
        this.shippingForm.controls['addressName'].setValue(this.addedShippingDetails.addressName);
        this.shippingForm.controls['addressLine'].setValue(this.addedShippingDetails.addressLine);
        this.shippingForm.controls['city'].setValue(this.addedShippingDetails.city);
        this.shippingForm.controls['state'].setValue(this.addedShippingDetails.state);
        this.shippingForm.controls['zipCode'].setValue(this.addedShippingDetails.zipCode);
        this.shippingForm.controls['phone'].setValue(this.addedShippingDetails.phone);
        this.shippingForm.controls['email'].setValue(this.addedShippingDetails.email);
        this.items.pop();
      }
    } else {
      this.setValidatorsToDefault();
    }
  }

  onClickCancel() {
    this.shippingForm.reset();
    this.addFormToggle = !this.addFormToggle;
    if (this.hasAddedShippingDetails) this.items.push(this.addedShippingDetails);
  }

  onClickConfirm() {
    this.validateNoBlankField()
    this.validateUntouchedForms();
    if (this.shippingForm.valid) {
      this.items.push({
        ...this.shippingForm.value,
        check: this.addedShippingDetails && this.addedShippingDetails.check ?
          this.addedShippingDetails.check : false,
      });
      this.shippingForm.reset();
      this.addFormToggle = !this.addFormToggle;
      let selectedItem = this.items.find(item => item.check);
      this.getSelectedShipping.emit({ selectedItem: selectedItem || null, items: this.items });
    }
  }

  private validateUntouchedForms(): void {
		Object.keys(this.shippingForm.controls).forEach(control => {
			this.shippingForm.get(control).markAsTouched();
      this.shippingForm.get(control).markAsDirty();
		});
  }

  validateNoBlankField() {
		Object.keys(this.shippingForm.controls).forEach(control => {
      if (control !== "check") {
        let trimmedValue = this.shippingForm.controls[control].value.trim();
        if (trimmedValue === '') this.shippingForm.controls[control].setValue('');
        else this.shippingForm.controls[control].setValue(trimmedValue);
      }
    });
  }

  addRequiredToFormValidator(): void {
    this.shippingForm.controls['addressName'].setValidators(Validators.compose([
      Validators.required,
      Validators.pattern(regexAlphanumericFormat)
    ]));
    this.shippingForm.controls['addressLine'].setValidators(Validators.compose([
      Validators.required,
      Validators.pattern(regexAlphanumericFormat)
    ]));
    this.shippingForm.controls['city'].setValidators(Validators.compose([
      Validators.required,
      Validators.pattern(regexAlphanumericFormat)
    ]));
    this.shippingForm.controls['state'].setValidators(
      Validators.required
    );
    this.shippingForm.controls['zipCode'].setValidators(Validators.compose([
      Validators.required,
      Validators.pattern(regexFloatingNumericFormat)
    ]));
    this.shippingForm.controls['phone'].setValidators(Validators.compose([
      Validators.required,
      Validators.pattern(regexAlphanumericFormat)
    ]));
    this.shippingForm.controls['email'].setValidators(Validators.compose([
      Validators.required,
      Validators.email
    ]));

    Object.keys(this.shippingForm.controls).forEach(key => {
      this.shippingForm.get(key).updateValueAndValidity();
    })
  }

  setValidatorsToDefault() {
    this.shippingForm.controls['addressName'].setValidators(
      Validators.pattern(regexAlphanumericFormat)
    );
    this.shippingForm.controls['addressLine'].setValidators(
      Validators.pattern(regexAlphanumericFormat)
    );
    this.shippingForm.controls['city'].setValidators(
      Validators.pattern(regexAlphanumericFormat)
    );
    this.shippingForm.controls['state'].setValidators([]);
    this.shippingForm.controls['zipCode'].setValidators(
      Validators.pattern(regexFloatingNumericFormat)
    );
    this.shippingForm.controls['phone'].setValidators(
      Validators.pattern(regexAlphanumericFormat)
    );
    this.shippingForm.controls['email'].setValidators(
      Validators.email
    );

    Object.keys(this.shippingForm.controls).forEach(key => {
      this.shippingForm.get(key).updateValueAndValidity();
    })
  }
  
}
