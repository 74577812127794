import { DataProperties } from "../../models/data-properties.model";

export const BUNDLE: string = "BUNDLE";
export const bundleTab: string = "BUNDLE";

export const BUNDLE_TYPE_1: string = "BUNDLE_TYPE_1";
export const BUNDLE_TYPE_2: string = "BUNDLE_TYPE_2";
export const BUNDLE_GROUP_1: string = "BUNDLE_GROUP_1";
export const BUNDLE_GROUP_2: string = "BUNDLE_GROUP_2";
export const BUNDLE_GROUP_3: string = "BUNDLE_GROUP_3";

export const sideNavLabelBundle: string[] = [
    "Main Form",
    "Add Curriculum",
    "Summary"
];

export const bundleDataProperties: DataProperties[] = [
    {
        label: "Code",
        property: "code",
        sortable: true,
        clickable: true,
        filterable: true,
        autocomplete: false,
        endpoint: ""
    },
    {
        label: "Name",
        property: "name",
        sortable: true,
        clickable: false,
        filterable: true,
        autocomplete: false,
        endpoint: ""
    },
    {
        label: "Price",
        property: "price",
        sortable: true,
        clickable: false,
        filterable: true,
        autocomplete: false,
        endpoint: ""
    },
    {
        label: "Curriculum",
        property: "curriculumList",
        sortable: false,
        clickable: false,
        filterable: false,
        autocomplete: false,
        endpoint: ""
    },
    {
        label: "Type",
        property: "bundleType",
        sortable: true,
        clickable: false,
        filterable: true,
        autocomplete: false,
        endpoint: ""
    },
    {
        label: "HQT Lead Tag",
        property: "hqtLeadTag",
        sortable: false,
        clickable: false,
        filterable: false,
        autocomplete: false,
        endpoint: ""
    },
    {
        label: "Status",
        property: "status",
        sortable: true,
        clickable: false,
        filterable: true,
        autocomplete: false,
        endpoint: ""
    }
];
