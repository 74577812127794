import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { DataCustomizerService } from 'src/app/core/services/util/data-customizer.service';
import { ResourceGroupForm } from 'src/app/core/models/form/resource-group-form-model';
import { ResourceHelper } from 'src/app/core/services/util/resource.helper';

@Component({
  selector: 'app-resource-details-dialog',
  templateUrl: './resource-details-dialog.component.html',
  styleUrls: ['./resource-details-dialog.component.scss']
})
export class ResourceDetailsDialogComponent implements OnInit {

  resource: any = {};
  groups: ResourceGroupForm[];
  onlineAccount: string;
  resourceType: string;

  constructor(public dialogRef: MatDialogRef<ResourceDetailsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dataCustomizerService: DataCustomizerService,
    private resourceHelper: ResourceHelper) { }

  ngOnInit() {
    this.onlineAccount = this.data.onlineAccount;
    this.resourceType = this.data.resourceType;
    this.resource = this.dataCustomizerService.formatResourceDetailsDisplay(this.data.response);
    this.groups = Object.assign([], this.resourceHelper.setResourceGroupFormValues(this.data.response.resourceAssoc));
  }

}
