import { Component, OnInit, OnDestroy, EventEmitter, Output, Input } from '@angular/core';
import { taskDataProperties, WORKBOOKTEXTBOOK } from 'src/app/core/constants/configuration/task-constants.config';
import { DataProperties } from 'src/app/core/models/data-properties.model';
import { Task } from "src/app/core/models/order/task.model";
import { LoaderMessagingService } from 'src/app/core/services/messaging/loader-messaging.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { faBell, faCheckCircle, faTimesCircle, faSort, faSortUp, faSortDown, faPlus } from '@fortawesome/free-solid-svg-icons';
import { DataCustomizerService } from 'src/app/core/services/util/data-customizer.service';
import { TASKS_PATH } from 'src/app/core/constants/routes.constant';
import { Router } from '@angular/router';
import {
  PROCESSING_STATUS_1,
  PROCESSING_STATUS_2,
  PROCESSING_STATUS_3,
  PROCESSING_STATUS_4,
  PROCESSING_STATUS_5,
  PROCESSING_STATUS_6,
  PROCESSING_STATUS_7,
} from 'src/app/core/constants/configuration/order-constants.config';
import { TASK_GROUP_2, TASK_GROUP_3 } from 'src/app/core/constants/configuration/task-constants.config';
import { ADMIN, SPECIALIST, SUPERVISOR } from 'src/app/core/constants/configuration/role-constants.config';
import { UserService } from '../../../../../core/services/util/user.service';
import { DESC, ASC } from 'src/app/core/constants/configuration/common.constant';

@Component({
	selector: 'task-list',
	templateUrl: './task-list.component.html',
	styleUrls: ['./task-list.component.scss']
})
export class TaskListComponent implements OnInit, OnDestroy {

    @Input() processingStatus: any[];
    @Input() taskGroupType: any[];
    @Input() showAssignToSpecialist: boolean;
    @Input() selectedSpecialistValue: any;
    @Input() sortedBy: string;
    @Input() sortOrder: string;
    @Output() bulkAction: EventEmitter<any> = new EventEmitter();
    @Output() getSelectedSpecialist: EventEmitter<any> = new EventEmitter();
    @Output() toggleSortOnClick : EventEmitter<any> = new EventEmitter();

    items: any[];
    specialists: any[] = [];
    specialistUsernames: string[] = [];
    unsubscribe: Subject<any> = new Subject();
    isLoading: boolean = false;
    dataProperties: DataProperties[] = taskDataProperties;
    checkedItems: any[] = [];
    selectedSpecialist: any;

    assignIcon: IconDefinition = faBell;
	completeIcon: IconDefinition = faCheckCircle;
	cancelIcon: IconDefinition = faTimesCircle;
	faSortDefaultIcon = faSort;
	faSortIcon = faSort;
	faSort = faSort;
    addIcon: IconDefinition = faPlus;

    constructor(
        private loaderMessagingService: LoaderMessagingService,
        private dataCustomizerService: DataCustomizerService,
        private router: Router,
        private userService: UserService,
    ) {
        this.subscribeToLoaderMessagingService();
    }

    ngOnInit(): void {
        this.items = this.dataCustomizerService.addCheckboxProperty(this.items);
    }

    ngOnDestroy(): void {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
    
    @Input()
    set specialistList(values: any []) {
        this.specialists = values;
        this.specialists.forEach(specialist => {
            if (!this.specialistUsernames.includes(specialist.username)) {
                this.specialistUsernames.push(specialist.username);
            }
        });
        if (this.selectedSpecialistValue && this.selectedSpecialistValue.username)
            this.selectedSpecialist = this.selectedSpecialistValue;
    }
    
    @Input()
    set tasks(values: any []) {
        this.items = values;
        this.checkedItems = [];
        if(!this.showAssignToSpecialist) this.selectedSpecialist = "";
    }

    onSelectSpecialist(value) {
        this.selectedSpecialist = value;
        this.specialists.forEach(specialist => {
            if (specialist.username === this.selectedSpecialist) {
                this.getSelectedSpecialist.emit(specialist);
            }
        });
    }

    onCheckedAll(): void {
        this.items.forEach(item => {
            if (!this.isCancelled(item) && !this.isCompleted(item)) {
                item.checked = !this.isAllChecked;
            }
        });
        if (!this.isAllChecked) {
            this.checkedItems = Object.assign([], this.items.filter(item =>
                !this.isCancelled(item) && !this.isCompleted(item))
            );
        } else {
            this.checkedItems = [];
        }
    }

    onChecked(currentItem: any): void {
        if (this.checkedItems.find(item => item.code === currentItem.code)) {
            this.items.find(item => item.code === currentItem.code).checked = false;
            this.checkedItems = this.checkedItems.filter(item => item.code !== currentItem.code);
        } else {
            this.items.find(item => item.code === currentItem.code).checked = true;
            this.checkedItems.push(currentItem);
        }
    }

    hasTooltipAndEllipses(property: string): boolean {
        return ['code', 'curriculumName', 'type', 'name', 'requestedBy'].includes(property);
    }

    get showAssignToOthers(): boolean {
        return this.showAssignToSpecialist && this.userService.hasRole([ADMIN, SUPERVISOR])
    }

    get canAssignToOthers(): boolean {
        return this.canAssign && this.selectedSpecialist.length > 0
    }

    get canAssign(): boolean {
        let hstApprovedStatus = this.processingStatus.find(status => status.code === PROCESSING_STATUS_1);
        return this.checkedItems.length > 0 && this.checkedItems.filter(item => item.status !== hstApprovedStatus.name).length === 0 &&
            this.userService.hasRole([ADMIN, SUPERVISOR, SPECIALIST])
    }

    get canCreateWorkbookTextBook(): boolean {
        return this.checkedItems.length == 1 && this.checkedItems.filter(item => item.type === "Class" && !item.hasWorkbookTextbook).length == 1 
            && this.userService.hasRole([ADMIN, SUPERVISOR, SPECIALIST]);
    }
 
    get canPreComplete(): boolean {
        let processingStatus = this.processingStatus.find(status => status.code === PROCESSING_STATUS_2);
        let isSameGroupType = this.checkGroupType();
        let isTemporaryDisableMultiClass = this.disableMultiClass();
        return this.checkedItems.length > 0 && isSameGroupType && this.checkedItems.filter(item =>
            item.status !== processingStatus.name || this.hasRequestToCancel(item)).length === 0 &&
            (this.userService.hasRole([ADMIN, SUPERVISOR]) ||
                (this.userService.hasRole([SPECIALIST]) && this.checkedItems.filter(item => item.assignedTo !== this.userService.getLoginUser().username).length === 0))
    }

    checkGroupType(): boolean {
        let isSameGroupType = true;

        for (let i = 1; i < this.checkedItems.length; i++) {
            if (this.checkedItems[i].type !== this.checkedItems[0].type) {
                return false;
            }
        }
        return isSameGroupType;
    }

    disableMultiClass(): boolean {
        let counter = 0;
        for (let i = 0; i < this.checkedItems.length; i++) {
            if (this.checkedItems[i].type !== WORKBOOKTEXTBOOK) {
                counter++;
            }
        }
        return counter <= 1;
    }

    get canComplete(): boolean {
        let processingStatus = this.processingStatus.find(status => status.code === PROCESSING_STATUS_2);
        let preCompletedStatus = this.processingStatus.find(status => status.code === PROCESSING_STATUS_3);
        let isSameGroupType = this.checkGroupType();
        let isTemporaryDisableMultiClass = this.disableMultiClass();
        return this.checkedItems.length > 0 && isSameGroupType && this.checkedItems.filter(item =>
            ![processingStatus.name, preCompletedStatus.name].includes(item.status) || this.hasRequestToCancel(item)).length === 0 &&
            (this.userService.hasRole([ADMIN, SUPERVISOR]) ||
                (this.userService.hasRole([SPECIALIST]) && this.checkedItems.filter(item => item.assignedTo !== this.userService.getLoginUser().username).length === 0))
    }

    getTaskGroupCode(item: any): string {
        let taskGroup = this.taskGroupType.find(type => type.name === item.type);
        return taskGroup ? taskGroup.code : ""
    }

    isCancelled(item: any): boolean {
        let cancelledStatus = this.processingStatus.find(status => status.code === PROCESSING_STATUS_5);
        return item.status === cancelledStatus.name;
    }

    isCompleted(item: any): boolean {
        let completedStatus = this.processingStatus.find(status => status.code === PROCESSING_STATUS_4);
        return item.status === completedStatus.name;
    }

    isProcessing(item: any): boolean {
        let status = this.processingStatus.find(status => status.code === PROCESSING_STATUS_2);
        return item.status === status.name;
    }

    isWorkbookTextBook(item: any): boolean {
        return this.getTaskGroupCode(item) === TASK_GROUP_2;
    }

    get isAllChecked(): boolean {
        return this.items.length > 0 && this.checkedItems.length > 0 &&
            this.checkedItems.length === this.items.filter(item =>
                !this.isCancelled(item) && !this.isCompleted(item)).length;
    }

    hasRequestToCancel(selectedItem: any): boolean {
        return selectedItem.orderItemAssoc.filter(orderItem => orderItem.forCancel).length > 0;
    }

    onBulkAction(action: string): void {
        this.bulkAction.emit({
            action,
            items: Object.assign([], this.checkedItems),
            selectedSpecialist: this.selectedSpecialist,
        });
    }

    onLinkClick($event: any, item: any): void {
        this.router.navigate([TASKS_PATH, item.syCode, item.id]);
    }

    getStatusClass(statusName: string): string {
      let currentStatus = this.processingStatus.find(status => status.name === statusName);
      if (currentStatus) {
        switch (currentStatus.code) {
          case PROCESSING_STATUS_1: return "approved status"
          case PROCESSING_STATUS_2: return "processing status"
          case PROCESSING_STATUS_3: return "pre-completed status"
          case PROCESSING_STATUS_4: return "completed status"
          case PROCESSING_STATUS_5: return "canceled status"
          case PROCESSING_STATUS_6: return "refunded status"
          case PROCESSING_STATUS_7: return "adjusted status"
          default: return "status"
        }
      }
    }

    private subscribeToLoaderMessagingService(): void {
        this.loaderMessagingService.listLoader$
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(show => setTimeout(() => (this.isLoading = show)));
    }

    toggleSort(property: string) {
        if (property === this.sortedBy) {
          if (this.sortOrder === DESC) {
            this.faSort = faSortUp;
            this.sortOrder = ASC;
          } else if (this.sortOrder === ASC) {
            this.faSort = faSortDown;
            this.sortOrder = DESC;
          } else if (this.faSort === faSortDown) {
            this.faSort = faSort;
            this.sortedBy = "";
            this.sortOrder = ASC;
          }
        } else {
          this.sortedBy = property;
          if (!this.sortOrder && property === 'name') {
            this.faSort =  faSortDown;
            this.sortOrder = DESC;
          } else {
            this.faSort =  faSortUp;
            this.sortOrder = ASC;
          }
        }
    
        this.toggleSortOnClick.emit({ sortedBy: this.sortedBy, sortOrder: this.sortOrder });
      }

}
