import { Component, OnInit } from '@angular/core';
import { Subject } from "rxjs";
import { takeUntil } from 'rxjs/operators';
import { FormGroup, FormArray, FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { FormUtil } from '../../../../core/services/util/form.util';
import { Page } from '../../../../core/models/page.model';
import { Resource } from '../../../../core/models/resource.model';
import { TASKS_PATH } from '../../../../core/constants/routes.constant';
import { ASC } from '../../../../core/constants/configuration/common.constant';
import { TASK } from '../../../../core/constants/configuration/task-constants.config';
import { homeRoutingPermissions } from "../../../../core/constants/configuration/role-constants.config";
import { CrudService } from '../../../../core/services/data/crud.service';
import { UserService } from '../../../../core/services/util/user.service';
import { QueryService } from '../../../../core/services/util/query.service';
import { ErrorHandlerService } from '../../../../core/services/util/error-handler.service';
import { DataCustomizerService } from '../../../../core/services/util/data-customizer.service';
import { HeaderMessagingService } from '../../../../core/services/messaging/header-messaging.service';
import { LoaderMessagingService } from '../../../../core/services/messaging/loader-messaging.service';
import { ConfirmDialogComponent } from "src/app/shared/components/confirm-dialog/confirm-dialog.component";
import { saveAs } from "file-saver";
import {
  allResourcesEndpoint,
  getProcessingStatusEndpoint,
  downloadBundlesEndpoint,
} from '../../../../core/constants/endpoints.constant';
import { baseServerUri, xlsxFileMediaType, fileNameBundle, downloadFileExtension, csvMediaType, csvFileExtension } from 'src/app/core/constants/configuration/config.constant';
import { FileService } from 'src/app/core/services/data/file.service';
import { HttpParams } from '@angular/common/http';
import { formatDate } from '@angular/common';
import { downloadOnlineAccountReportHeader, downloadOnlineAccountReportMessage } from 'src/app/core/constants/message.constant';

@Component({
  selector: 'app-download-online-accounts',
  templateUrl: './download-online-accounts.component.html',
  styleUrls: ['./download-online-accounts.component.scss']
})
export class DownloadOnlineAccountsComponent implements OnInit {
  
	unsubscribe: Subject<any> = new Subject();
  downloadOnlineAccountForm: FormGroup;
  onlineAccounts: any[] = [];
  processingStatus: any[] = [];
  selectedOnlineAccount: any;
  selectedStatus: any;
  fromDate: any;
  toDate: any;
  testcheck = true;

	downloadIcon: IconDefinition = faDownload;

  constructor(
		private crudService: CrudService,
    private dataCustomizerService: DataCustomizerService,
    private dialog: MatDialog,
		private errorHandlerService: ErrorHandlerService,
		private formsUtil: FormUtil,
		private headerMessagingService: HeaderMessagingService,
		private loaderMessagingService: LoaderMessagingService,
    private queryService: QueryService,
    private userService: UserService,
    private downloadFileService: FileService,
  ) {
  }

  ngOnInit() {
    this.downloadOnlineAccountForm = this.formsUtil.createDownloadOnlineAccountForm();
    this.initParams();
  }

  initParams() {
		this.loaderMessagingService.showPageLoader(true);
    this.getProcessingStatus();
    this.fromDate = new Date;
    this.downloadOnlineAccountForm.get("dateFrom").setValue(this.fromDate);
    this.toDate = new Date;
    this.downloadOnlineAccountForm.get("dateTo").setValue(this.toDate);
    this.clearValidator('curriculumCode');
  }

  onSelectOnlineAccount(value: any) {
    this.selectedOnlineAccount = value;
  }

  onSelectStatus(value: any) {
    this.selectedStatus = value;
  }
  
  getProcessingStatus(): void {
		this.crudService
			.getById<any>(getProcessingStatusEndpoint)
			.pipe(takeUntil(this.unsubscribe))
			.subscribe(response => {
        this.processingStatus = response;
        this.processingStatus.sort((a, b) => a.code.localeCompare(b.code));
        this.processingStatus.forEach(status => {
          if(status.code != "PROCESSING_STATUS_5") {
            status.checked = true;
          } else {
            status.checked = false;
          }
        });
        this.getOnlineAccounts();
      }, this.errorHandlerService.handleError,
      this.handleCompletion);
  }

  getOnlineAccounts(): void {
    let filters: Map<String, String> = new Map<string, string>();
    filters.set("type", "Online Account");
    
    this.crudService
      .getAll<Resource>(allResourcesEndpoint.concat(
        this.queryService.buildResourceSearchQuery(0, 1000, name, ASC, "", filters, this.getReference())))
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(
        this.handleSearchResponse,
        this.errorHandlerService.handleError,
        this.handleCompletion
      );
    // this.onlineAccounts.push({name: 'ConnectEd', code: '1'});
    // this.onlineAccounts.push({name: 'ConnectEd2', code: '4'});
    // this.onlineAccounts.push({name: 'Edgenuity', code: '7'});
    // this.onlineAccounts.push({name: 'Schoology', code: '10'});
  }

  handleSearchResponse = (response: Page<Resource>): void => {
    if (response) {
      response.content.forEach((resource: Resource) => {
        if(resource.code == "12"){
        this.onlineAccounts.push(this.dataCustomizerService.formatResourceDetailsDisplay(resource, true, this.getReference()))
        }
      }
      );
    }
  };

	handleCompletion = (): void => {
		this.loaderMessagingService.showPageLoader(false);
  }

  getReference(): any {
    return {
      resourceTypes: [],
      resourceGroups: [],
      onlineAccounts: [],
    };
  }

  onDownloadOnlineAccounts() {
    this.formatDates();

    this.validateUntouchedForms();

      if (this.downloadOnlineAccountForm.valid) {
      const params = new Map<String, String>();
      params.set("onlineAccount", this.selectedOnlineAccount.code);
      params.set("status", this.getStatusList());
      params.set("fromDate", this.fromDate + " 00:00:00");
      params.set("toDate", this.toDate + " 23:59:59");

      if (this.downloadOnlineAccountForm.valid) {
        const dialogRef = this.dialog.open(ConfirmDialogComponent);
        dialogRef.componentInstance.confirmTitle = downloadOnlineAccountReportHeader;
        dialogRef.componentInstance.confirmMessage = downloadOnlineAccountReportMessage.replace("%", this.selectedOnlineAccount.name);
        dialogRef.afterClosed().subscribe(result => {
          if(result) this.download(params);
        });
      }
    }
  }
  getStatusList(): String {
    let statusList = '';
    this.processingStatus.forEach(status =>{
      if(status.checked) {
        statusList += status.code + ",";
      }
    });

    return statusList;
  }

  formatDates() {
    this.fromDate = this.downloadOnlineAccountForm.controls['dateFrom'].value;
    this.toDate = this.downloadOnlineAccountForm.controls['dateTo'].value;

    const format = "yyyy-MM-dd";
    this.fromDate = formatDate(this.fromDate, format, 'en-US');
    this.toDate = formatDate(this.toDate, format, 'en-US');
  }

  refineURI(query: String): String {
		return encodeURIComponent(String(query));
	}

  download(params: any): void {
    this.loaderMessagingService.showPageLoader(true);
    const endpoint = baseServerUri.concat("/report/downloadOA");
    let query = "";
    if (params.size > 0) params.forEach((value, key) => query = query.concat(`&${key}=${this.refineURI(value)}`));
    this.downloadFileService
      .getFile(endpoint.concat('?' + query))
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(
        response => {
          let date = new Date();
          const format = "yyyyMMdd_HHmm";
          const formattedDate = formatDate(date, format, 'en-US');
          const fileName = this.selectedOnlineAccount.name + " " + formattedDate;
          const blob = new Blob([response], { type: csvMediaType });
          saveAs(blob, fileName.concat(csvFileExtension));
        },
        this.errorHandlerService.handleError,
        this.handleCompletion
      );
  }

  private validateUntouchedForms(): void {
		Object.keys(this.downloadOnlineAccountForm.controls).forEach(control => {
			this.downloadOnlineAccountForm.get(control).markAsTouched();
      this.downloadOnlineAccountForm.get(control).markAsDirty();
		});
  }

  clearValidator(control){
		this.downloadOnlineAccountForm.controls[control].setValidators([]);
		this.downloadOnlineAccountForm.controls[control].updateValueAndValidity();
  }
  
  onStatusChange(status) {
    if(status.code != "PROCESSING_STATUS_5") {
      this.processingStatus.forEach(status => {
        if(status.code == "PROCESSING_STATUS_5") {
          status.checked = false;
        }
      });
    } else {
      if(!status.checked) {
        this.processingStatus.forEach(status => {
          if(status.code != "PROCESSING_STATUS_5") {
            status.checked = false;
          }
        });
      } else {
        this.processingStatus.forEach(status => {
          if(status.code != "PROCESSING_STATUS_5") {
            status.checked = true;
          }
        });
      }

    }
  }

}
