import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Hqt } from 'src/app/core/models/hqt.model';

@Component({
	selector: 'app-hqt-details',
	templateUrl: './hqt-details.component.html',
	styleUrls: ['./hqt-details.component.scss']
	})
export class HqtDetailsComponent implements OnInit {

	@Output() linkClink: EventEmitter<number> = new EventEmitter();
	@Input() hqt: Hqt;
	@Input() curriculums: any[];
	@Input() totalClasses: number;

	mainDetails: any;

	constructor() { }

	ngOnInit() {
		const { name, email, createdBy, createdDate, modifiedBy, modifiedDate, status } = this.hqt;
		this.mainDetails = { name, email, createdBy, createdDate, modifiedBy, modifiedDate, status };
	}

	onViewClass(id: number): void {
		this.linkClink.emit(id);
	}

}
