import { Component, EventEmitter, OnInit, Input, Output } from '@angular/core';
import { StudentDetails } from "src/app/core/models/eos/student-details.model";
import { TeacherDetails } from "src/app/core/models/eos/teacher-details.model";
import { LoaderMessagingService } from 'src/app/core/services/messaging/loader-messaging.service';
import { CrudService } from 'src/app/core/services/data/crud.service';
import { allClassesEndpoint, getAllSchoolYearsEndpoint, getActiveSchoolYearEndpoint, getActivePeriodStatusEndpoint, allGradeLevelsEndpoint, getClassSubjectsEndpoint } from 'src/app/core/constants/endpoints.constant';
import { Class } from 'src/app/core/models/class.model';
import { QueryService } from 'src/app/core/services/util/query.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { DataCustomizerService } from 'src/app/core/services/util/data-customizer.service';
import { ErrorHandlerService } from 'src/app/core/services/util/error-handler.service';
import { SY_CODE, CLOSE, OPEN, pageSizeOptions, ASC, fullYearFormat, STATUS_1, STATUS, STUDENT_SCOPE, STATUS_FILTER, CURRICULUM_STATUS } from 'src/app/core/constants/configuration/common.constant';
import { DataProperties } from 'src/app/core/models/data-properties.model';
import { orderClassDataProperties } from 'src/app/core/constants/configuration/order-constants.config';
import { FilterModalComponent } from 'src/app/shared/components/filter-modal/filter-modal.component';
import { MatDialog } from '@angular/material';
import { faFilter } from '@fortawesome/free-solid-svg-icons';
import { changeSize } from 'src/app/core/constants/animations.constant';
import * as moment from 'moment';
import { OrderClassResourceDialogComponent } from './order-class-resource-dialog/order-class-resource-dialog.component';
import { UserService } from 'src/app/core/services/util/user.service';
import { ADMIN, SUPERVISOR, SPECIALIST } from 'src/app/core/constants/configuration/role-constants.config';
import { faQuestionCircle, IconDefinition} from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-order-class-resource',
  templateUrl: './order-class-resource.component.html',
  styleUrls: ['./order-class-resource.component.scss'],
  animations: [changeSize]
})
export class OrderClassResourceComponent implements OnInit {

  @Input() studentDetails: StudentDetails;
  @Input() teacherDetails: TeacherDetails;
  @Input() shippingDetails: any = {};
  @Input() selectedShippingDetail: any = {};
  @Input() selectedClasses: any;
  @Output() getSelectedClassAndResources = new EventEmitter<any>();
  @Output() onGetNewShippingDetails = new EventEmitter<any>();

  keyword: string = "";
  pageIndex: number = 0;
  pageSize: number = pageSizeOptions[0];
  sortedBy: string;
  sortOrder: string;
  filters: Map<String, String> = new Map<string, string>();
  items: any = [];
  unsubscribe: Subject<any> = new Subject();
  reference: any = {};
  selectedSyId: number;
  schoolYears: any[] = [];
  schoolYear: string;
  filterLabels: string[] = [];
  orderClassDataProperties: DataProperties[] = orderClassDataProperties;
  clearFilters: boolean = true;
  isType1: boolean = false;
  trigger: string = CLOSE;
  faFilter = faFilter;
  itemsCount: number;
  classResourceDialogRef: any;
  faQuestionCircle: IconDefinition = faQuestionCircle;
  
  constructor(
    private loaderMessagingService: LoaderMessagingService,
    private crudService: CrudService,
    private queryService: QueryService,
    private dataCustomizerService: DataCustomizerService,
    private errorHandlerService: ErrorHandlerService,
    private dialog: MatDialog,
    private userService: UserService
  ) {
    
  }

  ngOnInit() {
    this.loaderMessagingService.showPageLoader(true);
    this.setReferenceSubjects();
  }

  onSelectClass($event: Class): void {
    this.classResourceDialogRef = this.dialog.open(OrderClassResourceDialogComponent, { width: "80%", autoFocus: false });
    this.classResourceDialogRef.componentInstance.classDetails = $event;
    this.classResourceDialogRef.componentInstance.selectedClasses = this.selectedClasses;
    this.classResourceDialogRef.componentInstance.closeClassResourceDetailsModal.subscribe(() => {
      this.classResourceDialogRef.close();
    });
    this.classResourceDialogRef.componentInstance.getSelectedClassResource.subscribe((result) => {
      if (result) {
        this.getSelectedClassAndResources.emit(result);
        this.classResourceDialogRef.close();
      }
    });
  }

  get hasSelectedClass() {
    let hasSelectedClass = false;
    if(this.selectedClasses && this.selectedClasses.length > 0){
      this.selectedClasses.forEach(selectedClass => {
        if(selectedClass.selectedClass != null){
          hasSelectedClass = true;
        }
      });
    }
    return hasSelectedClass;
  }

  getNewShippingDetails($event) {
    this.onGetNewShippingDetails.emit($event);
  }

  get getOrderClassDataProperties() {
    let additionalFilters = [
      {
        label: "Subject",
        property: "subject",
        sortable: false,
        clickable: false,
        filterable: true,
        autocomplete: true,
        endpoint: ""
      },
      {
        label: "From Grade Level",
        property: "fromGradeLevel",
        sortable: false,
        clickable: false,
        filterable: true,
        autocomplete: false,
        endpoint: ""
      },
      {
        label: "To Grade Level",
        property: "toGradeLevel",
        sortable: false,
        clickable: false,
        filterable: true,
        autocomplete: false,
        endpoint: ""
      }
    ];
    return [
      ...this.orderClassDataProperties.filter(prop => ['curriculumName', 'name'].includes(prop.property)),
      ...additionalFilters,
    ];
  }

  onSearch(keyword: string): void {
    this.keyword = keyword;
    this.pageIndex = 0;
    if(this.canViewInactive()){
      this.filters = new Map<string, string>();
    }
    this.search();
  }

  canViewInactive(): boolean {
    return this.userService.hasRole([ADMIN, SUPERVISOR, SPECIALIST]);
  }

  search() : void {

    this.loaderMessagingService.showListLoader(true);
    this.items = [];
    
    this.filters.set(SY_CODE, this.schoolYear);
    // this.filters.set(STATUS_FILTER, STATUS_1);
    // this.filters.set(CURRICULUM_STATUS, STATUS_1);
    this.filters.set(STUDENT_SCOPE, this.studentDetails.scope);

    this.crudService
      .getAll<Class>(allClassesEndpoint.concat(this.queryService.buildClassSearchQuery(
            this.pageIndex, this.pageSize, this.sortedBy, this.sortOrder, this.keyword, this.filters)))
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(
        response => {
          if (response) {
            this.itemsCount = response.totalElements;
            response.content.forEach(clazz => {
              this.items.push(this.dataCustomizerService.createCustomizedOrderClass(clazz, this.reference));
            });
          }
        },
        this.errorHandlerService.handleError,
        this.handleCompletion
      );

  }

  handleCompletion = (): void => {
    this.loaderMessagingService.showListLoader(false);
    this.loaderMessagingService.showPageLoader(false);
  }

  setSchoolYear(schoolYear: any): void {
		this.selectedSyId = 0;
		if(schoolYear != undefined || schoolYear != null) {
		  this.selectedSyId = schoolYear.id;
      this.schoolYear = schoolYear.schoolYear;
      if (this.filters.has(SY_CODE)) this.filters.delete(SY_CODE);
      if (this.filters.has(STATUS_FILTER)) this.filters.delete(STATUS_FILTER);
      if (this.filters.has(CURRICULUM_STATUS)) this.filters.delete(CURRICULUM_STATUS);
		  this.setFilterLabels();
		  this.search();
		} else {
		  this.crudService
		  .getById<any>(getActiveSchoolYearEndpoint)
		  .pipe(takeUntil(this.unsubscribe))
				.subscribe(response => {
					if (response) {
            this.schoolYear = response.schoolYear;
            this.selectedSyId = this.schoolYears.find(sy =>
            String(sy.schoolYear).trim() === this.schoolYear.trim()).id;
            
            if (this.filters.has(SY_CODE)) this.filters.delete(SY_CODE);
            if (this.filters.has(STATUS_FILTER)) this.filters.delete(STATUS_FILTER);
            if (this.filters.has(CURRICULUM_STATUS)) this.filters.delete(CURRICULUM_STATUS);
            this.setFilterLabels();
            this.search();
					}
			}, this.errorHandlerService.handleError);
		}
	}

  setFilterLabels(): void {

    this.filterLabels = [];
    
    this.filters.forEach((value, key) => {
      let filter = this.getOrderClassDataProperties.find(property => property.property === key);
      if(filter) {
        this.filterLabels.push(`${filter.label}: ${value}`);
      }
    });

    this.filters.set(SY_CODE, this.schoolYear);
    
    this.clearFilters = this.filters.size === 1;

    const index: number = this.filterLabels.findIndex(label => label.includes('School Year:'));
    if (index !== -1) this.filterLabels.splice(index, 1);

    this.filterLabels.splice(0, 0, `School Year: ${this.schoolYear}`);

  }

  onChangeFilterType(): void {
    this.isType1 = !this.isType1;
  }

  filterOnClick(): void {

    if (!this.isType1) {
      this.trigger = this.trigger === CLOSE ? OPEN : CLOSE;
    } else {
      this.trigger = CLOSE;
      let data = [];

      this.getOrderClassDataProperties.forEach(property => {

        if (property.filterable)
          data.push({
            label: property.label,
            prop: property.property,
            value: this.filters.get(property.property) || "",
          });

      });

      const modalStyle = { width: "30%" };
      const dialogRef = this.dialog.open(FilterModalComponent, { ...modalStyle, data });
  
      dialogRef.afterClosed().subscribe(filters => {
        if (filters) this.filterOnChange(filters);
      });
    }

  }

  filterOnChange(filters: Map<string, string>) {
    this.filters = new Map<string, string>(filters);
    this.setFilterLabels();
    this.pageIndex = 0;
    this.search();
  }
  
  onPageChange($event: any): void {
		this.pageIndex = $event.pageIndex;
		this.pageSize = $event.pageSize;
		this.search();
  }

  onClearFilters(): void {
    this.clearFilters = true;
    this.keyword = '';
    this.filters.clear();
    this.filterOnChange(new Map<string, string>());
  }

  getSchoolYears(): void {

    this.crudService
      .getById<any>(getAllSchoolYearsEndpoint)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(response => {
        this.schoolYears = response;
        this.schoolYears.sort((a, b) => b.schoolYear.localeCompare(a.schoolYear));
        this.initParams();
      }, this.errorHandlerService.handleError);

  }

  initParams(): void {
    this.sortedBy = this.orderClassDataProperties[1].property;
    this.sortOrder = ASC; 
    let yearNow: string = moment((new Date()).valueOf()).format(fullYearFormat);
    this.setSchoolYear(null);
  }

  private setReferencePeriodTypes(): void {

    this.crudService
      .getById<any>(getActivePeriodStatusEndpoint)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(response => {

        if (response) {
          this.reference.periodTypes = response;
          this.getSchoolYears();
        }

      }, this.errorHandlerService.handleError);

  }

  private setReferenceGradeLevels(): void {

    this.crudService
      .getById<any>(allGradeLevelsEndpoint)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(response => {

        if (response) {
          this.reference.gradeLevels = response;
          this.setReferencePeriodTypes();
        }

      }, this.errorHandlerService.handleError);

  }

  private setReferenceSubjects(): void {

    this.crudService
      .getById<any>(getClassSubjectsEndpoint)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(response => {

        if (response) {
          this.reference.subjects = response;
          this.setReferenceGradeLevels();
        }

      }, this.errorHandlerService.handleError);

  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

}
