import {
  CURRICULUM_CREATE_PATH,
  CLASSES_PATH,
  BUNDLES_PATH,
  CURRICULUMS_PATH,
  RESOURCES_PATH,
  ORDERS_PATH,
  UPLOADS_PATH,
  HQTS_PATH,
  MODEL_PATH,
  TASKS_PATH,
  ASSET_PATH,
  STUDENTS_PATH
} from "../routes.constant";

export const ADMIN: string = "ROLE_COS_ADMIN";
export const SUPERVISOR: string = "ROLE_COS_SUPERVISOR";
export const SPECIALIST: string = "ROLE_COS_SPECIALIST";
export const TEACHER: string = "ROLE_TEACHER";

export const CURRICULUM_CREATE_ROUTE: any[] = [
  {
    path: CURRICULUM_CREATE_PATH,
    name: "Curriculum",
    roles: [ADMIN]
  }
];

export const homeRoutingPermissions: any[] = [
  {
    path: CURRICULUMS_PATH,
    name: "Curriculum",
    roles: [ADMIN]
  },
  {
    path: CLASSES_PATH,
    name: "Class",
    roles: [ADMIN]
  },
  {
    path: BUNDLES_PATH,
    name: "Bundle",
    roles: [ADMIN]
  },
  {
    path: RESOURCES_PATH,
    name: "Resource",
    roles: [ADMIN]
  },
  {
    path: ORDERS_PATH,
    name: "Order",
    roles: [ADMIN, SUPERVISOR, SPECIALIST, TEACHER]
  },
  {
    path: TASKS_PATH,
    name: "Task",
    roles: [ADMIN, SUPERVISOR, SPECIALIST]
  },
  {
    path: STUDENTS_PATH,
    name: "Student",
    roles: [ADMIN, SUPERVISOR, SPECIALIST, TEACHER]
  }

];

export const settingsRoutingPermissions: any[] = [
  {
    path: CURRICULUMS_PATH,
    name: "Home",
    roles: [ADMIN]
  },
  {
    path: UPLOADS_PATH,
    name: "Upload",
    roles: [ADMIN]
  },
  {
    path: HQTS_PATH,
    name: "HQT",
    roles: [ADMIN]
  },
  {
    path: MODEL_PATH,
    name: "Model",
    roles: [ADMIN]
  },
  {
    path: ASSET_PATH,
    name: "Asset",
    roles: [ADMIN]
  }
];
