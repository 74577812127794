import { NgModule } from "@angular/core";
import { SharedModule } from "src/app/shared/shared.module";
import { OrderRoutingModule } from "./route/order-routing.module";
import { OrderCreateComponent } from "./order-create/order-create.component";
import { OrdersComponent } from "./orders/orders.component";
import { OrderListComponent } from "./orders/order-list/order-list.component";
import { OrderStatisticsComponent } from "./orders/order-statistics/order-statistics.component";
import { OrderClassResourceComponent } from "./order-create/order-class-resource/order-class-resource.component";
import { OrderCreateLabelsComponent } from "./order-create/order-create-labels/order-create-labels.component";
import { OrderShippingDetailsComponent } from "./order-create/order-shipping-details/order-shipping-details.component";
import { OrderShippingDetailsListComponent } from "./shared/order-shipping-details-list/order-shipping-details-list.component";
import { OrderStudentSelectionComponent } from "./order-create/order-student-selection/order-student-selection.component";
import { OrderStudentSelectionListComponent } from "./order-create/order-student-selection/order-student-selection-list/order-student-selection-list.component";
import { OrderSummaryComponent } from "./order-create/order-summary/order-summary.component";
import { TasksComponent } from "./task/tasks/tasks.component";
import { TaskListComponent } from "./task/tasks/task-list/task-list.component";
import { TaskViewComponent } from "./task/task-view/task-view.component";
import { TaskStatisticsComponent } from "./task/tasks/task-statistics/task-statistics.component";
import { OrderResourceSelectionComponent } from "./order-create/order-resource-selection/order-resource-selection.component";
import { OrderResourceSelectionListComponent } from "./order-create/order-resource-selection/order-resource-selection-list/order-resource-selection-list.component";
import { OrderResourceModalFormComponent } from "./order-create/order-resource-modal-form/order-resource-modal-form.component";
import { OrderStudentDetailsComponent } from "./shared/order-student-details/order-student-details.component";
import { OrderClassResourceListComponent } from "./order-create/order-class-resource/order-class-resource-list/order-class-resource-list.component";
import { ShippingDetailsComponent } from "./shared/shipping-details/shipping-details.component";
import { OrderResourceDetailsComponent } from './order-create/order-resource-selection/order-resource-details/order-resource-details.component';
import { OrderShippingDialogComponent } from "./shared/order-shipping-dialog/order-shipping-dialog.component";
import { OrderClassResourceDialogComponent } from './order-create/order-class-resource/order-class-resource-dialog/order-class-resource-dialog.component';
import { OrderClassResourceAssociatesListComponent } from './order-create/order-class-resource/order-class-resource-associates-list/order-class-resource-associates-list.component';
import { OrderDetailsComponent } from './shared/order-details/order-details.component';
import { OrderItemsClassesComponent } from './shared/order-items-classes/order-items-classes.component';
import { OrderViewComponent } from "./orders/order-view/order-view.component";
import { OrderItemsBundlesComponent } from './shared/order-items-bundles/order-items-bundles.component';
import { OrderItemBooksComponent } from './shared/order-item-books/order-item-books.component';
import { OrderItemAdjustmentDialogComponent } from "./shared/order-item-adjustment-dialog/order-item-adjustment-dialog.component";
import { OldOrderItemRefundDialogComponent } from "../order/orders/old/order-item-refund-dialog/order-item-refund-dialog.component";
import { OrderItemRefundConfirmationDialogComponent } from "./shared/order-item-refund-confirmation-dialog/order-item-refund-confirmation-dialog.component";
import { OrderItemsDepositFeeComponent } from "./shared/order-items-deposit-fee/order-items-deposit-fee.component";
import { TxnHistoryComponent } from './shared/txn-history/txn-history.component';
import { OldOrderDetailsComponent } from './orders/old/order-details/order-details.component';
import { OldOrderItemsComponent } from './orders/old/order-items/order-items.component';
import { OldOrderStudentDetailsComponent } from './orders/old/order-student-details/order-student-details.component';
import { OldOrderViewComponent } from './orders/old/order-view/order-view.component';
import { WorkbooksListComponent } from "./shared/workbooks-list/workbooks-list.component";
import { OrderItemWithdrawDialogComponent } from './shared/order-item-withdraw-dialog/order-item-withdraw-dialog.component';

import { CardListComponent } from "./student/student-view/card-list/card-list.component";
import { StudentsComponent } from "./student/students/students.component";
import { StudentsListComponent } from "./student/students/students-list/students-list.component";
import { StudentViewComponent } from "./student/student-view/student-view.component";
import { OrderItemOnlineAccountsComponent } from "./shared/order-item-online-accounts/order-item-online-accounts.component";
import { OrderDepositViewComponent } from "./orders/old/order-deposit-view/order-deposit-view.component";
import { OrderItemsDepositComponent } from "./shared/order-items-deposit/order-items-deposit.component";
import { DownloadOnlineAccountsComponent } from "./task/download-online-accounts/download-online-accounts.component";
import { DownloadReportsComponent } from "./task/download-reports/download-reports.component";
import { DownloadCurriculumReportsComponent } from "./task/download-curriculum-reports/download-curriculum-reports.component";

@NgModule({
  imports: [
        SharedModule,
        OrderRoutingModule
    ],
    declarations: [
        OrdersComponent,
        OrderListComponent,
        OrderStatisticsComponent,
        OrderCreateComponent,
        OrderClassResourceComponent,
        OrderCreateLabelsComponent,
        OrderShippingDetailsComponent,
        OrderShippingDetailsListComponent,
        OrderStudentDetailsComponent,
        OrderStudentSelectionComponent,
        OrderStudentSelectionListComponent,
        OrderSummaryComponent,
        OrderStatisticsComponent,
        TasksComponent,
        TaskListComponent,
        TaskViewComponent,
        DownloadOnlineAccountsComponent,
        DownloadReportsComponent,
        DownloadCurriculumReportsComponent,
        TaskStatisticsComponent,
        OrderResourceSelectionComponent,
        OrderResourceSelectionListComponent,
        OrderResourceModalFormComponent,
        OrderClassResourceListComponent,
        ShippingDetailsComponent,
        OrderResourceDetailsComponent,
        OrderShippingDialogComponent,
        OrderClassResourceDialogComponent,
        OrderClassResourceAssociatesListComponent,
        OrderDetailsComponent,
        OrderItemsClassesComponent,
        OrderViewComponent,
        OrderItemsBundlesComponent,
        OrderItemBooksComponent,
        OrderItemOnlineAccountsComponent,
        OrderItemAdjustmentDialogComponent,
        OldOrderItemRefundDialogComponent,
        OrderItemRefundConfirmationDialogComponent,
        OrderItemWithdrawDialogComponent,
        OrderItemsDepositComponent,
        OrderItemsDepositFeeComponent,
        OrderDepositViewComponent,
        TxnHistoryComponent,
        OldOrderViewComponent,
        OldOrderDetailsComponent,
        OldOrderItemsComponent,
        OldOrderStudentDetailsComponent,
        WorkbooksListComponent,
        StudentsComponent,
        StudentsListComponent,
        StudentViewComponent,
        CardListComponent,
    ],
    exports: [
        OrdersComponent,
        OrderListComponent,
        OrderStatisticsComponent,
        OrderCreateComponent,
        OrderClassResourceComponent,
        OrderCreateLabelsComponent,
        OrderShippingDetailsComponent,
        OrderShippingDetailsListComponent,
        OrderStudentDetailsComponent,
        OrderStudentSelectionComponent,
        OrderStudentSelectionListComponent,
        OrderSummaryComponent,
        OrderStatisticsComponent,
        TasksComponent,
        TaskListComponent,
        TaskViewComponent,
        TaskStatisticsComponent,
        OrderResourceSelectionListComponent,
        ShippingDetailsComponent,
        OrderViewComponent,
        OldOrderViewComponent,
        OldOrderDetailsComponent,
        OldOrderItemsComponent,
        OldOrderStudentDetailsComponent,
        StudentsComponent,
        StudentsListComponent,
        StudentViewComponent,
        CardListComponent,
    ],
    entryComponents: [
        OrderResourceSelectionComponent,
        OrderResourceModalFormComponent,
        OrderResourceDetailsComponent,
        OrderShippingDialogComponent,
        OrderClassResourceDialogComponent,
        OrderItemAdjustmentDialogComponent,
        OldOrderItemRefundDialogComponent,
        OrderItemRefundConfirmationDialogComponent,
        OrderItemWithdrawDialogComponent,
    ],
})
export class OrderModule {}
