import { Component, OnInit, OnDestroy, Output, EventEmitter, Input } from '@angular/core';
import { Subject } from 'rxjs';
import { ErrorHandlerService } from 'src/app/core/services/util/error-handler.service';
import { MatDialog } from '@angular/material';
import { takeUntil } from 'rxjs/operators';
import { SAVE, PREV } from 'src/app/core/constants/configuration/common.constant';
import { ConfirmDialogComponent } from '../../../confirm-dialog/confirm-dialog.component';
import { saveModelConfirmation } from 'src/app/core/constants/message.constant';
import { Model } from 'src/app/core/models/model.model';

@Component({
	selector: 'model-summary',
	templateUrl: './model-summary.component.html',
	styleUrls: ['./model-summary.component.scss']
})
export class ModelSummaryComponent implements OnInit, OnDestroy {

	@Output() changePage = new EventEmitter<any>();
	@Input() model: Model;
	@Input() referenceValues: any;
	@Input() isFirst: boolean;
	@Input() isLast: boolean;

	unsubscribe: Subject<any> = new Subject();

	constructor(
		private errorHandlerService: ErrorHandlerService,
		public dialog: MatDialog,
	) { }

	ngOnInit() {
	}

	ngOnDestroy(): void {
		this.unsubscribe.next();
		this.unsubscribe.complete();
	}

	onClick = (label: string): void => {

		if (label === SAVE) {

			const dialogRef = this.dialog.open(ConfirmDialogComponent);
			dialogRef.componentInstance.confirmMessage = saveModelConfirmation;
			dialogRef.afterClosed()
				.pipe(takeUntil(this.unsubscribe))
				.subscribe(result => {
					if (result) {
						this.changePage.emit(SAVE);
					}
				}, this.errorHandlerService.handleError);

		}

		if (label === PREV) {
			this.changePage.emit(PREV);
		}

	}
}


