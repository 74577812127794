export class PaginationDto {
    previousPageIndex: number;
	pageIndex: number;
	pageSize: number;
	length: string;

	constructor(
		previousPageIndex: number,
		pageIndex: number,
		pageSize: number,
		length: string,
	) {
		this.previousPageIndex = previousPageIndex;
		this.pageIndex = pageIndex;
		this.pageSize = pageSize;
		this.length = length
	}
}