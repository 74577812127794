import { Component, OnInit } from '@angular/core';
import { HeaderMessagingService } from 'src/app/core/services/messaging/header-messaging.service';
import { assetModuleName } from 'src/app/core/constants/configuration/asset-constants.config';
import { CREATE_MODULE_NAME } from 'src/app/core/constants/configuration/common.constant';
import { allAssetsEndpoint } from 'src/app/core/constants/endpoints.constant';
import { takeUntil } from 'rxjs/operators';
import { ASSET_PATH } from 'src/app/core/constants/routes.constant';
import { DialogBoxComponent } from 'src/app/shared/components/dialog-box/dialog-box.component';
import { dialogBoxSuccessTitle, createdSuccessAsset } from 'src/app/core/constants/message.constant';
import { CrudService } from 'src/app/core/services/data/crud.service';
import { ErrorHandlerService } from 'src/app/core/services/util/error-handler.service';
import { LoaderMessagingService } from 'src/app/core/services/messaging/loader-messaging.service';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { Asset } from 'src/app/core/models/asset.model';
import { settingsRoutingPermissions } from "src/app/core/constants/configuration/role-constants.config";
import { UserService } from "src/app/core/services/util/user.service";

@Component({
	selector: 'app-asset-create',
	templateUrl: './asset-create.component.html',
	styleUrls: ['./asset-create.component.scss']
})
export class AssetCreateComponent implements OnInit {

	unsubscribe: Subject<any> = new Subject();
	statusList: any;

	constructor(
		private router: Router,
		private crudService: CrudService,
		private headerMessagingService: HeaderMessagingService,
		private errorHandlerService: ErrorHandlerService,
		private dialog: MatDialog,
		private loaderMessagingService: LoaderMessagingService,
		private userService: UserService,
	) {
		this.loaderMessagingService.showPageLoader(true);
		this.userService.checkRolePermission(ASSET_PATH, settingsRoutingPermissions);
		this.headerMessagingService.setHeader(assetModuleName, CREATE_MODULE_NAME, false, null);
	}

	ngOnInit() {
	}

	ngOnDestroy(): void {
		this.unsubscribe.next();
		this.unsubscribe.complete();
	}

	onSaveAsset($event: Asset) {
		this.loaderMessagingService.showPageLoader(true);
		this.transformStatus($event);
	}

	saveAsset(asset) {
		this.crudService
			.add<Asset>(allAssetsEndpoint, asset)
			.pipe(takeUntil(this.unsubscribe))
			.subscribe((response) => {
				this.router.navigate([ASSET_PATH, response.id]);
				const dialogRef = this.dialog.open(DialogBoxComponent);
				dialogRef.componentInstance.dialogTitle = dialogBoxSuccessTitle;
				dialogRef.componentInstance.contentMessage = createdSuccessAsset;
			}, this.errorHandlerService.handleError, this.handleCompletion);
	}

	transformStatus(asset) {
		this.crudService
			.getAll<any>(
				allAssetsEndpoint.concat("/status"))
			.pipe(takeUntil(this.unsubscribe))
			.subscribe((response: any) => {
				if (response) {
					response.forEach(status => {
						if(status.name === asset.status) {
							asset.status = status.code;
						}
					});
					this.saveAsset(asset);
				}
			},
				this.errorHandlerService.handleError,
			);
	}

	handleCompletion = (): void => {
		this.loaderMessagingService.showPageLoader(false);
	}

}
