import { ADMIN } from "./role-constants.config";

export const navTabsSettings: any[] = [
  {
    name: "Home",
    roles: [ADMIN],
  },
  {
    name: "Upload",
    roles: [ADMIN],
  },
  {
    name: "HQT",
    roles: [ADMIN],
  },
  {
    name: "Model",
    roles: [ADMIN],
  },
  {
    name: "Asset",
    roles: [ADMIN],
  }
];
export const navSideUpload: any[] = [
  {
    name: "Curriculum",
    roles: [ADMIN],
  },
  {
    name: "Class",
    roles: [ADMIN],
  },
  {
    name: "Bundle",
    roles: [ADMIN],
  },
  {
    name: "Model",
    roles: [ADMIN],
  },
  {
    name: "Asset",
    roles: [ADMIN],
  },
  {
    name: "Resource",
    roles: [ADMIN],
  },
];
export const navTabsHome: string[] = [
  "Curriculum",
  "Class",
  "Bundle",
  "Resource"
];
export const homeModuleName: string = "HOME";
export const settingsModuleName: string = "SETTINGS";

export const CREATE_MODULE_NAME = "Create";
export const UPDATE_MODULE_NAME = "Update";
export const VIEW_MODULE_NAME = "View";

export const SY_CODE = "syCode";
export const STATUS_FILTER = "status";
export const FILTER_CURRICULUM_NAME = 'curriculumName';
export const FILTER_NAME = 'name';
export const FILTER_SUBJECT = 'subject';
export const FILTER_FROM_GRADE_LEVEL = 'fromGradeLevel';
export const FILTER_TO_GRADE_LEVEL = 'toGradeLevel';
export const PAGE_SIZE = "Size";
export const PAGE_INDEX = "Page";
export const CURRICULUM_STATUS = "curriculumStatus";
export const STUDENT_SCOPE = "scope";
export const pageSizeOptions: number[] = [10, 25, 50, 100];
export const tableDateFormat: string = "MMM DD, YYYY";
export const dateSearchFormat: string = "MM-DD-YYYY";
export const fullYearFormat: string = "YYYY";
export const tableDateFormatWithTime: string = "MMM DD, YYYY hh:mm:ss A";
export const regexAlphanumericFormat: RegExp =
  new RegExp("^[a-zA-Z0-9_!#\\$%&'\\*\\+/=\\?\\^_`{\\|}~\\(\\)@\\.: ’ñÑ\\-]*$");
export const regexNumericFormat: RegExp = new RegExp("^(0|[1-9][0-9]*)$");
export const regexFloatingNumericFormat: RegExp = new RegExp("^[0-9]+(\.[0-9]{1,2})?$");
export const ASC: string = "asc";
export const DESC: string = "desc";
export const NEXT: string = "NEXT";
export const PREV: string = "PREV";
export const SAVE: string = "SAVE";
export const CLOSE: string = "CLOSE";
export const OPEN: string = "OPEN";
export const ERROR: string = "ERROR";
export const SUCCESS: string = "SUCCESS";
export const ACTIVE: string = "ACTIVE";
export const INACTIVE: string = "INACTIVE";
export const STATUS: string = "STATUS";
export const STATUS_1: string = "STATUS_1";
export const STATUS_2: string = "STATUS_2";
export const PER_YEAR: string = "PERIOD_TYPE_1";
export const FIRST_SEM: string = "PERIOD_TYPE_2";
export const SECOND_SEM: string = "PERIOD_TYPE_3";
export const PERIOD_TYPE_1: string = "PERIOD_TYPE_1";
export const PERIOD_TYPE_2: string = "PERIOD_TYPE_2";
export const AVAILABLE: string = "Available";
export const CHECKED_OUT: string = "Checked Out";
export const LOST: string = "Lost"
export const FOR_DISPOSAL: string = "For Disposal";
export const ASSET_STATUSES: string[] = [
"Available",
"Checked Out",
"Lost",
"For Disposal"
];
export const GRADE_LEVELS: string[] = [
  "-2",
  "0",
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
  "10",
  "11",
  "12"
];

export const ERROR_WORK_TEXTBOOK_INVALID_LENGTH = 'Cannot print shipping details without any Workbook/Textbooks selected.';