import { Component, OnInit } from "@angular/core";
import { HeaderMessagingService } from "src/app/core/services/messaging/header-messaging.service";
import { BUNDLE } from "src/app/core/constants/configuration/bundle-constants.config";
import { CREATE_MODULE_NAME } from "src/app/core/constants/configuration/common.constant";
import { BUNDLES_PATH } from 'src/app/core/constants/routes.constant';
import { UserService } from 'src/app/core/services/util/user.service';
import { homeRoutingPermissions } from 'src/app/core/constants/configuration/role-constants.config';

@Component({
  selector: "bundle-create",
  templateUrl: "./bundle-create.component.html",
  styleUrls: ["./bundle-create.component.scss"]
})
export class BundleCreateComponent implements OnInit {

  constructor(
    private headerMessagingService: HeaderMessagingService,
    private userService: UserService,
	) {
		this.userService.checkRolePermission(BUNDLES_PATH, homeRoutingPermissions);
    this.headerMessagingService.setHeader(BUNDLE, CREATE_MODULE_NAME, false, null);
  }

  ngOnInit() {}
}
