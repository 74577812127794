import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { baseServerUri } from "../../constants/configuration/config.constant";
import { Observable } from "rxjs";
import { Page } from "../../models/page.model";

@Injectable({ providedIn: 'root' })
export class CrudService {

  constructor(private http: HttpClient) {}

  getAll<T>(endpoint: string): Observable<Page<T>> {
    return this.http.get<Page<T>>(baseServerUri.concat(endpoint));
  }

  // can also be used to retrieve all data by list
  getAllBy<T>(endpoint: string): Observable<T[]> {
    return this.http.get<T[]>(baseServerUri.concat(endpoint));
  }

  getById<T>(endpoint: string): Observable<T> {
    return this.http.get<T>(baseServerUri.concat(endpoint));
  }
  
  add<T>(endpoint: string, obj: T): Observable<T> {
    return this.http.post<T>(baseServerUri.concat(endpoint), obj);
  }

  edit<T>(endpoint: string, obj: T): Observable<void> {
    return this.http.put<void>(baseServerUri.concat(endpoint), obj);
  }

  editWithResponse<T>(endpoint: string, obj: T): Observable<any> {
    return this.http.put<void>(baseServerUri.concat(endpoint), obj);
  }

  delete(endpoint: string): Observable<any> {
    const responseType: Object = { responseType: 'text' };
    return this.http.delete<any>(baseServerUri.concat(endpoint), responseType);
  }

}
