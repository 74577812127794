import { Component, OnInit, Input, OnDestroy } from "@angular/core";
import { SAVE } from "src/app/core/constants/configuration/common.constant";
import { Bundle } from "src/app/core/models/bundle.model";
import { BundleHelper } from "src/app/core/services/util/bundle.helper";
import { CrudService } from "src/app/core/services/data/crud.service";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import {
  allBundlesEndpoint,
  getSchoolYearsEndpoint,
  getBundleTypesEndpoint,
  getStatusEndpoint,
  getActivePeriodStatusEndpoint
} from "src/app/core/constants/endpoints.constant";
import { BundleMainForm } from "src/app/core/models/form/bundle-main-form.model";
import { BundleCurriculumForm } from "src/app/core/models/form/bundle-curriculum-form.model";
import { baseServerUri } from "src/app/core/constants/configuration/config.constant";
import { MatDialog } from "@angular/material";
import { Router } from "@angular/router";
import { DialogBoxComponent } from "../../dialog-box/dialog-box.component";
import {
  createSuccessBundle,
  dialogBoxSuccessTitle,
  updateSuccessBundle
} from "src/app/core/constants/message.constant";
import { ErrorHandlerService } from "src/app/core/services/util/error-handler.service";
import { LoaderMessagingService } from "src/app/core/services/messaging/loader-messaging.service";
import { FormUtil } from "src/app/core/services/util/form.util";
import { PageForm } from "src/app/core/models/form/page-form.model";
import { sideNavLabelBundle } from "src/app/core/constants/configuration/bundle-constants.config";
import { BUNDLES_PATH } from "src/app/core/constants/routes.constant";

@Component({
  selector: "app-bundle-form",
  templateUrl: "./bundle-form.component.html",
  styleUrls: ["./bundle-form.component.scss"]
})
export class BundleFormComponent implements OnInit, OnDestroy {

  @Input() forUpdate: boolean;
  @Input() bundleId: number;

  unsubscribe: Subject<any> = new Subject();
  activeIndex: number = 0;
  modifiedBundle: Bundle;
  bundleFormValues: BundleMainForm;
  bundleCurriculumFormValues: BundleCurriculumForm;
  sideNavLabels: string[] = [];
  isFirst: boolean = true;
  isLast: boolean = false;
  bundle: Bundle;
  schoolYears: any = [];
  bundleTypes: any = [];
  status: any[];
  activePeriods: any[];

  constructor(
    private bundleHelper: BundleHelper,
    private crudService: CrudService,
    private errorHandlerService: ErrorHandlerService,
    private loaderMessagingService: LoaderMessagingService,
    private formUtil: FormUtil,
    private dialog: MatDialog,
    private router: Router
  ) {
    this.sideNavLabels = sideNavLabelBundle;
    this.getSchoolYears();
    this.getBundleTypes();
    this.getStatus();
    this.getActivePeriods();
  }

  ngOnInit() {
    this.loaderMessagingService.showPageLoader(true);
    this.isFirst = true;
    this.isLast = false;
    this.initBundleForms();
    this.loaderMessagingService.showPageLoader(false);
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  onChangePage(label: string): void {

    let pageForm: PageForm = this.formUtil.setPageForm(label, this.sideNavLabels.length, this.activeIndex);

    this.activeIndex = pageForm.activeIndex;
    this.isFirst = pageForm.isFirst;
    this.isLast = pageForm.isLast;

    if (label === SAVE) {
      this.forUpdate ? this.update() : this.save();
    }

  }

  onFormSubmit($event: any, form: string): void {
    switch (form) {
      case "MAIN": {
        this.bundleFormValues = $event;
        break;
      }

      case "CURRICULUM": {
        this.bundleCurriculumFormValues = $event;
        this.modifiedBundle = this.bundleHelper.setBundleModel(
          this.bundleFormValues,
          this.bundleCurriculumFormValues,
          this.forUpdate,
          this.bundle
        );
        break;
      }

      default:
        break;
    }
  }

  onRawSend($event: BundleCurriculumForm): void {
    this.bundleCurriculumFormValues = $event;
  }

  save(): void {
    this.loaderMessagingService.showPageLoader(true);
    this.crudService
      .add<Bundle>(
        allBundlesEndpoint,
        this.modifiedBundle
      )
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(response => {
        this.router.navigate([BUNDLES_PATH, response.id]);
        const dialogRef = this.dialog.open(DialogBoxComponent);
        dialogRef.componentInstance.dialogTitle = dialogBoxSuccessTitle;
        dialogRef.componentInstance.contentMessage = createSuccessBundle;
      },
      this.errorHandlerService.handleError,
      this.handleCompletion);
  }

  update(): void {
    this.loaderMessagingService.showPageLoader(true);
    this.crudService
      .edit<Bundle>(
        allBundlesEndpoint.concat(`/${this.modifiedBundle.id}`),
        this.modifiedBundle
      )
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(response => {
        this.router.navigate([BUNDLES_PATH, this.modifiedBundle.id]);
        const dialogRef = this.dialog.open(DialogBoxComponent);
        dialogRef.componentInstance.dialogTitle = dialogBoxSuccessTitle;
        dialogRef.componentInstance.contentMessage = updateSuccessBundle;
      },
      this.errorHandlerService.handleError,
      this.handleCompletion);
  }

  initBundleForms(): void {
    if (this.forUpdate) {
      this.crudService
        .getById<Bundle>(allBundlesEndpoint.concat(`/${this.bundleId}`))
        .pipe(takeUntil(this.unsubscribe))
        .subscribe(data => {
          if (data) {
            this.bundle = data;
            this.bundleFormValues = this.bundleHelper.setBundleMainFormValues(
              data
            );
            this.bundleCurriculumFormValues = this.bundleHelper.setBundleCurriculumFormValues(
              data
            );
          }
        },
        this.errorHandlerService.handleError);
    }
  }

  getSchoolYears(): void {
    this.crudService
      .getById<any>(getSchoolYearsEndpoint)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(response => {
        this.schoolYears = response;
        this.schoolYears.sort((a, b) =>
          a.schoolYear.localeCompare(b.schoolYear)
        );
      }, this.errorHandlerService.handleError);
  }

  getBundleTypes(): void {
    this.crudService
      .getById<any>(getBundleTypesEndpoint)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(response => {
        this.bundleTypes = response;
        this.bundleTypes.sort((a, b) => a.code.localeCompare(b.code));
      }, this.errorHandlerService.handleError);
  }

  getStatus(): void {
    this.crudService
      .getById<any>(getStatusEndpoint)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(response => {
        this.status = response;
        this.status.sort((a, b) => a.code.localeCompare(b.code));
      }, this.errorHandlerService.handleError);
  }

  handleCompletion = (): void => {
    this.loaderMessagingService.showPageLoader(false);
  }

  getActivePeriods(): void {
    this.crudService
    .getById<any>(getActivePeriodStatusEndpoint)
    .pipe(takeUntil(this.unsubscribe))
    .subscribe(
      response => {
        this.activePeriods = response;
        this.activePeriods.sort((a,b) => a.code.localeCompare(b.code));
      }, this.errorHandlerService.handleError);
  }
}
