import { Component, OnInit, EventEmitter, Input, Output } from '@angular/core';
import { ResourceGroupForm } from 'src/app/core/models/form/resource-group-form-model';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ErrorHandlerService } from 'src/app/core/services/util/error-handler.service';
import { CrudService } from 'src/app/core/services/data/crud.service';
import { allCurriculumsEndpoint, allClassesEndpoint } from 'src/app/core/constants/endpoints.constant';
import { LoaderMessagingService } from 'src/app/core/services/messaging/loader-messaging.service';
import { Curriculum } from 'src/app/core/models/curriculum.model';
import { Class } from 'src/app/core/models/class.model';
import { BUNDLE_GROUP_1, BUNDLE_GROUP_2 } from 'src/app/core/constants/configuration/bundle-constants.config';

@Component({
  selector: 'resource-group-form',
  templateUrl: './resource-group-form.component.html',
  styleUrls: ['./resource-group-form.component.scss']
})
export class ResourceGroupFormComponent implements OnInit {

  @Output() formSubmit: EventEmitter<ResourceGroupForm[]> = new EventEmitter();
  @Output() changePage: EventEmitter<any> = new EventEmitter();
  @Input() resourceGroupFormValues: ResourceGroupForm[];
  @Input() resourceGroups: any[];
  @Input() forUpdate: boolean;
  @Input() isFirst: boolean;
  @Input() isLast: boolean;
  @Input() resourceType: string;

  unsubscribe: Subject<any> = new Subject();
  groups: ResourceGroupForm[] = [];
  originalCurriculums: any[] = [];
  originalClasses: any[] = [];
  modifiedCurriculums: any[] = [];
  modifiedClasses: any[] = [];
  selectables: any[] = [];
  isProcessing: boolean = false;
  fieldValues: any[] = [];

  constructor(
    private errorHandlerService: ErrorHandlerService,
    private crudService: CrudService,
    private loaderMessagingService: LoaderMessagingService,
    ) {
      loaderMessagingService.showPageLoader(true);
  }

  ngOnInit() {
    
    if (this.forUpdate || (this.resourceGroupFormValues && this.resourceGroupFormValues.length > 0)) {
      this.groups = Object.assign([], this.resourceGroupFormValues);
      this.fieldValues = this.groups.map(group => { return { name: group.name, hasError: false } });
    }

    this.fetchItems();

  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  handleCompletion = (): void => {
    this.isProcessing = false;
    this.loaderMessagingService.showPageLoader(false);
  };

  updateItems(): void {

    this.groups.forEach(group => {

      if (group.type === BUNDLE_GROUP_1) {
        const item: any = this.modifiedCurriculums.find(item => String(item.code) === group.code);
        if (item) {
          group.name = item.name;
          this.removeItem(item.code, BUNDLE_GROUP_1);
        }
      } else {
        const item: any = this.modifiedClasses.find(item => String(item.code) === group.code);
        if (item) {
          group.name = item.name;
          this.removeItem(item.code, BUNDLE_GROUP_2);
        }
      }

    });

  }

  onChangePage(label: string): void {
    this.formSubmit.emit(Object.assign([], this.groups.filter(group => group.code.trim() !== "" && group.disabled)));
    this.changePage.emit(label);
  }

  fetchItems(): void {
    this.isProcessing = true;
    this.getCurriculums();
  }

  onRequiredChange(isRequired: boolean, index: number): void {
    this.groups[index].isRequired = isRequired;
  }

  addEntry(): void {
    this.groups.push({
      type: this.modifiedCurriculums.length > 0 ? this.resourceGroups[0].code : this.resourceGroups[1].code,
      isRequired: false,
      code: "",
      name: "",
      disabled: false
    });
  }

  removeEntry(index: number): void {
    const group: ResourceGroupForm = this.groups[index];
    this.addItem(group.code, group.type);
    this.groups.splice(index, 1);
  }

  onChangeType(groupType: string, index: number): void {
    this.selectables = [];
    this.groups[index].type = groupType;
  }

  getGroupTypeName(type: string) {
    return this.resourceGroups.find(resourceGroup => type === resourceGroup.code).name;
  }

  onNameSelect(selected: any, index: number): void {
    this.groups[index].code = selected.code;
    this.groups[index].name = selected.name;
    this.fieldValues[index] = { name: selected.name, hasError: false };

    if (this.isCurriculum(this.groups[index].type)) {
      this.selectables = this.modifiedCurriculums.filter(item => String(item.code) !== String(selected.code));
    } else {
      this.selectables = this.modifiedClasses.filter(item => String(item.code) !== String(selected.code));
    }

  }

  onNameChange(name: string, index: number): void {
    this.fieldValues[index] = {
      name,
      hasError: name.length > 0 &&
        String(this.groups[index].name + ' (' + this.groups[index].code + ')') !== name,
    };
    if (name.trim().length === 0) return;
    const items: any [] = Object.assign([], this.isCurriculum(this.groups[index].type) ? this.modifiedCurriculums : this.modifiedClasses);
    this.selectables = items.filter(item => String(item.name).toLowerCase().includes(name.toLowerCase()) || String(item.code).includes(name));
  }

  get disableNextButton(): boolean {
    let hasErrors = this.fieldValues.filter(value => value && value.hasError === true).length > 0;
    let hasNoFieldValues = this.fieldValues && this.fieldValues.length > 0 ?
      this.fieldValues.filter(values => values.name.length > 0).length === 0
      : true;
    let hasNoGroup = this.groups && this.groups.length > 0 ?
      this.groups.filter(group => group.disabled).length === 0
      : true;
    return hasErrors || hasNoFieldValues || hasNoGroup;
  }

  onSave(index: number): void {
    this.selectables = [];
    const group: ResourceGroupForm = this.groups[index];
    group.disabled = true;
    this.removeItem(group.code, group.type);
  }

  addItem(code: string, type: string): void {
    if (this.isCurriculum(type)) {
      const curriculum: any = this.modifiedCurriculums.find(item => String(item.code) === code);
      if (!curriculum) {
        this.modifiedCurriculums.push(this.originalCurriculums.find(item => String(item.code) === code));
      }
    } else {
      const clazz: any = this.modifiedClasses.find(item => String(item.code) === code);
      if (!clazz) {
        this.modifiedClasses.push(this.originalClasses.find(item => String(item.code) === code));
      }
    }
  }

  removeItem(code: string, type: string): void {
    if (this.isCurriculum(type)) {
      const item = this.modifiedCurriculums.find(item => String(item.code) === code);
      if (item) this.modifiedCurriculums.splice(this.modifiedCurriculums.indexOf(item), 1);
    } else {
      const item = this.modifiedClasses.find(item => String(item.code) === code);
      if (item) this.modifiedClasses.splice(this.modifiedClasses.indexOf(item), 1);
    }

  }

  onEdit(index: number): void {
    this.groups[index].disabled = false;
  }

  private isCurriculum(type: string): boolean {
    return type === BUNDLE_GROUP_1;
  }

  // ALL API CALLS ARE DEFINED BELOW
  private getCurriculums(): void {

    this.crudService
      .getAllBy<Curriculum>(`${allCurriculumsEndpoint}/all`)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(response => {
        response.map(curriculum => this.originalCurriculums.push({ code: curriculum.code, name: curriculum.name }));
        this.modifiedCurriculums = Object.assign([], this.originalCurriculums);
        this.getClasses();
      }, this.errorHandlerService.handleError);

  }

  private getClasses(): void { 

    this.crudService
      .getAllBy<Class>(`${allClassesEndpoint}/all`)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(response => {
        response.map(clazz => this.originalClasses.push({ code: clazz.code, name: clazz.name }));
        this.modifiedClasses = Object.assign([], this.originalClasses);
        if (this.forUpdate || this.groups.length > 0) this.updateItems();
        else { this.addEntry(); }
      }, this.errorHandlerService.handleError, this.handleCompletion);

  }

}
