import { Component, OnInit, Output, EventEmitter, Input, ViewChild } from '@angular/core';
import { MatMenu } from '@angular/material';


@Component({
  selector: 'app-floating-dropdown',
  templateUrl: './floating-dropdown.component.html',
  styleUrls: ['./floating-dropdown.component.scss'],
  exportAs: "menuInOtherComponent"
})
export class FloatingDropdownComponent implements OnInit {
  @ViewChild(MatMenu) menu: MatMenu;
  @Output() rotate = new EventEmitter();
  @Output() onClick = new EventEmitter();

  @Input()
  choices: string[];
  
  constructor() {
  }

  ngOnInit() {
  }

  onFocused(status) {
    this.rotate.emit(status);
  }

  onClicked(item: string) {
    this.onClick.emit(item)
  }

}
