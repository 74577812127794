import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest } from '@angular/common/http';
import { baseServerUri } from '../../constants/configuration/config.constant';
import { uploadStatusEndpoint } from '../../constants/endpoints.constant';

@Injectable({
    providedIn: 'root'
})
export class UploadStatusService {
    constructor(private httpClient: HttpClient) { }

    checkUploadStatus() {
        const req = new HttpRequest('GET', baseServerUri.concat(uploadStatusEndpoint), {
            responseType: 'json',
        });

        return this.httpClient.request(req);
    }
}
